import ArticlesService from "../services/articles.service";
import {
  REQUEST_FAILURE,
  REQUEST_PENDING,
  REQUEST_SUCCESS,
  REQUEST_UNDEFINED,
} from "../shared/statuses";

const initialState = {
  allArticles: [],
  getArticlesStatus: REQUEST_UNDEFINED,
  getArticlesSupplierStatus: REQUEST_UNDEFINED,
  allArticlesSupplier: [],
  createProductArticleStatus: REQUEST_UNDEFINED,
  allProducts: [],
  allProductsCount: 0,
  getProductsStatus: REQUEST_UNDEFINED,
  allArticlesCategories: [],
  allArticlesCategoriesStatus: REQUEST_UNDEFINED,
  totalArticlesCount: 0,
  toggleFavoriteArticleStatus: REQUEST_UNDEFINED,
  addNewArticlePendingStatus: REQUEST_UNDEFINED,
  getAllCustomArticlesStatus: REQUEST_UNDEFINED,
  allCustomArticles: [],
  allCustomArticlesCount: 0,
  deleteCustomArticleStatus: REQUEST_UNDEFINED,
  toggleFavoriteCustomArticleStatus: REQUEST_UNDEFINED,
  toggleCustomArticleFavoriteStatus: REQUEST_UNDEFINED,
  editCustomArticleStatus: REQUEST_UNDEFINED,
  createOrUpdateArticleStatus: REQUEST_UNDEFINED,
  deleteArticleStatus: REQUEST_UNDEFINED,
  requestNewArticleStatus: REQUEST_UNDEFINED,
  updateArticleSettingsStatus: REQUEST_UNDEFINED,
  updateCustomArticleSettingsStatus: REQUEST_UNDEFINED,
};

export const articles = {
  namespaced: true,
  state: initialState,
  actions: {
    getDepartmentArticles({ commit }, payload) {
      let { id, pageNum, categoryId, searchQuery, departmentId, onlyFavorite } =
        payload;
      commit("getDepartmentArticlesPending");
      return ArticlesService.getArticlesDepartment(
        id,
        pageNum,
        categoryId,
        searchQuery,
        departmentId,
        onlyFavorite
      ).then(
        (response) => {
          commit("getDepartmentArticlesSuccess", response.data);
          return Promise.resolve(response);
        },
        (error) => {
          commit("getDepartmentArticlesFailure");
          return Promise.reject(error);
        }
      );
    },
    getArticlesSupplier({ commit }, payload) {
      let {
        departmentId,
        supplierId,
        pageNum,
        categoryId,
        searchQuery,
        pageSize,
        onlyFavorite,
      } = payload;
      commit("getArticlesSupplierPending");
      return ArticlesService.getAllArticlesSupplier(
        departmentId,
        supplierId,
        pageNum,
        categoryId,
        searchQuery,
        pageSize,
        onlyFavorite
      ).then(
        (response) => {
          commit("getArticlesSupplierSuccess", response.data);
          return Promise.resolve(response);
        },
        (error) => {
          commit("getArticlesSupplierFailure");
          return Promise.reject(error);
        }
      );
    },
    // createProductFromArticle({ commit }, data) {
    // commit("editProductArticlePending");
    // return ArticlesService.createProduct(data).then(
    //   (response) => {
    //     commit("editProductArticleSuccess");
    //     return Promise.resolve(response);
    //   },
    //   (error) => {
    //     commit("editProductArticleFailure");
    //     return Promise.reject(error);
    //   }
    // );
    // },
    editProductInArticles({ commit }, data) {
      commit("editProductArticlePending");
      return ArticlesService.editProduct(data).then(
        (response) => {
          commit("editProductArticleSuccess");
          return Promise.resolve(response);
        },
        (error) => {
          commit("editProductArticleFailure");
          return Promise.reject(error);
        }
      );
    },
    toggleFavoriteArticle({ commit }, data) {
      commit("toggleFavoriteArticlePending");
      return ArticlesService.toggleFavoriteArticle(data).then(
        (response) => {
          commit("toggleFavoriteArticleSuccess");
          return Promise.resolve(response);
        },
        (error) => {
          commit("toggleFavoriteArticleFailure");
          return Promise.reject(error);
        }
      );
    },
    clearAllArticles({ commit }) {
      commit("clearArticles");
    },
    clearAllArticlesSupplier({ commit }) {
      commit("clearArticlesSuppliers");
    },
    getAllArticleSupplierCategories({ commit }, payload) {
      commit("getAllArticlesSupplierPending");
      return ArticlesService.getArticlesSupplierCategoriesService(payload).then(
        (response) => {
          commit("getAllArticlesSupplierSuccess", response.data);
          return Promise.resolve(response);
        },
        (error) => {
          commit("getAllArticlesSupplierFailure");
          return Promise.reject(error);
        }
      );
    },
    addNewArticle({ commit }, payload) {
      commit("addNewArticlePending");
      return ArticlesService.addNewArticle(payload).then(
        (response) => {
          commit("addNewArticleSuccess", response.data);
          return Promise.resolve(response);
        },
        (error) => {
          commit("addNewArticleFailure");
          return Promise.reject(error);
        }
      );
    },
    getCustomArticles({ commit }, payload) {
      commit("getCustomArticlesPending");
      return ArticlesService.getCustomArticles(payload).then(
        (response) => {
          commit("getCustomArticlesSuccess", response.data);
          return Promise.resolve(response);
        },
        (error) => {
          commit("getCustomArticlesFailure");
          return Promise.reject(error);
        }
      );
    },
    deleteCustomArticle({ commit }, payload) {
      commit("deleteCustomArticlePending");
      return ArticlesService.deleteCustomArticle(payload).then(
        (response) => {
          commit("deleteCustomArticleSuccess");
          return Promise.resolve(response);
        },
        (error) => {
          commit("deleteCustomArticleFailure");
          return Promise.reject(error);
        }
      );
    },
    toggleCustomArticleFavorite({ commit }, payload) {
      commit("toggleCustomArticleFavoritePending");
      return ArticlesService.toggleCustomArticleFavorite(payload).then(
        (response) => {
          commit("toggleCustomArticleFavoriteSuccess");
          return Promise.resolve(response);
        },
        (error) => {
          commit("toggleCustomArticleFailure");
          return Promise.reject(error);
        }
      );
    },
    editCustomArticle({ commit }, payload) {
      commit("editCustomArticlePending");
      return ArticlesService.editCustomArticle(payload).then(
        (response) => {
          commit("editCustomArticleSuccess");
          return Promise.resolve(response);
        },
        (error) => {
          commit("editCustomArticleFailure");
          return Promise.reject(error);
        }
      );
    },
    createOrUpdateArticle({ commit }, payload) {
      commit("createOrUpdateArticlePending");
      return ArticlesService.createOrUpdateArticle(payload).then(
        (response) => {
          commit("createOrUpdateArticleSuccess");
          return Promise.resolve(response);
        },
        (error) => {
          commit("createOrUpdateArticleFailure");
          return Promise.reject(error);
        }
      );
    },
    deleteArticle({ commit }, payload) {
      commit("deleteArticlePending");
      return ArticlesService.deleteArticle(payload).then(
        (response) => {
          commit("deleteArticleSuccess");
          return Promise.resolve(response);
        },
        (error) => {
          commit("deleteArticleFailure");
          return Promise.reject(error);
        }
      );
    },
    requestArticle({ commit }, payload) {
      commit("requestArticlePending");
      return ArticlesService.requestArticle(payload).then(
        (response) => {
          commit("requestArticleSuccess");
          return Promise.resolve(response);
        },
        (error) => {
          commit("requestArticleFailure");
          return Promise.reject(error);
        }
      );
    },
    updateArticleSettings({ commit }, payload) {
      commit("updateArticleSettingsPending");
      return ArticlesService.updateArticleSetting(payload).then(
        (response) => {
          commit("updateArticleSettingsSuccess");
          return Promise.resolve(response);
        },
        (error) => {
          commit("updateArticleSettingsFailure");
          return Promise.reject(error);
        }
      );
    },
    updateCustomArticleSettings({ commit }, payload) {
      commit("updateCustomArticleSettingsPending");
      return ArticlesService.updateCustomArticleSetting(payload).then(
        (response) => {
          commit("updateCustomArticleSettingsSuccess");
          return Promise.resolve(response);
        },
        (error) => {
          commit("updateCustomArticleSettingsFailure");
          return Promise.reject(error);
        }
      );
    },
  },
  mutations: {
    getDepartmentArticlesSuccess(state, payload) {
      state.getProductsStatus = REQUEST_SUCCESS;
      state.allProducts = payload.articles;
      state.allProductsCount = payload.count;
    },
    getDepartmentArticlesPending(state) {
      state.getProductsStatus = REQUEST_PENDING;
    },
    getDepartmentArticlesFailure(state) {
      state.getProductsStatus = REQUEST_FAILURE;
    },
    getArticlesSupplierPending(state) {
      state.getArticlesSupplierStatus = REQUEST_PENDING;
    },
    getArticlesSupplierSuccess(state, data) {
      state.getArticlesSupplierStatus = REQUEST_SUCCESS;
      state.allArticlesSupplier = data.articles;
      state.totalArticlesCount = data.count;
    },
    getArticlesSupplierFailure(state) {
      state.getArticlesSupplierStatus = REQUEST_FAILURE;
    },
    editProductArticlePending(state) {
      state.createProductArticleStatus = REQUEST_PENDING;
    },
    editProductArticleSuccess(state) {
      state.createProductArticleStatus = REQUEST_SUCCESS;
    },
    editProductArticleFailure(state) {
      state.createProductArticleStatus = REQUEST_FAILURE;
    },
    toggleFavoriteArticlePending(state) {
      state.toggleFavoriteArticleStatus = REQUEST_PENDING;
    },
    toggleFavoriteArticleSuccess(state) {
      state.toggleFavoriteArticleStatus = REQUEST_SUCCESS;
    },
    toggleFavoriteArticleFailure(state) {
      state.toggleFavoriteArticleStatus = REQUEST_FAILURE;
    },
    clearArticles(state) {
      state.allProducts = [];
    },
    clearArticlesSuppliers(state) {
      state.allArticlesSupplier = [];
    },

    getAllArticlesSupplierPending(state) {
      state.allArticlesCategoriesStatus = REQUEST_PENDING;
    },
    getAllArticlesSupplierSuccess(state, categories) {
      state.allArticlesCategoriesStatus = REQUEST_SUCCESS;
      state.allArticlesCategories = categories;
    },
    getAllArticlesSupplierFailure(state) {
      state.allArticlesCategoriesStatus = REQUEST_FAILURE;
    },

    addNewArticlePending(state) {
      state.addNewArticlePendingStatus = REQUEST_PENDING;
    },
    addNewArticleSuccess(state) {
      state.addNewArticlePendingStatus = REQUEST_SUCCESS;
    },
    addNewArticleFailure(state) {
      state.addNewArticlePendingStatus = REQUEST_FAILURE;
    },

    getCustomArticlesPending(state) {
      state.getAllCustomArticlesStatus = REQUEST_PENDING;
    },
    getCustomArticlesSuccess(state, data) {
      state.getAllCustomArticlesStatus = REQUEST_SUCCESS;
      state.allCustomArticles = data.articles;
      state.allCustomArticlesCount = data.count;
    },
    getCustomArticlesFailure(state) {
      state.getAllCustomArticlesStatus = REQUEST_FAILURE;
    },

    deleteCustomArticlePending(state) {
      state.deleteCustomArticleStatus = REQUEST_PENDING;
    },
    deleteCustomArticleSuccess(state) {
      state.deleteCustomArticleStatus = REQUEST_SUCCESS;
    },
    deleteCustomArticleFailure(state) {
      state.deleteCustomArticleStatus = REQUEST_FAILURE;
    },

    toggleCustomArticleFavoritePending(state) {
      state.toggleCustomArticleFavoriteStatus = REQUEST_PENDING;
    },
    toggleCustomArticleFavoriteSuccess(state) {
      state.toggleCustomArticleFavoriteStatus = REQUEST_SUCCESS;
    },
    toggleCustomArticleFavoriteFailure(state) {
      state.toggleCustomArticleFavoriteStatus = REQUEST_FAILURE;
    },

    editCustomArticlePending(state) {
      state.editCustomArticleStatus = REQUEST_PENDING;
    },
    editCustomArticleSuccess(state) {
      state.editCustomArticleStatus = REQUEST_SUCCESS;
    },
    editCustomArticleFailure(state) {
      state.editCustomArticleStatus = REQUEST_FAILURE;
    },

    createOrUpdateArticlePending(state) {
      state.createOrUpdateArticleStatus = REQUEST_PENDING;
    },
    createOrUpdateArticleSuccess(state) {
      state.createOrUpdateArticleStatus = REQUEST_SUCCESS;
    },
    createOrUpdateArticleFailure(state) {
      state.createOrUpdateArticleStatus = REQUEST_FAILURE;
    },

    deleteArticlePending(state) {
      state.deleteArticleStatus = REQUEST_PENDING;
    },
    deleteArticleSuccess(state) {
      state.deleteArticleStatus = REQUEST_SUCCESS;
    },
    deleteArticleFailure(state) {
      state.deleteArticleStatus = REQUEST_FAILURE;
    },

    requestArticlePending(state) {
      state.requestNewArticleStatus = REQUEST_PENDING;
    },
    requestArticleSuccess(state) {
      state.requestNewArticleStatus = REQUEST_SUCCESS;
    },
    requestArticleFailure(state) {
      state.requestNewArticleStatus = REQUEST_FAILURE;
    },

    updateArticleSettingsPending(state) {
      state.updateArticleSettingsStatus = REQUEST_PENDING;
    },
    updateArticleSettingsSuccess(state) {
      state.updateArticleSettingsStatus = REQUEST_SUCCESS;
    },
    updateArticleSettingsFailure(state) {
      state.updateArticleSettingsStatus = REQUEST_FAILURE;
    },

    updateCustomArticleSettingsPending(state) {
      state.updateCustomArticleSettingsStatus = REQUEST_PENDING;
    },
    updateCustomArticleSettingsSuccess(state) {
      state.updateCustomArticleSettingsStatus = REQUEST_SUCCESS;
    },
    updateCustomArticleSettingsFailure(state) {
      state.updateCustomArticleSettingsStatus = REQUEST_FAILURE;
    },
  },
  getters: {
    getArticles: (state) => {
      return state.allArticles;
    },
    getDepartmentArticlesStatus: (state) => {
      return state.getArticlesStatus;
    },
    getAllSupplierArticles: (state) => {
      return state.allArticlesSupplier;
    },
    getAllSupplierArticlesStatus: (state) => {
      return state.getArticlesSupplierStatus;
    },
    getAllProducts: (state) => {
      return state.allProducts;
    },
    getAllProductsCount: (state) => {
      return state.allProductsCount;
    },
    getAllProductsStatus: (state) => {
      return state.getProductsStatus;
    },
    getAllArticlesCategories: (state) => {
      return state.allArticlesCategories;
    },
    getAllArticlesCategoriesStatus: (state) => {
      return state.allArticlesCategoriesStatus;
    },
    getTotalArticlesCount: (state) => {
      return state.totalArticlesCount;
    },
    getAllCustomArticles: (state) => {
      return state.allCustomArticles;
    },
    getAllCustomArticlesStatus: (state) => {
      return state.getAllCustomArticlesStatus;
    },
    getAllCustomArticlesCount: (state) => {
      return state.allCustomArticlesCount;
    },
    getDeleteCustomArticleStatus: (state) => {
      return state.deleteCustomArticleStatus;
    },
    getDeleteArticleStatus: (state) => {
      return state.deleteArticleStatus;
    },
    getRequestNewArticleStatus: (state) => {
      return state.requestNewArticleStatus;
    },
    getUpdateArticleSettingsState: (state) => {
      return state.updateArticleSettingsStatus;
    },
    getUpdateCustomArticleSettingsState: (state) => {
      return state.updateCustomArticleSettingsStatus;
    },
  },
};
