<template>
  <div class="text-center">
    <v-dialog
      v-model="showDialog"
      width="626"
      class="dialog-style"
      persistent
      :fullscreen="getIsMobile"
    >
      <div class="dialog-style">
        <div class="header mb-6">
          <div class="d-flex justify-space-between align-center">
            <span class="text-h5 primaryA--text font-weight-bold">{{
              $t("previewSupplierNotesModal.title", {
                name: supplier && supplier.name,
              })
            }}</span>
            <v-img
              @click="closeDialog"
              class="close-icon"
              max-width="20px"
              :src="require('../assets/img/CloseIcon.svg')"
            >
            </v-img>
          </div>
        </div>
        <v-divider></v-divider>
        <div>
          <div class="pt-3">
            <div class="d-flex align-center justify-between">
              <v-text-field
                :label="$t('previewSupplierNotesModal.timeForOrdering')"
                v-model="timeToOrder"
                type="time"
                class="mr-3"
              ></v-text-field>
              <v-text-field
                :label="$t('previewSupplierNotesModal.timeForDelivery')"
                v-model="timeToDeliver"
                type="time"
              ></v-text-field>
            </div>
            <div>
              <span class="text-subtitle-2 secondaryDark2--text">{{
                $t("previewSupplierNotesModal.workingDays")
              }}</span>
              <div>
                <v-chip
                  v-for="day in daysInWeek"
                  :key="day.id"
                  :color="day.color"
                  @click="onDayChange(day)"
                  >{{ day.label }}</v-chip
                >
              </div>
            </div>
            <!-- <v-divider></v-divider> -->
            <v-textarea
              class="mt-4"
              id="note"
              no-resize
              filled
              name="text-msg"
              :label="$t('common.note')"
              v-model="note"
            ></v-textarea>
          </div>
        </div>
        <div class="d-flex justify-center mt-6">
          <v-btn @click="closeDialog">{{ $t("suppliers.close") }}</v-btn>
          <v-btn
            :disabled="false"
            class="ml-3"
            @click="onSave"
            color="accent"
            >{{ $t("common.save") }}</v-btn
          >
        </div>
      </div>
    </v-dialog>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "PreviewSupplierNotesModal",
  props: { supplier: {}, dialog: {}, user: {}, venue: {} },
  data: () => ({
    showDialog: false,
    timeToOrder: "00:00",
    timeToDeliver: "00:00",
    workingDays: [],
    note: "",
    daysInWeek: [],
  }),
  created() {
    this.showDialog = this.dialog;
  },
  mounted() {
    this.supplierNote && this.processSupplierNotes();
    this.daysInWeek = [
      {
        id: 0,
        label: this.$t("daysInWeekShort.monday"),
        value: "monday",
        color: "tertiaryDark",
        selected: false,
      },
      {
        id: 1,
        label: this.$t("daysInWeekShort.tuesday"),
        value: "tuesday",
        color: "tertiaryDark",
        selected: false,
      },
      {
        id: 2,
        label: this.$t("daysInWeekShort.wednesday"),
        value: "wednesday",
        color: "tertiaryDark",
        selected: false,
      },
      {
        id: 3,
        label: this.$t("daysInWeekShort.thursday"),
        value: "thursday",
        color: "tertiaryDark",
        selected: false,
      },
      {
        id: 4,
        label: this.$t("daysInWeekShort.friday"),
        value: "friday",
        color: "tertiaryDark",
        selected: false,
      },
      {
        id: 5,
        label: this.$t("daysInWeekShort.saturday"),
        value: "saturday",
        color: "tertiaryDark",
        selected: false,
      },
      {
        id: 6,
        label: this.$t("daysInWeekShort.sunday"),
        value: "sunday",
        color: "tertiaryDark",
        selected: false,
      },
    ];
  },
  methods: {
    closeDialog() {
      this.timeToOrder = "";
      this.timeToDeliver = "";
      this.workingDays = [];
      this.note = "";
      this.$emit("onClose");
    },
    onSave() {
      const data = {
        supplierId: this.supplier.id,
        userId: this.user.id,
        timeToOrder: this.timeToOrder,
        timeToDeliver: this.timeToDeliver,
        daysInWeek: this.workingDays,
        note: this.note,
        noteId: this.supplierNote ? this.supplierNote.id : "",
      };
      this.$store.dispatch("supplier/addSupplierNotes", data).then(() => {
        this.$emit("onClose");
      });
    },
    onDayChange(day) {
      this.$set(
        day,
        "color",
        day.color === "accent" ? "tertiaryDark" : "accent"
      );
      this.$set(day, "selected", !day.selected);
      this.workingDays = this.daysInWeek.filter((day) => day.selected);
    },
    processSupplierNotes() {
      this.timeToOrder = this.supplierNote.timeToOrder;
      this.timeToDeliver = this.supplierNote.timeToDeliver;
      if (this.supplierNote.daysInWeek) {
        const daysTemp = JSON.parse(this.supplierNote.daysInWeek);
        // ovo nekad dodje kao string
        this.daysInWeek.forEach((day) => {
          daysTemp.forEach((dayTemp) => {
            if (dayTemp.id === day.id) {
              this.$set(day, "color", "accent");
              this.$set(day, "selected", true);
            }
          });
        });
        this.workingDays = JSON.parse(this.supplierNote.daysInWeek);
      } else {
        this.workingDays = [];
      }
      this.note = this.supplierNote.note;
    },
  },
  computed: {
    getIsMobile() {
      return this.$isMobile;
    },
    ...mapGetters({
      getSelectedSupplierNote: "userProfile/getSelectedSupplierNote",
    }),
    supplierNote() {
      return this.getSelectedSupplierNote;
    },
  },
  watch: {
    dialog() {
      this.showDialog = this.dialog;
    },
    supplierNote() {
      if (this.supplierNote) {
        this.processSupplierNotes();
      }
    },
  },
};
</script>
<style scoped lang="scss">
.dialog-style {
  background-color: #ffffff;
  padding: 24px;
  @media screen and (max-width: 959px) {
    height: 100%;
    padding: 16px;
  }
}
.close-icon {
  cursor: pointer;
}
.info-text {
  text-align: center;
}
</style>
