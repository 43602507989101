<template>
  <div class="root d-flex align-items-custom justify-center">
    <div class="d-flex align-center justify-center flex-column">
      <span class="text-subtitle-1 primaryDark--text mt-7">{{
        getNoText
      }}</span>
      <div v-if="isArticlesPage" class="d-flex flex-column justify-center">
        <span>{{ $t("articles.requestForArticle") }}</span>
        <v-btn class="mt-5" color="primary" @click="openAddNewArticleModal">
          {{ $t("articles.requestForArticleBtn") }}
        </v-btn>
      </div>
    </div>
    <request-article-modal
      v-if="showAddArticleModal"
      :dialog="showAddArticleModal"
      :supplierId="supplierId"
      :departmentId="departmentId"
      :requestedArticleName="requestedArticleName"
      @onClose="showAddArticleModal = false"
      @onSuccessRequestSent="handleOnSuccessRequestSent"
    ></request-article-modal>
  </div>
</template>
<script>
import EventBus from "../shared/EventBus";
import RequestArticleModal from "../views/Articles/modals/RequestArticleModal.vue";

export default {
  name: "NoResultsContent",
  components: { RequestArticleModal },
  props: {
    tabName: {},
    noResultsText: {},
    page: {},
    supplierId: {},
    departmentId: {},
    requestedArticleName: {},
    isFavoriteOnlySearch: {},
    isSearchInitiated: {},
    isMarketSupplier: {}
  },
  data: () => ({
    showAddArticleModal: false,
  }),
  created() {},
  methods: {
    openAddNewArticleModal() {
      this.showAddArticleModal = true;
    },
    handleOnSuccessRequestSent() {
      this.showAddArticleModal = false;
      EventBus.dispatch("showSnackbar", {
        text: this.$t("notifications.articleRequestSent"),
        timeout: 5000,
      });
      this.$emit("onSuccessRequestSent");
    },
  },
  computed: {
    getNoText() {
      switch (this.page) {
        case "orders":
          return this.$t("currentOrders.noOrders");

        case "articles":
        case "stepTwoList":
          return this.$t("articles.noArticles");
        default:
          return this.noResultsText;
      }
    },
    isArticlesPage() {
      return (
        (this.page === "articles" || this.page === "stepTwoList") &&
        !this.isFavoriteOnlySearch &&
        this.isSearchInitiated && !this.isMarketSupplier
      );
    },
  },
  watch: {},
};
</script>
<style lang="scss" scoped>
.root {
  height: calc(100vh - 550px);
}
.align-items-custom {
  align-items: center;
  @media screen and (max-width: 959px) {
    align-items: flex-start!important;
  }
}
</style>
