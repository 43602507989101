<template>
  <div class="root">
    <div class="d-flex justify-space-between align-center">
      <span class="text-h5 primaryA--text font-weight-bold">{{
        $t("archived.archivedTitle")
      }}</span>
    </div>
    <div class="mt-8">
      <data-table
        v-if="!noOrders"
        :headers="headers"
        :data="ordersData"
        :isDataLoading="isDataLoading"
        :archive="true"
      ></data-table>
      <no-results-content
        v-else
        :noResultsText="$t('archived.noArchived')"
        :page="'archived'"
      ></no-results-content>
    </div>
  </div>
</template>
<script>
import DataTable from "../components/DataTable.vue";
import NoResultsContent from "../components/NoResultsContent.vue";
export default {
  name: "ArchivedOrders",
  components: { DataTable, NoResultsContent },
  data: () => ({
    headers: [],
    ordersData: [],
    isDataLoading: true,
  }),
  created() {
    this.headers = [
      { text: this.$t("archived.date"), value: "date" },
      {
        text: this.$t("archived.supplier"),
        value: "supplier",
      },
      { text: this.$t("archived.id"), value: "id" },
      {
        text: this.$t("archived.category"),
        value: "category",
      },
      {
        text: this.$t("archived.status"),
        value: "statusText",
      },
      { text: "", value: "actions", sortable: false },
    ];
  },
  mounted() {
    setTimeout(() => {
      this.ordersData = [
        {
          id: 8,
          date: "02-09-2021",
          supplier: "Corp",
          category: "Sank",
          status: "not_sent",
          statusText: "Nije poslato",
          articles: [
            {
              id: 0,
              name: "Paradajz",
              qty: "12kg",
            },
            {
              id: 1,
              name: "Zelena salata",
              qty: "30kom",
            },
            {
              id: 2,
              name: "Spanac",
              qty: "2kg",
            },
            {
              id: 3,
              name: "Krompir",
              qty: "10kg",
            },
            {
              id: 4,
              name: "Biftek",
              qty: "5kg",
            },
            {
              id: 5,
              name: "Pivo",
              qty: "20kom",
            },
            {
              id: 6,
              name: "Nesto",
              qty: "2kg",
            },
          ],
        },
        {
          id: 44,
          date: "01-09-2021",
          supplier: "Slatki vocko",
          category: "Kuhinja",
          status: "not_sent",
          statusText: "Nije poslato",
          articles: [],
        },
        {
          id: 33,
          date: "02-10-2021",
          supplier: "Corp",
          category: "Sank",
          status: "not_sent",
          statusText: "Nije poslato",
          articles: [],
        },
        {
          id: 22,
          date: "02-11-2021",
          supplier: "Slatki vocko",
          category: "Ostalo",
          status: "not_sent",
          statusText: "Nije poslato",
          articles: [],
        },
        {
          id: 34,
          date: "02-09-2021",
          supplier: "Corp",
          category: "Sank",
          status: "not_sent",
          statusText: "Nije poslato",
          articles: [],
        },
        {
          id: 24,
          date: "05-11-2021",
          supplier: "Corp",
          category: "Sank",
          status: "not_sent",
          statusText: "Nije poslato",
          articles: [],
        },
        {
          id: 11,
          date: "10-11-2021",
          supplier: "Slatki vocko",
          category: "Ostalo",
          status: "not_sent",
          statusText: "Nije poslato",
          articles: [],
        },
        {
          id: 55,
          date: "06-11-2021",
          supplier: "Slatki vocko",
          category: "Kuhinja",
          status: "not_sent",
          statusText: "Nije poslato",
          articles: [],
        },
        {
          id: 62,
          date: "20-10-2021",
          supplier: "Slatki vocko",
          category: "Sank",
          status: "not_sent",
          statusText: "Nije poslato",
          articles: [],
        },
        {
          id: 47,
          date: "29-10-2021",
          supplier: "Metro",
          category: "Kuhinja",
          status: "not_sent",
          statusText: "Nije poslato",
          articles: [],
        },
      ];
      this.isDataLoading = false;
    }, 3000);
  },
  computed: {
    noOrders() {
      return this.ordersData.length === 0 && this.isDataLoading === false;
    },
  },
};
</script>
<style lang="scss" scoped>
.root {
  padding: 32px;
}
</style>
