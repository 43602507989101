const initialState = {
  allArticleCategories: [
    {
      id: 0,
      name: "Korisnici, Objekti, Stanice",
      allArticles: [
        {
          id: 1,
          title: "Registracija",
          text: `
            <div>
            <ul>
              <li>
            Na početnoj strani klikom na Registrujte se ovde na dnu stranice otvaraju se polja koja je neophodno popuniti
            </li>
           <ul>
             <li><b>Email -</b> uneti email korisnika</li>
             <li><b>Ime i prezime -</b> uneti ime i prezime korisnika</li>
             <li><b>Loznika -</b> mora imati minimum 8 karaktera </li>
             <li><b>Broj telefona -</b> u formatu +381 691231234 </li>
             <li><b>Registruj se</b></li>
           </ul>
           <li>
             Nakon ovoga na mejl koji ste naveli će stići email za verifikaciju. Otvorite email i kliknite na <b>Potvrdite Registraciju.</b> Automatski će Vas prebaciti na login stranu na kojoj je neophodno da unesete Username i password koji ste prethodno naveli
           </li>
            </ul>
          <div style="text-align:center">
              <img width="250px" src="https://www.supplyme.rs/images/register.jpeg" alt="register"/>
          </div>
        </div>
            `,
        },
        {
          id: 2,
          title: "Log in",
          text: `
          <div>
          <ol>
            <li>Otvorite stranicu <a href="https://www.app.supplyme.rs" target="_blank">www.app.supplyme.rs</a></li>
            <li>U polje Email upisati mejl koji ste naveli prilikom registracije
       </li>
            <li>U polje Lozinka upisati lozinku koju ste naveli prilikom registracije
       </li>
            <li>Kliknite na Uloguj se</li>
          </ol>
         <div style="text-align:center">
             <img width="250px" src="https://www.supplyme.rs/images/login.jpeg" alt="register"/>
         </div>
       </div>
            `,
        },
        {
          id: 3,
          title: "Registracija objekta",
          text: `
          <div>
   <ul>
     <li>Ukoliko unosite podatke prvi put, unesite sve tražene podatke</li>
     <li>Ukoliko unosite podatke već registrovanog objekta dovoljno je da unesete PIB
</li>
     <li>Nakon registracije objekta, neophodno je da Vas podrška SupplyMe ovlasti kao Admina objekta, za to je potrebno nekoliko minuta</li>
   </ul>
  <div style="text-align:center">
      <img width="250px" src="https://www.supplyme.rs/images/reg-objekta.jpeg" alt="register"/>
  </div>
</div>
            `,
        },
        {
          id: 4,
          title: "Dodavanje korisnika",
          text: `
          <div>
 <span>Admin može da doda novog korisnika tako što će ga pozvati na sledeći način:
</span>
   <ol>
     <li>U gornjem desnom uglu klikom na “sendvič” meni otvoriće se opcija Admin
</li>
     <li>Ponovo kliknuti na “sendvič” meni i odabrati Restoran
</li>
<li>
  <div style="display:flex; flex-direction:column;">
    <span>Na novootvorenoj stranici kliknuti na plavi znak +</span>
    <div style="">
          <img width="250px" src="https://www.supplyme.rs/images/add_korisnici.jpeg" alt="register"/>
    </div>
  </div>
</li>
  <li>
    Uneti email adresu novog korisnika kome će stići mejl i kliknuti na <b>Pošalji</b>
  </li>
  <li>
    Kada novi korisnik dobije mejl, treba da prati instrukcije i upiše sve neophodne podatke u formu
  </li>
   </ol>

</div>
            `,
        },
        {
          id: 5,
          title: "Profil korisnika",
          text: `
            <div>
 <span>Promene o podacima korisnika moguće je promeniti na sledeći način
</span>
 <ol>
  <li>Klikom na “sendvič” meni u gornjem desnom uglu na početnom ekranu
  </li>
 <li>Kliknuti na <b>Korisnički profil</b>
  </li>

  <li>
    Jednostavno promeniti željene podatke
  </li>
  <li>
    Nakon izmena kliknuti na <b>Sačuvaj izmene</b>
  </li>
</ol>
<div style="text-align:center">
      <img width="250px" src="https://www.supplyme.rs/images/profil-izmena.png" alt="register"/>
  </div>
</div>
              `,
        },
        {
          id: 6,
          title: "Promena šifre",
          text: `
            <div>
 <ol>
  <li>Klikom na “sendvič” meni u gornjem desnom uglu na početnom ekranu
  </li>
   <li>Kliknuti na <b>Korisnički profil</b>
  </li>
  <li>Kliknuti na <b>Promeni šifru</b>
  </li>

  <li>
    Nakon otvaranja prozora za unos neophodno je u polje <b>Lozinka</b> ukucati trenutnu lozinku
  </li>
  <li>
    U polju <b>Nova lozinka i Ponovite novu lozinku</b> neophodno je ukucati željenu novu lozinku
  </li>
    <li>
    Nakon unosa kliknuti na <b>Sačuvaj izmene</b>
  </li>
</ol>
<div style="text-align:center">
      <img width="250px" src="https://www.supplyme.rs/images/promena-sifre.png" alt="register"/>
  </div>
</div>
              `,
        },
      ],
    },
    {
      id: 1,
      name: "Dobavljači i Artikli",
      allArticles: [
        {
          id: 0,
          title: "Dodavanje dobavljača restoranu",
          text: `
          <div>
          <span>Dodavanje dodeljenih dobavljača restoranu na odgovarajuću stanicu može da radi isključivo Admin restorana i to na sledeći način:
          </span>
           <ol>
            <li>U gornjem desnom uglu klikom na “sendvič” meni otvoriće se opcija Admin
            </li>
             <li>Ponovo kliknuti na “sendvič” meni i odabrati Stanice
            </li>
            <li>Ukoliko imate samo jednan restoran klikom na strelicu u delu “Dodaj/ukloni dobavljača”
            </li>
          
            <li>
              Klikom na željenog dobavaljača koji nije na listi dodaćete ga u listu
            </li>
            <li>
              Klikom na željenog dobavaljača koji je već u listi uklonićete ga sa liste
            </li>
          </ol>
            <div style="display: flex; justify-content: center;flex-wrap:wrap">
              <div style="display: flex; flex-direction: column; margin-right:32px; margin-bottom: 16px;align-items:center">
                <div style="margin-bottom:10px"><b>Dodavanje dobavljača</b></div>
                <img width="250px" src="https://www.supplyme.rs/images/dodavanje-dobavljaca.jpeg" alt="register"/>
              </div>
               <div style="display: flex; flex-direction: column;align-items:center">
                <div style="margin-bottom:25px"><b>Uklanjanje dobavljača</b></div>
                <img width="250px" src="https://www.supplyme.rs/images/uklanjanje-dobavljaca.jpeg" alt="register"/>
              </div>
            </div>
          </div>
        `,
        },
        {
          id: 1,
          title: "Dodavanje dobavljača koji nije u sistemu",
          text: `
            <div>
 <ul>
  <li>Ukoliko želite da dodate novog dobavljača neophodno je da kontaktirate podršku slanjem mejla na <a href="mailto:office@supplyme.rs">office@supplyme.rs</a> sledeće sadržine:
  </li>
  <ul>
  <li>Naziv restorana
</li>
  <li>Naziv dobavljača

</li>
  <li>PIB dobavljača
</li>
  <li>Kontakt telefon
</li>
  </ul>
  <li>Nakon prijema mejla podrška će vam dodeliti željenog dobavljača u nekoliko minuta, a u koliko dobavljača ne sarađuje sa SupplyMe.rs period dodavanja je nekoliko sati</li>
</ul>
</div>
          `,
        },
        {
          id: 2,
          title: "Prikaz artikala - Favoriti",
          text: `
            <div>
            <ul>
             <li>Ukoliko ne želite da vidite kompletan spisak artikala za određenog dobavljača, već želite prikaz samo onih artikala koje od određenog dobavljača poručujete neophodno je selektovati željene artikle na sledeći način:
             </li>
             <ol>
             <li>U gornjem desnom uglu klikom na <i>“sendvič”</i> meni otvoriće se opcija <b>Artikli</b>
           </li>
             <li>Odabrati stanicu klikom na strelicu Stanice
           </li>
             <li>Odabrati dobavljača klikom na strelicu Dobavljači
           </li>
           <li>Klikom na zvezdicu menjate status artikla. Ako je zvezdica <span style="color:#4A86E7">plava</span>, taj artikl postaje favorizovani artikl i on će se videti prvi u listi prilikom kreiranja porudžbine. Ako je zvezdica <span style="color:#999999">siva</span>, videće se ispod favorizovanih artikala prilikom kreiranja porudžbine
           </li>
             </ol>
           </ul>
           <div style="text-align:center">
                 <img width="250px" src="https://www.supplyme.rs/images/lista-proizvoda-dobavljaca.jpeg" alt="register"/>
             </div>
           </div>
            `,
        },
      ],
    },
    {
      id: 2,
      name: "Porudžbine",
      allArticles: [
        {
          id: 0,
          title: "Kreiranje nove porudžbine",
          text: `<div>
      <ol>
      <li>Na početnoj strani ispod Kreiraj novu porudžbinu, klikom na Izaberi stanicu otvoriće se padajući meni sa spiskom stanica, izaberite za koju stanicu, odnosno lokaciju, poručujete robu
      <div style="text-align:center">
          <img width="250px" src="https://www.supplyme.rs/images/kreiranje-porudzbine-1.jpeg" alt="register"/>
      </div>
    </li>
      <li>Na drugom koraku <b>odaberite datum</b>. Klikom na ponuđeni datum otvoriće se kalendar u kom je neophodno odabrati željeni datum
     <div style="text-align:center">
          <img width="250px" src="https://www.supplyme.rs/images/kreiranje-porudzbine-2.jpeg" alt="register"/>
      </div>
    </li>
      <li>Nakon odabira datuma neophodno je <b>izabrati Dobavljača</b>. Klikom na <b>Dobavljač</b>, otvoriće se padajući meni sa spiskom svih dobavljača dodeljenih za tu stanicu. Odaberite željenog dobavljača
     <div style="text-align:center">
          <img width="250px" src="https://www.supplyme.rs/images/kreiranje-porudzbine-3.jpeg" alt="register"/>
      </div>
    </li>
    <li>Nakon odabira dobavljača kliknuti na <b>Nastavi dalje</b>
    </li>
    <li><b>Popunjavanje količina za artikle</b> se vrši klikom na prazno polje u nastavku naziva artikla i upisivanjem željene količine. Neophodno je obratiti pažnju na jedinicu mere iskazanu u nazivu artikla
     <div style="text-align:center">
          <img width="250px" src="https://www.supplyme.rs/images/kreiranje-porudzbine-5.jpeg" alt="register"/>
      </div>
    </li>
    <li>Moguće je skratiti pretragu odabirom određene kategorije. Klikom na <b>Sve kategorije</b> i odabirom željene prikazaće samo onu kategoriju koju ste izabrali
     <div style="text-align:center">
          <img width="250px" src="https://www.supplyme.rs/images/kreiranje-porudzbine-6.jpeg" alt="register"/>
      </div>
    </li>
    <li>Moguće je vršiti <b>pretragu upisivanjem reči ili dela reči</b> koje artikl sadrži
     <div style="text-align:center">
          <img width="250px" src="https://www.supplyme.rs/images/kreiranje-porudzbine-7.jpeg" alt="register"/>
      </div>
    </li>
    <li>Moguć je prikaz samo Favorizovanih artikala klikom na kvadratić ispred <b>Samo omiljeni</b>
     <div style="text-align:center">
          <img width="250px" src="https://www.supplyme.rs/images/kreiranje-porudzbine-8.jpeg" alt="register"/>
      </div>
    </li>
    <li>Moguće je upisati napomenu za svaki pojedinačni artikl klikom na olovku sa desne strane na ekranu u liniji sa nazivom artikla. Ta napomena će se kasnije pojaviti u sklopu poruke koja se generiše dobavljaču
     <div style="text-align:center">
          <img width="250px" src="https://www.supplyme.rs/images/kreiranje-porudzbine-9.jpeg" alt="register"/>
      </div>
    </li>
    <li>Moguće je vratiti se na prethodni korak klikom na dugme <b>Nazad</b>, koje se nalazi na dnu ekrana
    </li>
    <li>Moguće je sačuvati nedovršenu porudžbinu klikom na <b>Sačuvaj</b>, koje se nalazi na dnu ekrana
    </li>
    
    <li>Ukoliko je sve od artikala uneto prelazimo na sledeći korak klikom na dugme <b>Nastavi dalje</b>, koje se nalazi na dnu ekrana
    </li>
    <li>Neophodno je odabrati kontakt iz padajućeg menija <b>Kontakt</b>
     <div style="text-align:center">
          <img width="250px" src="https://www.supplyme.rs/images/kreiranje-porudzbine-13.jpeg" alt="register"/>
      </div>
    </li>
    <li>Ukoliko kontakt nije dodat, neophodno je dodati ga klikom na <b>Dodaj kontakt</b>. U zavisnosti od toga na koji način komunicirate sa dobavljačem neophodno je uneti e-mail ili broj telefona, nije obavezno uneti oba podatka. Ovu akciju radite samo jednom, a objašnjenje o menjanju broja telefona, dodavanju i brisanju kontakta možete videi u delu <b>Dodavanje kontakta</b>
     <div style="text-align:center">
          <img width="250px" src="https://www.supplyme.rs/images/kreiranje-porudzbine-14.jpeg" alt="register"/>
      </div>
    </li>
    <li>Nakon odabira kontakta, neophodo je odabrati način plaćanja klikom na jedno od ponuđenih opcija, a moguće je i ostaviti to polje prazno klikom na <b>Očisti</b>
     <div style="text-align:center">
          <img width="250px" src="https://www.supplyme.rs/images/kreiranje-porudzbine-15.jpeg" alt="register"/>
      </div>
    </li>
    <li>Napomenu za celu porudžbinu možete upisati i ta napomena će se pojaviti na kraju poruke koju šaljete dobavljaču
     <div style="text-align:center">
          <img width="250px" src="https://www.supplyme.rs/images/kreiranje-porudzbine-16.jpeg" alt="register"/>
      </div>
    </li>
    <li>Odaberite <b>način slanja porudžbine</b>. U opcijama su Whatsapp, Viber, SMS,, E-mail, a možete i pozvati dobavljača. Klikom na jednu od opcija kopirate ceo sadržaj porudžbine, bićete prebačeni na odabranu aplikaciju, a onda je neophodno nalepiti tekst poružbine u polje za unos teksta. Ovim korakom porudžbina dobija status <b>Naručena porudžbina</b>
     <div style="text-align:center">
          <img width="250px" src="https://www.supplyme.rs/images/kreiranje-porudzbine-17.jpeg" alt="register"/>
      </div>
    </li>
      </ol>
    </div>`,
        },
        {
          id: 1,
          title: "Izmena poslate porudžbine",
          text: `<div>
            <ul>
            <li>Ukoliko je neophodno na bilo koji način izmeniti poslatu porudžbinu to je moguće uraditi klikom na naručenu porudžbinu i onda odabrati Izmeni iz padajućeg menija
            </li>
            </ul>
            <div><b>*U svakom trenutku možete odustati od porudžbine klikom na strelicu u gornjem levom uglu</b>
            </div>
            <div><b>*U svakom trenutku možete sačuvati porudžbinu i nastaviti kasnije sa njenim popunjavanjem i slanjem</b>
            </div>
            </div>`,
        },
        {
          id: 2,
          title: "Prijem porudžbine",
          text: `<div>
            <ol>
            <li>Na početnom ekranu postoji brojač porudžbina. U <b>Očekivane porudžbine</b> stoji informacija koji je broj očekivanih porudžbina
            <div style="text-align:center">
                <img width="250px" src="https://www.supplyme.rs/images/prijem-porudzbine-1.jpeg" alt="register"/>
            </div>
          </li>
            <li>Klikom na <b>Očekivane porudžbine</b> otvoriće se spisak porudžbina sa statusom <b>Naručena</b>, složene po datumu
           <div style="text-align:center">
                <img width="250px" src="https://www.supplyme.rs/images/prijem-porudzbine-2.jpeg" alt="register"/>
            </div>
          </li>
            <li>Klikom na meni “tri tačke” koji se nalazi sa desne strane pored dobavljač
           <div style="text-align:center">
                <img width="250px" src="https://www.supplyme.rs/images/prijem-porudzbine-3.jpeg" alt="register"/>
            </div>
            <ol type="a">
                <li><b>Izmeni</b> - ova opcija se koristi kada želimo da promenimo neki od podataka vezanih za porudžbinu: datum isporuke, količine, način plaćanja itd. VAŽNO: moguće je menjati i poslatu porudžbinu, i u tom slučaju dobavljaču će stići nova porudžbina
          </li>
          <li><b>Obriši</b> - Nije moguće brisati poslatu porudžbinu, moguće je ući u nju i putem opcije Primi rešiti je odabirom opcije Nije stiglo
          </li>
          <li><b>Primi</b> - odabirom ove opcije otvara se prozor u kome je neophodno kliknuti na dugme <b>Stiglo</b> ukoliko je artikl stigao, odnosno na <b>Nije stiglo</b> ukoliko artikl nije stigao (<b>Nije stiglo</b> opcija otvara dodatne opcije koje je opisano u delu <b>Nekompletna porudžbina</b>).
           <div style="text-align:center">
                <img width="250px" src="https://www.supplyme.rs/images/prijem-porudzbine-4.jpeg" alt="register"/>
            </div>
            <div>
            *Ukoliko je sve od artikala stiglo, kliknuti na <b>Sve stiglo</b>, pa na <b>Zaključi porudžbinu</b>
            </div>
              <div>
            *Nakon toga će na mejl admina stići obaveštenje o primljenoj porudžbini i svim detaljima vezanim za nju
            </div>
             <div style="text-align:center">
                <img width="250px" src="https://www.supplyme.rs/images/prijem-porudzbine-6.jpeg" alt="register"/>
            </div>
          </li>
          <li><b>Ponovi</b> - odabirom ove opcije omogućava se korisniku da ponovi celu porudžbinu sa identičnim sadržajem uz mogućnost izmena datuma isporuke, kontakta i načina plaćanja
           <div style="text-align:center">
                <img width="250px" src="https://www.supplyme.rs/images/prijem-porudzbine-5.jpeg" alt="register"/>
            </div>
          </li>
          <li><b>Kopiraj SMS poruku</b> - Ova opcija se pokazala kao korisna kada želimo da obavestimo nekog od članova tima koji nemaju pristup SupplyMe aplikaciji. Sadžaj poruke je isti kao onaj koji dobija dobavljač
          </li>
          <li><b>Pozovi dobavljača</b> - Ova opcija nas automatski prebacuje u poziv prema dobavljaču
          </li>
          
            </ol>
          </li>
          </ol>
          </div>`,
        },
        {
          id: 3,
          title: "Nekompletna porudžbina",
          text: `<div>
            <span>U slučaju da neki od artikala nije stigao, moguće je odabrati neku od ponuđenih opcija koje će se kasnije pojaviti u vidu obaveštenja u izveštaju koji stiže na mejl admina:
            </span>
              <div style="text-align:center">
                  <img width="250px" src="https://www.supplyme.rs/images/nekompletna-porudzbina.jpeg" alt="register"/>
              </div>
              <ol>
              <li>
                <b>Prebaci na novu porudžbinu </b>
                <ul>
                <li>Nakon odabira ove opcije, neophodno je i ostale artikle iz porudžbine, ako ih ima, obeležiti sa Stiglo ili Nije stiglo, i nakon zaključivanja porudžbine, otvoriće se prozor za novu porudžbinu koja će u sebi već sadržati željeni artikl, koju je moguće dalje menjati
            </li>
                </ul>
              </li>
              <li>
                <b>Nema na stanju</b>
                <ul>
                <li>Nakon odabira ove opcije, zaključuje se akcija nad određenim artiklom i smatra se da taj artikl neće stići od odabranog dobavljača, a porudžbina koja je zatvorena nakon odabira ove opcije menja status u <b>Isporučena</b>
            </li>
                </ul>
              </li>
                <li>
                <b>Stiže kasnije</b>
                <ul>
                <li>Odabirom ove opcije, status porudžbine i dalje ostaje <b>Očekivana</b> u kojoj će svaki od artikala zadržati odabrani status do rešavanja porudžbine
            </li>
                </ul>
              </li>
              </ol>
            </div>`,
        },
        {
          id: 4,
          title: "Spajanje sa već postojećom porudžbinom",
          text: `<div>
            <ul>
            <li>Ukoliko se pravi porudžbina za dan za koji je od odabranog dobavljača već odrađena porudžbina, izaći će obaveštenje o postojećoj porudžbini i otvoriće se opcija spajanja sa tom porudžbinom
              <div style="text-align:center">
                  <img width="250px" src="https://www.supplyme.rs/images/spajanje-1.jpeg" alt="register"/>
              </div>
            </li>
            <li>Klikom na dugme <b>Vidi</b> otvoriće se prozor u kome će biti prikazana porudžbina koja je već napravljena
              <div style="text-align:center">
                  <img width="250px" src="https://www.supplyme.rs/images/spajanje-2.jpeg" alt="register"/>
              </div>
            </li>
            <li>Klikom na <b>Idi na porudžbinu</b> otvoriće se odgovarajuća porudžbina koju je moguće dodatno menjati, a klikom na <b>Spoji sa ovom porudžbinom</b> dve porudžbine će se sumirati u jednu
            </li>
            <li>Ostatak kreiranja porudžbine je objašnjen u delu <b>Kreiranje nove porudžbine</b>
            </li>
            </ul>
            </div>
            `,
        },
      ],
    },
    { id: 3, name: "Magacin", allArticles: [
        {
            id: 0, title:"Kreiranje, izmena i brisanje artikala", text: `<div>
            <span>
              <b>Kreiranje</b> novih artikala u Magacinu može da radi isključivo Admin restorana
            </span>
            <ol>
            <li>U gornjem desnom uglu klikom na “sendvič” meni otvoriće se opcija <b>Artikli</b>
              <div style="text-align:center">
                  <img width="250px" src="https://www.supplyme.rs/images/magacin-1.jpeg" alt="register"/>
              </div>
            </li>
            <li>U novootvorenom prozoru odabrati <b>Magacin</b>
              <div style="text-align:center">
                  <img width="250px" src="https://www.supplyme.rs/images/magacin-2.jpeg" alt="register"/>
              </div>
            </li>
            <li>Kliknuti na <b>Dodaj magacin artikl</b>
              <div style="text-align:center">
                  <img width="250px" src="https://www.supplyme.rs/images/magacin-3.jpeg" alt="register"/>
              </div>
              <ul>
              <li>Odabrati stanicu - odabrati stanicu na kojoj želimo da Magacin artikl bude dodeljen
            </li>
              <li>Ukucati ime artikla sa jedinicom mere, odnosno pakovanjem, primer PELAT 2,5kg, JABUKA KG…
            </li>
              <li>Odabrati kategoriju proizvoda iz padajućeg menija
            </li>
             <li>Kliknuti na <b>Sačuvaj</b>
            </li>
              </ul>
            </li>
            </ol>
            <span>Izmenu već napravljenih artikala moguće je uraditi na sledeći način:</span>
            <ol>
            <li>U gornjem desnom uglu klikom na “sendvič” meni otvoriće se opcija <b>Artikli</b>
             <div style="text-align:center">
                  <img width="250px" src="https://www.supplyme.rs/images/magacin-1.jpeg" alt="register"/>
              </div>
            </li>
            <li>U novootvorenom prozoru odabrati <b>Magacin</b>
             <div style="text-align:center">
                  <img width="250px" src="https://www.supplyme.rs/images/magacin-4.jpeg" alt="register"/>
              </div>
            </li>
            <li>Odabrati željeni Magacin, otvoriće se spisak već napravljenih artikala vezanih za <b>Magacin</b>
            </li>
             <li>U <b>Pretraži prizvode</b> ukucati deo naziva željenog artikla</li>
             <li>
             Klikom na ikonicu (olovčica)
             moguće je izmeniti željene podatke vezane za artikl
             </li>
              <li>
            Nakon unosa izmena kliknuti na <b>Sačuvaj</b>
             </li>
            </ol>
            <span><b>Brisanje</b> već napravljenih artikala moguće je uraditi na sledeći način
            </span>
            <ol>
            <li>
              U gornjem desnom uglu klikom na “sendvič” meni otvoriće se opcija <b>Artikli</b>
               <div style="text-align:center">
                  <img width="250px" src="https://www.supplyme.rs/images/magacin-1.jpeg" alt="register"/>
              </div>
            </li>
            <li>
              U novootvorenom prozoru odabrati <b>Magacin</b>
               <div style="text-align:center">
                  <img width="250px" src="https://www.supplyme.rs/images/magacin-4.jpeg" alt="register"/>
              </div>
            </li>
            <li>Odabrati željeni Magacin, otvoriće se spisak već napravljenih artikala vezanih za <b>Magacin</b></li>
            <li>U <b>Pretraži prizvode</b> ukucati deo naziva željenog artikla
            </li>
            <li>Klikom na ikonicu (kanta) moguće je obrisati željeni artikl
            </li>
            <li>Nakon unosa izmena kliknuti na <b>Sačuvaj</b>
            </li>
            </ol>
            </div>`
        },
        {
            id: 1, title:"Kreiranje porudžbine", text: `<div>
            <ol>
            <li>Na početnoj strani ispod <b>Kreiraj novu porudžbinu</b>, klikom na <b>Izaberi stanicu</b> otvoriće se padajući meni sa spiskom stanica, izaberite za koju stanicu, odnosno lokaciju, poručujete robu
              <div style="text-align:center">
                  <img width="250px" src="https://www.supplyme.rs/images/magacin-kreiraj-1.jpeg" alt="register"/>
              </div>
            </li>
            <li>Na drugom koraku <b>odaberite datum</b>. Klikom na ponuđeni datum otvoriće se kalendar u kom je neophodno odabrati željeni datum
              <div style="text-align:center">
                  <img width="250px" src="https://www.supplyme.rs/images/magacin-kreiraj-2.jpeg" alt="register"/>
              </div>
            </li>
            <li>Nakon odabira datuma neophodno je <b>izabrati Dobavljača</b>. Klikom na <b>Dobavljač</b>, otvoriće se padajući meni sa spiskom svih dobavljača dodeljenih za tu stanicu. Odaberite dobavljača <b>Magacin</b>
              <div style="text-align:center">
                  <img width="250px" src="https://www.supplyme.rs/images/magacin-kreiraj-3.jpeg" alt="register"/>
              </div>
            </li>
            <li>Nakon odabira dobavljača kliknuti na <b>Nastavi dalje</b>
            </li>
            <li><b>Popunjavanje količina za artikle</b> se vrši klikom na prazno polje u nastavku naziva artikla i upisivanjem željene količine. Neophodno je obratiti pažnju na jedinicu mere iskazanu u nazivu artikla
             <div style="text-align:center">
                  <img width="250px" src="https://www.supplyme.rs/images/magacin-kreiraj-4.jpeg" alt="register"/>
              </div>
            </li>
            <li>U polju <b>Pretraži</b> ukucajte deo naziva artikla kako biste suzili pretragu
             <div style="text-align:center">
                  <img width="250px" src="https://www.supplyme.rs/images/magacin-kreiraj-5.jpeg" alt="register"/>
              </div>
            </li>
            <li>Moguće je upisati napomenu za svaki pojedinačni artikl klikom na olovku sa desne strane na ekranu u liniji sa nazivom artikla. Ta napomena će se kasnije pojaviti u sklopu poruke koja se generiše dobavljaču
             <div style="text-align:center">
                  <img width="250px" src="https://www.supplyme.rs/images/magacin-kreiraj-6.jpeg" alt="register"/>
              </div>
            </li>
            <li>Moguće je vratiti se na prethodni korak klikom na dugme <b>Nazad</b>, koje se nalazi na dnu ekrana
            
            </li>
            <li>Moguće je sačuvati nedovršenu porudžbinu klikom na <b>Sačuvaj</b>, koje se nalazi na dnu ekrana
            
            </li>
            <li>Ukoliko je sve od artikala uneto prelazimo na sledeći korak klikom na dugme <b>Nastavi dalje</b>, koje se nalazi na dnu ekrana
            </li>
            <li>Napomenu za celu porudžbinu možete upisati i ta napomena će se pojaviti na kraju poruke koju šaljete Magacioneru
            </li>
            <li>Nakon što smo se uverili da je sadržaj porudžbine validan, neophodno je zaključiti porudžbinu klikom na <b>Zaključi</b>
             <div style="text-align:center">
                  <img width="250px" src="https://www.supplyme.rs/images/magacin-kreiraj-7.jpeg" alt="register"/>
              </div>
            </li>
            
            </ol>
            </div>`
        },
        {
            id: 2, title:"Prijem porudžbine", text: `<div>
            <ol>
            <li>Na početnom ekranu postoji brojač porudžbina. U <b>Očekivane porudžbine</b> stoji informacija koji je broj očekivanih porudžbina
              <div style="text-align:center">
                  <img width="250px" src="https://www.supplyme.rs/images/magacin-prijem-1.jpeg" alt="register"/>
              </div>
            </li>
            <li>Klikom na <b>Očekivane porudžbine</b> otvoriće se spisak porudžbina sa statusom <b>Naručena</b>, složene po datumu
              <div style="text-align:center">
                  <img width="250px" src="https://www.supplyme.rs/images/magacin-prijem-2.jpeg" alt="register"/>
              </div>
            </li>
            <li>Klikom na meni “tri tačke” koji se nalazi sa desne strane pored dobavljač
              <div style="text-align:center">
                  <img width="250px" src="https://www.supplyme.rs/images/magacin-prijem-3.jpeg" alt="register"/>
              </div>
              <ul>
                <li><b>Izmeni</b> - ova opcija se koristi kada želimo da promenimo neki od podataka vezanih za porudžbinu: datum isporuke, količine itd. VAŽNO: moguće je menjati i poslatu porudžbinu, i u tom slučaju Magacioneru će stići nova porudžbina</li>
                 <li><b>Obriši</b> - Nije moguće brisati poslatu porudžbinu, moguće je ući u nju i putem opcije Primi rešiti je odabirom opcije <b>Nije stiglo</b>
            </li>
                  <li><b>Primi</b> - odabirom ove opcije otvara se prozor u kome je neophodno kliknuti na dugme <b>Stiglo</b> ukoliko je artikl stigao, odnosno na <b>Nije stiglo</b> ukoliko artikl nije stigao (<b>Nije stiglo</b> opcija otvara dodatne opcije koje je opisano u delu <b>Nekompletna proudžbina</b>).
                    <div style="text-align:center">
                  <img width="250px" src="https://www.supplyme.rs/images/magacin-prijem-4.jpeg" alt="register"/>
              </div>
              <span>*Ukoliko je sve od artikala stiglo, kliknuti na <b>Sve stiglo</b>, pa na <b>Zaključi porudžbinu</b>
            </span>
            <span>*Nakon toga će na mejl admina stići obaveštenje o primljenoj porudžbini i svim detaljima vezanim za nju
            </span>
              <div style="text-align:center">
                  <img width="250px" src="https://www.supplyme.rs/images/magacin-prijem-5.jpeg" alt="register"/>
              </div>
            </li>
            <li>
              <b>Ponovi</b> - odabirom ove opcije omogućava se korisniku da ponovi celu porudžbinu sa identičnim sadržajem uz mogućnost izmena datuma isporuke
              <div style="text-align:center">
                  <img width="250px" src="https://www.supplyme.rs/images/magacin-prijem-6.jpeg" alt="register"/>
              </div>
            </li>
            <li><b>Kopiraj SMS poruku</b> - Ova opcija se pokazala kao korisna kada želimo da obavestimo nekog od članova tima koji nemaju pristup SupplyMe aplikaciji. Sadžaj poruke je isti kao onaj koji dobija Magacioner
            </li>
            
                  
              </ul>
            </li>
            
            
            </ol>
            </div>`
        },
        {
            id: 3, title:"Pregled porudžbina", text: ``
        }
    ] },
  ],
};

export const help = {
  namespaced: true,
  state: initialState,
  getters: {
    getAllHelpArticles: (state) => {
      return state.allArticleCategories;
    },
  },
};
