import api from "./api";
import {
  getAllOrdersURL,
  createOrderURL,
  getOrderURL,
  updateOrderURL,
  sendOrderURL,
  completeOrderURL,
  getAllOrdersVenueURL,
  deleteOrderURL,
  createCustomOrderURL,
  updateCustomOrderURL,
  getCustomOrderURL,
  deleteCustomProductOrderURL,
  deleteCustomOrderURL,
  completeCustomOrderURL,
  sendCustomOrderURL,
  getAllOrdersForUserAdminURL,
  getAllRegularOrdersURL,
  getAllCustomOrdersURL,
} from "../shared/endpoints";

class OrdersService {
  getAllOrders(payload) {
    const { departmentId, page, pageSize, status } = payload;
    return api.get(getAllOrdersURL(departmentId, page, pageSize, status));
  }
  createOrder(payload) {
    let data = {
      deadline: payload.order.date,
      products: payload.order.articles,
      orderNote: payload.order.orderNote,
      paymentMethod: payload.order.paymentMethod,
    };
    return api.post(
      createOrderURL(payload.departmentID, payload.order.supplier.id),
      JSON.stringify(data)
    );
  }
  sendOrder(payload) {
    return api.post(sendOrderURL(payload.id), {
      supplierContactNumber: payload.supplierContactNumber,
    });
  }
  getOrder(id) {
    return api.get(getOrderURL(id));
  }
  updateOrder(payload) {
    let data = {
      deadline: payload.order.date,
      productsToUpdate: payload.order.articles,
      orderNote: payload.order.orderNote,
      paymentMethod: payload.order.paymentMethod,
      receivingNote: payload.receivingNote
    };
    return api.post(updateOrderURL(payload.orderId), JSON.stringify(data));
  }
  completeOrder(data) {
    const payload = {
      products: data.products,
      receivingNote: data.receivingNote,
    }
    return api.post(completeOrderURL(data.id), JSON.stringify(payload));
  }
  getAllOrdersVenue(payload) {
    return api.get(getAllOrdersVenueURL(payload.venueId));
  }
  deleteOrder(orderId) {
    return api.delete(deleteOrderURL(orderId));
  }
  createCustomOrder(payload) {
    const data = {
      deadline: payload.order.date,
      products: payload.order.articles,
      orderNote: payload.order.orderNote,
    };
    return api.post(
      createCustomOrderURL(payload.departmentID),
      JSON.stringify(data)
    );
  }
  updateCustomOrder(payload) {
    let data = {
      deadline: payload.order.date,
      productsToUpdate: payload.order.articles,
      orderNote: payload.order.orderNote,
      paymentMethod: payload.order.paymentMethod,
      receivingNote: payload.receivingNote
    };
    return api.post(
      updateCustomOrderURL(payload.orderId),
      JSON.stringify(data)
    );
  }
  getCustomOrder(id) {
    return api.get(getCustomOrderURL(id));
  }
  deleteCustomProductOrder(orderId) {
    return api.delete(deleteCustomProductOrderURL(orderId));
  }
  deleteCustomOrder(orderId) {
    return api.delete(deleteCustomOrderURL(orderId));
  }
  completeCustomOrder(data) {
    const payload = {
      products: data.products,
      receivingNote: data.receivingNote,
    }
    return api.post(
      completeCustomOrderURL(data.id),
      JSON.stringify(payload)
    );
  }
  sendCustomOrder(orderId) {
    return api.post(sendCustomOrderURL(orderId));
  }

  getAllOrdersForUserAdmin(payload) {
    const { venueId, page, pageSize, status } = payload;
    return api.get(
      getAllOrdersForUserAdminURL(venueId, page, pageSize, status)
    );
  }
  getAllRegularOrdersInProgress(payload) {
    const { departmentId, page, pageSize } = payload;
    return api.get(
      getAllRegularOrdersURL(departmentId, page, pageSize, "IN_PROGRESS")
    );
  }
  getAllCustomOrdersInProgress(payload) {
    const { departmentId, page, pageSize } = payload;
    return api.get(
      getAllCustomOrdersURL(departmentId, page, pageSize, "IN_PROGRESS")
    );
  }
  getAllRegularOrders(payload) {
    const { departmentId, page, pageSize, status, supplierId } = payload;
    return api.get(
      getAllRegularOrdersURL(departmentId, page, pageSize, status, supplierId)
    );
  }
  getAllCustomOrders(payload) {
    const { departmentId, page, pageSize, status } = payload;
    return api.get(getAllCustomOrdersURL(departmentId, page, pageSize, status));
  }
}
export default new OrdersService();
