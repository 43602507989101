<template>
  <div class="padding-adjust">
    <div class="header">
      <div class="d-flex justify-center" style="width: 100%">
        <div class="d-flex flex-column align-center" style="width: 100%">
          <div class="white--text mb-2 text-h6">{{ $t("help.title") }}</div>
          <v-text-field
            id="search"
            name="search"
            class="search-input"
            :placeholder="$t('help.searchPlaceholder')"
            v-model="searchValue"
            type="text"
            solo
            prepend-inner-icon="mdi-magnify"
            clearable
            @keyup="onSearchUp"
            @keydown="onSearchDown"
            @click:clear="onSearchUp"
          ></v-text-field>
        </div>
      </div>
    </div>
    <div
      v-if="searching"
      class="d-flex justify-center align-center"
      style="margin-top: 5vh"
    >
      <v-progress-circular
        :size="70"
        :width="7"
        color="accent"
        indeterminate
      ></v-progress-circular>
    </div>
    <div v-else>
      <div v-if="searchResult.length > 0" class="search-results">
        <div class="mt-3 mb-3 text-subtitle-1">{{ $t("help.search") }}</div>

        <div class="d-flex justify-center align-center marginBiggerScreens">
          <v-expansion-panels>
            <v-expansion-panel
              v-for="item in searchResult"
              :key="item.article.id"
            >
              <v-expansion-panel-header>
                <div>
                  <strong>{{ item.category }}</strong> -
                  {{ item.article.title }}
                </div>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <div style="text-align: left" v-html="item.article.text"></div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </div>
      </div>
      <div
        v-else-if="searched && searchResult.length === 0"
        class="d-flex justify-center align-center"
        style="margin-top: 5vh"
      >
        <span>{{ $t("help.noResults") }}</span>
      </div>
      <div
        v-else
        class="d-flex flex-column align-center justify-center content"
      >
        <div class="mt-3 mb-3 text-subtitle-1">{{ $t("help.byCategory") }}</div>
        <div
          class="d-flex flex-wrap flex-row justify-center"
          style="max-width: 625px"
        >
          <v-card
            v-for="helpItem in helpBlocks"
            :key="helpItem.id"
            class="mt-2 cursor-pointer card"
            outlined
            elevation="2"
            @click="goToHelpCategory(helpItem)"
          >
            <v-list-item>
              <v-list-item-content>
                <div class="d-flex flex-column align-center justify-center">
                  <svg-icon
                    type="mdi"
                    :path="helpItem.iconPath"
                    :size="40"
                  ></svg-icon>
                  <span class="card-subtitle">{{ helpItem.title }}</span>
                </div>
              </v-list-item-content>
            </v-list-item>
          </v-card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import SvgIcon from "@jamescoyle/vue-icon";
import { mdiStore, mdiAccountGroup, mdiCart, mdiWarehouse } from "@mdi/js";
import { mapGetters } from "vuex";
export default {
  name: "HelpSearchAndCategories",
  components: { SvgIcon },
  data: () => ({
    searchValue: "",
    typingTimer: null,
    doneTypingInterval: 1500,
    helpBlocks: [],
    searching: false,
    searched: false,
    searchResult: [],
  }),
  mounted() {
    this.helpBlocks = [
      {
        id: 0,
        iconPath: mdiAccountGroup,
        title: this.$t("help.usersVenuesDepartments"),
      },
      {
        id: 1,
        iconPath: mdiStore,
        title: this.$t("help.supplersArticles"),
      },
      {
        id: 2,
        iconPath: mdiCart,
        title: this.$t("help.orders"),
      },
      {
        id: 3,
        iconPath: mdiWarehouse,
        title: this.$t("help.storage"),
      },
    ];
  },
  methods: {
    onSearchUp() {
      clearTimeout(this.typingTimer);
      this.searching = true;
      this.typingTimer = setTimeout(
        this.searchHelpArticles,
        this.doneTypingInterval
      );
    },
    onSearchDown() {
      clearTimeout(this.typingTimer);
    },
    searchHelpArticles() {
      this.searched = true;
      const articlesCopy = JSON.parse(JSON.stringify(this.getAllArticles));
      const res = [];
      if (this.searchValue) {
        articlesCopy.forEach((articleCategory) => {
          articleCategory.allArticles.forEach((article) => {
            const articleTitle = article.title;
            const articleText = article.text;
            if (
              articleTitle
                .toLowerCase()
                .includes(this.searchValue.toLowerCase()) ||
              articleText.toLowerCase().includes(this.searchValue.toLowerCase())
            ) {
              res.push({ article, category: articleCategory.name });
            }
          });
        });
        this.searchResult = res;
        this.searching = false;
      } else {
        this.searching = false;
        this.searched = false;
        this.searchResult = [];
      }
    },
    goToHelpCategory(helpItem) {
      this.$router.push(`/help/${helpItem.id}`);
    },
  },
  computed: {
    ...mapGetters({
      allHelpArticles: "help/getAllHelpArticles",
    }),
    getAllArticles() {
      return this.allHelpArticles;
    },
  },
};
</script>
<style lang="scss" scoped>
.padding-adjust {
  margin-top: 32px;
  @media screen and (max-width: 575px) {
    padding: 8px;
  }
}
.header {
  background-color: #276ef1;
  width: 100%;
  min-height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 24px;
}
.search-input {
  max-width: 500px;
  margin-top: 12px;
  min-width: 500px;
  @media screen and (max-width: 575px) {
    min-width: 300px;
  }
}
.content {
  @media screen and (max-width: 625px) {
    margin: auto;
  }
}
.cursor-pointer {
  cursor: pointer;
}
.card-subtitle {
  white-space: pre-wrap;
}
.card {
  max-width: 340px;
  min-width: 300px;
  margin-right: 5px !important;
  @media screen and (max-width: 625px) {
    max-width: 180px;
    margin-right: 5px !important;
  }
}
.marginBiggerScreens {
  @media screen and (min-width: 900px) {
    margin: auto 10vw;
  }
}
.search-results {
  margin: auto 10vw;
  text-align: center;
}
</style>
