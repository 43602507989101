<template>
  <modal-component
    :dialog="showDialog"
    @onClose="onCloseDialog"
    :adjustHeight="'none'"
  >
    <div>
      <div class="header-title mt-10">
        <span class="text-h5 primaryA--text font-weight-bold">{{
          $t("repeatOrder.repeatOrderTitle")
        }}</span>
      </div>
      <div class="content-wrap mt-8">
        <div
          class="d-flex flex-wrap justify-space-between disabled-input-fields"
        >
          <v-text-field
            class="text-input-width mr-1"
            outlined
            id="supplier"
            name="supplier"
            type="text"
            :value="
              order && order.supplier
                ? order.supplier.name
                : $t('articles.market')
            "
            readonly
            :label="$t('common.supplier')"
          ></v-text-field>
          <div>
            <v-menu
              v-model="menu2"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  class="date-picker"
                  :value="getDate()"
                  :label="$t('common.date')"
                  outlined
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="date"
                :first-day-of-week="1"
                locale="sr-Latn-RS"
                @input="menu2 = false"
                :allowed-dates="allowedDates"
                @change="onDatePicked"
                format="ampm"
              ></v-date-picker>
            </v-menu>
          </div>
          <!-- <v-text-field
            class="text-input-width mr-1"
            outlined
            id="date"
            name="date"
            type="text"
            :value="getFormattedDate(order && order.date)"
            readonly
            :label="$t('common.date')"
          ></v-text-field> -->

          <v-select
            v-if="order && order.supplier"
            class="text-input-width"
            :items="getAllContactsForSupplier"
            v-model="selectedSupplierPhone"
            :label="$t('common.contact')"
            item-text="name"
            item-value="phone"
            outlined
            required
            :rules="rules.phone"
            :hint="`Tel: ${getSelectedSupplierPhone}  ${
              selectedSupplierEmail && selectedSupplierEmail.email
                ? '- Email:' + selectedSupplierEmail.email
                : ''
            } `"
            persistent-hint
            :no-data-text="$t('newOrder.noContactsAdded')"
          >
            <template v-slot:append-item>
              <div class="d-flex justify-center align-center">
                <v-btn
                  class="btn-lowercase"
                  text
                  x-small
                  @click="handleAddContact"
                  >{{ $t("common.addContact") }}</v-btn
                >
              </div>
            </template>
          </v-select>
        </div>
        <div>
          <v-textarea
            id="smsText"
            no-resize
            readonly
            filled
            name="text-msg"
            :label="$t('newOrder.textMsg')"
            :value="getGeneratedSMSText"
          ></v-textarea>
        </div>
        <div v-if="order && order.supplier">
          <span class="text-subtitle-1 primaryA--text font-weight-bold"
            >{{ $t("newOrder.paymentMetod") }}:</span
          >
          <div class="d-flex align-center">
            <v-radio-group row v-model="paymentMethodSelected">
              <v-radio
                v-for="n in paymentMethods"
                :key="n.id"
                :label="n.text"
                :value="n.value"
              ></v-radio>
            </v-radio-group>
            <v-btn
              :disabled="paymentMethodSelected.length === 0"
              @click="paymentMethodSelected = []"
              >{{ $t("newOrder.clear") }}</v-btn
            >
          </div>
        </div>
        <div>
          <v-textarea
            id="orderNote"
            no-resize
            filled
            name="text-msg"
            :label="$t('newOrder.orderNoteLabel')"
            v-model="orderNote"
          ></v-textarea>
        </div>
        <div v-if="order && !order.supplier">
          <div class="header-title">
            <span class="text-h5 primaryA--text font-weight-bold">{{
              $t("newOrder.concludeOrder")
            }}</span>
          </div>
          <div class="mt-3 mb-3">
            <v-banner
              color="warning"
              icon="mdi-information-outline"
              elevation="6"
              rounded
              icon-color="primaryB"
            >
              <div class="d-flex align-center">
                <span class="banner-text primaryLight--text font-weight-medium">
                  {{ $t("newOrder.marketOrderSendingHint") }}
                </span>
              </div>
            </v-banner>
          </div>
          <div class="mt-5">
            <div class="d-flex justify-center align-center button-actions">
              <v-btn
                large
                class="button-width-transform mr-2"
                color="accent"
                @click="onConcludeMarketOrder"
                >{{ $t("newOrder.conclude") }}</v-btn
              >
              <div
                class="d-flex align-center pa-4 contact-box-market-order"
                @click="copyTextForSMS()"
              >
                <v-img
                  class="contact-icon mr-5"
                  max-width="18"
                  :src="require('../../../assets/img/social/CopyIcon.svg')"
                ></v-img>
                <span class="subtitle-1-text primaryA--text font-weight-bold">{{
                  $t("newOrder.copyTextMsg")
                }}</span>
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <div class="header-title">
            <span class="text-h5 primaryA--text font-weight-bold">{{
              $t("newOrder.sendOrderTo")
            }}</span>
          </div>
          <div class="mt-3 mb-3">
            <v-banner
              color="warning"
              icon="mdi-information-outline"
              elevation="6"
              rounded
              icon-color="primaryB"
            >
              <div class="d-flex align-center">
                <span class="banner-text primaryLight--text font-weight-medium">
                  {{ $t("newOrder.sendingHint") }}
                </span>
              </div>
            </v-banner>
          </div>

          <div class="mt-5">
            <div class="d-flex justify-space-between align-center">
              <div
                :class="{ disabledSend: !selectedSupplierPhone }"
                class="d-flex align-center pa-4 margin-right-adjust contact-box"
                @click="sendVia('WhatsApp')"
              >
                <v-img
                  class="contact-icon"
                  max-width="28"
                  :src="require('../../../assets/img/social/WhatsAppIcon.svg')"
                ></v-img>
                <span class="subtitle-1-text primaryA--text font-weight-bold"
                  >WhatsApp</span
                >
              </div>
              <div
                :class="{ disabledSend: !selectedSupplierPhone }"
                class="d-flex align-center pa-4 contact-box"
                @click="sendVia('Viber')"
              >
                <v-img
                  class="contact-icon"
                  max-width="28"
                  :src="require('../../../assets/img/social/ViberIcon.svg')"
                ></v-img>
                <span class="subtitle-1-text primaryA--text font-weight-bold"
                  >Viber</span
                >
              </div>
            </div>
            <div
              class="d-flex justify-space-between align-center margin-top-adjust"
            >
              <div
                :class="{ disabledSend: !selectedSupplierPhone }"
                class="d-flex align-center pa-4 margin-right-adjust contact-box"
                @click="sendVia('SMS')"
              >
                <v-img
                  class="contact-icon"
                  max-width="28"
                  :src="require('../../../assets/img/social/SmsIcon.svg')"
                ></v-img>
                <span class="subtitle-1-text primaryA--text font-weight-bold"
                  >SMS</span
                >
              </div>
              <div
                :class="{
                  disabledSend:
                    (selectedSupplierEmail && !selectedSupplierEmail.email) ||
                    !selectedSupplierEmail,
                }"
                class="d-flex align-center pa-4 contact-box"
                @click="sendVia('Email')"
              >
                <v-img
                  class="contact-icon"
                  max-width="28"
                  :src="require('../../../assets/img/social/EmailIcon.svg')"
                ></v-img>
                <span class="subtitle-1-text primaryA--text font-weight-bold"
                  >Email</span
                >
              </div>
            </div>
            <div
              class="d-flex align-center pa-4 mr-8 margin-top-adjust contact-box"
              @click="copyTextForSMS()"
            >
              <v-img
                class="contact-icon"
                max-width="18"
                :src="require('../../../assets/img/social/CopyIcon.svg')"
              ></v-img>
              <span class="subtitle-1-text primaryA--text font-weight-bold">{{
                $t("newOrder.copyTextMsg")
              }}</span>
            </div>
          </div>
        </div>
        <div class="button-actions d-flex align-center justify-center mt-8">
          <v-btn
            class="button-width-transform cancelBtn"
            color="primaryB"
            @click="onCancelOrder"
            >{{ $t("common.cancel") }}</v-btn
          >
        </div>
      </div>
      <warning-modal
        :dialog="showWarningModal"
        @onClose="handleOnClose"
        :warninModalType="warninModalType"
        :departmentID="depID"
      ></warning-modal>
      <modal-component
        :dialog="showConfirmSendingDialog"
        @onClose="handleCloseConfirmSendingDialog"
      >
        <div class="modal-confirm-content mt-6">
          <span
            class="modal-confirm-content-title text-h6 primaryA--text font-weight-black"
          >
            {{ $t("confirmModal.areYouSure") }}
          </span>
          <span
            class="modal-confirm-content-subtitle text-subtitle-2 primaryDark--text"
          >
            {{ $t("confirmModal.areYouSureSubtitle") }}
          </span>
          <div class="mt-8 d-flex align-center flex-wrap justify-center">
            <v-btn
              class="remove-uppercase"
              :width="getIsMobile ? '100%' : 'auto'"
              color="accent"
              @click="onConfirmSend"
              >{{ $t("confirmModal.send") }}</v-btn
            >
            <v-btn
              :width="getIsMobile ? '100%' : 'auto'"
              class="ml-5 remove-uppercase"
              color="tertiaryDark"
              :class="{
                'margin-adjust-button': getIsMobile,
              }"
              @click="onCancelSend"
              >{{ $t("confirmModal.cancel") }}</v-btn
            >
          </div>
        </div>
      </modal-component>
      <add-supplier-contact-modal
        :dialog="showAddSupplierContactModal"
        @onClose="handleCloseAddSupplierContactModal"
        :selectedSupplier="selectedSupplier"
        @onAddedSupplierContact="handleAddedSupplierContact"
      ></add-supplier-contact-modal>
    </div>
  </modal-component>
</template>
<script>
//components
import ModalComponent from "../../../components/ModalComponent.vue";
import WarningModal from "../../NewOrderPages/WarningModal.vue";
import AddSupplierContactModal from "../../../components/AddSupplierContactModal.vue";
//vuex
import { mapGetters } from "vuex";
//services
import EventBus from "../../../shared/EventBus";
import moment from "moment";
// shared
import {
  generateSMSText,
  generateEmailText,
  formatDate,
} from "../../../helpers/helper";

export default {
  name: "RepeatOrderModal",
  components: { WarningModal, ModalComponent, AddSupplierContactModal },
  props: { showDialog: {}, order: {} },
  data: () => ({
    showWarningModal: false,
    showConfirmSendingDialog: false,
    warninModalType: "",
    selectedType: null,
    selectedSupplierPhone: null,
    selectedSupplierEmail: null,
    rules: {
      phone: [
        (val) => (val || "").length > 0 || this.$t("common.requiredField"),
      ],
    },
    showAddSupplierContactModal: false,
    selectedSupplier: {},
    depID: null,
    isOrderChanged: false,
    paymentMethods: [],
    paymentMethodSelected: "",
    orderNote: "",
    menu2: false,
    date: "",
    todaysDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    tomorrowsDate: "",
  }),
  mounted() {
    this.selectedSupplierPhone = "";
    this.selectedSupplier = this.order && this.order.supplier;
    this.paymentMethods = [
      { id: 0, value: "cash", text: this.$t("newOrder.paymentCash") },
      {
        id: 1,
        value: "wire_transfer",
        text: this.$t("newOrder.paymentVirman"),
      },
      { id: 2, value: "credit_card", text: this.$t("newOrder.paymentCC") },
      { id: 3, value: "revers", text: this.$t("newOrder.paymentRevers") },
    ];
    this.rules = {
      phone: [
        (val) => (val || "").length > 0 || this.$t("common.requiredField"),
      ],
    };
    this.$forceUpdate();
  },
  created() {
    this.$store.dispatch("userProfile/getUserProfile");
    let today = new Date();
    let tomorrow = new Date(today);
    tomorrow.setDate(new Date().getDate() + 1);
    this.tomorrowsDate = tomorrow.toISOString().substr(0, 10);
    this.date = this.tomorrowsDate;
  },
  methods: {
    onCloseDialog() {
      this.$emit("onClose");
    },
    onBackClick() {
      this.$emit("onStepChange", this.step - 1, true);
    },
    onSaveClick() {
      this.$emit("onSaveOrder");
    },
    onCancelOrder() {
      this.$emit("onClose");
    },
    handleOnClose() {
      this.showWarningModal = false;
    },
    handleCloseConfirmSendingDialog() {
      this.showConfirmSendingDialog = false;
    },
    copyTextForSMS() {
      let copyText = document.getElementById("smsText");

      /* Select the text field */
      copyText.select();
      copyText.setSelectionRange(0, 99999); /* For mobile devices */
      /* Copy the text inside the text field */
      document.execCommand("copy");
    },
    onConfirmSend() {
      let departmentID = this.$route.params && this.$route.params.departmentID;
      departmentID = departmentID || this.order.department.id;
      this.showConfirmSendingDialog = false;
      let order = this.order;
      order.articles = this.order.products;
      order.date = new moment.utc(this.date).format();
      this.$store
        .dispatch("orders/createNewOrder", {
          departmentID: departmentID,
          order: order,
        })
        .then(
          (response) => {
            // console.log("response", response);

            this.$store
              .dispatch("orders/sendOrder", {
                id: response.data && response.data.id,
                supplierContactNumber: this.selectedSupplierPhone,
              })
              .then(() => {
                this.$emit("onCloseSent");
              });
          },
          (error) => {
            console.log("error", error);
          }
        );

      switch (this.selectedType) {
        case "WhatsApp":
          window.open(
            `https://wa.me/${this.$t("common.phoneCountryPrefix")}${
              this.getSelectedSupplierPhone
            }`,
            "_blank"
          );
          break;
        case "Viber":
          window.open(
            `viber://chat?number=${this.getSelectedSupplierPhone}&text=${this.getGeneratedSMSText}`,
            "_blank"
          );
          break;
        case "SMS":
          window.open(`sms:${this.getSelectedSupplierPhone}`, "_blank");
          break;
        case "Email":
          window.open(
            `mailto:${this.selectedSupplierEmail.email}?subject=${
              "Porudzbina za " + this.venue.name
            }&body=${this.getGeneratedSMSTextEmail}`,
            "_blank"
          );

          break;
      }
    },
    onCancelSend() {
      this.selectedType = null;
      this.showConfirmSendingDialog = false;
    },
    sendVia(type) {
      this.copyTextForSMS();
      this.selectedType = type;
      this.showConfirmSendingDialog = true;
    },
    handleAddContact() {
      this.showAddSupplierContactModal = true;
    },
    handleCloseAddSupplierContactModal() {
      this.showAddSupplierContactModal = false;
    },
    handleAddedSupplierContact() {
      this.showAddSupplierContactModal = false;
      EventBus.dispatch("showSnackbar", {
        text: this.$t("notifications.addedContactSupplier"),
      });
      this.$store.dispatch("userProfile/getUserProfile");
    },
    getFormattedDate(value) {
      const dateValue = new moment.utc(value).format();
      return formatDate(dateValue);
    },
    getPhoneFormatted(user) {
      return user && user.phone && user.phone[0] === "0"
        ? user.phone
        : "0" + user.phone;
    },
    allowedDates(val) {
      return val >= this.todaysDate;
    },
    onDatePicked(event) {
      //   if (event === this.todaysDate) {
      //     this.showSameDayWarningModal = true;
      //   }
    },
    getDate() {
      return moment.utc(this.date).format("DD.MM.YYYY");
    },
    onConcludeMarketOrder() {
      let departmentID = this.$route.params && this.$route.params.departmentID;
      departmentID = departmentID || this.order.department.id;

      this.showConfirmSendingDialog = false;
      let order = this.order;
      order.articles = this.order.products;
      order.date = new moment.utc(this.date).format();
      order.orderNote = this.orderNote;
      this.$store
        .dispatch("orders/createCustomOrder", {
          departmentID: departmentID,
          order: order,
        })
        .then(
          (response) => {
            // console.log("response", response);
            const payload = this.order.supplier
              ? {
                  id: response.data && response.data.id,
                  supplierContactNumber: this.getSupplierPhone,
                }
              : response.data && response.data.id;
            this.$store.dispatch("orders/sendCustomOrder", payload).then(() => {
              // this.$analytics.logEvent("new_order_created", {
              //   venue_name: this.venue.name,
              // });
              this.$router.push("/");
            });
          },
          (error) => {
            console.log("error", error);
          }
        );
    },
  },
  computed: {
    ...mapGetters({
      sendOrderStatus: "orders/getSendOrderStatus",
      createOrderStatus: "orders/getCreateOrderStatus",
      getUser: "auth/getUser",
      getVenue: "userProfile/getVenue",
      allSupplierContacts: "userProfile/allSupplierContacts",
    }),
    getSendOrderStatus() {
      return this.sendOrderStatus;
    },
    getCreateOrderStatus() {
      return this.createOrderStatus;
    },
    getGeneratedSMSText() {
      return (
        this.order &&
        this.order.products &&
        generateSMSText(
          this.order.products,
          this.venue.bussiness_name,
          this.venue.address,
          this.getFormattedDate(this.date),
          this.orderNote,
          this.paymentMethodSelected,
          null,
          null,
          null,
          null,
          !this.order.supplier,
          this.order.department
        )
      );
    },
    getGeneratedSMSTextEmail() {
      return (
        this.order &&
        this.order.products &&
        generateEmailText(
          this.order.products,
          this.venue.bussiness_name,
          this.venue.address,
          this.getFormattedDate(this.date),
          this.orderNote,
          this.paymentMethodSelected,
          null,
          null,
          null,
          null,
          !this.order.supplier,
          this.order.department
        )
      );
    },
    getIsMobile() {
      return this.mobile;
    },
    getSelectedSupplierPhone() {
      let phone =
        this.selectedSupplierPhone && this.selectedSupplierPhone[0] === "0"
          ? this.selectedSupplierPhone
          : "0" + this.selectedSupplierPhone;
      return phone !== "0" ? phone : "";
    },
    getAllContactsForSupplier() {
      return this.allSupplierContacts;
      // let sup = this.getSupplierContacts(this.order && this.order.supplier.id);
      // if (sup && sup.length > 0)
      //   return sup && sup.length > 0 && sup[0].contacts;
    },
    user() {
      return this.getUser;
    },
    venue() {
      return this.getVenue;
    },
  },
  watch: {
    getSelectedSupplierPhone() {
      if (!this.getSelectedSupplierPhone) {
        this.rules.phone = [
          (val) => (val || "").length > 0 || this.$t("common.requiredField"),
        ];
      }
      if (this.getAllContactsForSupplier) {
        this.selectedSupplierEmail = this.getAllContactsForSupplier.filter(
          (f) => f.phone === this.selectedSupplierPhone
        )[0];
      }
    },
    order() {
      if (this.order && this.order.supplier) {
        this.$store.dispatch("userProfile/getAllSupplierContacts", {
          supplierId: this.order.supplier.id,
          venueId: this.venue.id,
        });
      }
    },
    getAllContactsForSupplier() {
      if (this.getAllContactsForSupplier && this.order) {
        this.selectedSupplierPhone = this.getAllContactsForSupplier.find(
          (contact) => contact.phone === this.order.supplierContactNumber
        )?.phone;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.header-title {
  width: 100%;
  text-align: center;
}
.content-wrap {
  @media screen and (min-width: 960px) {
    max-width: 33vw;
    margin: 0 auto;
  }
}
.text-input-width {
  width: 178px;
}
.contact-box {
  width: 100%;
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 2px;
  background: #ffffff;
  height: 60px;
  cursor: pointer;
  position: relative;
  justify-content: center;
  @media screen and (max-width: 959px) {
    flex-direction: column;
  }
  &:hover {
    background: #f6f7f8;
  }
}
.contact-icon {
  @media screen and (max-width: 959px) {
    max-width: 20px !important;
  }
  @media screen and (min-width: 960px) {
    position: absolute;
    top: 16px;
    left: 16px;
  }
}
.margin-top-adjust {
  margin-top: 32px;
  @media screen and (max-width: 959px) {
    margin-top: 10px !important;
  }
}
.margin-right-adjust {
  margin-right: 32px;
  @media screen and (max-width: 959px) {
    margin-right: 10px !important;
  }
}
.button-actions {
  @media screen and (max-width: 959px) {
    flex-wrap: wrap;
  }
}
.button-width-transform {
  text-transform: none !important;
  @media screen and (max-width: 959px) {
    width: 100% !important;
    margin-right: 0 !important;
    margin-top: 8px;
  }
}
.archiveBtn {
  min-height: 50px;
}
.cancelBtn {
  min-height: 50px;
}
.backBtn {
  min-height: 50px;
}
.modal-confirm-content {
}
.modal-confirm-content-title {
  display: block;
  width: 100%;
}
.modal-confirm-content-subtitle {
  display: block;
  width: 100%;
}
.disabledSend {
  pointer-events: none;
  opacity: 0.5;
}
.btn-lowercase {
  text-transform: capitalize;
}
.banner-text {
  font-size: 16px;
  @media screen and(max-width:660px) {
    font-size: 14px;
  }
}
.contact-box-market-order {
  width: 50%;
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 2px;
  background: #ffffff;
  height: 50px;
  cursor: pointer;
  position: relative;
  justify-content: center;
  @media screen and (max-width: 959px) {
    // flex-direction: column;
    width: 100%;
    margin-top: 10px;
  }
  &:hover {
    background: #f6f7f8;
  }
}
</style>
