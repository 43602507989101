import api from "./api";
import {
  getArticlesDepartment,
  getArticlesSupplierURL,
  editProductURL,
  supplierArticlesCategoriesURL,
  toggleFavoriteArticleURL,
  manualAddArticleURL,
  getAllCustomArticlesURL,
  deleteCustomArticleURL,
  toggleCustomArticleFavoriteURL,
  editCustomArticleURL,
  createOrUpdateArticleURL,
  deleteArticleURL,
  REQUEST_NEW_ARTICLE,
  articleSettingsURL,
  articleCustomSettingsURL,
} from "../shared/endpoints";

class ArticlesService {
  getArticlesDepartment(
    id,
    pageNum,
    categoryId,
    searchQuery,
    departmentId,
    onlyFavorite
  ) {
    return api.get(
      getArticlesDepartment(
        id,
        pageNum,
        categoryId,
        searchQuery,
        departmentId,
        onlyFavorite
      )
    );
  }
  getAllArticlesSupplier(
    departmentId,
    supplierId,
    pageNum,
    categoryId,
    searchQuery,
    pageSize,
    onlyFavorite
  ) {
    return api.get(
      getArticlesSupplierURL(
        departmentId,
        supplierId,
        pageNum,
        categoryId,
        searchQuery,
        pageSize,
        onlyFavorite
      )
    );
  }
  editProduct(values) {
    let data = {
      productId: values.articleID,
      enabled: values.enabled,
    };
    if (values && values.defaultPrice) {
      data.defaultPrice = values.defaultPrice;
    }
    return api.post(editProductURL(values.departmentId), JSON.stringify(data));
  }
  getArticlesSupplierCategoriesService(payload) {
    return api.get(supplierArticlesCategoriesURL(payload.supplierId));
  }
  toggleFavoriteArticle(payload) {
    return api.post(
      toggleFavoriteArticleURL(payload.departmentId, payload.articleId)
    );
  }
  addNewArticle(payload) {
    return api.post(manualAddArticleURL(payload.departmentId), payload);
  }
  getCustomArticles(payload) {
    return api.get(
      getAllCustomArticlesURL(
        payload.departmentId,
        payload.pageNum,
        payload.searchQuery,
        payload.pageSize,
        payload.onlyFavorite,
        payload.categoryName
      )
    );
  }
  deleteCustomArticle(payload) {
    return api.delete(
      deleteCustomArticleURL(payload.departmentId, payload.articleId)
    );
  }
  toggleCustomArticleFavorite(payload) {
    return api.post(
      toggleCustomArticleFavoriteURL(payload.departmentId, payload.articleId)
    );
  }
  editCustomArticle(payload) {
    return api.post(
      editCustomArticleURL(payload.departmentId, payload.articleId),
      payload.article
    );
  }
  createOrUpdateArticle(payload) {
    return api.post(
      createOrUpdateArticleURL(payload.supplierId),
      payload.article
    );
  }
  deleteArticle(payload) {
    return api.delete(deleteArticleURL(payload.articleId));
  }
  requestArticle(payload) {
    return api.post(REQUEST_NEW_ARTICLE, JSON.stringify(payload));
  }
  updateArticleSetting(payload) {
    const data = {
      recommendedQuantity: payload.recommendedQuantity,
      comment: payload.comment,
    };
    return api.post(
      articleSettingsURL(payload.departmentId, payload.articleId),
      JSON.stringify(data)
    );
  }
  updateCustomArticleSetting(payload) {
    const data = {
      recommendedQuantity: payload.recommendedQuantity,
      comment: payload.comment,
    };
    return api.post(
      articleCustomSettingsURL(payload.departmentId, payload.articleId),
      JSON.stringify(data)
    );
  }
}
export default new ArticlesService();
