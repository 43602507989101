import axiosInstance from "./api";
import TokenService from "./token.service";

const setup = (store) => {
  axiosInstance.interceptors.request.use(
    (config) => {
      const token = TokenService.getLocalAccessToken();
      if (token && config.url !== "/login" && config.url !== "/refresh_token") {
        config.headers["Authorization"] = `Bearer ${token}`;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  axiosInstance.interceptors.response.use(
    (res) => {
      return res;
    },
    async (err) => {
      // if (!err.response) {
      //   console.log("OVDE", err.response);
      //   store.dispatch("auth/logout");
      //   window.location = "/login";
      // }
      const originalConfig = err.config;
      const jwtExpired =
        err?.response?.status === 401 &&
        err?.response?.data?.message === "Invalid Token";
      if (originalConfig.url !== "/login" && err.response) {
        if (jwtExpired && !originalConfig._retry) {
          originalConfig._retry = true;

          try {
            const rs = await axiosInstance.post("/refresh_token", {
              refresh_token: TokenService.getLocalRefreshToken(),
            });
            // .then((res) => console.log("res", res));

            const { token, refresh_token } = rs.data;

            store.dispatch("auth/refreshToken", token, refresh_token);
            TokenService.updateLocalAccessToken(token, refresh_token);
            return axiosInstance(originalConfig);
          } catch (_error) {
            store.dispatch("auth/logout");
            window.location = "/login";

            return Promise.reject(_error);
          }
        }
      }
      return Promise.reject(err);
    }
  );
};

export default setup;
