<template>
  <div class="pt-6 pr-2 pb-6 pl-2">
    <div class="pa-4">
      <v-tabs v-if="!isBasicPlan" @change="tabSelected" centered>
        <v-tab class="item-text text-body-1 font-weight-bold">{{
          $t("articles.suppliers")
        }}</v-tab>
        <v-tab class="item-text text-body-1 font-weight-bold">{{
          $t("articles.market")
        }}</v-tab>
      </v-tabs>
    </div>
    <articles-page v-if="selectedTab === 'articles'"></articles-page>
    <market-page v-else></market-page>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import ArticlesPage from "./ArticlesPage.vue";
import MarketPage from "./MarketPage.vue";
export default {
  name: "ArticlesDashboard",
  components: { ArticlesPage, MarketPage },
  props: {},
  data: () => ({
    selectedTab: "articles",
  }),
  mounted() {},
  created() {},
  methods: {
    tabSelected(event) {
      this.selectedTab = event === 0 ? "articles" : "market";
    },
  },
  computed: {
    ...mapGetters({
      basicPlan: "subscription/isBasicPlan",
    }),
    isBasicPlan() {
      return this.basicPlan;
    },
  },
};
</script>
<style scoped lang="scss">
.item-text {
  text-transform: none !important;
}
</style>
