<template>
  <v-dialog
    v-model="showDialog"
    width="626"
    class="dialog-style"
    persistent
    :fullscreen="getIsMobile"
  >
    <div class="dialog-style-content">
      <div class="header mb-6">
        <div class="d-flex justify-space-between align-center">
          <span class="text-h5 primaryA--text font-weight-bold">{{
            $t("articles.requestForArticleTitle")
          }}</span>
          <v-img
            @click="closeDialog"
            class="close-icon"
            max-width="20px"
            :src="require('../../../assets/img/CloseIcon.svg')"
          >
          </v-img>
        </div>
      </div>
      <v-divider></v-divider>
      <div class="pt-6">
        <v-select
          class="select-width"
          :items="getAllSuppliers"
          :placeholder="$t('common.suppliers')"
          v-model="selectedSupplierId"
          item-text="name"
          item-value="id"
          solo
          required
          :disabled="true"
        ></v-select>
        <v-text-field
          name="name"
          id="name"
          type="text"
          :placeholder="$t('editArticle.name')"
          :label="$t('editArticle.name')"
          outlined
          color="accent"
          required
          :rules="rules.name"
          v-model="article.name"
        ></v-text-field>
        <v-text-field
          name="unit"
          id="unit"
          type="text"
          :placeholder="$t('articles.requestArticleUnitPlaceholder')"
          :label="$t('articles.requestArticleUnit')"
          outlined
          color="accent"
          v-model="article.unit"
        ></v-text-field>
      </div>
      <div class="d-flex">
        <v-btn @click="closeDialog">{{ $t("suppliers.close") }}</v-btn>
        <v-btn
          :disabled="isFormValid"
          class="ml-3"
          @click="onRequestArticle"
          color="accent"
          >{{ $t("articles.sendRequest") }}</v-btn
        >
      </div>
    </div>
  </v-dialog>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "RequestArticleModal",
  props: {
    dialog: {},
    supplierId: {},
    departmentId: {},
    requestedArticleName: {},
  },
  data: () => ({
    showDialog: false,
    article: {},
    rules: {},
    isFormValid: true,
    selectedSupplierId: null,
    selectedDepartmentId: null,
  }),
  created() {
    this.showDialog = this.dialog;
    this.rules = {
      name: [
        (val) => (val || "").length > 0 || this.$t("common.requiredField"),
      ],
      storeName: [
        (val) => (val || "").length > 0 || this.$t("common.requiredField"),
      ],
      category: [
        (val) => (val || "").length > 0 || this.$t("common.requiredField"),
      ],
      department: [
        (val) => (val || "").length > 0 || this.$t("common.requiredField"),
      ],
    };
  },
  mounted() {
    this.selectedSupplierId = this.supplierId;
    this.article.name = this.requestedArticleName;
    this.article.departmentId = this.departmentId;
    this.article.supplierId = this.supplierId;
  },
  methods: {
    closeDialog() {
      this.resetForm();
      this.$emit("onClose");
    },
    resetForm() {
      this.article = {
        name: "",
        unit: "",
        supplierId: "",
        departmentId: "",
      };
    },
    onRequestArticle() {
      const payload = {
        departmentId: this.departmentId,
        userId: this.userProfile.id,
        supplierId: this.supplierId,
        articleName: this.article.name,
        articleUnit: this.article.unit,
      };
      this.$store.dispatch("articles/requestArticle", payload).then(() => {
        this.$emit("onSuccessRequestSent");
      });
    },
  },
  computed: {
    ...mapGetters({
      allSuppliers: "supplier/getDepartmentSuppliers",
      getVenue: "userProfile/getVenue",
      userProfile: "userProfile/getUserProfile",
    }),
    getIsMobile() {
      return this.$isMobile;
    },
    articleName() {
      return this.article.name;
    },
    getAllSuppliers() {
      return this.allSuppliers;
    },
    supplierIdComputed() {
      return this.supplierId;
    },
  },
  watch: {
    dialog() {
      this.showDialog = this.dialog;
    },
    articleName() {
      this.isFormValid = this.articleName === "" ? true : false;
    },
    supplierId() {
      this.selectedSupplierId = this.supplierId;
    },
  },
};
</script>
<style scoped lang="scss">
.dialog-style {
  background-color: #ffffff;
  padding: 24px;
  @media screen and (max-width: 959px) {
    height: 100%;
  }
}
.dialog-style-content {
  background-color: #ffffff;
  padding: 24px;
  @media screen and (max-width: 959px) {
    height: 100%;
  }
}
.close-icon {
  cursor: pointer;
}
.price-input {
  max-width: 200px;
  min-width: 60px;
  position: relative;
}
</style>
