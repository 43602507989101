<template>
  <modal-component
    :dialog="dialog"
    @onClose="$emit('onClose')"
    :modalWidth="'540px'"
  >
    <div class="dialog-style">
      <div class="header">
        <span class="text-h5 font-weight-black">{{
          $t("changeOrderDateModal.title")
        }}</span>
      </div>

      <span class="text-caption mt-2 secondaryDark--text font-weight-black">{{
        $t("changeOrderDateModal.description")
      }}</span>
      <div class="mt-5 width100">
        <v-menu
          v-model="menu2"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              class="date-picker"
              :value="getDate()"
              :label="$t('common.date')"
              outlined
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="newDateForOrder"
            :first-day-of-week="1"
            locale="sr-Latn-RS"
            @input="menu2 = false"
            :allowed-dates="allowedDates"
            @change="onDatePicked"
            format="ampm"
          ></v-date-picker>
        </v-menu>
      </div>
      <div class="d-flex">
        <v-btn
          small
          dark
          color="accent"
          @click="$emit('onClose')"
          class="mr-2"
          >{{ $t("common.close") }}</v-btn
        >
        <v-progress-circular
          v-if="isOrderUpdating"
          indeterminate
          color="primary"
        ></v-progress-circular>

        <v-btn v-else small dark color="accent" @click="onUpdateOrderDate()">{{
          $t("common.save")
        }}</v-btn>
      </div>
    </div>
  </modal-component>
</template>
<script>
import ModalComponent from "../components/ModalComponent.vue";
import moment from "moment";
import { mapGetters } from "vuex";
export default {
  name: "ChangeOrderDateModal",
  components: { ModalComponent },
  props: {
    dialog: {},
    orderToChangeDate: {},
  },
  data: () => ({
    newDateForOrder: "",
    menu2: false,
    todaysDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
  }),
  created() {},
  mounted() {
    this.newDateForOrder = this.todaysDate;
  },
  methods: {
    closePreviewDialog() {
      this.$emit("onClose");
    },
    allowedDates(val) {
      return val >= this.todaysDate;
    },
    onDatePicked(event) {
      console.log("event", event);
    },
    getDate() {
      return moment.utc(this.newDateForOrder).format("DD.MM.YYYY");
    },
    onUpdateOrderDate() {
      let utcOrderDate = new moment.utc(this.newDateForOrder).format();
      const order = JSON.parse(JSON.stringify(this.orderToChangeDate));
      order.date = utcOrderDate;
      order.articles = order.products;
      const isMarketOrder = !order.supplier;

      this.$store
        .dispatch(
          isMarketOrder ? "orders/updateCustomOrder" : "orders/updateOrder",
          { orderId: order.id, order: order }
        )
        .then(() => {
          this.$emit("onUpdatedOrderDate");
        });
    },
  },
  computed: {
    ...mapGetters({
      getUpdateOrderStatus: "orders/getUpdateOrderStatus",
      getUpdateCustomOrderStatus: "orders/getUpdateCustomOrderStatus",
    }),
    isOrderUpdating() {
      return (
        this.getUpdateOrderStatus === "REQUEST_PENDING" ||
        this.getUpdateCustomOrderStatus === "REQUEST_PENDING"
      );
    },
  },
};
</script>
<style scoped lang="scss">
.dialog-style {
  background-color: #ffffff;

  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.info-text {
  text-align: center;
}
.header {
  position: relative;
}
.margin-adjust-button {
  margin-left: 0 !important;
  margin-top: 12px;
}
.remove-uppercase {
  text-transform: none !important;
}
.width100 {
  width: 100%;
}
</style>
