<template>
  <div class="text-center">
    <v-dialog
      v-model="showDialog"
      width="626"
      class="dialog-style"
      persistent
      :fullscreen="getIsMobile"
    >
      <div class="dialog-style-content">
        <div class="header mb-6">
          <div class="d-flex justify-space-between align-center">
            <span class="text-h5 primaryA--text font-weight-bold">{{
              !editSupplierData
                ? $t("suppliers.addSupplier")
                : $t("editSupplier.title", {
                    supplierName: editSupplierData.name,
                  })
            }}</span>
            <v-img
              @click="closeDialog"
              class="close-icon"
              max-width="20px"
              :src="require('../assets/img/CloseIcon.svg')"
            >
            </v-img>
          </div>
        </div>
        <v-divider></v-divider>
        <div>
          <div class="pt-6">
            <v-text-field
              name="supplierName"
              id="supplierName"
              type="text"
              :placeholder="$t('common.name')"
              :label="$t('common.name')"
              outlined
              color="accent"
              required
              :rules="rules.name"
              v-model="supplier.name"
            ></v-text-field>
            <v-text-field
              name="supplierPib"
              id="supplierPib"
              type="number"
              :placeholder="$t('common.pib')"
              :label="$t('common.pib')"
              outlined
              color="accent"
              required
              :rules="rules.pib"
              v-model="supplier.pib"
              hide-spin-buttons
            ></v-text-field>
            <v-text-field
              name="supplierRegNumber"
              id="supplierRegNumber"
              type="text"
              :placeholder="$t('suppliers.registrationNumber')"
              :label="$t('suppliers.registrationNumber')"
              outlined
              color="accent"
              :rules="rules.registrationNumber"
              v-model="supplier.registrationNumber"
            ></v-text-field>
            <v-text-field
              name="supplierAddress"
              id="supplierAddress"
              type="text"
              :placeholder="$t('suppliers.address')"
              :label="$t('suppliers.address')"
              outlined
              color="accent"
              required
              :rules="rules.address"
              v-model="supplier.address"
            ></v-text-field>
            <v-text-field
              name="postNumber"
              id="postNumber"
              type="text"
              :placeholder="$t('suppliers.postNumber')"
              :label="$t('suppliers.postNumber')"
              outlined
              color="accent"
              required
              :rules="rules.postNumber"
              v-model="supplier.postNumber"
            ></v-text-field>
            <v-text-field
              name="supplierCity"
              id="supplierCity"
              type="text"
              :placeholder="$t('suppliers.city')"
              :label="$t('suppliers.city')"
              outlined
              color="accent"
              required
              :rules="rules.city"
              v-model="supplier.city"
            ></v-text-field>
            <v-text-field
              name="supplierEmail"
              id="supplierEmail"
              type="text"
              :placeholder="$t('suppliers.email')"
              :label="$t('suppliers.email')"
              outlined
              color="accent"
              required
              :rules="rules.email"
              v-model="supplier.email"
            ></v-text-field>
            <!-- <v-radio-group
              class="mb-2 mt-0"
              v-model="supplier.isRestaurant"
              row
              :rules="rules.isRestaurant"
            >
              <v-radio :label="$t('common.restaurant')" :value="true"></v-radio>
              <v-radio :label="$t('common.supplier')" :value="false"></v-radio>
            </v-radio-group> -->
            <v-text-field
              name="supplierPhone"
              id="supplierPhone"
              type="text"
              :placeholder="$t('suppliers.phone')"
              :label="$t('suppliers.phone')"
              outlined
              color="accent"
              required
              :rules="rules.phone"
              v-model="supplier.phone"
            ></v-text-field>

            <v-text-field
              name="supplierBankAccount"
              id="supplierBankAccount"
              type="text"
              :placeholder="$t('suppliers.bankAccountNumber')"
              :label="$t('suppliers.bankAccountNumber')"
              outlined
              color="accent"
              required
              :rules="rules.bankAccountNumber"
              v-model="supplier.bankAccountNumber"
            ></v-text-field>

            <v-divider></v-divider>
          </div>
        </div>
        <div class="d-flex justify-center mt-6">
          <v-btn @click="closeDialog">{{ $t("suppliers.close") }}</v-btn>
          <v-btn
            v-if="!editSupplierData"
            :disabled="!isFormValid"
            class="ml-3"
            @click="addSupplier"
            color="accent"
            >{{ $t("suppliers.add") }}</v-btn
          >
          <v-btn
            v-else
            :disabled="!isFormValid"
            class="ml-3"
            @click="saveSupplierData"
            color="accent"
            >{{ $t("common.save") }}</v-btn
          >
        </div>
      </div>
    </v-dialog>
  </div>
</template>
<script>
export default {
  name: "AddSupplierModal",
  props: { dialog: {}, editSupplierData: {} },
  data: () => ({
    showDialog: false,
    supplier: {},
    rules: {},
    pibIsValid: false,
    regNumberIsValid: true,
  }),
  created() {
    this.showDialog = this.dialog;
    this.rules = {
      name: [
        (val) => (val || "").length > 0 || this.$t("common.requiredField"),
      ],
      address: [
        (val) => (val || "").length > 0 || this.$t("common.requiredField"),
      ],
      pib: [(val) => (val || "").length > 0 || this.$t("common.requiredField")],
      postNumber: [
        (val) => (val || "").length > 0 || this.$t("common.requiredField"),
      ],
      city: [
        (val) => (val || "").length > 0 || this.$t("common.requiredField"),
      ],
      email: [
        (val) => (val || "").length > 0 || this.$t("common.requiredField"),
      ],
      phone: [
        (val) => (val || "").length > 0 || this.$t("common.requiredField"),
      ],
      registrationNumber: [],
      bankAccountNumber: [
        (val) => (val || "").length > 0 || this.$t("common.requiredField"),
      ],
      // isRestaurant: [(val) => val !== null || this.$t("common.requiredField")],
    };
  },
  mounted() {
    if (this.editSupplierData) {
      this.supplier = this.editSupplierData;
    } else {
      this.supplier = {
        name: "",
        address: "",
        pib: "",
        postNumber: "",
        city: "",
        email: "",
        phone: "",
        registrationNumber: "",
        bankAccountNumber: "",
        // isRestaurant: null,
      };
    }
  },
  methods: {
    closeDialog() {
      this.resetForm();
      this.$emit("onClose");
    },
    addSupplier() {
      this.$emit("onAddSupplier", this.supplier);
      this.resetForm();
    },
    saveSupplierData() {
      this.$emit("onSaveSupplier", this.supplier);
      this.resetForm();
    },
    resetForm() {
      this.supplier = {
        name: "",
        address: "",
        pib: "",
        postNumber: "",
        city: "",
        email: "",
        phone: "",
        registrationNumber: "",
        bankAccountNumber: "",
        // isRestaurant: null,
      };
      this.rules.name = [];
      this.rules.address = [];
      this.rules.pib = [];
      this.rules.postNumber = [];
      this.rules.city = [];
      this.rules.email = [];
      this.rules.phone = [];
      this.rules.registrationNumber = [];
      this.rules.bankAccountNumber = [];
      // this.rules.isRestaurant = [];
    },
  },
  computed: {
    isFormValid() {
      return (
        this.supplier.name !== "" &&
        this.supplier.address !== "" &&
        this.supplier.pib !== "" &&
        this.pibIsValid &&
        this.supplier.postNumber !== "" &&
        this.supplier.city !== "" &&
        this.supplier.email !== "" &&
        this.supplier.phone !== "" &&
        this.supplier.bankAccountNumber !== "" &&
        this.regNumberIsValid
        // this.supplier.isRestaurant !== null
      );
    },
    getPibValue() {
      return this.supplier && this.supplier.pib;
    },
    getRegistrationNumberValue() {
      return this.supplier && this.supplier.registrationNumber;
    },
    getIsMobile() {
      return this.$isMobile;
    },
  },
  watch: {
    dialog() {
      this.showDialog = this.dialog;
    },
    getPibValue() {
      if (
        this.getPibValue &&
        (this.getPibValue.length < 9 || this.getPibValue.length > 13)
      ) {
        this.rules.pib = [this.$t("common.pibConstraint")];
        this.pibIsValid = false;
      } else if (!this.getPibValue) {
        this.rules.pib = [
          (val) => (val || "").length > 0 || this.$t("common.requiredField"),
        ];
        this.pibIsValid = false;
      } else {
        this.rules.pib = [];
        this.pibIsValid = true;
      }
    },
    getRegistrationNumberValue() {
      if (
        (this.getRegistrationNumberValue &&
          this.getRegistrationNumberValue.length === 8) ||
        !this.getRegistrationNumberValue
      ) {
        this.rules.registrationNumber = [];
        this.regNumberIsValid = true;
      } else {
        this.rules.registrationNumber = [this.$t("common.regNumberConstraint")];
        this.regNumberIsValid = false;
      }
    },
    editSupplierData() {
      if (this.editSupplierData) {
        this.supplier = JSON.parse(JSON.stringify(this.editSupplierData));
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.dialog-style {
  background-color: #ffffff;
  padding: 24px;
  @media screen and (max-width: 959px) {
    height: 100%;
  }
}
.dialog-style-content {
  background-color: #ffffff;
  padding: 24px;
}
.close-icon {
  cursor: pointer;
}
.info-text {
  text-align: center;
}
</style>
