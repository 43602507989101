import UserProfileService from "../services/user-profile.service";
import {
  REQUEST_FAILURE,
  REQUEST_PENDING,
  REQUEST_SUCCESS,
  REQUEST_UNDEFINED,
} from "../shared/statuses";

const initialState = {
  userProfile: {},
  userProfileStatus: REQUEST_UNDEFINED,
  stations: [],
  suppliers: [],
  venue: {},
  updateUserProfileStatus: REQUEST_UNDEFINED,
  checkIfPibExistsStatus: REQUEST_UNDEFINED,
  checkIfPibExistsState: {},
  requestVenueAccessStatus: REQUEST_UNDEFINED,
  approveUserRequestStatus: REQUEST_UNDEFINED,
  makeAdminRequestStatus: REQUEST_UNDEFINED,
  removeAdminRequestStatus: REQUEST_UNDEFINED,
  isVenueDisabled: false,
  isUserVerified: false,
  allUsers: [],
  changeUserPasswordStatus: REQUEST_UNDEFINED,
  venueDepartmentsStatus: REQUEST_UNDEFINED,
  getSuppliersStatus: REQUEST_UNDEFINED,
  getSupplierContactsStatus: REQUEST_UNDEFINED,
  selectedSupplierContacts: [],
  selectedSupplierNote: null,
  getSupplierNoteStatus: REQUEST_UNDEFINED,
};

export const userProfile = {
  namespaced: true,
  state: initialState,
  actions: {
    getUserProfile({ commit }) {
      commit("getUserProfilePending");
      return UserProfileService.getUserProfile().then(
        (response) => {
          commit("getUserProfileSuccess", response.data);
          return Promise.resolve(response);
        },
        (error) => {
          commit("getUserProfileFailure");
          return Promise.reject(error);
        }
      );
    },
    updateUserProfile({ commit }, data) {
      commit("updateUserProfilePending");
      return UserProfileService.updateUserProfile(data).then(
        (response) => {
          commit("updateUserProfileSuccess");
          return Promise.resolve(response);
        },
        (error) => {
          commit("updateUserProfileFailure");
          return Promise.reject(error);
        }
      );
    },
    checkIfVenuePibExists({ commit }, pib) {
      commit("checkIfVenuePibExistsPending");
      return UserProfileService.checkPibExistsService(pib).then(
        (response) => {
          commit("checkIfVenuePibExistsSuccess", response.data);
        },
        (error) => {
          commit("checkIfVenuePibExistsFailure");
          return Promise.reject(error);
        }
      );
    },
    requestVenueAccess({ commit }, pib) {
      commit("requestVenueAccessPending");
      return UserProfileService.requestVenueAccessService(pib).then(
        () => {
          commit("requestVenueAccessSuccess");
        },
        (error) => {
          commit("requestVenueAccessFailure");
          return Promise.reject(error);
        }
      );
    },
    approveUserRequest({ commit }, inviteId) {
      commit("approveUserRequestPending");
      return UserProfileService.approveUserRequestService(inviteId).then(
        () => {
          commit("approveUserRequestSuccess");
        },
        (error) => {
          commit("approveUserRequestFailure");
          return Promise.reject(error);
        }
      );
    },
    makeAdminUser({ commit }, id) {
      commit("makeAdminUserPending");
      return UserProfileService.makeAdminService(id).then(
        () => {
          commit("makeAdminUserSuccess");
        },
        (error) => {
          commit("makeAdminUserFailure");
          return Promise.reject(error);
        }
      );
    },
    removeAdminUser({ commit }, id) {
      commit("removeAdminUserPending");
      return UserProfileService.removeAdminService(id).then(
        () => {
          commit("removeAdminUserSuccess");
        },
        (error) => {
          commit("removeAdminUserFailure");
          return Promise.reject(error);
        }
      );
    },
    updateUserPassword({ commit }, data) {
      commit("changeUserPasswordPending");
      return UserProfileService.changeUserPasswordService(data).then(
        () => {
          commit("changeUserPasswordSuccess");
        },
        (error) => {
          commit("changeUserPasswordFailure");
          return Promise.reject(error);
        }
      );
    },
    getDepartments({ commit }) {
      commit("getDepartmentsPending");
      return UserProfileService.getDepartments().then(
        (response) => {
          commit("getDepartmentsSuccess", response.data);
          return Promise.resolve(response);
        },
        () => {
          commit("getDepartmentsFailure");
        }
      );
    },
    getVenueSuppliers({ commit }, data) {
      commit("getSuppliersPending");
      return UserProfileService.getSuppliers(data).then(
        (response) => {
          commit("getSuppliersSuccess", response.data);
          return Promise.resolve(response);
        },
        (error) => {
          commit("getSuppliersFailure");
          return Promise.reject(error);
        }
      );
    },
    getAllSupplierContacts({ commit }, data) {
      commit("getSupplierContactsPending");
      return UserProfileService.getSupplierContacts(data).then(
        (response) => {
          commit("getSupplierContactsSuccess", response.data);
          return Promise.resolve(response);
        },
        (error) => {
          commit("getSupplierContactsFailure");
          return Promise.reject(error);
        }
      );
    },
    getSupplierNote({ commit }, data) {
      commit("getSupplierNotePending");
      return UserProfileService.getSupplierNote(data).then(
        (response) => {
          commit("getSupplierNoteSuccess", response);
          return Promise.resolve(response);
        },
        (error) => {
          commit("getSupplierNoteFailure");
          return Promise.reject(error);
        }
      );
    },
  },
  mutations: {
    getUserProfileSuccess(state, userProfile) {
      state.userProfileStatus = REQUEST_SUCCESS;
      state.userProfile = userProfile;
      // state.stations = userProfile?.stations;
      state.suppliers = userProfile?.suppliers;
      // state.stations = userProfile?.stations;
      // state.suppliers = userProfile?.suppliers;
      state.venue = userProfile?.venue;
      state.isUserVerified = userProfile.verified;
      state.isVenueDisabled = userProfile?.venue?.disabled;
      state.allUsers = userProfile?.venueUsers;
    },
    getUserProfilePending(state) {
      state.userProfileStatus = REQUEST_PENDING;
    },
    getUserProfileFailure(state) {
      state.userProfileStatus = REQUEST_FAILURE;
    },
    updateUserProfileSuccess(state) {
      state.updateUserProfileStatus = REQUEST_SUCCESS;
    },
    updateUserProfilePending(state) {
      state.updateUserProfileStatus = REQUEST_PENDING;
    },
    updateUserProfileFailure(state) {
      state.updateUserProfileStatus = REQUEST_FAILURE;
    },
    checkIfVenuePibExistsPending(state) {
      state.checkIfPibExistsStatus = REQUEST_PENDING;
    },
    checkIfVenuePibExistsSuccess(state, payload) {
      state.checkIfPibExistsStatus = REQUEST_SUCCESS;
      state.checkIfPibExistsState = payload;
    },
    checkIfVenuePibExistsFailure(state) {
      state.checkIfPibExistsStatus = REQUEST_FAILURE;
    },
    requestVenueAccessPending(state) {
      state.requestVenueAccessStatus = REQUEST_PENDING;
    },
    requestVenueAccessSuccess(state) {
      state.requestVenueAccessStatus = REQUEST_SUCCESS;
    },
    requestVenueAccessFailure(state) {
      state.requestVenueAccessStatus = REQUEST_FAILURE;
    },
    approveUserRequestPending(state) {
      state.approveUserRequestStatus = REQUEST_PENDING;
    },
    approveUserRequestSuccess(state) {
      state.approveUserRequestStatus = REQUEST_SUCCESS;
    },
    approveUserRequestFailure(state) {
      state.approveUserRequestStatus = REQUEST_FAILURE;
    },
    makeAdminUserPending(state) {
      state.makeAdminRequestStatus = REQUEST_PENDING;
    },
    makeAdminUserSuccess(state) {
      state.makeAdminRequestStatus = REQUEST_SUCCESS;
    },
    makeAdminUserFailure(state) {
      state.makeAdminRequestStatus = REQUEST_FAILURE;
    },
    removeAdminUserPending(state) {
      state.removeAdminRequestStatus = REQUEST_PENDING;
    },
    removeAdminUserSuccess(state) {
      state.removeAdminRequestStatus = REQUEST_SUCCESS;
    },
    removeAdminUserFailure(state) {
      state.removeAdminRequestStatus = REQUEST_FAILURE;
    },
    changeUserPasswordPending(state) {
      state.changeUserPasswordStatus = REQUEST_PENDING;
    },
    changeUserPasswordSuccess(state) {
      state.changeUserPasswordStatus = REQUEST_SUCCESS;
    },
    changeUserPasswordFailure(state) {
      state.changeUserPasswordStatus = REQUEST_FAILURE;
    },
    getDepartmentsPending(state) {
      state.venueDepartmentsStatus = REQUEST_PENDING;
      state.stations = [];
    },
    getDepartmentsSuccess(state, payload) {
      state.venueDepartmentsStatus = REQUEST_SUCCESS;
      state.stations = payload;
    },
    getDepartmentsFailure(state) {
      state.venueDepartmentsStatus = REQUEST_FAILURE;
      state.stations = [];
    },
    getSuppliersPending(state) {
      state.getSuppliersStatus = REQUEST_PENDING;
    },
    getSuppliersSuccess(state, payload) {
      state.getSuppliersStatus = REQUEST_SUCCESS;
      state.suppliers = payload;
    },
    getSuppliersFailure(state) {
      state.getSuppliersStatus = REQUEST_FAILURE;
    },
    getSupplierContactsPending(state) {
      state.getSupplierContactsStatus = REQUEST_PENDING;
      state.selectedSupplierContacts = [];
    },
    getSupplierContactsSuccess(state, payload) {
      state.getSupplierContactsStatus = REQUEST_SUCCESS;
      state.selectedSupplierContacts = payload;
    },
    getSupplierContactsFailure(state) {
      state.getSupplierContactsStatus = REQUEST_FAILURE;
      state.selectedSupplierContacts = [];
    },
    getSupplierNotePending(state) {
      state.getSupplierNoteStatus = REQUEST_PENDING;
      state.selectedSupplierContacts = [];
    },
    getSupplierNoteSuccess(state, payload) {
      state.getSupplierNoteStatus = REQUEST_SUCCESS;
      console.lo;
      state.selectedSupplierNote = payload.data;
    },
    getSupplierNoteFailure(state) {
      state.getSupplierNoteStatus = REQUEST_FAILURE;
      state.selectedSupplierContacts = [];
    },
  },
  getters: {
    getUserProfile: (state) => {
      return state.userProfile;
    },
    getStations: (state) => {
      return state.stations;
    },
    getSuppliers: (state) => {
      return state.suppliers;
    },
    getVenue: (state) => {
      return state.venue;
    },
    getUserProfileStatus: (state) => {
      return state.userProfileStatus;
    },
    getUpdateUserProfileStatus: (state) => {
      return state.updateUserProfileStatus;
    },
    getCheckIfPibExistsState: (state) => {
      return state.checkIfPibExistsState;
    },
    getCheckIfPibExistsStatus: (state) => {
      return state.checkIfPibExistsStatus;
    },
    getRequestVenueAccessStatus: (state) => {
      return state.requestVenueAccessStatus;
    },
    getMakeAdminUserRequestStatus: (state) => {
      return state.makeAdminRequestStatus;
    },
    getRemoveAdminUserRequestStatus: (state) => {
      return state.removeAdminRequestStatus;
    },
    getIsVenueDisabled: (state) => {
      return state.isVenueDisabled;
    },
    getIsUserVerified: (state) => {
      return state.isUserVerified;
    },
    getAllUsers: (state) => {
      return state.allUsers;
    },
    getVenueDepartmentsStatus: (state) => {
      return state.venueDepartmentsStatus;
    },
    allSupplierContacts: (state) => {
      return state?.selectedSupplierContacts;
    },
    suppliersStatus: (state) => {
      return state.getSuppliersStatus;
    },
    allSupplierContactsStatus: (state) => {
      return state.getSupplierContactsStatus;
    },
    getSelectedSupplierNote: (state) => {
      return state.selectedSupplierNote;
    },
  },
};
