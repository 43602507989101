<template>
  <div class="width-max" :class="[getIsMobile ? 'mt-2' : 'mt-10']">
    <div class="d-flex justify-center">
      <span
        class="text-h5 primaryA--text font-weight-bold"
        :class="{ 'text-h6': getIsMobile }"
        >{{ $t("newOrder.chooseDate") }}</span
      >
    </div>
    <div class="mt-5">
      <v-menu
        v-model="menu2"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            class="date-picker"
            :value="getDate()"
            :label="$t('common.date')"
            outlined
            readonly
            v-bind="attrs"
            v-on="on"
            :disabled="disableDateField"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="date"
          :first-day-of-week="1"
          locale="sr-Latn-RS"
          @input="menu2 = false"
          :allowed-dates="allowedDates"
          @change="onDatePicked"
          format="ampm"
        ></v-date-picker>
      </v-menu>
    </div>
    <div>
      <v-select
        :items="allSuppliers"
        :label="$t('common.supplier')"
        v-model="selectedSupplier"
        item-text="name"
        item-value="pib"
        outlined
        @change="onSupplierSelected"
        :disabled="isEdit"
      >
      </v-select>
    </div>
    <div class="d-flex justify-center">
      <v-btn
        :disabled="!date || !selectedSupplier"
        class="continue-button"
        color="accent"
        @click="onContinueClick"
        >{{ $t("newOrder.continue") }}</v-btn
      >
    </div>
    <div v-if="selectedSupplier && getNotes" class="mt-3">
      <v-expansion-panels>
        <v-expansion-panel>
          <v-expansion-panel-header>
            {{ $t("common.basicSupplierInformation") }}
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <div class="pl-4 pr-4 pt-8">
              <div>
                <div class="d-flex flex-column align-start">
                  <span class="text-body-2 font-weight-bold mb-1">{{
                    $t("previewSupplierNotesModal.timeForOrdering")
                  }}</span>
                  <span>{{ getNotes && getNotes.timeToOrder }}</span>
                </div>
                <v-divider class="mt-3 mb-3"></v-divider>
                <div class="d-flex flex-column align-start">
                  <span class="text-body-2 font-weight-bold mb-1">{{
                    $t("previewSupplierNotesModal.timeForDelivery")
                  }}</span>
                  <span>{{ getNotes && getNotes.timeToDeliver }}</span>
                </div>
                <v-divider class="mt-3 mb-3"></v-divider>
                <div class="d-flex flex-column align-start">
                  <span class="text-body-2 font-weight-bold mb-1">{{
                    $t("previewSupplierNotesModal.workingDays")
                  }}</span>
                  <div>
                    <v-chip
                      v-for="day in daysInWeek"
                      :key="day.id"
                      :color="day.color"
                      >{{ day.label }}</v-chip
                    >
                  </div>
                </div>
                <v-divider class="mt-3 mb-3"></v-divider>
                <div
                  v-if="getNotes && getNotes.note"
                  class="d-flex flex-column align-start"
                >
                  <span class="text-body-2 font-weight-bold mb-1">{{
                    $t("previewSupplierNotesModal.additionalNote")
                  }}</span>
                  <span>{{ getNotes && getNotes.note }}</span>
                </div>
                <v-divider
                  v-if="getNotes && getNotes.note"
                  class="mt-3 mb-3"
                ></v-divider>
              </div>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>

    <modal-component
      :dialog="showSameDayWarningModal"
      @close="onCloseSameDayWarningModal"
      :hideClosing="true"
    >
      <div>
        <div class="text-h6 font-weight-black">
          {{ $t("newOrder.sameDayOrderTitle") }}
        </div>
        <div
          class="text-subtitle-2 secondaryDark2--text pt-0 pb-0 pl-8 pr-8 mt-3"
        >
          {{ $t("newOrder.sameDayOrderDesc") }}
        </div>

        <v-btn
          class="mt-6"
          color="accent"
          @click="onCloseSameDayWarningModal"
          >{{ $t("newOrder.thatIsFine") }}</v-btn
        >
      </div>
    </modal-component>
  </div>
</template>
<script>
//services
import LocalStorageService from "../../services/local-storage.service";
//components
import ModalComponent from "../../components/ModalComponent.vue";
import moment from "moment";
import { mapGetters } from "vuex";

export default {
  name: "StepOneDateSupplier",
  components: { ModalComponent },
  props: {
    step: {},
    order: {},
    isEdit: {},
    allSteps: {},
  },
  data: () => ({
    date: "",
    menu2: false,
    selectedSupplier: "",
    todaysDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    showSameDayWarningModal: false,
    tomorrowsDate: "",
    disableDateField: false,
    daysInWeek: [],
  }),
  created() {
    let data = LocalStorageService.getLocalStorageData("mergeOrderData");
    if (data && this.isEdit) {
      this.disableDateField = true;
    }
    let today = new Date();
    let tomorrow = new Date(today);
    tomorrow.setDate(new Date().getDate() + 1);
    this.tomorrowsDate = tomorrow.toISOString().substr(0, 10);
    this.selectedSupplier = (this.order && this.order.supplier) || "";
    this.processLocalStorageData();
  },
  mounted() {
    this.setLocalStorage();
    this.$store.dispatch("userProfile/getUserProfile");
    this.$store.dispatch("supplier/getSuppliersForDepartment", {
      departmentId: this.$route.params.departmentID,
    });

    this.processNotesData();
    this.daysInWeek = [
      {
        id: 0,
        label: this.$t("daysInWeekShort.monday"),
        value: "monday",
        color: "tertiaryDark",
        selected: false,
      },
      {
        id: 1,
        label: this.$t("daysInWeekShort.tuesday"),
        value: "tuesday",
        color: "tertiaryDark",
        selected: false,
      },
      {
        id: 2,
        label: this.$t("daysInWeekShort.wednesday"),
        value: "wednesday",
        color: "tertiaryDark",
        selected: false,
      },
      {
        id: 3,
        label: this.$t("daysInWeekShort.thursday"),
        value: "thursday",
        color: "tertiaryDark",
        selected: false,
      },
      {
        id: 4,
        label: this.$t("daysInWeekShort.friday"),
        value: "friday",
        color: "tertiaryDark",
        selected: false,
      },
      {
        id: 5,
        label: this.$t("daysInWeekShort.saturday"),
        value: "saturday",
        color: "tertiaryDark",
        selected: false,
      },
      {
        id: 6,
        label: this.$t("daysInWeekShort.sunday"),
        value: "sunday",
        color: "tertiaryDark",
        selected: false,
      },
    ];
    if (this.selectedSupplier) {
      this.$store
        .dispatch("userProfile/getSupplierNote", {
          supplierId: this.selectedSupplier.id,
          venueId: this.getVenue.id,
        })
        .then(() => {
          this.processNotesData();
        });
    }
  },
  methods: {
    onSupplierSelected(event) {
      LocalStorageService.deleteLocalStorageItem("newOrderDraft");
      this.$store.dispatch("orders/clearSelectedArticles", true);

      this.selectedSupplier = this.allSuppliers.filter(
        (f) => f.pib === event
      )[0];
      if (!this.selectedSupplier.isMarket) {
        this.$store.dispatch("userProfile/getAllSupplierContacts", {
          supplierId: this.selectedSupplier.id,
          venueId: this.venue.id,
        });
      }
      this.$store.dispatch("articles/clearAllArticles");
      this.$store.dispatch(
        "supplier/setIsSelectedSupplierMarket",
        this.selectedSupplier.isMarket
      );
      this.setLocalStorage();
      if (!this.selectedSupplier.isMarket) {
        this.$store
          .dispatch("userProfile/getSupplierNote", {
            supplierId: this.selectedSupplier.id,
            venueId: this.getVenue.id,
          })
          .then(() => {
            this.processNotesData();
          });
      }
    },
    onContinueClick() {
      this.setLocalStorage();
      this.$emit("onStepChange", this.step + 1);
      this.$emit("onOrderUpdate", {
        date: this.date,
        supplier: this.selectedSupplier,
      });
    },
    setLocalStorage() {
      LocalStorageService.setLocalStorageData("newOrderDraft", {
        orderObj: {
          date: this.date,
          supplier: this.selectedSupplier,
        },
        allSteps: this.allSteps,
        currentStep: this.step,
      });
    },
    processLocalStorageData() {
      let data = LocalStorageService.getLocalStorageData("newOrderDraft");
      let dateFromLS = null;
      if (data) {
        dateFromLS = data.orderObj.date;
      }
      this.date = this.order.deadline
        ? new Date(this.order.deadline).toISOString().slice(0, 10)
        : dateFromLS
        ? new Date(dateFromLS).toISOString().slice(0, 10)
        : this.tomorrowsDate;
    },
    allowedDates(val) {
      return val >= this.todaysDate;
    },
    onDatePicked(event) {
      LocalStorageService.setLocalStorageData("orderChanged", true);
      if (event === this.todaysDate) {
        this.showSameDayWarningModal = true;
      }
    },
    onCloseSameDayWarningModal() {
      this.showSameDayWarningModal = false;
    },
    onCancelOrder() {},
    getDate() {
      return moment.utc(this.date).format("DD.MM.YYYY");
    },
    processNotesData() {
      if (this.getNotes) {
        const daysTemp = JSON.parse(this.getNotes.daysInWeek);
        // ovo nekad dodje kao string
        this.daysInWeek.forEach((day) => {
          daysTemp.forEach((dayTemp) => {
            if (dayTemp.id === day.id) {
              this.$set(day, "color", "accent");
              this.$set(day, "selected", true);
            }
          });
        });
      }
    },
  },
  computed: {
    ...mapGetters({
      getSuppliers: "supplier/getDepartmentSuppliers",
      getVenue: "userProfile/getVenue",
      areSelectedArticlesCleared: "orders/getAreSelectedArticlesCleared",
      getSelectedSupplierNote: "userProfile/getSelectedSupplierNote",
    }),
    venue() {
      return this.getVenue;
    },
    suppliers() {
      return this.$route.path.includes("new-custom-order")
        ? this.getSuppliers?.filter((f) => f.isMarket === true)
        : this.getSuppliers?.filter((f) => f.isMarket === false) || [];
    },
    allSuppliers() {
      if (!this.suppliers) return [];

      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      return this.suppliers.sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });
    },
    getIsMobile() {
      return this.$isMobile;
    },
    getAreSelectedArticlesCleared() {
      return this.areSelectedArticlesCleared;
    },
    getNotes() {
      return this.getSelectedSupplierNote;
    },
  },
  watch: {
    order() {
      this.selectedSupplier = this.order.supplier || "";

      this.date = this.order.deadline
        ? new Date(this.order.deadline).toISOString().slice(0, 10)
        : this.tomorrowsDate;
      if (this.order.deadline < this.todaysDate) {
        this.date = "";
      }
      this.setLocalStorage();
    },
    date() {
      this.setLocalStorage();
    },
  },
};
</script>
<style lang="scss" scoped>
.width-max {
  max-width: 357px;
  margin: auto;
}
.continue-button {
  text-transform: none !important;
  width: 100%;
}
</style>
