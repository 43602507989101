<template>
  <modal-component :dialog="dialog" :modalWidth="'540px'" :hideClosing="true">
    <div class="dialog-style">
      <div class="header">
        <span class="text-h5 font-weight-black">{{
          $t("subscriptions.trialPeriodEnded")
        }}</span>
      </div>
      <div class="mt-4">
        <span>{{ $t("subscriptions.trialPlanEndedExplanation") }}</span>
      </div>
      <div class="mt-4">
        <span class="text-body-2 secondaryDark2--text">{{
          $t("subscriptions.trialPlanEndedExplanation2")
        }}</span>
      </div>

      <div class="d-flex justify-center mt-4">
        <v-btn
          color="accent"
          @click="openPlanInquiryModal"
          :disabled="inqurySentSuccesfully"
          >{{ $t("subscriptions.sendInquiry") }}</v-btn
        >
        <v-btn class="ml-3" color="primaryB" @click="logOut">{{
          $t("subscriptions.logout")
        }}</v-btn>
      </div>
      <div v-if="inqurySentSuccesfully" class="positive1--text mt-4">
        <v-icon class="mr-2 positive1--text">mdi-check-circle</v-icon
        >{{ $t("sendPlanInquiry.inquirySuccesfullySent") }}
      </div>
    </div>
    <send-plan-inquiry-modal
      v-if="showPlanInquiryModal"
      :dialog="showPlanInquiryModal"
      @onClose="showPlanInquiryModal = false"
      @onSendInquiry="handleOnSendInquiry"
    ></send-plan-inquiry-modal>
  </modal-component>
</template>
<script>
import { mapGetters } from "vuex";
import ModalComponent from "../components/ModalComponent.vue";
import SendPlanInquiryModal from "../components/SendPlanInquiryModal.vue";
import { REQUEST_SUCCESS } from "../shared/statuses";
export default {
  name: "FreeTrialExpiredModal",
  components: { ModalComponent, SendPlanInquiryModal },
  props: {
    dialog: {},
    userProfile: {},
  },
  data: () => ({
    showPlanInquiryModal: false,
    inqurySentSuccesfully: false,
  }),
  methods: {
    openPlanInquiryModal() {
      this.showPlanInquiryModal = true;
    },
    logOut() {
      this.$router.push("/logout");
    },
    handleOnSendInquiry(data) {
      this.showPlanInquiryModal = false;
      const payload = {
        userId: this.userProfile.id,
        venueId: this.userProfile.venue.id,
        selectedPlan: data.selectedPlan,
        checkedStations: data.checkedStations,
      };
      this.$store.dispatch("subscription/sendPlanInquiry", payload);
    },
  },
  computed: {
    ...mapGetters({
      getSendPlanInquiryStatus: "subscription/getSendPlanInquiryStatus",
    }),
    sendInquiryStatus() {
      return this.getSendPlanInquiryStatus;
    },
  },
  watch: {
    sendInquiryStatus() {
      if (this.sendInquiryStatus === REQUEST_SUCCESS) {
        this.inqurySentSuccesfully = true;
      }
    },
  },
};
</script>
<style scoped lang="scss"></style>
