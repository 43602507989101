// import VueCookie from 'vue-cookie';
import AuthService from "../services/auth.service";
import {
  REQUEST_FAILURE,
  REQUEST_PENDING,
  REQUEST_SUCCESS,
  REQUEST_UNDEFINED,
} from "../shared/statuses";

const userData = JSON.parse(localStorage.getItem("userData"));
const initialState = {
  status: { loggedIn: userData ? true : false },
  userData: userData,
  registerStatus: REQUEST_UNDEFINED,
  checkEmailStatus: REQUEST_UNDEFINED,
  emailExists: false,
  registerVenueStatus: REQUEST_UNDEFINED,
  getAllCategoriesStatus: REQUEST_UNDEFINED,
  allCategories: [],
  loginFailStatus: null,
  requestPasswordResetStatus: REQUEST_UNDEFINED,
  requestChangePasswordStatus: REQUEST_UNDEFINED,
  requestVerifyEmailStatus: REQUEST_UNDEFINED,
  registerFromInviteStatus: REQUEST_UNDEFINED,
  loginLoadingStatus: REQUEST_UNDEFINED,
};

export const auth = {
  namespaced: true,
  state: initialState,
  actions: {
    login({ commit }, user) {
      commit("loginPending");
      return AuthService.login(user).then(
        (response) => {
          commit("loginSuccess", response);
          return Promise.resolve(response);
        },
        (error) => {
          if (error.response) {
            commit("loginFailure", error.response.data.key);
          }

          return Promise.reject(error);
        }
      );
    },

    logout({ commit }) {
      AuthService.logout();
      commit("logout");
    },

    refreshToken({ commit }, jwt, refreshToken) {
      commit("refreshToken", jwt, refreshToken);
    },

    register({ commit }, user) {
      commit("registerPending");
      return AuthService.register(user).then(
        (response) => {
          commit("registerSuccess");
          return Promise.resolve(response.data);
        },
        (error) => {
          commit("registerFailure");
          return Promise.reject(error);
        }
      );
    },

    registerVenue({ commit }, venue) {
      commit("registerVenuePending");
      return AuthService.registerVenue(venue).then(
        (response) => {
          commit("registerVenueSuccess");
          return Promise.resolve(response);
        },
        (error) => {
          commit("registerVenueFailure");
          return Promise.reject(error);
        }
      );
    },

    checkEmail({ commit }, email) {
      commit("checkEmailPending");
      return AuthService.checkEmail(email).then(
        (response) => {
          commit("checkEmailSuccess", response.data);
          return Promise.resolve(response);
        },
        (error) => {
          commit("checkEmailFailure");
          return Promise.reject(error);
        }
      );
    },

    getAllCategories({ commit }) {
      commit("getAllCategoriesPending");
      return AuthService.getAllCategories().then(
        (response) => {
          commit("getAllCategoriesSuccess", response.data);
          return Promise.resolve(response);
        },
        (error) => {
          commit("getAllCategoriesFailure");
          return Promise.reject(error);
        }
      );
    },

    requestPasswordReset({ commit }, email) {
      commit("requestPasswordResetPending");
      return AuthService.requestPasswordReset(email).then(
        (response) => {
          commit("requestPasswordResetSuccess");
          return Promise.resolve(response);
        },
        (error) => {
          commit("requestPasswordResetFailure");
          return Promise.reject(error);
        }
      );
    },

    requestChangePassword({ commit }, data) {
      commit("requestChangePasswordPending");
      return AuthService.requestChangePassword(data).then(
        (response) => {
          commit("requestChangePasswordSuccess");
          return Promise.resolve(response);
        },
        (error) => {
          commit("requestChangePasswordFailure");
          return Promise.reject(error);
        }
      );
    },

    requestVerifyEmail({ commit }, token) {
      commit("requestVerifyEmailPending");
      return AuthService.requestVerifyEmailService(token).then(
        (response) => {
          commit("requestVerifyEmailSuccess");
          return Promise.resolve(response);
        },
        (error) => {
          commit("requestVerifyEmailFailure");
          return Promise.reject(error);
        }
      );
    },

    registerFromInvite({ commit }, user) {
      commit("registerFromInvitePending");
      return AuthService.registerFromInvite(user).then(
        (response) => {
          commit("registerFromInviteSuccess");
          return Promise.resolve(response.data);
        },
        (error) => {
          commit("registerFromInviteFailure");
          return Promise.reject(error);
        }
      );
    },
  },
  mutations: {
    loginPending(state) {
      state.loginLoadingStatus = REQUEST_PENDING;
    },
    loginSuccess(state, user) {
      state.status.loggedIn = true;
      state.userData = user;
      state.loginLoadingStatus = REQUEST_SUCCESS;
    },
    loginFailure(state, code) {
      state.status.loggedIn = false;
      state.loginFailStatus = code;
      state.userData = null;
      state.loginLoadingStatus = REQUEST_FAILURE;
    },
    logout(state) {
      state.status.loggedIn = false;
      state.userData = null;
    },
    registerPending(state) {
      state.status.loggedIn = false;
      state.registerStatus = REQUEST_PENDING;
    },
    registerSuccess(state) {
      state.status.loggedIn = false;
      state.registerStatus = REQUEST_SUCCESS;
    },
    registerFailure(state) {
      state.status.loggedIn = false;
      state.registerStatus = REQUEST_FAILURE;
    },
    refreshToken(state, jwt, refreshToken) {
      state.status.loggedIn = true;
      state.userData = {
        ...state.userData,
        jwt: jwt,
        refresh_token: refreshToken,
      };
    },
    checkEmailPending(state) {
      state.checkEmailStatus = REQUEST_PENDING;
    },
    checkEmailSuccess(state, data) {
      state.checkEmailStatus = REQUEST_SUCCESS;
      state.emailExists = data?.exists;
    },
    checkEmailFailure(state) {
      state.checkEmailStatus = REQUEST_FAILURE;
    },
    registerVenuePending(state) {
      state.registerVenueStatus = REQUEST_PENDING;
    },
    registerVenueSuccess(state) {
      state.registerVenueStatus = REQUEST_SUCCESS;
    },
    registerVenueFailure(state) {
      state.registerVenueStatus = REQUEST_FAILURE;
    },
    getAllCategoriesPending(state) {
      state.getAllCategoriesStatus = REQUEST_PENDING;
    },
    getAllCategoriesSuccess(state, data) {
      state.allCategories = data;
      state.getAllCategoriesStatus = REQUEST_SUCCESS;
    },
    getAllCategoriesFailure(state) {
      state.getAllCategoriesStatus = REQUEST_FAILURE;
    },
    requestPasswordResetPending(state) {
      state.requestPasswordResetStatus = REQUEST_PENDING;
    },
    requestPasswordResetSuccess(state) {
      state.requestPasswordResetStatus = REQUEST_SUCCESS;
    },
    requestPasswordResetFailure(state) {
      state.requestPasswordResetStatus = REQUEST_FAILURE;
    },
    requestChangePasswordPending(state) {
      state.requestChangePasswordStatus = REQUEST_PENDING;
    },
    requestChangePasswordSuccess(state) {
      state.requestChangePasswordStatus = REQUEST_SUCCESS;
    },
    requestChangePasswordFailure(state) {
      state.requestChangePasswordStatus = REQUEST_FAILURE;
    },
    requestVerifyEmailPending(state) {
      state.requestVerifyEmailStatus = REQUEST_PENDING;
    },
    requestVerifyEmailSuccess(state) {
      state.requestVerifyEmailStatus = REQUEST_SUCCESS;
    },
    requestVerifyEmailFailure(state) {
      state.requestVerifyEmailStatus = REQUEST_FAILURE;
    },
    registerFromInvitePending(state) {
      state.status.loggedIn = false;
      state.registerFromInviteStatus = REQUEST_PENDING;
    },
    registerFromInviteSuccess(state) {
      state.status.loggedIn = false;
      state.registerFromInviteStatus = REQUEST_SUCCESS;
    },
    registerFromInviteFailure(state) {
      state.status.loggedIn = false;
      state.registerFromInviteStatus = REQUEST_FAILURE;
    },
  },
  getters: {
    isUserAuthenticated: (state) => {
      return state.status.loggedIn;
    },
    getUser: (state) => {
      return state.userData?.user;
    },
    getEmailExists: (state) => {
      return state.emailExists;
    },
    getCategories: (state) => {
      return state.allCategories;
    },
    getRegisterVenueStatus: (state) => {
      return state.registerVenueStatus;
    },
    getLoginFailStatus: (state) => {
      return state.loginFailStatus;
    },
    isUserSuperAdmin: (state) => {
      return state.userData?.user?.role === "ROLE_SUPERADMIN";
    },
    isUserAdmin: (state) => {
      return state.userData?.user?.role === "ROLE_ADMIN";
    },
    getRequestPasswordResetStatus: (state) => {
      return state.requestPasswordResetStatus;
    },
    getRequestChangePasswordStatus: (state) => {
      return state.requestChangePasswordStatus;
    },
    getVerifyEmailStatus: (state) => {
      return state.requestVerifyEmailStatus;
    },
    getLoginLoadingStatus: (state) => {
      return state.loginLoadingStatus;
    },
    getRegisterStatus: (state) => {
      return state.registerStatus;
    },
  },
};
