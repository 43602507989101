<template>
  <div class="root" :class="[getIsMobile ? 'mt-5' : 'mt-10']">
    <div class="steps-wrapper d-flex align-center justify-center">
      <div
        class="step mr-1"
        v-for="(step, index) in allSteps"
        :class="{
          activeStep: step.active || step.finished,
        }"
        :key="index"
      ></div>
    </div>
  </div>
</template>
<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "StepperComponent",
  props: { step: {}, allSteps: {} },
  methods: {},
  computed: {
    getIsMobile() {
      return this.$isMobile;
    },
  },
  watch: {
    step() {
      this.$forceUpdate();
    },
  },
};
</script>
<style lang="scss" scoped>
.root {
  width: 100%;
}
.steps-wrapper {
  width: 280px;
  margin: auto;
}
.step {
  width: 33%;
  height: 6px;
  background: #e0e0e0;
  @media screen and (max-width: 575px) {
    height: 3px !important;
  }
}
.activeStep {
  background: #276ef1;
}
</style>
