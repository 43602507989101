import colors from "./colors";

export default {
  primaryA: colors.primary.primaryA,
  primaryB: colors.primary.primaryB,
  negative: colors.primary.negative,
  warning: colors.primary.warning,
  positive: colors.primary.positive,
  positive1: colors.primary.positive1,
  primaryDark: colors.gray.primaryDark,
  secondaryDark: colors.gray.secondaryDark,
  secondaryDark2: colors.gray.secondaryDark2,
  tertiaryDark: colors.gray.tertiaryDark,
  primaryLight: colors.gray.primaryLight,
  secondaryLight: colors.gray.secondaryLight,
  tertiaryLight: colors.gray.tertiaryLight,
  accent: colors.primary.accent,
};

// .text-{value}
// .text-{breakpoint}-{value}

// h1 - 96px
// h2 - 60px
// h3 - 48px
// h4 - 34px
// h5 - 24px
// h6 - 20px
// subtitle-1 - 16px
// subtitle-2 - 14px
// body-1 -16px
// body-2 - 14px
// button - 14px
// caption - 12px
// overline - 12px

// font-weight-black - 900
// font-weight-bold - 700
// font-weight-medium - 500
// font-weight-regular - 400
// font-weight-light - 300
// font-weight-thin - 100
