<template>
  <div class="d-flex justify-center">
    <div class="d-flex flex-column align-center">
      <span class="text-h5 primaryDark--text">{{
        $t("verify.successVerifyEmail")
      }}</span>
      <span class="text-subtitle-1 secondaryDark--text">{{
        $t("verify.redirectSoon", { time: time })
      }}</span>
      <div class="d-flex align-center mt-4">
        <span class="text-subtitle-1 secondaryDark--text">{{
          $t("verify.orClickHere")
        }}</span>
        <a class="ml-3" href="/login">{{ $t("verify.here") }}</a>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "VerifyEmail",
  data: () => ({
    time: 5,
  }),
  created() {
    let interval = setInterval(() => {
      if (this.time === 0) {
        clearInterval(interval);
        this.$router.push("/login");
      } else {
        this.time = this.time - 1;
      }
    }, 1000);
    const token = this.$route.query.activationToken;
    this.$store.dispatch("auth/requestVerifyEmail", token);
  },
};
</script>
<style scoped lang="scss"></style>
