<template>
  <v-data-table
    :headers="headers"
    :items="data"
    :sort-by="['name']"
    :sort-desc="[false, true]"
    class="elevation-1"
    :loading="isDataLoading"
    :loading-text="$t('common.loadingTableText')"
    mobile-breakpoint="320"
    :items-per-page="5"
    :footer-props="{
      itemsPerPageAllText: $t('common.all'),
      itemsPerPageText: $t('common.itemsPerPage'),
    }"
  >
    <template v-slot:item.deadline="{ item }">
      <span
        class="text-body-1 accent--text order-deadline"
        :class="{ statusMobileText: getIsMobile }"
        >{{ getFormattedDate(item.deadline) }}</span
      >
    </template>

    <template v-slot:item.statusText="{ item }">
      <span
        v-if="item.status === 'ARRIVED'"
        class="tag-style text-body-1 negative--text"
        :class="{ statusMobileText: getIsMobile }"
        >{{ getStatusText(item.status) }}</span
      >
      <span
        v-else
        class="tag-style text-body-1 accent--text"
        :class="{ statusMobileText: getIsMobile }"
        >{{ item.statusText }}</span
      >
    </template>
  </v-data-table>
</template>
<script>
//services
// import EventBus from "../shared/EventBus";
import moment from "moment";
import { formatDate } from "../../../helpers/helper";
export default {
  props: {
    headers: {},
    data: {},
    isDataLoading: {},
    archive: {},
    pageType: {},
    getIsMobile: {},
  },
  name: "SupplierDataTable",
  data: () => ({
    loader: null,
  }),
  created() {},
  methods: {
    getFormattedDate(value) {
      return formatDate(value);
    },
    getStatusText(status) {
      switch (status) {
        case orderStatuses.ARRIVED:
          return this.$t("supplierDashboard.statusArrived");
        case orderStatuses.CONFIRMED:
          return this.$t("supplierDashboard.statusConfirmed");
        case orderStatuses.DELIVERED:
          return this.$t("supplierDashboard.statusDelivered");
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.tag-style {
  background: #f7f7f8;
  padding: 8px 4px;
  text-transform: uppercase;
  @media screen and (max-width: 959px) {
    font-size: 14px !important;
  }
}
.remove-uppercase {
  span {
    text-decoration: none !important;
  }
}
.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}
@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-o-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
.option-item {
  cursor: pointer;
}
.order-deadline {
  @media screen and (max-width: 959px) {
    font-size: 14px !important;
  }
}
.uploadedYes {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: #35d2bc;
  display: flex;
  justify-content: center;
  align-items: center;
}
.uploadedNo {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: #fd4a34;
  display: flex;
  justify-content: center;
  align-items: center;
}
.disabled {
  pointer-events: none;
  opacity: 0.3;
}
.statusMobileText {
  font-size: 12px !important;
}
</style>
