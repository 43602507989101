<template>
  <div>
    <v-bottom-sheet
      v-model="showSheet"
      inset
      scrollable
      :fullscreen="getIsMobile"
      :persistent="isAnyItemErrorInput"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          class="cart-button"
          color="accent"
          dark
          absolute
          bottom
          right

          v-show="numberOfItemsInCart && currentStep === 1"
          @click="onShowSheet"
        >
          <v-badge
            :content="numberOfItemsInCart"
            :value="numberOfItemsInCart"
            color="black"
            overlap
          >
            <v-icon>mdi-cart-outline</v-icon>
          </v-badge>
        </v-btn>
      </template>
      <v-sheet class="text-center">
        <v-btn class="mt-6" text color="error" @click="showSheet = !showSheet" :disabled="isAnyItemErrorInput">
          {{ $t("common.close") }}
        </v-btn>
        <div class="my-3 cart-view-table">
          <v-simple-table
            class="mt-3 pa-4"
            fixed-header
            :height="getIsMobile ? '100%' : '300px'"
            dense
          >
            <template v-slot:default>
              <thead>
                <tr>
                  <th>{{ $t("common.name") }}</th>
                  <th>{{ $t("common.qty") }}</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="product in products"
                  :key="product.id"
                  style="text-align: left"
                >
                  <td>{{ product.name }}</td>
                  <td>
                    <v-text-field
                      class="amount-text-field"
                      :single-line="true"
                      v-model="product.amount"
                      @change="amountChange($event, product)"
                      type="text"
                      :rules="product.amountError"
                      dense
                      @input="validateInput($event, product)"
                    >
                    </v-text-field>
                  </td>
                  <td>
                    <v-btn
                      icon
                      color="pink"
                      @click="onRemoveItemFromCart(product)"
                    >
                      <v-icon>mdi-trash-can-outline</v-icon>
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </div>
      </v-sheet>
    </v-bottom-sheet>
  </div>
</template>
<script>
import LocalStorageService from "../../../services/local-storage.service";
export default {
  name: "CartViewSheet",
  props: {
    numberOfItemsInCart: Number,
    currentStep: Number,
  },
  data: () => ({
    products: [],
    showSheet: false,
    invalidInputForm: false,
    rules: [],
  }),
  mounted() {
    this.getProducts();
  },
  methods: {
    onShowSheet() {
      this.getProducts();
      this.showSheet = true;
    },
    getProducts() {
      const data = LocalStorageService.getLocalStorageData("newOrderDraft");
      if (data) {
        this.products = data.orderObj.articles;
      }
    },
    onRemoveItemFromCart(product) {
      const data = LocalStorageService.getLocalStorageData("newOrderDraft");
      if (data) {
        data.orderObj.articles = data.orderObj.articles.filter(
          (p) => p.id !== product.id
        );
        LocalStorageService.setLocalStorageData("newOrderDraft", data);
      }
      this.getProducts();
      this.$emit("onRemoveItemFromCart", product);
    },
    validateInput(event, item) {
      if (item.amount && item.amount.startsWith(".")) {
        this.$set(item, "amount", "0" + item.amount);
        this.setAmountItem({ target: { value: "0" + event } }, item);
      } else {
        this.setAmountItem({ target: { value: event } }, item);
      }
    },
    setAmountItem(evt, item) {
      const valueEnterred = evt.target.value;
      const valueValidated =
        valueEnterred !== null ? this.validate(valueEnterred) : true;
      const valueEnterredFloat = parseFloat(valueEnterred);
      if (!valueValidated || valueEnterredFloat < 0.1) {
        this.rules.amount = [""];
        this.invalidInputForm = true;
        this.$set(item, "amountError", [""]);
      } else {
        this.rules.amount = [];
        this.invalidInputForm = false;
        this.$set(item, "amountError", []);
        this.amountChange(valueEnterred, item);
      }
    },
    validate(s) {
      let rgx = /^[0-9]*\.?[0-9]*$/;
      return rgx.test(s);
    },
    amountChange(event, item) {
      this.$set(item, "amount", event);
      if (!event) {
        return;
      } else {
        this.$emit("onAmountChangeInCart",item)
      }
      this.$forceUpdate();
    },
  },
  computed: {
    getIsMobile() {
      return this.$isMobile;
    },
    getIsInputInvalid() {
      return this.invalidInputForm;
    },
    isAnyItemErrorInput() {
      return this.products?.some((p) => p.amountError?.length > 0 || p.amount === "");
    }
  },
};
</script>
<style scoped lang="scss">
.cart-button {
  position: absolute;
  top: 10px;
  right: 40px;
  z-index: 999;
  @media screen and (max-width: 960px) {
    position:fixed;
    top: 10px;
    right: 10px;
  }
}
.height-overflow {
  max-height: 200px;
  overflow: auto;
}
.amount-text-field {
  @media screen and (max-width: 660px) {
    min-width: 50px !important;
    max-width: 50px !important;
  }
  height: 50px !important;
  margin-top: 12px;
  margin-right: 12px;
}
</style>
