<template>
  <div class="root">
    <div class="d-flex mb-11">
      <span class="text-h4">{{ $t("adminUsers.listOfInvites") }}</span>
      <div class="add-new-btn ml-4" @click="onVerifyUserManually">
        <v-img
          max-width="12"
          :src="require('../../assets/img/Plus.svg')"
        ></v-img>
      </div>
    </div>
    <admin-data-table
      v-if="!noUsers"
      :headers="headers"
      class="elevation-0"
      :hide-default-footer="true"
      :data="allUnnaproved"
      :isDataLoading="isDataLoading"
      @onApproveUserRequest="showApprovalRequestModal"
    ></admin-data-table>
    <no-results-content
      v-else
      :noResultsText="$t('common.noResuls')"
      :page="'adminUsers'"
    ></no-results-content>
    <modal-component
      :dialog="showApprovalUserConfirmationModal"
      @onClose="closeApprovalUserConfirmationModal"
    >
      <div class="mt-10 d-flex flex-column align-center">
        <span class="text-h6">{{
          $t("adminUsers.confirmUserApproval", {
            user: userToBeApproved && userToBeApproved.user.name,
            venue: userToBeApproved && userToBeApproved.venue.name,
          })
        }}</span>

        <v-btn class="mt-7" color="accent" @click="handleApproveUserRequest">{{
          $t("adminUsers.confirm")
        }}</v-btn>
      </div>
    </modal-component>

    <modal-component
      :dialog="showVerifyUserManuallyModal"
      @onClose="closeWarningModal"
    >
      <div class="mt-5">
        <span class="text-h6">{{ $t("adminUsers.enterEmailToVerify") }}</span>
        <div>
          <v-text-field
            name="userEmail"
            id="userEmail"
            type="text"
            :label="$t('adminUsers.inviteUserEmail')"
            color="accent"
            v-model="userEmailToVerify"
            :rules="rules.email"
          ></v-text-field>
          <v-btn
            :disabled="invalidInput || !userEmailToVerify"
            :loading="isVerifyManuallyLoading"
            @click="handleEmailToManuallyVerify"
            >{{ $t("adminRestaurant.verify") }}</v-btn
          >
        </div>
      </div>
    </modal-component>
  </div>
</template>
<script>
//vuex
import { mapGetters } from "vuex";
//components
import NoResultsContent from "../../components/NoResultsContent.vue";
import { REQUEST_PENDING, REQUEST_SUCCESS } from "../../shared/statuses";
import AdminDataTable from "./components/AdminDataTable.vue";
import ModalComponent from "../../components/ModalComponent.vue";
import EventBus from "../../shared/EventBus";

export default {
  name: "AdminUsers",
  components: { NoResultsContent, AdminDataTable, ModalComponent },
  data: () => ({
    headers: [],
    isDataLoading: false,
    userToBeApproved: null,
    showApprovalUserConfirmationModal: false,
    showVerifyUserManuallyModal: false,
    userEmailToVerify: "",
    rules: {},
    invalidInput: false,
  }),
  mounted() {
    this.rules = {
      email: [
        (val) => (val || "").length > 0 || this.$t("common.requiredField"),
      ],
    };
  },
  created() {
    this.isDataLoading = true;
    this.getAllUnnaprovedUsers();
    this.headers = [
      {
        text: this.$t("common.nameSurname"),
        value: "userName",
      },
      {
        text: this.$t("common.email"),
        value: "userEmail",
      },
      {
        text: this.$t("common.restaurantName"),
        value: "resName",
      },
      {
        text: this.$t("common.pib"),
        value: "invResPib",
      },
      {
        text: this.$t("adminDashboard.actions"),
        value: "invActions",
      },
    ];
  },
  computed: {
    ...mapGetters({
      getUnnaprovedUsers: "admin/getUnnaprovedUsers",
      getUnnaprovedUsersStatus: "admin/getUnnaprovedUsersStatus",
      getManuallyVerifyEmailStatus: "admin/getManuallyVerifyEmailStatus",
    }),
    allUnnaproved() {
      return this.getUnnaprovedUsers;
    },
    noUsers() {
      return (
        this.allUnnaproved &&
        this.allUnnaproved.length === 0 &&
        this.getUnnaprovedUsersStatus === REQUEST_SUCCESS
      );
    },
    isVerifyManuallyLoading() {
      return this.getManuallyVerifyEmailStatus === REQUEST_PENDING;
    },
  },
  methods: {
    closeWarningModal() {
      this.showVerifyUserManuallyModal = false;
    },
    handleEmailToManuallyVerify() {
      // api here
console.log("adsdasadssad")
      this.$store
        .dispatch("admin/manuallyVerifyEmail", this.userEmailToVerify)
        .then(
          () => {
            this.showVerifyUserManuallyModal = false;
            EventBus.dispatch("showSnackbar", {
              text: this.$t("notifications.userManuallyVerifiedSuccess"),
            });
          },
          () => {
            this.showVerifyUserManuallyModal = false;
            EventBus.dispatch("showSnackbar", {
              text: this.$t("notifications.userManuallyVerifiedFailed"),
            });
          }
        );
    },
    onVerifyUserManually() {
      this.showVerifyUserManuallyModal = true;
    },
    getAllUnnaprovedUsers() {
      this.$store.dispatch("admin/getUnnaprovedUsers").then(
        () => {
          this.isDataLoading = false;
        },
        () => {
          this.isDataLoading = false;
        }
      );
    },
    showApprovalRequestModal(item) {
      this.userToBeApproved = item;
      setTimeout(() => {
        this.showApprovalUserConfirmationModal = true;
      }, 700);
    },
    handleApproveUserRequest() {
      this.$store
        .dispatch("userProfile/approveUserRequest", this.userToBeApproved.id)
        .then((response) => {
          this.showApprovalUserConfirmationModal = false;
          this.getAllUnnaprovedUsers();
        });
    },
    closeApprovalUserConfirmationModal() {
      this.userToBeApproved = null;
      this.showApprovalUserConfirmationModal = false;
    },
  },
  watch: {
    userEmailToVerify() {
      if (/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(this.userEmailToVerify)) {
        this.rules.email = [];
        this.invalidInput = false;
      } else {
        this.rules.email = [this.$t("common.invalidEmailInput")];
        this.invalidInput = true;
      }
      // this.rules.email.push(
      //   (val) =>
      //     /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(val) ||
      //     this.$t("common.invalidEmailInput")
      // );
    },
  },
};
</script>
<style scoped lang="scss">
.add-new-btn {
  background: #276ef1;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
</style>
