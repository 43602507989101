<template>
  <div class="root">
    <div class="d-flex">
      <span class="text-h4">{{ $t("adminDashboard.adminRestaurants") }}</span>
    </div>
    <div class="mb-6"></div>

    <admin-data-table
      v-if="!noRestaurants"
      :headers="headers"
      class="elevation-0"
      :hide-default-footer="true"
      :data="allRestaurants"
      :isDataLoading="isDataLoading"
      @onTableRowClicked="handleOnTableRowClicked"
      @onToggleRestaurantStatus="handleToggleRestaurantStatus"
    ></admin-data-table>
    <no-results-content
      v-else
      :noResultsText="$t('common.noResuls')"
      :page="'adminUsers'"
    ></no-results-content>
  </div>
</template>
<script>
//components
import NoResultsContent from "../../components/NoResultsContent.vue";
import AdminDataTable from "./components/AdminDataTable.vue";

export default {
  name: "AdminRestaurants",
  components: { NoResultsContent, AdminDataTable },
  props: { allRestaurants: {} },
  data: () => ({
    headers: [],
    isDataLoading: false,
  }),
  created() {
    this.headers = [
      {
        text: this.$t("adminDashboard.restaurantPib"),
        value: "pib",
      },
      {
        text: this.$t("adminDashboard.businessName"),
        value: "bussinessName",
      },
      {
        text: this.$t("adminDashboard.restaurantName"),
        value: "name",
      },
      {
        text: this.$t("adminDashboard.status"),
        value: "status",
      },
      {
        text: this.$t("adminDashboard.actions"),
        value: "toggleStatus",
      },
    ];
  },
  methods: {
    handleOnTableRowClicked(value) {
      this.$router.push(`${value.id}/restaurant`);
      window.location.reload();
    },
    handleToggleRestaurantStatus(venueId) {
      this.$store.dispatch("admin/toggleVenueStatus", venueId).then(
        () => {
          window.location.reload();
        },
        (error) => {
          console.log("Toggle venue error", error);
        }
      );
    },
  },
  computed: {
    noRestaurants() {
      return this.allRestaurants && this.allRestaurants.length === 0;
    },
  },
};
</script>
<style scoped lang="scss">
.num-restaurants {
  padding: 6px 8px;
  background: #276ef1;
  color: #ffffff;
  border-radius: 30%;
}
</style>
