<template>
  <v-layout align-center justify-center class="mt-160 pt-0 pb-0 pl-6 pr-6">
    <v-flex xs12 sm8 md4>
      <v-img
        class="ma-auto mb-118"
        max-width="227"
        :src="require('../../assets/img/NavLogo.png')"
      >
      </v-img>
      <v-card class="elevation-0 ma-auto" width="358px">
        <div class="d-flex flex-column">
          <span class="text-h5 text-sm-h6 text-md-h6 primaryA--text">{{
            $t("invites.acceptInviteRestaurant")
          }}</span>
          <span class="text-h5 text-sm-h6 text-md-h6 accent--text">{{
            inviteData && inviteData.venue.name
          }}</span>
        </div>
        <v-card-text class="pa-0 mt-6">
          <v-form ref="form">
            <v-text-field
              name="email"
              :label="$t('common.email')"
              id="email"
              type="text"
              :placeholder="$t('registerForm.typeYourEmail')"
              outlined
              color="accent"
              required
              :rules="rules.email"
              v-model="form.email"
              :disabled="true"
            ></v-text-field>
            <v-text-field
              name="username"
              :label="$t('common.nameSurname')"
              id="username"
              type="text"
              :placeholder="$t('registerForm.typeYourNameSurname')"
              outlined
              color="accent"
              required
              :rules="rules.username"
              v-model="form.username"
            ></v-text-field>
            <v-text-field
              name="password"
              :label="$t('common.password')"
              :placeholder="$t('registerForm.typeYourPassword')"
              id="password"
              outlined
              color="accent"
              required
              :rules="rules.password"
              v-model="form.password"
              :append-icon="showPasswordPreview ? 'mdi-eye' : 'mdi-eye-off'"
              :type="showPasswordPreview ? 'text' : 'password'"
              @click:append="showPasswordPreview = !showPasswordPreview"
            ></v-text-field>
            <v-text-field
              name="repeatPassword"
              :label="$t('common.repeatPassword')"
              :placeholder="$t('registerForm.typeYourPasswordAgain')"
              id="repeatPassword"
              outlined
              color="accent"
              required
              :rules="rules.passwordRepeat"
              v-model="form.passwordRepeat"
              :append-icon="showPasswordRepeat ? 'mdi-eye' : 'mdi-eye-off'"
              :type="showPasswordRepeat ? 'text' : 'password'"
              @click:append="showPasswordRepeat = !showPasswordRepeat"
            ></v-text-field>
            <v-text-field
              name="phone"
              :label="$t('common.phone')"
              id="phone"
              type="number"
              :placeholder="$t('registerForm.phone')"
              outlined
              color="accent"
              required
              :rules="rules.phone"
              v-model="form.phone"
              :prefix="$t('common.phoneCountryPrefix')"
            ></v-text-field>
          </v-form>
        </v-card-text>
        <div
          v-if="!passwordFieldsAreEqual && registerAction"
          class="password-fields-warning mb-3"
        >
          <span>
            {{ $t("registerForm.passwordFieldsNotEqual") }}
          </span>
        </div>
        <v-card-actions class="pa-0 d-block text-align-center mb-32">
          <v-btn
            :disabled="!formIsValid"
            color="accent"
            width="100%"
            type="submit"
            @click="register"
            >{{ $t("registerForm.register") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-flex>
  </v-layout>
</template>
<script>
import { mapGetters } from "vuex";
import emailValidator from "../../shared/Shared";
export default {
  name: "AcceptInvite",
  data() {
    const defaultForm = Object.freeze({
      email: "",
      username: "",
      password: "",
      passwordRepeat: "",
      phone: "",
    });
    return {
      form: Object.assign({}, defaultForm),
      rules: {
        email: [
          (val) => (val || "").length > 0 || this.$t("common.requiredField"),
        ],
        username: [
          (val) => (val || "").length > 0 || this.$t("common.requiredField"),
        ],
        password: [
          (val) => (val || "").length > 0 || this.$t("common.requiredField"),
        ],
        passwordRepeat: [
          (val) => (val || "").length > 0 || this.$t("common.requiredField"),
        ],
        phone: [
          (val) => (val || "").length > 0 || this.$t("common.requiredField"),
        ],
      },
      passwordFieldsAreEqual: false,
      registerAction: false,
      tags: [
        this.$t("restaurantGroups.italian"),
        this.$t("restaurantGroups.mediterranean"),
        this.$t("restaurantGroups.sushi"),
        this.$t("restaurantGroups.asian"),
        this.$t("restaurantGroups.national"),
        this.$t("restaurantGroups.coffe"),
        this.$t("restaurantGroups.fastFood"),
        this.$t("restaurantGroups.pizzaPasta"),
        this.$t("restaurantGroups.greek"),
        this.$t("restaurantGroups.barbeque"),
        this.$t("restaurantGroups.mexican"),
      ],
      timeout: null,
      invalidEmail: false,
      showPasswordPreview: false,
      showPasswordRepeat: false,
    };
  },
  created() {
    let token = this.$route.query && this.$route.query.token;
    if (token) {
      this.$store.dispatch("invites/acceptInvitationRestaurant", token).then(
        () => {},
        (error) => {
          console.log(error);
          this.$router.push("/login");
        }
      );
    } else {
      this.$router.push("/login");
    }
  },
  mounted() {
    this.$watch(
      (vm) => [vm.form.password, vm.form.passwordRepeat],
      () => {
        if (
          this.form.password &&
          this.form.passwordRepeat &&
          this.form.password !== this.form.passwordRepeat
        ) {
          this.rules.password = [""];
          this.rules.passwordRepeat = [this.$t("common.passwordMissmatch")];
        } else if (
          (this.form.password && this.form.password.length < 8) ||
          (this.form.passwordRepeat && this.form.passwordRepeat.length < 8)
        ) {
          this.rules.password = [""];
          this.rules.passwordRepeat = [this.$t("common.passwordLength")];
        } else if (
          this.form.password &&
          !this.form.password.match(/^\w+$/) &&
          this.form.passwordRepeat &&
          !this.form.passwordRepeat.match(/^\w+$/)
        ) {
          this.rules.password = [""];
          this.rules.passwordRepeat = [this.$t("common.passwordAlphanumeric")];
        } else if (
          this.form.password &&
          this.form.passwordRepeat &&
          this.form.password === this.form.passwordRepeat &&
          this.form.password.length >= 8 &&
          this.form.passwordRepeat.length >= 8 &&
          this.form.password.match(/^\w+$/) &&
          this.form.passwordRepeat.match(/^\w+$/)
        ) {
          this.rules.password = [];
          this.rules.passwordRepeat = [];
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );
  },
  methods: {
    register() {
      this.passwordFieldsAreEqual =
        this.form.password !== "" &&
        this.form.passwordRepeat !== "" &&
        this.form.password === this.form.passwordRepeat;
      if (this.passwordFieldsAreEqual) {
        this.$store.dispatch("auth/registerFromInvite", this.form).then(() => {
          this.$router.push("/login");
        }),
          (error) => {
            console.log("error", error);
          };
      } else {
        this.registerAction = true;
      }
    },
  },
  computed: {
    ...mapGetters({
      getAcceptInviteData: "invites/getAcceptInviteData",
    }),
    formIsValid() {
      return (
        this.form.email &&
        this.form.username &&
        this.form.password &&
        this.form.passwordRepeat &&
        this.form.phone &&
        this.invalidEmail
      );
    },
    emailValue() {
      return this.form.email;
    },
    inviteData() {
      return this.getAcceptInviteData;
    },
  },
  watch: {
    emailValue() {
      this.invalidEmail = emailValidator(this.emailValue);
      this.rules.email =
        this.form.email.length > 0 && this.invalidEmail
          ? []
          : this.form.email.length > 0 && !this.invalidEmail
          ? [this.$t("common.invalidEmailInput")]
          : [this.$t("common.requiredField")];
    },
    inviteData: {
      handler() {
        if (this.inviteData) {
          this.form.email = this.inviteData.email;
        }
      },
      deep: true,
    },
  },
};
</script>
<style type="scss" scoped>
@media screen and (max-width: 959px) {
  .mt-160 {
    margin-top: 0 !important;
  }
}
.password-fields-warning {
  color: #fd4a34;
  width: 100%;
  text-align: center;
  min-height: 24px;
}
.mt-160 {
  margin-top: 24px;
}
.mb-118 {
  margin-bottom: 118px !important;
}
</style>
