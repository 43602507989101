<template>
  <v-tabs mobile-breakpoint="md" :show-arrows="true">
    <v-tab
      class="item-text text-body-1 font-weight-bold"
      v-for="item in navItems"
      :key="item.id"
      :id="'tab-' + item.id"
      @change="tabSelected(item)"
      >{{ item.name }}</v-tab
    >
  </v-tabs>
</template>
<script>
export default {
  name: "TabsComponent",
  props: {
    navItems: {},
  },
  data: () => ({
    tab: null,
  }),
  methods: {
    tabSelected(value) {
      this.$emit("onTabSelected", value);
    },
    tabClick() {
      this.$emit("tabClick");
    },
  },
};
</script>
<style lang="scss" scoped>
.item-text {
  text-transform: none !important;
}
</style>
