<template>
  <div class="padding-adjust" :class="{ fullScreen: getIsMobile }">
    <div class="d-flex align-center">
      <div class="arrow-left-wrap mr-5" @click="goBack">
        <v-img
          max-width="8"
          :src="require('../assets/img/ArrowLeft.svg')"
        ></v-img>
      </div>
      <div class="d-flex flex-wrap">
        <span
          class="text-h5 primaryA--text font-weight-bold"
          :class="{ 'text-subtitle-1': getIsMobile }"
          >{{
            $t("editOrders.edit", {
              name: stationName,
            })
          }}</span
        >
        <div
          v-if="currentStep > 0"
          class="header-title d-flex align-start justify-center"
        >
          <span
            class="text-h5 ml-1 primaryA--text font-weight-bold"
            :class="{ 'text-subtitle-1': getIsMobile }"
            >{{
              $t("newOrder.articleList", {
                supplier: order.supplier && order.supplier.name,
              })
            }}</span
          >
          <!-- <v-btn
            v-if="allSupplierNotes"
            class="mx-2"
            fab
            dark
            color="accent"
            height="20px"
            width="20px"
            @click="previewSupplieNotes"
          >
            <v-icon dark> mdi-information-outline </v-icon>
          </v-btn> -->
        </div>
      </div>
    </div>

    <div>
      <div>
        <stepper-component
          :step="currentStep"
          :allSteps="getAllSteps"
          :station="station"
        ></stepper-component>
      </div>
      <div>
        <step-one-date-supplier
          v-if="currentStep === 0"
          @onStepChange="handleStepChange"
          :suppliers="suppliers"
          :step="currentStep"
          :order="getSingleOrder"
          @onOrderUpdate="handleOrderUpdate"
          :isEdit="true"
          :orderStatus="getSingleOrder && getSingleOrder.status"
          :customOrderToEdit="customOrderToEdit"
        ></step-one-date-supplier>
        <step-two-list
          v-if="currentStep === 1"
          @onStepChange="handleStepChange"
          :step="currentStep"
          :order="orderForEdit"
          @onOrderUpdate="handleOrderUpdate"
          :isEdit="true"
          :orderStatus="getSingleOrder && getSingleOrder.status"
          @onSaveOrder="handleOnSaveOrder"
          :station="station"
          @onAmountChange="handleOnAmountChange"
          :productRemovedFromCart="productRemovedFromCart"
          @onEditOrderAmountSet="handleOnAmountChange"
        ></step-two-list>
        <step-three-preview
          v-if="currentStep === 2"
          @onStepChange="handleStepChange"
          :step="currentStep"
          :order="order"
          :isEdit="true"
          :orderStatus="getSingleOrder && getSingleOrder.status"
          :orderID="getSingleOrder && getSingleOrder.id"
          :venue="getVenue"
          :suppliers="suppliers"
          @onOrderUpdate="handleOrderUpdate"
          @onSaveOrder="handleOnSaveOrder"
          :user="user"
          :station="station"
        >
        </step-three-preview>
        <cart-view-sheet
          :numberOfItemsInCart="numberOfItemsInCart"
          @onRemoveItemFromCart="handleOnAmountChange"
          @onAmountChangeInCart="handleOnAmountChangeInCart"
          :currentStep="currentStep"
        ></cart-view-sheet>
      </div>
    </div>
    <warning-modal
      :dialog="showWarningModal"
      @onClose="handleOnCloseWarningModal"
      :warninModalType="warninModalType"
      @onSavingOrder="handleSavingOrder"
      :departmentID="depID"
      :noValidOrder="noValidOrder"
      :order="getSingleOrder"
    ></warning-modal>
  </div>
</template>
<script>
//vuex
import { mapGetters } from "vuex";
//components
import StepOneDateSupplier from "./NewOrderPages/StepOneDateSupplier.vue";
import StepTwoList from "./NewOrderPages/StepTwoList.vue";
import StepThreePreview from "./NewOrderPages/StepThreePreview.vue";
import StepperComponent from "../components/StepperComponent.vue";
import WarningModal from "../views/NewOrderPages/WarningModal.vue";
//services
import LocalStorageService from "../services/local-storage.service";
import moment from "moment";
import CartViewSheet from "./NewOrderPages/components/CartViewSheet.vue";
export default {
  name: "EditOrder",
  components: {
    StepOneDateSupplier,
    StepperComponent,
    StepTwoList,
    StepThreePreview,
    WarningModal,
    CartViewSheet,
  },
  data: () => ({
    date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    menu2: false,
    selectedSupplier: "",
    selectedDate: "",
    currentStep: 0,
    allSteps: [
      {
        id: 0,
        name: "date-supplier",
        active: true,
        finished: true,
      },
      {
        id: 1,
        name: "list",
        active: false,
        finished: false,
      },
      {
        id: 2,
        name: "finish",
        active: false,
        finished: false,
      },
    ],
    order: {},
    selectedArticles: [],
    stationID: null,
    showWarningModal: false,
    warninModalType: "",
    depID: null,
    noValidOrder: false,
    orderId: null,
    numberOfItemsInCart: 0,
    showCartViewModal: false,
    productRemovedFromCart: null,
    orderForEdit: {},
  }),
  created() {
    this.$store.dispatch("userProfile/getUserProfile");

    this.stationID = this.$route.params?.departmentID;
    this.$store.dispatch("supplier/getSuppliersForDepartment", {
      departmentId: this.stationID,
    });
    this.orderId = this.$route.params?.orderID;
  },
  mounted() {
    this.$store.dispatch("orders/clearCustomOrder");
    if (this.customOrderToEdit) {
      this.$store.dispatch("orders/getCustomOrder", this.orderId);
    } else {
      this.$store.dispatch("orders/getOrder", this.orderId);
    }
    this.$store.dispatch("userProfile/getUserProfile").then(() => {
      this.order?.supplier?.id &&
        this.$store.dispatch("userProfile/getSupplierNote", {
          supplierId: this.order?.supplier.id,
          venueId: this.getVenue.id,
        });
    });
  },
  beforeDestroy() {
    LocalStorageService.deleteLocalStorageItem("newOrderDraft");
    LocalStorageService.deleteLocalStorageItem("orderChanged");
  },
  methods: {
    goBackToDashboard() {
      this.warninModalType = "";
      this.depID = this.$route.params && this.$route.params.departmentID;
      this.noValidOrder =
        Object.keys(this.order).length === 0 || !this.order.articles;
      this.showWarningModal = true;
    },
    goBack() {
      if (this.currentStep === 0) {
        this.goBackToDashboard();
      } else {
        this.$set(this.allSteps[this.currentStep], "finished", false);
        this.currentStep -= 1;
      }
    },
    handleSelectedSupplier(value) {
      this.selectedSupplier = value;
    },
    handleSelectedDate(value) {
      this.selectedDate = value;
    },
    handleStepChange(step, goingBack) {
      this.currentStep = step;
      this.allSteps.forEach((s, index) => {
        if (this.currentStep >= index) {
          this.$set(s, "finished", true);
        }
      });
      if (goingBack) {
        this.$set(this.allSteps[step + 1], "finished", false);
      }
      this.$forceUpdate();
    },
    handleOrderUpdate(data) {
      Object.assign(this.order, data);
    },
    handleSavingOrder() {
      let utcOrderDate = new moment.utc(this.order.date).format();
      this.order.date = utcOrderDate;
      this.$store
        .dispatch(
          this.customOrderToEdit
            ? "orders/updateCustomOrder"
            : "orders/updateOrder",
          {
            orderId: this.getSingleOrder && this.getSingleOrder.id,
            order: this.order,
          }
        )
        .then(() => {
          LocalStorageService.deleteLocalStorageItem("newOrderDraft");
          LocalStorageService.deleteLocalStorageItem("orderChanged");
          this.customOrderToEdit ? this.$router.push(
            `/current-orders/${this.$route.params.departmentID}/custom-orders`
          ) :  this.$router.push(
            `/current-orders/${this.$route.params.departmentID}/orders`
          );
        });
    },
    handleOnCloseWarningModal() {
      this.showWarningModal = false;
    },
    handleOnSaveOrder() {
      this.warninModalType = "saving";
      this.showWarningModal = true;
    },
    processDataFromLocalStorage() {
      let data = LocalStorageService.getLocalStorageData("newOrderDraft");
      if (data) {
        this.numberOfItemsInCart = data.orderObj.articles?.length;
      }
      this.$forceUpdate();
    },
    handleOnAmountChange(product) {
      this.processDataFromLocalStorage();
      this.productRemovedFromCart = product;
    },
    getSingleOrderObjectForEdit() {
      if (
        this.order.articles &&
        this.order.articles.length
      ) {
        let newOrderData = JSON.parse(JSON.stringify(this.getSingleOrder));
        newOrderData.products = this.order.articles;
        return newOrderData;
      } else {
        return this.getSingleOrder;
      }
    },
    handleOnAmountChangeInCart(product) {
      let tempOrder = null;
       if (
        this.order.articles &&
        this.order.articles.length
      ) {
        let newOrderData = JSON.parse(JSON.stringify(this.getSingleOrder));
        newOrderData.products = this.order.articles;
        tempOrder = newOrderData;
      } else {
        tempOrder = this.getSingleOrder;
      }
      tempOrder.products.forEach(p => {
        if (p.id === product.id) {
          p.amount = product.amount;
        }
      });
      this.orderForEdit = tempOrder;
    }
  },
  computed: {
    ...mapGetters({
      getSuppliers: "supplier/getDepartmentSuppliers",
      orderObj: "orders/getOrderState",
      getVenue: "userProfile/getVenue",
      getUser: "auth/getUser",
      getCustomOrderToEdit: "orders/getCustomOrderToEdit",
      customOrder: "orders/getCustomOrderObj",
      getSelectedSupplierNote: "userProfile/getSelectedSupplierNote",
    }),
    getSingleOrder() {
      if (Object.keys(this.getCustomOrder).length === 0) {
        if (this.orderObj && this.suppliers && this.orderObj.supplier) {
          const { supplier } = this.orderObj;
          const contact =
            this.suppliers &&
            this.suppliers.filter((s) => s.id === supplier.id);
          if (contact && contact.length > 0) {
            this.$set(supplier, "contacts", contact[0].contacts);
          }
          return this.orderObj;
        }
      } else {
        this.customOrder.supplier = {
          address: null,
          id: null,
          isMarket: true,
          name: "Magacin",
          pib: null,
        };
        return this.customOrder;
      }
      return {};
    },

    getAllSteps() {
      return this.allSteps;
    },
    getIsMobile() {
      return this.$isMobile;
    },
    suppliers() {
      return this.getSuppliers;
    },
    user() {
      return this.getUser;
    },
    customOrderToEdit() {
      return this.getCustomOrderToEdit;
    },
    getCustomOrder() {
      return this.customOrder;
    },
    station() {
      return (
        this.stationID &&
        this.$attrs.userProfile?.departments?.find(
          (d) => d.id === this.stationID
        )
      );
    },
    stationName() {
      return this.station?.name;
    },
    allSupplierNotes() {
      return this.getSelectedSupplierNote;
    },
  },
  watch: {
    currentStep() {
      if (this.currentStep === 1) {
        this.processDataFromLocalStorage();
        this.orderForEdit = this.getSingleOrderObjectForEdit();
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.arrow-left-wrap {
  background: #ffffff;
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.06);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 44px;
  height: 40px;
  cursor: pointer;
}
.fullScreen {
  position: absolute;
  top: 0;
  height: 100vh;
  background: #ffffff;
  z-index: 99;
  width: 100vw;
  padding: 24px !important;
}
.hideOnMobile {
  @media screen and (max-width: 960px) {
    display: none !important;
  }
}
.hideOnBigScreens {
  @media screen and (min-width: 961px) {
    display: none !important;
  }
}
.padding-adjust {
  padding: 0 32px !important;
  @media screen and (max-width: 959px) {
    padding: 8px !important;
  }
}
.cart-button {
  position: fixed;
  top: 90%;
}
.text-subtitle-1 {
  white-space: break-spaces;
}
</style>
