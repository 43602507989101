<template>
  <div class="padding-adjust">
    <div class="d-flex align-center justify-space-between">
      <span class="text-h5 font-weight-bold primaryA--text">{{
        $t("articles.listArticles")
      }}</span>
      <v-btn
        v-if="showBackToNewOrder"
        color="accent"
        small
        @click="backToNewOrder"
        >{{ $t("articles.backToOrder") }}</v-btn
      >
    </div>
    <div class="search-filter">
      <v-text-field
        :disabled="!selectedFilterSupplier"
        id="search"
        name="search"
        class="search-input"
        :placeholder="$t('articles.searchArticles')"
        v-model="searchValue"
        type="text"
        solo
        prepend-inner-icon="mdi-magnify"
        clearable
        @keyup="onSearchUp"
        @keydown="onSearchDown"
      ></v-text-field>
      <div class="d-flex selects-wrap">
        <v-select
          class="select-width"
          :items="getAllDepartments"
          :placeholder="$t('common.stations')"
          v-model="selectedDepartmentFilter"
          item-text="name"
          item-value="id"
          solo
          @change="onDepartmentSelected"
        ></v-select>
        <v-select
          class="select-width"
          :items="getAllSuppliers"
          :placeholder="$t('common.suppliers')"
          v-model="selectedFilterSupplier"
          item-text="name"
          item-value="id"
          solo
          @change="onSupplierSelected"
          :disabled="!selectedDepartmentFilter"
        ></v-select>
        <v-select
          :disabled="!selectedFilterSupplier"
          class="select-width"
          :items="allCategories"
          :placeholder="$t('articles.articlesCategories')"
          v-model="selectedFilterGroup"
          item-text="category"
          item-value="id"
          solo
          @change="onGroupSelected"
        ></v-select>
      </div>
      <v-checkbox
        class="ml-2"
        :label="$t('articles.favoriteOnly')"
        v-model="isFavoriteFilterSelected"
        :disabled="!selectedFilterSupplier || !selectedDepartmentFilter"
      ></v-checkbox>
    </div>

    <div :class="[$isMobile ? 'mt-0' : 'mt-11']">
      <articles-data-table
        v-if="!noArticles"
        :headers="headers"
        class="elevation-0"
        :hide-default-footer="false"
        :data="getAllArticles"
        :isDataLoading="isDataLoading"
        @onArticlePriceUpdate="handleArticlePriceUpdated"
        :totalCount="getTotalCount"
        @onPageChange="handlePageChange"
        :pageSize="pageSize"
        @onArticleFavorite="handleOnFavoriteArticle"
        @itemsPerPageChange="onItemsPerPageChange"
        :departmentId="selectedDepartmentFilter"
      ></articles-data-table>
      <no-results-content
        v-else
        :noResultsText="$t('common.noResuls')"
        :page="'articles'"
        :isFavoriteOnlySearch="isFavoriteFilterSelected && !searchValue"
        :supplierId="selectedFilterSupplier"
        :departmentId="selectedDepartmentFilter"
        :requestedArticleName="searchValue"
        :isSearchInitiated="getIsSearchedInitiated"
        @onSuccessRequestSent="handleOnSuccessRequestSent"
      ></no-results-content>
    </div>
  </div>
</template>
<script>
import NoResultsContent from "../../components/NoResultsContent.vue";
import ArticlesDataTable from "./ArticlesDataTable.vue";
//vuex
import { mapGetters } from "vuex";
import { REQUEST_PENDING, REQUEST_SUCCESS } from "../../shared/statuses";
//Event bus
import EventBus from "../../shared/EventBus";

export default {
  name: "ArticlesPage",
  components: { NoResultsContent, ArticlesDataTable },
  data: () => ({
    fab: false,
    top: true,
    left: true,
    headers: [],
    articlesData: [],
    isDataLoading: false,
    selectedArticles: [],
    searchValue: "",
    selectedFilterGroup: "",
    selectedFilterSupplier: "",
    selectedDepartmentFilter: "",
    showImportArticleModal: false,
    initialArticles: {},
    typingTimer: null,
    doneTypingInterval: 1500,
    pageNumber: 1,
    pageSize: 10,
    isFavoriteFilterSelected: false,
  }),
  mounted() {
    // this.$analytics.logEvent("page_view", { page_title: "Articles Page" });
  },
  created() {
    this.$store.dispatch("userProfile/getUserProfile");
    // .then(() => {
    //   this.$store.dispatch("userProfile/getVenueSuppliers", {
    //     venueId: this.getVenue.id,
    //   });
    // });
    this.$store.dispatch("userProfile/getDepartments");

    this.headers = [
      {
        text: this.$t("articles.favorite"),
        value: "checkbox_action",
        sortable: false,
      },
      {
        text: this.$t("articles.recommendedQuantity"),
        value: "recommendedQuantity",
        sortable: false,
      },
      {
        text: this.$t("common.name"),
        value: "name",
        sortable: false,
      },
      {
        text: this.$t("common.group"),
        value: "__category__",
        sortable: false,
      },
      // temporarily disabled on client
      // {
      //   text: this.$t("articles.price"),
      //   value: "defaultPrice",
      //   sortable: false,
      // },
    ];
  },
  methods: {
    handleImportArticles() {
      this.showImportArticleModal = true;
    },
    handleSelectedArticles(article) {
      this.$store
        .dispatch("articles/editProductInArticles", {
          departmentId: this.selectedDepartmentFilter,
          articleID: article.id,
          defaultPrice: article.defaultPrice,
          enabled: article.enabled,
        })
        .then(
          () => {},
          (error) => {
            console.log("article error", error);
          }
        );
    },
    handleCancel() {
      this.selectedArticles = [];
      this.articlesData.forEach((a) => {
        this.$set(a, "selected", false);
      });
      this.$forceUpdate();
    },
    onSupplierSelected(e) {
      this.selectedFilterGroup = null;
      this.$store
        .dispatch("articles/getAllArticleSupplierCategories", {
          supplierId: e,
        })
        .then((response) => {
          this.selectedFilterGroup = response && response.data[0].id;
          this.getAllArticlesSupplier();
        });
    },
    backToNewOrder() {
      let data = localStorage.getItem("cameFromNewOrder");
      data = JSON.parse(data);
      this.$router.push(`/${data.id}/new-order`);
      localStorage.removeItem("cameFromNewOrder");
    },
    handleArticlePriceUpdated(value) {
      this.$store
        .dispatch("articles/editProductInArticles", {
          departmentId: this.selectedDepartmentFilter,
          articleID: value.item.id,
          defaultPrice: value.newPrice,
          enabled: value.item.enabled,
        })
        .then(
          () => {
            EventBus.dispatch("showSnackbar", {
              text: this.$t("notifications.articleUpdated"),
            });
          },
          (error) => {
            console.log("article update error", error);
          }
        );
    },
    onSearchUp() {
      clearTimeout(this.typingTimer);
      this.typingTimer = setTimeout(
        this.searchArticles,
        this.doneTypingInterval
      );
    },
    onSearchDown() {
      clearTimeout(this.typingTimer);
    },
    searchArticles() {
      this.pageNumber = 1;
      this.pageSize = 10;
      this.getAllArticlesSupplier();
    },
    getAllArticlesSupplier() {
      this.$store.dispatch("articles/clearAllArticlesSupplier");
      let payload = {
        departmentId: this.selectedDepartmentFilter,
        supplierId: this.selectedFilterSupplier,
        pageNum: this.pageNumber,
        categoryId: this.selectedFilterGroup,
        searchQuery: this.searchValue,
        pageSize: this.pageSize,
        onlyFavorite: this.isFavoriteFilterSelected,
      };
      !this.selectedFilterGroup && delete payload.categoryId;
      this.$store.dispatch("articles/getArticlesSupplier", payload).then(
        () => {},
        (error) => {
          console.log("error", error);
        }
      );
    },
    onGroupSelected() {
      this.pageNumber = 1;
      this.getAllArticlesSupplier();
    },
    handlePageChange(page) {
      this.pageNumber = page;
      this.getAllArticlesSupplier();
    },
    onDepartmentSelected(departmentId) {
      this.selectedFilterSupplier = "";
      this.selectedFilterGroup = null;
      this.$store.dispatch("articles/clearAllArticlesSupplier");
      this.$store.dispatch("supplier/getSuppliersForDepartment", {
        departmentId,
      });
    },
    handleOnFavoriteArticle(item) {
      this.$store
        .dispatch("articles/toggleFavoriteArticle", {
          departmentId: this.selectedDepartmentFilter,
          articleId: item.id,
        })
        .then(
          () => {
            // this.getAllArticlesSupplier();
          },
          (err) => {
            alert(err);
          }
        );
    },
    onItemsPerPageChange(event) {
      this.pageSize = event;
      this.getAllArticlesSupplier();
    },
    handleOnSuccessRequestSent() {
      this.searchValue = "";
      this.searchArticles();
    },
  },
  watch: {
    getAllProductsStatus() {
      this.isDataLoading = this.getAllProductsStatus === REQUEST_PENDING;
    },
    getAllArticles() {
      // console.log("getAllArticles", this.getAllArticles);
    },
    isFavoriteFilterSelected() {
      this.getAllArticlesSupplier();
    },
  },
  computed: {
    ...mapGetters({
      allSuppliers: "supplier/getDepartmentSuppliers",
      getAllProducts: "articles/getAllSupplierArticles",
      getAllProductsStatus: "articles/getAllSupplierArticlesStatus",
      getAllArticlesCategories: "articles/getAllArticlesCategories",
      getTotalArticlesCount: "articles/getTotalArticlesCount",
      getStations: "userProfile/getStations",
      getVenue: "userProfile/getVenue",
    }),
    getAllArticles() {
      return this.getAllProducts;
    },
    getAllSuppliers() {
      return this.allSuppliers
        ?.filter((supplier) => supplier.id)
        ?.sort((a, b) => {
          return a.name > b.name ? 1 : -1;
        });
    },
    getAllArticlesStatus() {
      return this.getAllProductsStatus;
    },
    noArticles() {
      return this.getAllArticles.length === 0 && this.isDataLoading === false;
    },
    getIsMobile() {
      return this.$isMobile;
    },
    getNumArticles() {
      return this.selectedArticles.length;
    },
    showBackToNewOrder() {
      let data = localStorage.getItem("cameFromNewOrder");
      data = JSON.parse(data);
      return data && data.show;
    },
    allCategories() {
      let ctgs = this.getAllArticlesCategories;
      ctgs.unshift({ id: null, category: this.$t("articles.all") });
      return ctgs;
    },
    getTotalCount() {
      return this.getTotalArticlesCount;
    },
    getAllDepartments() {
      return this.getStations;
    },
    getIsSearchedInitiated() {
      return this.searchValue && this.getAllProductsStatus === REQUEST_SUCCESS;
    },
  },
};
</script>
<style lang="scss" scoped>
.text-fix {
  text-transform: unset !important;
}
.bottom-actions {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  box-shadow: 0px -4px 14px rgba(0, 0, 0, 0.04);
  height: 80px;
  display: flex;
  align-items: center;
  padding-left: 100px;
}
.add-button {
  min-height: 54px;
  width: 189px;
  text-transform: none;
}
.cancel-button {
  min-height: 54px;
  width: 147px;
  margin-left: 16px;
  text-transform: none;
  color: #a6a6a6;
}
.search-input {
  max-width: 315px;
  height: 54px;
  @media screen and (max-width: 575px) {
    max-width: 100%;
  }
}
.search-filter {
  margin-top: 24px;
  display: flex;
  @media screen and (max-width: 575px) {
    flex-direction: column;
  }
}
.select-width {
  max-width: 315px;
  margin-left: 24px;
  @media screen and (max-width: 575px) {
    margin-left: 0;
    justify-content: space-between;
    max-width: 33%;
  }
}
.selects-wrap {
  @media screen and (max-width: 575px) {
    justify-content: space-between;
  }
}
.position-fixed {
  position: fixed;
  top: 50%;
  left: 0;
}
.float-add-btn {
  width: 36px !important;
  border-radius: unset !important;
  border-top-right-radius: 30% !important;
  border-bottom-right-radius: 30% !important;
}
.padding-adjust {
  padding: 32px;
  margin-top: 32px;
  @media screen and (max-width: 575px) {
    padding: 8px;
  }
}
</style>
