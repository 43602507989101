import { render, staticRenderFns } from "./SupplierSingleOrder.vue?vue&type=template&id=4190a72b&scoped=true&"
import script from "./SupplierSingleOrder.vue?vue&type=script&lang=js&"
export * from "./SupplierSingleOrder.vue?vue&type=script&lang=js&"
import style0 from "./SupplierSingleOrder.vue?vue&type=style&index=0&id=4190a72b&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4190a72b",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
installComponents(component, {VBtn,VCheckbox,VDatePicker,VDialog,VMenu,VSimpleTable,VTextField,VTextarea})
