<template>
  <div class="padding-adjust">
    <div class="d-flex align-center" style="cursor:pointer" @click="goBack">
      <div class="arrow-left-wrap mr-2" >
        <v-img
          max-width="8"
          :src="require('../../assets/img/ArrowLeft.svg')"
        ></v-img>
      </div>
      <span>{{$t("help.back")}}</span>
    </div>
    <div class="d-flex justify-center align-center mt-3 mb-3 text-h6">
      {{ getAllArticles[$route.params.id].name }}
    </div>
    <div class="d-flex justify-center align-center marginBiggerScreens">
      <v-expansion-panels>
        <v-expansion-panel v-for="item in allArticles" :key="item.id">
          <v-expansion-panel-header>{{ item.title }}</v-expansion-panel-header>
          <v-expansion-panel-content>
            <div v-html="item.text"></div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "SingleHelpArticle",
  data: () => ({
    allArticles: [],
  }),
  mounted() {
    this.allArticles =
      this.getAllArticles[parseInt(this.$route.params.id)].allArticles;
  },
  methods: {
    goBack() {
      this.$router.push("/help");
    },
  },
  computed: {
    ...mapGetters({
      allHelpArticles: "help/getAllHelpArticles",
    }),
    getAllArticles() {
      return this.allHelpArticles;
    },
  },
  watch: {
    getAllArticles() {
      if (this.getAllArticles) {
        this.allArticles = this.getAllArticles[0];
      }
    },
  },
};
</script>
<style scoped lang="scss">
.padding-adjust {
  margin-top: 32px;
  padding: 24px;
  @media screen and (max-width: 575px) {
    padding: 8px;
  }
}
.arrow-left-wrap {
  background: #ffffff;
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.06);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 44px;
  height: 40px;
  cursor: pointer;
}
.marginBiggerScreens {
    @media screen and (min-width: 900px) {
    margin: auto 10vw;
  }
}
</style>
