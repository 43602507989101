import store from ".";
import OrdersService from "../services/orders.service";
import {
  orderStatuses,
  REQUEST_FAILURE,
  REQUEST_PENDING,
  REQUEST_SUCCESS,
  REQUEST_UNDEFINED,
} from "../shared/statuses";

const initialState = {
  allOrders: [],
  order: {},
  getAllOrdersStatus: REQUEST_UNDEFINED,
  createOrderStatus: REQUEST_UNDEFINED,
  getOrderStatus: REQUEST_UNDEFINED,
  updateOrderStatus: REQUEST_UNDEFINED,
  sendOrderStatus: REQUEST_UNDEFINED,
  completeOrderStatus: REQUEST_UNDEFINED,
  getAllVenueOrdersStatus: REQUEST_UNDEFINED,
  allOrdersVenue: [],
  deleteOrderStatus: REQUEST_UNDEFINED,
  createCustomOrderStatus: REQUEST_UNDEFINED,
  updateCustomOrderStatus: REQUEST_UNDEFINED,
  customOrder: {},
  getCustomOrderStatus: REQUEST_UNDEFINED,
  deleteCustomProductOrderStatus: REQUEST_UNDEFINED,
  deleteCustomOrderStatus: REQUEST_UNDEFINED,
  completeCustomOrderStatus: REQUEST_UNDEFINED,
  sendCustomOrderStatus: REQUEST_UNDEFINED,
  customOrderToEdit: null,
  totalOrdersCount: 0,
  totalRegularOrdersCount: 0,
  totalCustomOrdersCount: 0,
  getAllOrdersForUserStatus: REQUEST_UNDEFINED,
  getAllRegularOrdersInProgressStatus: REQUEST_UNDEFINED,
  getAllCustomOrdersInProgressStatus: REQUEST_UNDEFINED,
  allRegularOrdersInProgressAndDueDate: [],
  allCustomOrdersInProgressAndDueDate: [],
  areSelectedArticlesCleared: false,
  allRegularOrders: [],
  allCustomOrders: [],
  allRegularOrdersStatus: REQUEST_UNDEFINED,
  allCustomOrdersStatus: REQUEST_UNDEFINED,
};

export const orders = {
  namespaced: true,
  state: initialState,
  actions: {
    getAllOrders({ commit }, payload) {
      commit("getAllOrdersPending");
      return OrdersService.getAllOrders(payload).then(
        (response) => {
          commit("getAllOrdersSuccess", response.data);
          return Promise.resolve(response);
        },
        (error) => {
          commit("getAllOrdersFailure");
          return Promise.reject(error);
        }
      );
    },
    getAllRegularOrders({ commit }, payload) {
      commit("getAllRegularOrdersPending");
      return OrdersService.getAllRegularOrders(payload).then(
        (response) => {
          commit("getAllRegularOrdersSuccess", response.data);
          return Promise.resolve(response);
        },
        (error) => {
          commit("getAllRegularOrdersFailure");
          return Promise.reject(error);
        }
      );
    },
    getAllCustomOrders({ commit }, payload) {
      commit("getAllCustomOrdersPending");
      return OrdersService.getAllCustomOrders(payload).then(
        (response) => {
          commit("getAllCustomOrdersSuccess", response.data);
          return Promise.resolve(response);
        },
        (error) => {
          commit("getAllCustomOrdersFailure");
          return Promise.reject(error);
        }
      );
    },
    getAllRegularOrdersInProgress({ commit }, payload) {
      commit("getAllRegularOrdersInProgressPending");
      return OrdersService.getAllRegularOrdersInProgress(payload).then(
        (response) => {
          commit("getAllRegularOrdersInProgressSuccess", response.data);
          return Promise.resolve(response);
        },
        (error) => {
          commit("getAllRegularOrdersInProgressFailure");
          return Promise.reject(error);
        }
      );
    },
    getAllCustomOrdersInProgress({ commit }, payload) {
      commit("getAllCustomOrdersInProgressPending");
      return OrdersService.getAllCustomOrdersInProgress(payload).then(
        (response) => {
          commit("getAllCustomOrdersInProgressSuccess", response.data);
          return Promise.resolve(response);
        },
        (error) => {
          commit("getAllCustomOrdersInProgressFailure");
          return Promise.reject(error);
        }
      );
    },

    createNewOrder({ commit }, payload) {
      commit("createOrderPending");
      return OrdersService.createOrder(payload).then(
        (response) => {
          commit("createOrderSuccess");
          return Promise.resolve(response);
        },
        (error) => {
          commit("createOrderFailure");
          return Promise.reject(error);
        }
      );
    },

    sendOrder({ commit }, payload) {
      commit("sendOrderPending");
      return OrdersService.sendOrder(payload).then(
        (response) => {
          commit("sendOrderSuccess");
          return Promise.resolve(response);
        },
        (error) => {
          commit("sendOrderFailure");
          return Promise.reject(error);
        }
      );
    },

    getOrder({ commit }, id) {
      commit("getOrderPending");
      return OrdersService.getOrder(id).then(
        (response) => {
          commit("getOrderSuccess", response.data);
          return Promise.resolve(response);
        },
        (error) => {
          commit("getOrderFailure");
          return Promise.reject(error);
        }
      );
    },

    updateOrder({ commit }, payload) {
      commit("updateOrderPending");
      return OrdersService.updateOrder(payload).then(
        (response) => {
          commit("updateOrderSuccess");
          return Promise.resolve(response);
        },
        (error) => {
          commit("updateOrderFailure");
          return Promise.reject(error);
        }
      );
    },

    completeOrder({ commit }, data) {
      commit("completeOrderPending");
      return OrdersService.completeOrder(data).then(
        (response) => {
          commit("completeOrderSuccess");
          return Promise.resolve(response);
        },
        (error) => {
          commit("completeOrderFailure");
          return Promise.reject(error);
        }
      );
    },

    getAllVenueOrders({ commit }, payload) {
      commit("getAllVenueOrdersPending");
      return OrdersService.getAllOrdersVenue(payload).then(
        (response) => {
          commit("getAllVenueOrdersSuccess", response.data);
          return Promise.resolve(response);
        },
        (error) => {
          commit("getAllVenueOrdersFailure");
          return Promise.reject(error);
        }
      );
    },

    deleteOrder({ commit }, id) {
      commit("deleteOrderPending");
      return OrdersService.deleteOrder(id).then(
        (response) => {
          commit("deleteOrderSuccess");
          return Promise.resolve(response);
        },
        (error) => {
          commit("deleteOrderFailure");
          return Promise.reject(error);
        }
      );
    },

    //////CUSTOM//////
    createCustomOrder({ commit }, payload) {
      commit("createCustomOrderPending");
      return OrdersService.createCustomOrder(payload).then(
        (response) => {
          commit("createCustomOrderSuccess");
          return Promise.resolve(response);
        },
        (error) => {
          commit("createCustomOrderFailure");
          return Promise.reject(error);
        }
      );
    },
    updateCustomOrder({ commit }, payload) {
      commit("updateCustomOrderPending");
      return OrdersService.updateCustomOrder(payload).then(
        (response) => {
          commit("updateCustomOrderSuccess");
          return Promise.resolve(response);
        },
        (error) => {
          commit("updateCustomOrderFailure");
          return Promise.reject(error);
        }
      );
    },
    getCustomOrder({ commit }, id) {
      commit("getCustomOrderPending");
      return OrdersService.getCustomOrder(id).then(
        (response) => {
          commit("getCustomOrderSuccess", response.data);
          return Promise.resolve(response);
        },
        (error) => {
          commit("getCustomOrderFailure");
          return Promise.reject(error);
        }
      );
    },
    deleteCustomProductOrder({ commit }, id) {
      commit("deleteCustomProductOrderPending");
      return OrdersService.deleteCustomProductOrder(id).then(
        (response) => {
          commit("deleteCustomProductOrderSuccess");
          return Promise.resolve(response);
        },
        (error) => {
          commit("deleteCustomProductOrderFailure");
          return Promise.reject(error);
        }
      );
    },
    deleteCustomOrder({ commit }, id) {
      commit("deleteCustomOrderPending");
      return OrdersService.deleteCustomOrder(id).then(
        (response) => {
          commit("deleteCustomOrderSuccess");
          return Promise.resolve(response);
        },
        (error) => {
          commit("deleteCustomOrderFailure");
          return Promise.reject(error);
        }
      );
    },
    completeCustomOrder({ commit }, payload) {
      commit("completeCustomOrderPending");
      return OrdersService.completeCustomOrder(payload).then(
        (response) => {
          commit("completeCustomOrderSuccess");
          return Promise.resolve(response);
        },
        (error) => {
          commit("completeCustomOrderFailure");
          return Promise.reject(error);
        }
      );
    },
    sendCustomOrder({ commit }, id) {
      commit("sendCustomOrderPending");
      return OrdersService.sendCustomOrder(id).then(
        (response) => {
          commit("sendCustomOrderSuccess");
          return Promise.resolve(response);
        },
        (error) => {
          commit("sendCustomOrderFailure");
          return Promise.reject(error);
        }
      );
    },
    setCustomOrderToEdit({ commit }, payload) {
      commit("setOrderToEdit", payload);
    },
    clearCustomOrder({ commit }) {
      commit("clearCustomOrder");
    },
    clearSelectedArticles({ commit }, payload) {
      commit("clearSelectedArticles", payload);
    },
    //////CUSTOM//////
  },
  mutations: {
    getAllOrdersPending(state) {
      state.getAllOrdersStatus = REQUEST_PENDING;
    },
    getAllOrdersSuccess(state, data) {
      state.getAllOrdersStatus = REQUEST_SUCCESS;
      state.totalOrdersCount = data.total_order_count;
      state.totalCustomOrdersCount = data.total_custom_order_count;
      let tempOrders = data.orders;
      if (tempOrders) {
        tempOrders.forEach((d) => {
          switch (d.status) {
            case orderStatuses.OPEN:
              d.statusText = "Otvorena";
              break;
            case orderStatuses.COMPLETED:
              d.statusText = "Isporučeno";
              break;
            case orderStatuses.IN_PROGRESS:
              d.statusText = "Naručeno";
              break;
            case orderStatuses.INCOMPLETE:
              d.statusText = "NEKOMPLETNA";
              break;
          }
        });
      }
      // state.allOrders = data.orders;

      let tempCustomOrders = data.customOrders;
      if (tempCustomOrders) {
        tempCustomOrders.forEach((d) => {
          d.isCustomOrder = true;
          switch (d.status) {
            case orderStatuses.OPEN:
              d.statusText = "Otvorena";
              break;
            case orderStatuses.COMPLETED:
              d.statusText = "Isporučeno";
              break;
            case orderStatuses.IN_PROGRESS:
              d.statusText = "Naručeno";
              break;
            case orderStatuses.INCOMPLETE:
              d.statusText = "NEKOMPLETNA";
              break;
          }
        });
      }

      const tempCombinedOrders = data.allOrdersCombined;
      tempCombinedOrders.forEach((d) => {
        d.isCustomOrder = !d.supplier;
        switch (d.status) {
          case orderStatuses.OPEN:
            d.statusText = "Otvorena";
            break;
          case orderStatuses.COMPLETED:
            d.statusText = "Isporučeno";
            break;
          case orderStatuses.IN_PROGRESS:
            d.statusText = "Naručeno";
            break;
          case orderStatuses.INCOMPLETE:
            d.statusText = "NEKOMPLETNA";
            break;
        }
      });

      state.allOrders = tempCombinedOrders;
      // state.allOrders = [...data.orders, ...data.customOrders];
    },
    getAllOrdersFailure(state) {
      state.getAllOrdersStatus = REQUEST_FAILURE;
    },
    //current orders > regular orders
    getAllRegularOrdersPending(state) {
      state.allRegularOrdersStatus = REQUEST_PENDING;
    },
    getAllRegularOrdersSuccess(state, data) {
      state.allRegularOrdersStatus = REQUEST_SUCCESS;
      state.totalRegularOrdersCount = data.total_order_count;
      let tempOrders = data.orders;
      const language = store.getters["orders/getProfileData"].language;
      const isLanguageEnglish = language === "en";
      if (tempOrders) {
        tempOrders.forEach((d) => {
          switch (d.status) {
            case orderStatuses.OPEN:
              d.statusText = isLanguageEnglish ? "Open" : "Otvorena";
              break;
            case orderStatuses.COMPLETED:
              d.statusText = isLanguageEnglish ? "Delivered" : "Isporučeno";
              break;
            case orderStatuses.IN_PROGRESS:
              d.statusText = isLanguageEnglish ? "Ordered" : "Naručeno";
              break;
            case orderStatuses.INCOMPLETE:
              d.statusText = isLanguageEnglish ? "Incomplete" : "NEKOMPLETNA";
              break;
          }
        });
      }
      state.allRegularOrders = tempOrders;
    },
    getAllRegularOrdersFailure(state) {
      state.allRegularOrdersStatus = REQUEST_FAILURE;
    },
    //
    getAllCustomOrdersPending(state) {
      state.allCustomOrdersStatus = REQUEST_PENDING;
    },
    getAllCustomOrdersSuccess(state, data) {
      state.allCustomOrdersStatus = REQUEST_SUCCESS;
      state.totalCustomOrdersCount = data.total_order_count;

      let tempOrders = data.orders;
      const language = store.getters["orders/getProfileData"].language;
      const isLanguageEnglish = language === "en";

      if (tempOrders) {
        tempOrders.forEach((d) => {
          switch (d.status) {
            case orderStatuses.OPEN:
              d.statusText = isLanguageEnglish ? "Open" : "Otvorena";
              break;
            case orderStatuses.COMPLETED:
              d.statusText = isLanguageEnglish ? "Delivered" : "Isporučeno";
              break;
            case orderStatuses.IN_PROGRESS:
              d.statusText = isLanguageEnglish ? "Ordered" : "Naručeno";
              break;
            case orderStatuses.INCOMPLETE:
              d.statusText = isLanguageEnglish ? "Incomplete" : "NEKOMPLETNA";
              break;
          }
        });
      }
      state.allCustomOrders = tempOrders;
    },
    getAllCustomOrdersFailure(state) {
      state.allCustomOrdersStatus = REQUEST_FAILURE;
    },
    getAllRegularOrdersInProgressPending(state) {
      state.getAllRegularOrdersInProgressStatus = REQUEST_PENDING;
      state.allRegularOrdersInProgressAndDueDate = [];
    },
    getAllRegularOrdersInProgressSuccess(state, data) {
      state.getAllRegularOrdersInProgressStatus = REQUEST_SUCCESS;
      const timestamp = new Date().getTime();
      const yesterdayTimeStamp = timestamp - 24 * 60 * 60 * 1000;
      const yesterdayDate = new Date(yesterdayTimeStamp).toISOString();
      state.allRegularOrdersInProgressAndDueDate = data.orders
        .filter((order) => yesterdayDate >= order.deadline)
        .sort((a, b) => new Date(a.deadline) - new Date(b.deadline));
    },
    getAllRegularOrdersInProgressFailure(state) {
      state.getAllRegularOrdersInProgressStatus = REQUEST_FAILURE;
      state.allRegularOrdersInProgressAndDueDate = [];
    },

    getAllCustomOrdersInProgressPending(state) {
      state.getAllCustomOrdersInProgressStatus = REQUEST_PENDING;
      state.allCustomOrdersInProgressAndDueDate = [];
    },
    getAllCustomOrdersInProgressSuccess(state, data) {
      state.getAllCustomOrdersInProgressStatus = REQUEST_SUCCESS;
      const timestamp = new Date().getTime();
      const yesterdayTimeStamp = timestamp - 24 * 60 * 60 * 1000;
      const yesterdayDate = new Date(yesterdayTimeStamp).toISOString();
      state.allCustomOrdersInProgressAndDueDate = data.orders
        .filter((order) => yesterdayDate >= order.deadline)
        .sort((a, b) => new Date(a.deadline) - new Date(b.deadline));
    },
    getAllCustomOrdersInProgressFailure(state) {
      state.getAllCustomOrdersInProgressStatus = REQUEST_FAILURE;
      state.allCustomOrdersInProgressAndDueDate = [];
    },

    createOrderPending(state) {
      state.createOrderStatus = REQUEST_PENDING;
    },
    createOrderSuccess(state) {
      state.createOrderStatus = REQUEST_SUCCESS;
    },
    createOrderFailure(state) {
      state.createOrderStatus = REQUEST_FAILURE;
    },
    getOrderPending(state) {
      state.getOrderStatus = REQUEST_PENDING;
    },
    getOrderSuccess(state, data) {
      state.getOrderStatus = REQUEST_SUCCESS;
      state.order = data;
    },
    getOrderFailure(state) {
      state.getOrderStatus = REQUEST_FAILURE;
    },
    updateOrderPending(state) {
      state.updateOrderStatus = REQUEST_PENDING;
    },
    updateOrderSuccess(state) {
      state.updateOrderStatus = REQUEST_SUCCESS;
    },
    updateOrderFailure(state) {
      state.updateOrderStatus = REQUEST_FAILURE;
    },
    sendOrderPending(state) {
      state.sendOrderStatus = REQUEST_PENDING;
    },
    sendOrderSuccess(state) {
      state.sendOrderStatus = REQUEST_SUCCESS;
    },
    sendOrderFailure(state) {
      state.sendOrderStatus = REQUEST_FAILURE;
    },
    completeOrderPending(state) {
      state.completeOrderStatus = REQUEST_PENDING;
    },
    completeOrderSuccess(state) {
      state.completeOrderStatus = REQUEST_SUCCESS;
    },
    completeOrderFailure(state) {
      state.completeOrderStatus = REQUEST_FAILURE;
    },
    getAllVenueOrdersPending(state) {
      state.getAllVenueOrdersStatus = REQUEST_PENDING;
    },
    getAllVenueOrdersSuccess(state, data) {
      state.getAllVenueOrdersStatus = REQUEST_SUCCESS;
      data.customOrders?.forEach((f) => (f.isCustomOrder = true));
      state.allOrdersVenue = data.orders;
    },
    getAllVenueOrdersFailure(state) {
      state.getAllVenueOrdersStatus = REQUEST_FAILURE;
    },

    deleteOrderPending(state) {
      state.deleteOrderStatus = REQUEST_PENDING;
    },
    deleteOrderSuccess(state) {
      state.deleteOrderStatus = REQUEST_SUCCESS;
    },
    deleteOrderFailure(state) {
      state.deleteOrderStatus = REQUEST_FAILURE;
    },

    getAllOrdersForUserAdminPending(state) {
      state.getAllOrdersForUserStatus = REQUEST_PENDING;
    },
    getAllOrdersForUserAdminSuccess(state) {
      state.getAllOrdersForUserStatus = REQUEST_SUCCESS;
    },
    getAllOrdersForUserAdminFailure(state) {
      state.getAllOrdersForUserStatus = REQUEST_FAILURE;
    },

    createCustomOrderPending(state) {
      state.createCustomOrderStatus = REQUEST_PENDING;
    },
    createCustomOrderSuccess(state) {
      state.createCustomOrderStatus = REQUEST_SUCCESS;
    },
    createCustomOrderFailure(state) {
      state.createCustomOrderStatus = REQUEST_FAILURE;
    },

    updateCustomOrderPending(state) {
      state.updateCustomOrderStatus = REQUEST_PENDING;
    },
    updateCustomOrderSuccess(state) {
      state.updateCustomOrderStatus = REQUEST_SUCCESS;
    },
    updateCustomOrderFailure(state) {
      state.updateCustomOrderStatus = REQUEST_FAILURE;
    },

    getCustomOrderPending(state) {
      state.getCustomOrderStatus = REQUEST_PENDING;
      state.customOrder = {};
    },
    getCustomOrderSuccess(state, payload) {
      state.getCustomOrderStatus = REQUEST_SUCCESS;
      state.customOrder = payload;
    },
    getCustomOrderFailure(state) {
      state.getCustomOrderStatus = REQUEST_FAILURE;
      state.customOrder = {};
    },

    deleteCustomProductOrderPending(state) {
      state.deleteCustomProductOrderStatus = REQUEST_PENDING;
    },
    deleteCustomProductOrderSuccess(state) {
      state.deleteCustomProductOrderStatus = REQUEST_SUCCESS;
    },
    deleteCustomProductOrderFailure(state) {
      state.deleteCustomProductOrderStatus = REQUEST_FAILURE;
    },

    deleteCustomOrderPending(state) {
      state.deleteCustomOrderStatus = REQUEST_PENDING;
    },
    deleteCustomOrderSuccess(state) {
      state.deleteCustomOrderStatus = REQUEST_SUCCESS;
    },
    deleteCustomOrderFailure(state) {
      state.deleteCustomOrderStatus = REQUEST_FAILURE;
    },

    completeCustomOrderPending(state) {
      state.completeCustomOrderStatus = REQUEST_PENDING;
    },
    completeCustomOrderSuccess(state) {
      state.completeCustomOrderStatus = REQUEST_SUCCESS;
    },
    completeCustomOrderFailure(state) {
      state.completeCustomOrderStatus = REQUEST_FAILURE;
    },

    sendCustomOrderPending(state) {
      state.sendCustomOrderStatus = REQUEST_PENDING;
    },
    sendCustomOrderSuccess(state) {
      state.sendCustomOrderStatus = REQUEST_SUCCESS;
    },
    sendCustomOrderFailure(state) {
      state.sendCustomOrderStatus = REQUEST_FAILURE;
    },
    setOrderToEdit(state, payload) {
      state.customOrderToEdit = payload;
    },
    clearCustomOrder(state) {
      state.customOrder = {};
    },
    clearSelectedArticles(state, payload) {
      state.areSelectedArticlesCleared = payload;
    },
  },
  getters: {
    getAllOrdersState: (state) => {
      return state.allOrders;
    },
    getAllRegularOrdersState: (state) => {
      return state.allRegularOrders;
    },
    getAllRegularOrdersStatus: (state) => {
      return state.allRegularOrdersStatus;
    },
    getAllOrdersStatus: (state) => {
      return state.getAllOrdersStatus;
    },
    getAllCustomOrdersState: (state) => {
      return state.allCustomOrders;
    },
    getAllCustomOrdersStatus: (state) => {
      return state.allCustomOrdersStatus;
    },
    getCreateOrderStatus: (state) => {
      return state.createOrderStatus;
    },
    getOrderState: (state) => {
      return state.order;
    },
    getOrderStatus: (state) => {
      return state.getOrderStatus;
    },
    getUpdateOrderStatus: (state) => {
      return state.updateOrderStatus;
    },
    getSendOrderStatus: (state) => {
      return state.sendOrderStatus;
    },
    completeOrderStatus: (state) => {
      return state.completeOrderStatus;
    },
    getAllOrdersVenueStatus: (state) => {
      return state.getAllVenueOrdersStatus;
    },
    getAllOrdersVenueState: (state) => {
      return state.allOrdersVenue;
    },
    getDeleteOrderStatus: (state) => {
      return state.deleteOrderStatus;
    },
    getCustomOrderObj: (state) => {
      return state.customOrder;
    },
    getCustomOrderToEdit: (state) => {
      return state.customOrderToEdit;
    },
    getUpdateCustomOrderStatus: (state) => {
      return state.updateCustomOrderStatus;
    },
    getTotalOrdersCount: (state) => {
      return state.totalOrdersCount + state.totalCustomOrdersCount;
    },
    getTotalRegularOrdersCount: (state) => {
      return state.totalRegularOrdersCount;
    },
    getTotalCustomOrdersCount: (state) => {
      return state.totalCustomOrdersCount;
    },
    getAllRegularOrdersInProgressDueDate: (state) => {
      return state.allRegularOrdersInProgressAndDueDate;
    },
    getAllCustomOrdersInProgressDueDate: (state) => {
      return state.allCustomOrdersInProgressAndDueDate;
    },
    getAreSelectedArticlesCleared: (state) => {
      return state.areSelectedArticlesCleared;
    },
    getProfileData: (state, _, rootState) => {
      return rootState.userProfile.userProfile;
    },
  },
};
