<template>
  <div class="ml-5 date-range-wrapper">
    <v-menu
      ref="menu"
      v-model="menu"
      :close-on-content-click="false"
      :return-value.sync="dates"
      transition="scale-transition"
      offset-y
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          v-model="dateRangeText"
          :label="$t('common.dateRange')"
          prepend-icon="mdi-calendar"
          readonly
          v-bind="attrs"
          v-on="on"
          class="date-range-input"
          :disabled="isDisabled"
        ></v-text-field>
      </template>
      <v-date-picker v-model="dates" no-title scrollable range>
        <v-spacer></v-spacer>
        <v-btn text color="primary" @click="menu = false"> Cancel </v-btn>
        <v-btn
          :disabled="dates.length < 2"
          text
          color="primary"
          @click="onDateRangeSelected()"
        >
          OK
        </v-btn>
      </v-date-picker>
    </v-menu>
  </div>
</template>

<script>
export default {
  name: "DateRangePicker",
  props: {
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    dates: [],
    menu: false,
  }),
  methods: {
    onDateRangeSelected() {
      console.log("dates", this.dates);
      this.$refs.menu.save(this.dates);
      this.menu = false;
      this.$emit("onDateRangeSelected", this.dates);
    },
  },
  computed: {
    dateRangeText() {
      return this.dates.join(" ~ ");
    },
  },
};
</script>
<style scoped lang="scss">
.date-range-wrapper {
  @media screen and (max-width: 575px) {
    margin-left: 0 !important;
    width: 100%;
  }
}
.date-range-input {
  width: 230px;
  @media screen and (max-width: 575px) {
    width: 100%;
  }
}
</style>
