<template>
  <div :class="className">
    <v-tooltip right :color="color" open-on-click open-on-hover :max-width="width">
      <template v-slot:activator="{ on, attrs }">
        <v-icon :color="color" dark v-bind="attrs" v-on="on">
          {{ icon }}
        </v-icon>
      </template>
      <span class="primaryB--text font-weight-bold">{{ text }}</span>
    </v-tooltip>
  </div>
</template>
<script>
export default {
  name: "TooltipComponent",
  props: {
    text: {},
    icon: {},
    color: {},
    className: {},
    width: {}
  },
};
</script>
<style lang="scss" scoped></style>
