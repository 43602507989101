<template functional>
  <v-card
    elevation="3"
    class="pa-6 mr-6 d-flex flex-column primaryB--text cursor stats-card-mobile"
    height="120px"
    color="secondaryDark2"
  >
    <span class="text-h3">{{ props.title }}</span>
    <span>{{ props.subtitle }}</span>
  </v-card>
</template>
<script>
export default {
  functional: true,
  name: "StatsCard",
  props: { title: {}, subtitle: {} },
};
</script>
<style lang="scss" scoped>
.cursor {
  cursor: pointer;
}
.stats-card-mobile {
  @media screen and(max-width: 600px) {
    width: 100% !important;
    margin-right: 0px !important;
    margin-bottom: 8px !important;
  }
}
</style>
