<template>
  <v-dialog v-model="dialog" max-width="600" :fullscreen="getIsMobile">
    <v-card class="pa-4" elevation="0" style="overflow:hidden">
      <div class="d-flex justify-end mt-2">
        <v-btn
          @click="selectedLanguage = 'sr'"
          v-if="selectedLanguage === 'sr'"
          color="accent"
          small
          >{{selectedLanguage === "sr" ? "Srpski" : "Serbian"}}</v-btn
        >
        <v-btn
          @click="selectedLanguage = 'sr'"
          v-else
          color="accent"
          plain
          small
          >{{selectedLanguage === "sr" ? "Srpski" : "Serbian"}}</v-btn
        >
        <v-btn
          @click="selectedLanguage = 'en'"
          v-if="selectedLanguage === 'en'"
          color="accent"
          small
          >{{selectedLanguage === "sr" ? "Engleski" : "English"}}</v-btn
        >
        <v-btn
          @click="selectedLanguage = 'en'"
          v-else
          color="accent"
          plain
          small
          >{{selectedLanguage === "sr" ? "Engleski" : "English"}}</v-btn
        >
      </div>
      <div class="d-flex flex-column">
        <v-card-title class="pa-0 d-flex justify-center">{{
          getTitle
        }}</v-card-title>
        <v-card-title class="pa-0 d-flex justify-center">{{
          this.selectedLanguage === "sr" ? "SupplyMe" : "SupplyMe application"
        }}</v-card-title>
      </div>
      <v-card-text class="pa-2">
        <div :class="{textWrapper: getIsMobile}" v-html="getText"></div>
      </v-card-text>
      <v-card-actions class="pa-2">
        <v-btn color="primary" text @click="$emit('onClose')">{{
          this.selectedLanguage === "sr" ? "Zatvori" : "Close"
        }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: "TermsAndPoliciesModal",
  props: {
    dialog: {},
    type: {},
  },
  data: () => ({
    title: "",
    text: "",
    selectedLanguage: "sr",
  }),
  created() {
    this.selectedLanguage = "sr"
  },
  methods: {
    getTermsTextSer() {
      return `
    <p>
        Dobrodošli na SupplyMe, aplikaciju za upravljanje nabavkom u ugostiteljstvu, koja olakšava komunikaciju između ugostitelja i dobavljača, optimizuje proces nabavke i promoviše ekološku svest. Korišćenjem SupplyMe, saglasni ste sa sledećim uslovima korišćenja:
    </p>
    <ol>
        <li>Prihvatanje uslova<br>
            Pristupom ili korišćenjem aplikacije SupplyMe, potvrđujete da ste pročitali, razumeli i saglasni da budete obavezani ovim Uslovima korišćenja. Ako se ne slažete sa bilo kojim delom ovih uslova, molimo vas da ne koristite aplikaciju.</li>
        <li>Upotreba SupplyMe<br>
            SupplyMe vam dodeljuje neekskluzivnu, neprenosivu i povlačivu licencu za korišćenje aplikacije u svrhu za koju je namenjena. Saglasni ste da nećete koristiti aplikaciju u nezakonite ili nedozvoljene svrhe i da ćete se pridržavati svih važećih zakona i propisa tokom korišćenja aplikacije.</li>
        <li>Registracija naloga<br>
            Za korišćenje SupplyMe, morate kreirati nalog. Odgovorni ste za očuvanje poverljivosti podataka vašeg naloga i odgovorni za sve aktivnosti koje se odvijaju pod vašim nalogom. Morate pružiti tačne i ažurirane informacije prilikom registracije i blagovremeno ažurirati sve promene.</li>
        <li>Privatnost i bezbednost podataka<br>
            SupplyMe se obavezuje da zaštiti privatnost vaših podataka i primenjuje standardne bezbednosne mere kako bi zaštitio vaše informacije. Korišćenjem aplikacije, dajete saglasnost za prikupljanje, skladištenje i obradu vaših podataka u skladu sa našom Politikom privatnosti.</li>
        <li>Korisnički sadržaj<br>
            Zadržavate vlasništvo nad bilo kojim sadržajem koji unesete ili otpremite na aplikaciju, uključujući, ali ne ograničavajući se na narudžbe, poruke i povratne informacije. Međutim, dajete SupplyMe svetsku, bez naknade, neekskluzivnu licencu za korišćenje, reprodukciju, izmenu, prilagođavanje, objavljivanje, prevođenje, distribuciju i prikazivanje ovog sadržaja u svrhu pružanja usluga.</li>
        <li>Zabranjene aktivnosti<br>
            Saglasni ste da nećete se baviti bilo kojim aktivnostima koje mogu oštetiti, onesposobiti, preopteretiti ili narušiti funkcionalnost SupplyMe ili ometati uživanje drugih korisnika aplikacije. To uključuje, ali nije ograničeno na korišćenje automatizovanih sistema, neovlašćeni pristup aplikaciji i pokušaj zaobilaženja sigurnosnih mera.</li>
        <li>Intelektualna svojina<br>
            SupplyMe zadržava sva prava, vlasništvo i interese u aplikaciji, uključujući, ali ne ograničavajući se na autorska prava, zaštitne znakove, poslovna imena i patente. Ne smete koristiti našu intelektualnu svojinu bez prethodnog pismenog odobrenja.</li>
        <li>Cene i plaćanja<br>
            SupplyMe pruža usluge upravljanja nabavkom, ali nije odgovoran za cene i plaćanja za proizvode naručene putem aplikacije. Sve transakcije i finansijski aranžmani sa dobavljačima su isključivo između vas i dobavljača. SupplyMe ne vrši obradu plaćanja niti čuva finansijske informacije.</li>
        <li>Ograničenje odgovornosti<br>
            SupplyMe neće biti odgovoran za bilo kakvu direktnu, indirektnu, slučajnu, posebnu ili posledičnu štetu proizašlu iz korišćenja ili nemogućnosti korišćenja aplikacije, uključujući, ali ne ograničavajući se na gubitak profita, podataka ili poslovnih mogućnosti.</li>
        <li>Izmena i prekid<br>
            SupplyMe zadržava pravo da u bilo kom trenutku bez prethodne najave izmeni, suspenduje ili prekine aplikaciju ili bilo koji njen deo. Takođe možemo ažurirati ove Uslove korišćenja s vremena na vreme, a vi ste odgovorni za proveru ažuriranja.</li>
        <li>Zakon koji se primenjuje<br>
            Ovi Uslovi korišćenja biće regulisani i tumačeni u skladu sa zakonima Republike Srbije. Svi sporovi koji proisteknu iz korišćenja SupplyMe biće podložni isključivoj nadležnosti nadležnih sudova u Republici Srbiji.</li>
        <li>Deobnost<br>
            Ukoliko se utvrdi da je neka odredba ovih Uslova korišćenja nevažeća ili nemoćna za izvršenje, preostale odredbe ostaju u punoj snazi i efektu.</li>
    </ol>
    <p>
        Korišćenjem SupplyMe, pristajete da budete obavezani ovim Uslovima korišćenja. Ako imate bilo kakvih pitanja ili nedoumica u vezi sa ovim uslovima, kontaktirajte nas na <a href="mailto:office@supplyme.rs">office@supplyme.rs</a>.
    </p>
    <p>
        Poslednje ažuriranje: 1.1.2023.
    </p>
        `;
    },
    getTermsTextEng() {
      return `
    <p>
         Welcome to SupplyMe, the catering procurement management application that facilitates communication between caterers and suppliers, streamlines the procurement process, and promotes environmental awareness. By using SupplyMe, you agree to the following terms and conditions:
    </p>
    <ol>
        <li>Acceptance of Terms<br>
            By accessing or using the SupplyMe application, you acknowledge that you have read, understood, and agreed to be bound by these Terms and Conditions. If you do not agree with any part of these terms, you should refrain from using the application.</li>
        <li>Use of SupplyMe<br>
            SupplyMe grants you a non-exclusive, non-transferable, and revocable license to use the application for its intended purpose. You agree not to use the application for any illegal or unauthorized purpose and will comply with all applicable laws and regulations while using the application.</li>
        <li>Account Registration<br>
            To use SupplyMe, you must create an account. You are responsible for maintaining the confidentiality of your account credentials and are liable for all activities that occur under your account. You must provide accurate and up-to-date information during registration and promptly update any changes.</li>
        <li>Data Privacy and Security<br>
            SupplyMe is committed to protecting your data privacy and employs industry-standard security measures to safeguard your information. By using the application, you consent to the collection, storage, and processing of your data in accordance with our Privacy Policy.</li>
        <li>User Content<br>
            You retain ownership of any content you submit or upload to the application, including but not limited to orders, messages, and feedback. However, you grant SupplyMe a worldwide, royalty-free, and non-exclusive license to use, reproduce, modify, adapt, publish, translate, distribute, and display this content for the purpose of providing the services.</li>
        <li>Prohibited Activities<br>
            You agree not to engage in any activities that may harm, disable, overburden, or impair the functioning of SupplyMe or interfere with other users' enjoyment of the application. This includes, but is not limited to, the use of automated systems, unauthorized access to the application, and attempting to bypass security features.</li>
        <li>Intellectual Property<br>
            SupplyMe retains all rights, title, and interest in the application, including but not limited to copyrights, trademarks, trade names, and patents. You may not use any of our intellectual property without prior written permission.</li>
        <li>Pricing and Payments<br>
            While SupplyMe provides procurement management services, we are not responsible for pricing and payments for goods ordered through the application. All transactions and financial dealings with suppliers are solely between you and the supplier. SupplyMe does not process payments or store financial information.</li>
        <li>Limitation of Liability<br>
            SupplyMe shall not be liable for any direct, indirect, incidental, special, or consequential damages arising out of or in connection with the use or inability to use the application, including but not limited to loss of profits, data, or business opportunities.</li>
        <li>Modification and Termination<br>
            SupplyMe reserves the right to modify, suspend, or terminate the application or any part of it at any time without notice. We may also update these Terms and Conditions from time to time, and you are responsible for checking for updates.</li>
        <li>Governing Law<br>
            These Terms and Conditions shall be governed by and construed in accordance with the laws of Republic of Serbia. Any disputes arising from the use of SupplyMe shall be subject to the exclusive jurisdiction of the competent courts in  Republic of Serbia.</li>
        <li>Severability<br>
            If any provision of these Terms and Conditions is found to be invalid or unenforceable, the remaining provisions shall remain in full force and effect.</li>
    </ol>
    <p>
        By using SupplyMe, you agree to be bound by these Terms and Conditions. If you have any questions or concerns about these terms, please contact us at <a href="mailto:office@supplyme.rs">office@supplyme.rs</a>.
    </p>
    <p>
        Last updated: 1.1.2023.
    </p>
        `;
    },
  },
  computed: {
    getText() {
      if (this.type === "termsAndConditions") {
        return this.selectedLanguage === "sr"
          ? this.getTermsTextSer()
          : this.getTermsTextEng();
      }
      return "";
    },
    getTitle() {
      if (this.type === "termsAndConditions") {
        return this.selectedLanguage === "sr"
          ? "Uslovi korišćenja za aplikaciju"
          : "Terms and Conditions for";
      }
      return "";
    },
    getIsMobile() {
      return this.$isMobile;
    },
  },
};
</script>
<style scoped lang="scss">
.textWrapper {
    max-height: 80vh;
    overflow-x: hidden;
    overflow-y: auto;
}
</style>
