<template>
  <modal-component
    :dialog="dialog"
    @onClose="closePreviewDialog"
    :modalWidth="'650px'"
  >
    <div class="dialog-style">
      <div class="header">
        <span class="text-h5 font-weight-black">{{
          $t(
            ordersDue && ordersDue.length > 1
              ? "orderDueWarning.titlePlural"
              : "orderDueWarning.title"
          )
        }}</span>
      </div>

      <span class="text-caption mt-2 secondaryDark--text font-weight-black">{{
        $t(
          ordersDue && ordersDue.length > 1
            ? "orderDueWarning.descriptionPlural"
            : "orderDueWarning.description"
        )
      }}</span>
      <div v-if="ordersDue" class="mt-5 width100 order-due-modal">
        <v-data-table
          :headers="headers"
          :items="ordersDue"
          :items-per-page="5"
          :disable-pagination="true"
          :hide-default-footer="true"
          class="elevation-1"
        >
          <template v-slot:item.deadline="{ item }">
            <span>{{ getFormattedDate(item.deadline) }}</span>
          </template>
          <template v-slot:item.departmentName="{ item }">
            <span>{{ item.department.name }}</span>
          </template>
          <template v-slot:item.supplierName="{ item }">
            <span>{{
              item.supplier ? item.supplier.name : $t("articles.market")
            }}</span>
          </template>
          <template v-slot:item.createdByName="{ item }">
            <span>{{ item.createdBy.name }}</span>
          </template>
          <template v-slot:item.action="{ item }">
            <div class="d-flex pt-1" style="width: 100%">
              <v-btn
                small
                dark
                color="accent"
                @click="handleChangeOrderDate(item)"
                class="mr-2"
                >{{ $t("orderDueWarning.changeShippingOrderDate") }}</v-btn
              >
              <v-btn small dark color="accent" @click="handleOrder(item)">{{
                $t("orderDueWarning.handle")
              }}</v-btn>
            </div>
          </template>
        </v-data-table>
      </div>
    </div>
  </modal-component>
</template>
<script>
import ModalComponent from "../components/ModalComponent.vue";
import { formatDate } from '../helpers/helper';
export default {
  name: "OrderDueWarningModal",
  components: { ModalComponent },
  props: {
    dialog: {},
    ordersDue: {},
  },
  data: () => ({
    headers: [],
  }),
  created() {},
  mounted() {
    this.headers = [
      {
        text: this.$t("common.date"),
        value: "deadline",
        sortable: false,
      },
      {
        text: this.$t("orderDueWarning.station"),
        value: "departmentName",
        sortable: false,
      },
      {
        text: this.$t("orderDueWarning.supplier"),
        value: "supplierName",
        sortable: false,
      },
      {
        text: this.$t("orderDueWarning.createdBy"),
        value: "createdByName",
        sortable: false,
      },
      {
        text: "",
        value: "action",
        sortable: false,
      },
    ];
  },
  methods: {
    getFormattedDate(value) {
      return formatDate(value);
    },
    closePreviewDialog() {
      this.$emit("onClose");
    },
    handleOrder(order) {
      this.$emit("handleOrder", order);
    },
    cancelOrder() {
      this.$emit("onClose");
    },
    handleChangeOrderDate(order) {
      this.$emit("onChangeOrderDate", order);
    },
  },
  computed: {},
};
</script>
<style scoped lang="scss">
.dialog-style {
  background-color: #ffffff;

  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.info-text {
  text-align: center;
}
.header {
  position: relative;
}
.margin-adjust-button {
  margin-left: 0 !important;
  margin-top: 12px;
}
.remove-uppercase {
  text-transform: none !important;
}
.width100 {
  width: 100%;
}
</style>
