<template>
  <div class="text-center">
    <v-dialog
      v-model="showDialog"
      width="626"
      class="dialog-style"
      persistent
      :fullscreen="getIsMobile"
    >
      <div class="dialog-style">
        <div class="header mb-6">
          <div class="d-flex justify-space-between align-center">
            <span class="text-h5 primaryA--text font-weight-bold">{{
              $t("suppliers.addSupplierContact", {
                name: selectedSupplier && selectedSupplier.name,
              })
            }}</span>
            <v-img
              @click="closeDialog"
              class="close-icon"
              max-width="20px"
              :src="require('../assets/img/CloseIcon.svg')"
            >
            </v-img>
          </div>
        </div>
        <v-divider></v-divider>
        <div>
          <div class="pt-6">
            <v-text-field
              name="supplierName"
              id="supplierName"
              type="text"
              :placeholder="$t('common.personName')"
              outlined
              color="accent"
              required
              :rules="rules.name"
              v-model="supplier.name"
            ></v-text-field>
            <v-text-field
              name="supplierEmail"
              id="supplierEmail"
              type="text"
              :placeholder="$t('common.email')"
              outlined
              color="accent"
              required
              :rules="rules.email"
              v-model="supplier.email"
            ></v-text-field>
            <v-text-field
              name="supplierPhone"
              id="supplierPhone"
              type="number"
              :placeholder="$t('common.phone')"
              outlined
              color="accent"
              required
              :rules="rules.phone"
              v-model="supplier.phone"
              :prefix="$t('common.phoneCountryPrefix')"
            ></v-text-field>
            <v-divider></v-divider>
          </div>
        </div>
        <div class="d-flex justify-center mt-6">
          <v-btn @click="closeDialog">{{ $t("suppliers.close") }}</v-btn>
          <v-btn
            :disabled="!isFormValid"
            class="ml-3"
            @click="addContactSupplier"
            color="accent"
            >{{ $t("suppliers.add") }}</v-btn
          >
        </div>
      </div>
    </v-dialog>
  </div>
</template>
<script>
import emailValidator from "../shared/Shared";
export default {
  name: "AddSupplierContactModal",
  props: { dialog: {}, selectedSupplier: {} },
  data: () => ({
    showDialog: false,
    supplier: {},
    rules: {},
    invalidEmail: false,
  }),
  created() {
    this.showDialog = this.dialog;
    this.supplier = {
      name: "",
      email: "",
      phone: "",
    };
    this.rules = {
      name: [
        (val) => (val || "").length > 0 || this.$t("common.requiredField"),
      ],
      email: [
        (val) => (val || "").length > 0 || this.$t("common.requiredField"),
      ],
      phone: [
        (val) => (val || "").length > 0 || this.$t("common.requiredField"),
      ],
    };
  },
  watch: {
    dialog() {
      this.showDialog = this.dialog;
    },
    emailValue() {
      this.invalidEmail = emailValidator(this.emailValue);
      if (this.supplier.phone === "") {
        this.rules.email =
          this.emailValue.length > 0 && this.invalidEmail
            ? []
            : this.emailValue.length > 0 && !this.invalidEmail
            ? [this.$t("common.invalidEmailInput")]
            : [this.$t("common.requiredField")];
      } else {
        this.rules.email = [];
      }
      if (this.supplier.email === "") {
        this.rules.phone =
          this.phoneValue.length > 0 ? [] : [this.$t("common.requiredField")];
      } else {
        this.rules.phone = [];
      }
    },
    phoneValue() {
      this.invalidEmail = emailValidator(this.emailValue);
      if (this.supplier.phone === "") {
        this.rules.email =
          this.emailValue.length > 0 && this.invalidEmail
            ? []
            : this.emailValue.length > 0 && !this.invalidEmail
            ? [this.$t("common.invalidEmailInput")]
            : [this.$t("common.requiredField")];
      } else {
        this.rules.email = [];
      }
      if (this.supplier.email === "") {
        this.rules.phone =
          this.phoneValue.length > 0 ? [] : [this.$t("common.requiredField")];
      } else {
        this.rules.phone = [];
      }
    },
  },
  mounted() {},
  computed: {
    isFormValid() {
      // return (
      //   this.supplier.name !== "" &&
      //   ((this.supplier.email !== "" && !this.invalidEmail) ||
      //     this.supplier.phone !== "")
      // );

      let phoneOrMail;
      if (this.supplier.email === "") {
        phoneOrMail = this.supplier.phone !== "";
      } else if (this.supplier.phone === "") {
        phoneOrMail = this.supplier.email !== "" && this.invalidEmail;
      } else {
        phoneOrMail =
          this.supplier.phone !== "" &&
          this.supplier.email !== "" &&
          this.invalidEmail;
      }
      return this.supplier.name !== "" && phoneOrMail;
    },
    emailValue() {
      return this.supplier.email;
    },
    phoneValue() {
      return this.supplier.phone;
    },
    getIsMobile() {
      return this.$isMobile;
    },
  },
  methods: {
    closeDialog() {
      this.supplier = {
        name: "",
        email: "",
        phone: "",
      };
      this.$emit("onClose");
    },
    addContactSupplier() {
      this.$store
        .dispatch("supplier/addContactSupplier", {
          id: this.selectedSupplier && this.selectedSupplier.id,
          data: this.supplier,
        })
        .then(
          () => {
            this.supplier = {
              name: "",
              email: "",
              phone: "",
            };
            this.rules.name = [];
            this.rules.email = [];
            this.rules.phone = [];
            this.$emit("onAddedSupplierContact");
          },
          (error) => {
            console.log("error adding supplier", error);
          }
        );
    },
    addSupplier() {},
  },
};
</script>
<style lang="scss" scoped>
.dialog-style {
  background-color: #ffffff;
  padding: 24px;
  @media screen and (max-width: 959px) {
    height: 100%;
  }
}
.close-icon {
  cursor: pointer;
}
.info-text {
  text-align: center;
}
</style>
