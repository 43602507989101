import StationsService from "../services/stations.service";
import {
  REQUEST_FAILURE,
  REQUEST_PENDING,
  REQUEST_SUCCESS,
  REQUEST_UNDEFINED,
} from "../shared/statuses";

const initialState = {
  createNewStationStatus: REQUEST_UNDEFINED,
  getAllStationsStatus: REQUEST_UNDEFINED,
  allStations: [],
  linkUserAndDepartmentStatus: REQUEST_UNDEFINED,
  unLinkUserAndDepartmentStatus: REQUEST_UNDEFINED,
  editStationStatus: REQUEST_UNDEFINED,
  deleteStationStatus: REQUEST_UNDEFINED,
};

export const stations = {
  namespaced: true,
  state: initialState,
  actions: {
    createStation({ commit }, data) {
      commit("createNewStationPending");
      return StationsService.createStation(data).then(
        (response) => {
          commit("createNewStationSuccess");
          return Promise.resolve(response);
        },
        (error) => {
          commit("createNewStationFailure");
          return Promise.reject(error);
        }
      );
    },
    getAllStations({ commit }, venueId) {
      commit("getAllStationsPending");
      return StationsService.getAllDepartmentStations(venueId).then(
        (response) => {
          commit("getAllStationsSuccess", response.data);
          return Promise.resolve(response);
        },
        (error) => {
          commit("getAllStationsError");
          return Promise.reject(error);
        }
      );
    },
    linkUserAndDepartment({ commit }, payload) {
      commit("linkUserAndDepartmentPending");
      return StationsService.linkUserAndDepartmentService(payload).then(
        (response) => {
          commit("linkUserAndDepartmentSuccess", payload);
          return Promise.resolve(response);
        },
        (error) => {
          commit("linkUserAndDepartmentError");
          return Promise.reject(error);
        }
      );
    },
    unLinkUserAndDepartment({ commit }, payload) {
      commit("unLinkUserAndDepartmentPending");
      return StationsService.unLinkUserAndDepartmentService(payload).then(
        (response) => {
          commit("unLinkUserAndDepartmentSuccess", payload);
          return Promise.resolve(response);
        },
        (error) => {
          commit("unLinkUserAndDepartmentError");
          return Promise.reject(error);
        }
      );
    },
    editStation({ commit }, payload) {
      commit("deleteStationPending");
      return StationsService.editStationService(payload).then(
        (response) => {
          commit("editStationSuccess");
          return Promise.resolve(response);
        },
        (error) => {
          commit("editStationError");
          return Promise.reject(error);
        }
      );
    },
    deleteStation({ commit }, payload) {
      commit("editStationPending");
      return StationsService.deleteStationService(payload).then(
        (response) => {
          commit("deleteStationSuccess");
          return Promise.resolve(response);
        },
        (error) => {
          commit("deleteStationError");
          return Promise.reject(error);
        }
      );
    },
  },
  mutations: {
    createNewStationPending(state) {
      state.createNewStationStatus = REQUEST_PENDING;
    },
    createNewStationSuccess(state) {
      state.createNewStationStatus = REQUEST_SUCCESS;
    },
    createNewStationFailure(state) {
      state.createNewStationStatus = REQUEST_FAILURE;
    },
    getAllStationsPending(state) {
      state.getAllStationsStatus = REQUEST_PENDING;
    },
    getAllStationsSuccess(state, payload) {
      state.getAllStationsStatus = REQUEST_SUCCESS;
      state.allStations = payload;
    },
    getAllStationsError(state) {
      state.getAllStationsStatus = REQUEST_FAILURE;
    },
    linkUserAndDepartmentPending(state) {
      state.linkUserAndDepartmentStatus = REQUEST_PENDING;
    },
    linkUserAndDepartmentSuccess(state) {
      state.linkUserAndDepartmentStatus = REQUEST_SUCCESS;
    },
    linkUserAndDepartmentError(state) {
      state.linkUserAndDepartmentStatus = REQUEST_FAILURE;
    },
    unLinkUserAndDepartmentPending(state) {
      state.unLinkUserAndDepartmentStatus = REQUEST_PENDING;
    },
    unLinkUserAndDepartmentSuccess(state) {
      state.unLinkUserAndDepartmentStatus = REQUEST_SUCCESS;
    },
    unLinkUserAndDepartmentError(state) {
      state.unLinkUserAndDepartmentStatus = REQUEST_FAILURE;
    },
    editStationPending(state) {
      state.editStationStatus = REQUEST_PENDING;
    },
    editStationSuccess(state) {
      state.editStationStatus = REQUEST_SUCCESS;
    },
    editStationError(state) {
      state.editStationStatus = REQUEST_FAILURE;
    },
    deleteStationPending(state) {
      state.deleteStationStatus = REQUEST_PENDING;
    },
    deleteStationSuccess(state) {
      state.deleteStationStatus = REQUEST_SUCCESS;
    },
    deleteStationError(state) {
      state.deleteStationStatus = REQUEST_FAILURE;
    },
  },
  getters: {
    getCreateNewStationStatus: (state) => {
      return state.createNewStationStatus;
    },
    getAllStationsStatus: (state) => {
      return state.getAllStationsStatus;
    },
    getAllVenueStations: (state) => {
      return state.allStations;
    },
    getEditStationStatus: (state) => {
      return state.editStationStatus;
    },
    getDeleteStationStatus: (state) => {
      return state.deleteStationStatus;
    },
  },
};
