var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.data,"sort-by":['name'],"sort-desc":[false, true],"loading":_vm.isDataLoading,"loading-text":_vm.$t('common.loadingTableText'),"mobile-breakpoint":"400","footer-props":{
    itemsPerPageAllText: _vm.$t('common.all'),
    itemsPerPageText: _vm.$t('common.itemsPerPage'),
    disableItemsPerPage: _vm.pageSize ? false : true,
    itemsPerPageOptions: _vm.pageSize ? [10, 20, 30] : [30, 30, 30],
  },"options":_vm.options,"server-items-length":_vm.totalOptions,"items-per-page":_vm.pageSize || 30},on:{"update:options":function($event){_vm.options=$event},"update:page":_vm.handlePageChange,"update:items-per-page":_vm.handleItemsPerPageChange},scopedSlots:_vm._u([{key:"item.deadline",fn:function(ref){
  var item = ref.item;
return [_c('span',{staticClass:"text-body-1 accent--text order-deadline"},[_vm._v(_vm._s(_vm.getFormattedDate(item.deadline)))])]}},{key:"item.statusText",fn:function(ref){
  var item = ref.item;
return [(item.status === 'OPEN')?_c('span',{staticClass:"tag-style text-body-1 negative--text"},[_vm._v(_vm._s(item.statusText))]):_c('span',{staticClass:"tag-style text-body-1 accent--text"},[_vm._v(_vm._s(item.statusText))])]}},{key:"item.supplierOrders",fn:function(ref){
  var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item && item.supplier && item.supplier.name)+" ")])]}},{key:"item.options",fn:function(ref){
  var item = ref.item;
return [_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
  var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',_vm._l((_vm.optionsItems),function(opt,i){return _c('v-list-item',{key:i},[_c('v-list-item-title',{staticClass:"option-item",class:{
              disabled:
                (item.status === 'COMPLETED' && opt.value === 'edit') ||
                (item.status !== 'IN_PROGRESS' && opt.value === 'receive'),
            },on:{"click":function($event){return _vm.handleOptionsSelection(opt.value, item)}}},[_vm._v(_vm._s(opt.title))])],1)}),1)],1)]}},{key:"item.name",fn:function(ref){
            var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item && item.name))])]}},{key:"item.recommendedQuantity",fn:function(ref){
            var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(item.recommendedQuantity),expression:"item.recommendedQuantity"}],staticClass:"recommmended-quantity-input",attrs:{"type":"text","placeholder":_vm.$t('articles.recommendedQuantity'),"inputmode":"decimal","pattern":"[0-9]*\\.?[0-9]*","disabled":!_vm.isAdmin},domProps:{"value":(item.recommendedQuantity)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.$set(item, "recommendedQuantity", $event.target.value)},function($event){return _vm.validateInput(item)}],"paste":function($event){$event.preventDefault();}}})])]}},{key:"item.__category__",fn:function(ref){
            var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item && item.category && item.category.name))])]}},{key:"item.supplierName",fn:function(ref){
            var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item && item.supplier && item.supplier.name))])]}},{key:"item.checkbox_action",fn:function(ref){
            var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","color":item.isFavorite ? 'accent' : 'secondaryDark'},on:{"click":function($event){return _vm.handleArticleFavorite(item)}}},[_c('v-icon',[_vm._v("mdi-star")])],1)]}},{key:"item.article_action",fn:function(ref){
            var item = ref.item;
return [_c('div',[_c('v-btn',{staticClass:"mx-2",attrs:{"fab":"","dark":"","color":"accent","height":"35px","width":"35px"},on:{"click":function () { return _vm.onEditArticle(item); }}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-pencil ")])],1),_c('v-btn',{staticClass:"mx-2",attrs:{"fab":"","dark":"","color":"negative","height":"35px","width":"35px"},on:{"click":function($event){return _vm.removeCustomArticle(item)}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-delete ")])],1)],1)]}},{key:"item.supplersName",fn:function(ref){
            var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.name))])]}},{key:"item.add_contact",fn:function(ref){
            var item = ref.item;
return [_c('div',[_c('v-btn',{staticClass:"mx-2",attrs:{"fab":"","dark":"","color":"accent","height":"35px","width":"35px"},on:{"click":function($event){return _vm.addContact(item)}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-plus ")])],1),(item && item.contacts && item.contacts.length > 0)?_c('v-btn',{staticClass:"mx-2",attrs:{"fab":"","dark":"","color":"warning","height":"35px","width":"35px"},on:{"click":function($event){return _vm.previewContacts(item)}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-format-list-bulleted-square ")])],1):_vm._e()],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }