<template>
  <v-dialog
    v-model="showDialog"
    width="626"
    class="dialog-style"
    persistent
    :fullscreen="getIsMobile"
  >
    <div class="dialog-style-content">
      <div class="header mb-6">
        <div class="d-flex justify-space-between align-center">
          <span class="text-h5 primaryA--text font-weight-bold">{{
            $t("suppliers.requestForAddingSupplier")
          }}</span>
          <v-img
            @click="closeDialog"
            class="close-icon"
            max-width="20px"
            :src="require('../../../assets/img/CloseIcon.svg')"
          >
          </v-img>
        </div>
      </div>
      <v-divider></v-divider>
      <div class="pt-6">
        <v-text-field
          name="name"
          id="name"
          type="text"
          :placeholder="$t('suppliers.name')"
          :label="$t('suppliers.name')"
          outlined
          color="accent"
          required
          :rules="rules.name"
          v-model="supplier.name"
        ></v-text-field>
        <v-text-field
          name="pib"
          id="pib"
          type="text"
          :placeholder="$t('suppliers.pib')"
          :label="$t('suppliers.pib')"
          outlined
          color="accent"
          v-model="supplier.pib"
          :rules="rules.pib"
        ></v-text-field>
        <v-text-field
          name="contact"
          id="contact"
          type="text"
          :placeholder="$t('suppliers.contact')"
          :label="$t('suppliers.contact')"
          outlined
          color="accent"
          v-model="supplier.contact"
        ></v-text-field>
      </div>
      <div class="mb-6 secondaryDark--text">
        <span>{{ $t("suppliers.supplierAddingInfo") }}</span>
      </div>
      <div class="d-flex">
        <v-btn @click="closeDialog">{{ $t("suppliers.close") }}</v-btn>
        <v-btn
          :disabled="isFormInvalid"
          class="ml-3"
          @click="onRequestArticle"
          color="accent"
          >{{ $t("articles.sendRequest") }}</v-btn
        >
      </div>
    </div>
  </v-dialog>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "RequestSupplierModal",
  props: {
    dialog: {},
  },
  data: () => ({
    showDialog: false,
    supplier: {},
    rules: {},
    isFormInvalid: true,
    invalidPib: false,
  }),
  created() {
    this.showDialog = this.dialog;
    this.rules = {
      name: [
        (val) => (val || "").length > 0 || this.$t("common.requiredField"),
      ],
    };
  },
  mounted() {},
  methods: {
    closeDialog() {
      this.resetForm();
      this.$emit("onClose");
    },
    resetForm() {
      this.supplier = {
        name: "",
        pib: "",
        contact: "",
      };
    },
    onRequestArticle() {
      const payload = {
        name: this.supplier.name,
        pib: this.supplier.pib,
        contact: this.supplier.contact,
      };
      this.$store.dispatch("supplier/requestNewSupplier", payload).then(() => {
        this.$emit("onSuccessRequestSent");
      });
    },
  },
  computed: {
    ...mapGetters({
      allSuppliers: "supplier/getDepartmentSuppliers",
      userProfile: "userProfile/getUserProfile",
    }),
    getIsMobile() {
      return this.$isMobile;
    },
    supplierName() {
      return this.supplier.name;
    },
    getPibValue() {
      return this.supplier.pib;
    },
  },
  watch: {
    dialog() {
      this.showDialog = this.dialog;
    },
    supplierName() {
      this.isFormInvalid = this.supplierName === "" ? true : false;
    },
    getPibValue() {
      if (
        this.getPibValue &&
        (this.getPibValue.length < 9 || this.getPibValue.length > 13)
      ) {
        this.rules.pib = [this.$t("common.pibConstraint")];
        this.invalidPib = true;
        this.isFormInvalid = true;
      } else {
        this.rules.pib = [];
        this.invalidPib = false;
        this.isFormInvalid = false;
      }
    },
  },
};
</script>
<style scoped lang="scss">
.dialog-style {
  background-color: #ffffff;
  padding: 24px;
  @media screen and (max-width: 959px) {
    height: 100%;
  }
}
.dialog-style-content {
  background-color: #ffffff;
  padding: 24px;
  @media screen and (max-width: 959px) {
    height: 100%;
  }
}
.close-icon {
  cursor: pointer;
}
.price-input {
  max-width: 200px;
  min-width: 60px;
  position: relative;
}
</style>
