<template>
  <div class="root">
    <div class="d-flex align-center mb-11">
      <span class="text-h4">{{
        $t("adminStations.listOfCreatedStations")
      }}</span>
      <!-- <div
        class="add-new-btn ml-4"
        @click="addNewStation"
        :class="{ disabled: stationsNumberLimitReached }"
      >
        <v-img
          :max-width="$isMobile ? '20' : '12'"
          :src="require('../../assets/img/Plus.svg')"
        ></v-img>
      </div> -->
      <tooltip-component
        v-if="stationsNumberLimitReached"
        :icon="'info'"
        :color="'accent'"
        :text="$t('subscriptions.numOfStationsLimitReached')"
        :className="'mb-4'"
      ></tooltip-component>
    </div>
    <div>
      <div class="d-flex flex-wrap pr-8 p-bottom-80 width-100 padding-mobile">
        <station-card-admin
          v-if="showAddNewStation"
          :stations="allStations"
          :newStation="true"
          @onCancelAddNew="handleCancelAddNew"
          @onStationCreated="handleOnStationCreated"
          :venue="venue"
          :allUsers="allUsers"
        ></station-card-admin>
        <station-card-admin
          v-for="station in allStations"
          :station="station"
          :key="station.department.id"
          :venue="venue"
          :allUsers="allUsers"
          @onStationUpdated="handleOnStationUpdated"
          @onDeleteStation="handleOnStationDeleted"
          @onLinkUserAndDepartment="handleLinkUserAndDepartment"
          @onUnlinkUserAndDepartment="handleUnlinkUserAndDepartment"
          :allVenueSuppliers="allVenueSuppliers"
          @onLinkSupplierAndDepartment="handleOnLinkSupplierDepartment"
          @onUnlinkSupplierAndDepartment="handleOnUnlinkSupplierDepartment"
        ></station-card-admin>
      </div>
    </div>
    <simple-warning-modal
      :dialog="showDeleteWarningModal"
      @onClose="showDeleteWarningModal = false"
      @onConfirm="onConfirmDeleteStation"
      @onCancel="showDeleteWarningModal = false"
      :warningMessage="warningModalTitle"
    ></simple-warning-modal>
  </div>
</template>
<script>
//vuex
import { mapGetters } from "vuex";
//components
import StationCardAdmin from "./components/StationCardAdmin.vue";
import SimpleWarningModal from "../../components/SimpleWarningModal.vue";
import TooltipComponent from "../../components/TooltipComponent.vue";

export default {
  name: "AdminStations",
  components: { StationCardAdmin, SimpleWarningModal, TooltipComponent },
  props: { venue: {} },
  data: () => ({
    isDataLoading: false,
    showAddNewStation: false,
    stationToDelete: null,
    showDeleteWarningModal: false,
    warningModalTitle: "",
  }),
  created() {
    this.isDataLoading = true;
    const venueId = this.$route.params.id;
    this.$store.dispatch("stations/getAllStations", venueId);
    this.$store.dispatch("admin/getSingleRestaurant", venueId);
  },
  mounted() {
    this.$store.dispatch("userProfile/getUserProfile").then(() => {
      this.$store.dispatch("userProfile/getVenueSuppliers", {
        venueId: this.getVenue.id,
      });
    });
  },
  computed: {
    ...mapGetters({
      getSingleRestaurant: "admin/getSingleRestaurant",
      getSingleRestaurantStatus: "admin/getSingleRestaurantStatus",
      allVenueStations: "stations/getAllVenueStations",
      getVenue: "userProfile/getVenue",
      venueSuppliers: "userProfile/getSuppliers",
    }),
    noStations() {
      return (
        (this.allStations && Object.keys(this.allStations).length === 0) ||
        !this.allStations
      );
    },
    restaurant() {
      return this.getSingleRestaurant;
    },
    allUsers() {
      return this.restaurant && this.restaurant.users;
    },
    allStations() {
      return this.allVenueStations;
    },
    stationsNumberLimitReached() {
      return (
        this.$attrs.isBasicPlan && Object.keys(this.allStations).length >= 2
      );
    },
    allVenueSuppliers() {
      return this.venueSuppliers;
    },
  },
  methods: {
    addNewStation() {
      this.showAddNewStation = true;
    },
    handleCancelAddNew() {
      this.showAddNewStation = false;
    },
    handleOnStationCreated(data) {
      this.$store.dispatch("stations/createStation", data).then(() => {
        window.location.reload();
      });
    },
    handleOnStationUpdated(data) {
      this.$store.dispatch("stations/editStation", data).then(() => {
        window.location.reload();
      });
    },
    handleOnStationDeleted(data) {
      this.stationToDelete = data;
      this.warningModalTitle = this.$t(
        "adminStations.deleteWarningModalMessage"
      );
      this.showDeleteWarningModal = true;
    },
    handleLinkUserAndDepartment(data) {
      this.$store.dispatch("stations/linkUserAndDepartment", data);
    },
    handleUnlinkUserAndDepartment(data) {
      this.$store.dispatch("stations/unLinkUserAndDepartment", data);
    },
    onConfirmDeleteStation() {
      this.$store
        .dispatch("stations/deleteStation", this.stationToDelete)
        .then(() => {
          this.showDeleteWarningModal = false;
          window.location.reload();
          // NOTE: get all stations here
        });
    },
    handleOnLinkSupplierDepartment(data) {
      this.$store.dispatch("admin/linkSupplierAndDepartment", data);
    },
    handleOnUnlinkSupplierDepartment(data) {
      this.$store.dispatch("admin/unLinkSupplierAndDepartment", data);
    },
  },
  watch: {
    // venue() {
    //   this.venue &&
    //     this.$store.dispatch("admin/getSingleRestaurant", this.venue.id);
    // },
  },
};
</script>
<style scoped lang="scss">
.add-new-btn {
  background: #276ef1;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  @media screen and(max-width: 600px) {
    width: 45px;
    height: 45px;
  }
}
.padding-mobile {
  @media screen and(max-width: 600px) {
    padding-right: 0 !important;
  }
}
.disabled {
  opacity: 0.5;
  pointer-events: none;
}
</style>
