const colors = {
  primary: {
    primaryA: "#000000",
    primaryB: "#FFFFFF",
    accent: "#276EF1",
    negative: "#FD4A34",
    warning: "#EAA23F",
    positive: "#35D2BC",
    positive1: "#00A991",
  },
  gray: {
    primaryDark: "#181818",
    secondaryDark: "#A6A6A6",
    secondaryDark2: "#565961",
    tertiaryDark: "#E0E0E0",
    primaryLight: "#F5F5F5",
    secondaryLight: "#F7F7F8",
    secondaryLight2: "#F6F7F8",
    tertiaryLight: "#FAFAFA",
  },
};

export default colors;
