<template>
  <v-layout align-center justify-center class="mt-160 pt-0 pb-0 pl-6 pr-6">
    <v-flex xs12 sm8 md4>
      <v-img
        class="ma-auto mb-118 mt-8"
        max-width="227"
        :src="require('../assets/img/NavLogo.png')"
      >
      </v-img>
      <v-card
        v-if="!forgotPasswordView"
        class="elevation-0 ma-auto"
        width="358px"
      >
        <v-toolbar-title class="text-h5 text-sm-h6 text-md-h6">{{
          $t("loginForm.title")
        }}</v-toolbar-title>
        <v-card-text class="pa-0 mt-6">
          <v-form ref="form" lazy-validation>
            <v-text-field
              name="login"
              :label="$t('common.email')"
              id="login"
              type="text"
              :placeholder="$t('loginForm.typeYourEmail')"
              outlined
              color="accent"
              required
              :rules="emailRules"
              v-model="form.email"
            ></v-text-field>
            <v-text-field
              name="password"
              :label="$t('common.password')"
              :placeholder="$t('loginForm.typeYourPassword')"
              id="password"
              outlined
              color="accent"
              required
              :rules="passwordRules"
              v-model="form.password"
              :append-icon="showPasswordPreview ? 'mdi-eye' : 'mdi-eye-off'"
              :type="showPasswordPreview ? 'text' : 'password'"
              @click:append="showPasswordPreview = !showPasswordPreview"
              @keypress.enter="login"
            ></v-text-field>
          </v-form>
        </v-card-text>
        <div class="d-flex" v-if="showResendVerifyEmail">
          <span class="text-subtitle-2"
            >{{ $t("loginForm.resendVerifyEmail") }}
            <span
              class="accent--text"
              style="cursor: pointer"
              @click="resendVerifyEmail"
              >{{ $t("verify.here") }}</span
            >
          </span>
          <!-- <v-btn color="accent" elevation="0" small text>{{
            $t("verify.here")
          }}</v-btn> -->
        </div>
        <v-card-actions class="pa-0 d-block text-align-center">
          <v-checkbox
            style="margin-top: 0 !important"
            v-model="rememberMe"
            :label="$t('loginForm.rememberMe')"
          ></v-checkbox>
          <v-spacer></v-spacer>
          <div
            v-if="registerStatus === 'REQUEST_SUCCESS'"
            class="positive1--text"
          >
            <v-icon class="mr-2 positive1--text">mdi-check-circle</v-icon
            >{{ $t("loginForm.validationEmailSent") }}
          </div>
          <div
            class="d-flex justify-end mt-2 mb-7 forgot-pass-link"
            @click="forgotPassword"
          >
            <span class="text-body-2 accent--text forgot-pass-link">{{
              $t("loginForm.forgotPasswordLink")
            }}</span>
          </div>
          <v-btn
            :disabled="!formIsValid"
            color="accent"
            width="100%"
            type="submit"
            @click="login"
            :loading="loginLoading"
            >{{ $t("loginForm.login") }}</v-btn
          >
        </v-card-actions>

        <!-- show on tablets phones -->
        <div class="d-block d-md-none mt-32">
          <div class="noAccountTextWrapper">
            <v-card-text
              class="ma-auto text-align-center text-body-1 pa-0 secondaryDark--text"
            >
              {{ $t("loginForm.noAccount") }}
            </v-card-text>
          </div>
          <v-card-text
            @click="registerHere"
            class="ma-auto text-align-center font-weight-bold text-body-2 accent--text pa-0 mt-32 register-here-link"
          >
            {{ $t("loginForm.registerHere") }}
          </v-card-text>
        </div>
        <!-- shown on laptops and above -->
        <div class="d-none d-md-block">
          <v-card-text
            class="ma-auto text-align-center text-body-2 font-weight-bold pa-0 mt-50"
          >
            {{ $t("loginForm.noAccount") }}
          </v-card-text>
          <v-card-text
            @click="registerHere"
            class="ma-auto text-align-center font-weight-bold text-body-2 accent--text pa-0 mt-32 register-here-link"
          >
            {{ $t("loginForm.registerHere") }}
          </v-card-text>
        </div>
      </v-card>
      <v-card v-else class="elevation-0 ma-auto" width="358px">
        <v-toolbar-title class="text-h5 text-sm-h6 text-md-h6">{{
          $t("loginForm.forgotPassword")
        }}</v-toolbar-title>
        <v-card-text class="pa-0 mt-6">
          <v-form ref="form">
            <v-text-field
              name="resetEmail"
              :label="$t('common.email')"
              id="resetEmail"
              type="text"
              :placeholder="$t('loginForm.typeYourEmail')"
              outlined
              color="accent"
              required
              :rules="rules.email"
              v-model="forgotPasswordEmailValue"
            ></v-text-field>
          </v-form>
        </v-card-text>
        <div class="email-success-text-wrap mb-3">
          <v-card-text
            class="ma-auto positive--text text--caption pa-0"
            v-if="emailSentSuccess"
          >
            {{ $t("loginForm.emailSentSuccessMessage") }}
          </v-card-text>
        </div>
        <v-card-actions class="pa-0 d-block text-align-center">
          <v-btn
            :disabled="!forgotPasswordEmailValue"
            color="accent"
            width="100%"
            type="submit"
            @click="sendResetPasswordLink"
            >{{ $t("loginForm.passwordResetLinkEmail") }}</v-btn
          >
        </v-card-actions>
        <v-card-text
          @click="backToLogin"
          class="ma-auto text-align-center text-body-2 accent--text pa-0 register-here-link mt-32"
        >
          {{ $t("registerForm.backToLogin") }}
        </v-card-text>
      </v-card>
    </v-flex>
  </v-layout>
</template>
<script>
import { mapGetters } from "vuex";
import emailValidator from "../shared/Shared";
import LocalStorageService from "../services/local-storage.service";
import { REQUEST_PENDING } from "@/shared/statuses";
export default {
  name: "LoginPage",
  data() {
    const defaultForm = Object.freeze({
      email: "",
      password: "",
    });
    return {
      form: Object.assign({}, defaultForm),
      rules: {
        email: [
          (val) => (val || "").length > 0 || this.$t("common.requiredField"),
        ],
        password: [
          (val) => (val || "").length > 0 || this.$t("common.requiredField"),
        ],
      },
      emailRules: [
        (val) => (val || "").length > 0 || this.$t("common.requiredField"),
      ],
      passwordRules: [
        (val) => (val || "").length > 0 || this.$t("common.requiredField"),
      ],
      myImage: require("../assets/img/NavLogo.png"),
      forgotPasswordView: false,
      forgotPasswordEmailValue: "",
      emailSentSuccess: false,
      invalidEmail: false,
      showPasswordPreview: false,
      rememberMe: false,
      loginLoading: false,
      showResendVerifyEmail: false,
    };
  },
  created() {
    let data = LocalStorageService.getLocalStorageData("loginData");
    this.form.email = data && data.username;
    this.form.password = data && data.password;
    this.rememberMe = data ? true : false;
  },
  methods: {
    login() {
      this.form.email = this.form.email.toLowerCase();
      let user = {
        username: this.form.email.toLowerCase(),
        password: this.form.password,
      };

      if (this.form.email && this.form.password) {
        this.rememberMe
          ? LocalStorageService.setLocalStorageData("loginData", user)
          : LocalStorageService.deleteLocalStorageItem("loginData");
        this.$store.dispatch("auth/login", user).then(() => {
          this.$router.push("/");
        }),
          (error) => {
            console.log("error", error);
          };
      }
    },
    registerHere() {
      this.$router.push("/sign-up");
    },
    forgotPassword() {
      this.forgotPasswordView = true;
    },
    sendResetPasswordLink() {
      this.$store
        .dispatch("auth/requestPasswordReset", this.forgotPasswordEmailValue)
        .then(() => {
          this.emailSentSuccess = true;
          setTimeout(() => {
            this.emailSentSuccess = false;
          }, 3000);
        });
    },
    backToLogin() {
      this.forgotPasswordView = false;
    },
    resendVerifyEmail() {
      this.$store
        .dispatch("admin/resendVerifyEmail", this.emailValue)
        .then(() => {
          console.log("email poslat");
        });
    },
  },
  computed: {
    ...mapGetters({
      getLoginFailStatus: "auth/getLoginFailStatus",
      getLoginLoadingStatus: "auth/getLoginLoadingStatus",
      getRegisterStatus: "auth/getRegisterStatus",
    }),
    formIsValid() {
      return this.form.email && this.form.password && this.invalidEmail;
    },
    emailValue() {
      return this.form.email;
    },
    loginFailStatus() {
      return this.getLoginFailStatus;
    },
    loginLoadingStatus() {
      return this.getLoginLoadingStatus;
    },
    isValidationEmailSent() {
      return false;
    },
    registerStatus() {
      return this.getRegisterStatus;
    },
  },
  watch: {
    emailValue() {
      this.invalidEmail = emailValidator(this.emailValue);
      this.emailRules =
        this.emailValue && this.emailValue.length > 0 && this.invalidEmail
          ? []
          : this.emailValue && this.emailValue.length > 0 && !this.invalidEmail
          ? [this.$t("common.invalidEmailInput")]
          : [this.$t("common.requiredField")];
    },
    loginFailStatus() {
      this.showResendVerifyEmail = false;
      this.passwordRules = [this.$t("errors." + this.loginFailStatus)];
      if (this.loginFailStatus === "user_not_verified") {
        this.showResendVerifyEmail = true;
      }
    },
    loginLoadingStatus() {
      this.loginLoading = this.loginLoadingStatus === REQUEST_PENDING;
    },
  },
};
</script>

<style type="scss" scoped>
.mb-118 {
  margin-bottom: 118px !important;
}
.mt-50 {
  margin-top: 50px !important;
}
.forgot-pass-link {
  cursor: pointer;
}
.text-align-center {
  text-align: center;
}
.forgot-pass-link:hover {
  text-decoration: underline;
}
.register-here-link {
  cursor: pointer;
}
.register-here-link:hover {
  text-decoration: underline;
}
.noAccountTextWrapper {
  position: relative;
}
.noAccountTextWrapper:before {
  position: absolute;
  content: "";
  left: 0;
  top: 10px;
  height: 1px;
  background: #e0e0e0;
  width: 75px;
}
.noAccountTextWrapper:after {
  position: absolute;
  content: "";
  right: 0;
  top: 10px;
  height: 1px;
  background: #e0e0e0;
  width: 75px;
}
.mt-32 {
  margin-top: 32px !important;
}
.email-success-text-wrap {
  text-align: center;
}
</style>
