import { render, staticRenderFns } from "./SupplierDataTable.vue?vue&type=template&id=425f3e5b&scoped=true&"
import script from "./SupplierDataTable.vue?vue&type=script&lang=js&"
export * from "./SupplierDataTable.vue?vue&type=script&lang=js&"
import style0 from "./SupplierDataTable.vue?vue&type=style&index=0&id=425f3e5b&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "425f3e5b",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VDataTable } from 'vuetify/lib/components/VDataTable';
installComponents(component, {VDataTable})
