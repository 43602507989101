<template>
  <v-layout align-center justify-center class="mt-160 pt-0 pb-0 pl-6 pr-6">
    <v-flex xs12 sm8 md4>
      <v-card width="358px" class="elevation-0 ma-auto">
        <!-- <div class="d-flex justify-space-between align-center">
        <span class="text-h5 primaryA--text font-weight-bold"></span>
      </div> -->
        <v-card-title class="pl-0">
          {{ $t("forgotPassword.title") }}
        </v-card-title>
        <v-card-subtitle class="pl-0">
          {{ $t("forgotPassword.enterNewPassword") }}
        </v-card-subtitle>
        <v-card-text class="pa-0 mt-6">
          <v-form ref="form">
            <v-text-field
              name="password"
              :label="$t('common.password')"
              :placeholder="$t('loginForm.typeYourPassword')"
              id="password"
              outlined
              color="accent"
              required
              :rules="rules.password"
              v-model="form.password"
              :append-icon="showPasswordPreview ? 'mdi-eye' : 'mdi-eye-off'"
              :type="showPasswordPreview ? 'text' : 'password'"
              @click:append="showPasswordPreview = !showPasswordPreview"
            ></v-text-field>
            <v-text-field
              name="passwordRepeat"
              :label="$t('common.repeatPassword')"
              :placeholder="$t('loginForm.typeYourPassword')"
              id="passwordRepeat"
              outlined
              color="accent"
              required
              :rules="rules.passwordRepeat"
              v-model="form.passwordRepeat"
              :append-icon="
                showPasswordRepeatPreview ? 'mdi-eye' : 'mdi-eye-off'
              "
              :type="showPasswordRepeatPreview ? 'text' : 'password'"
              @click:append="
                showPasswordRepeatPreview = !showPasswordRepeatPreview
              "
            ></v-text-field>
          </v-form>
        </v-card-text>
        <v-btn
          :disabled="!formIsValid"
          color="accent"
          width="100%"
          type="submit"
          @click="confirmChangePassword"
          >{{ $t("forgotPassword.confirm") }}</v-btn
        >
      </v-card>
    </v-flex>
  </v-layout>
</template>
<script>
export default {
  name: "ForgotPassword",

  data: () => ({
    form: Object.assign(
      {},
      Object.freeze({
        password: "",
        passwordRepeat: "",
      })
    ),
    rules: {},
    showPasswordPreview: false,
    showPasswordRepeatPreview: false,
  }),
  mounted() {
    this.rules = {
      email: [
        (val) => (val || "").length > 0 || this.$t("common.requiredField"),
      ],
      password: [
        (val) => (val || "").length > 0 || this.$t("common.requiredField"),
      ],
    };
  },
  computed: {
    formIsValid() {
      return (
        this.form.password &&
        this.form.passwordRepeat &&
        this.form.password === this.form.passwordRepeat
      );
    },
  },
  methods: {
    confirmChangePassword() {
      const id = this.$route.query.id;
      const token = this.$route.query.token;
      this.$store
        .dispatch("auth/requestChangePassword", {
          id,
          token,
          password: this.form.password,
        })
        .then(() => this.$router.push("/login"));
    },
  },
};
</script>
<style scoped lang="scss"></style>
