<template>
  <v-layout align-center justify-center class="mt-160 pt-0 pb-0 pl-6 pr-6">
    <v-flex xs12 sm8 md4>
      <div
        @click="backToLogin"
        class="ma-auto pa-0 back-to-login-mobile d-flex d-sm-flex d-md-none mt-6"
      >
        <div class="arrow-left-wrap mr-3">
          <v-img
            max-width="8"
            :src="require('../assets/img/ArrowLeft.svg')"
          ></v-img>
        </div>
        <v-card-text
          class="text-body-2 pa-0 font-weight-bold secondaryDark--text"
        >
          {{ $t("registerForm.backToLogin") }}
        </v-card-text>
      </div>

      <v-img
        class="ma-auto mb-118"
        max-width="227"
        :src="require('../assets/img/NavLogo.png')"
      >
      </v-img>
      <v-card class="elevation-0 ma-auto" width="358px">
        <v-toolbar-title class="text-h5 text-sm-h6 text-md-h6 primaryA--text">{{
          $t("registerForm.title")
        }}</v-toolbar-title>
        <v-card-text class="pa-0 mt-6">
          <v-form ref="form">
            <v-text-field
              name="email"
              :label="$t('common.email')"
              id="email"
              type="text"
              :placeholder="$t('registerForm.typeYourEmail')"
              outlined
              color="accent"
              required
              :rules="rules.email"
              v-model="form.email"
              @change="checkEmail"
            ></v-text-field>
            <v-text-field
              name="username"
              :label="$t('common.nameSurname')"
              id="username"
              type="text"
              :placeholder="$t('registerForm.typeYourNameSurname')"
              outlined
              color="accent"
              required
              :rules="rules.username"
              v-model="form.username"
            ></v-text-field>
            <v-text-field
              name="password"
              :label="$t('common.password')"
              :placeholder="$t('registerForm.typeYourPassword')"
              id="password"
              outlined
              color="accent"
              required
              :rules="rules.password"
              v-model="form.password"
              :append-icon="showPasswordPreview ? 'mdi-eye' : 'mdi-eye-off'"
              :type="showPasswordPreview ? 'text' : 'password'"
              @click:append="showPasswordPreview = !showPasswordPreview"
            ></v-text-field>
            <v-text-field
              name="repeatPassword"
              :label="$t('common.repeatPassword')"
              :placeholder="$t('registerForm.typeYourPasswordAgain')"
              id="repeatPassword"
              outlined
              color="accent"
              required
              :rules="rules.passwordRepeat"
              v-model="form.passwordRepeat"
              :append-icon="showPasswordRepeat ? 'mdi-eye' : 'mdi-eye-off'"
              :type="showPasswordRepeat ? 'text' : 'password'"
              @click:append="showPasswordRepeat = !showPasswordRepeat"
            ></v-text-field>
            <v-text-field
              name="phone"
              :label="$t('common.phone')"
              id="phone"
              type="number"
              :placeholder="$t('registerForm.phone')"
              outlined
              color="accent"
              required
              :rules="rules.phone"
              v-model="form.phone"
              :prefix="$t('common.phoneCountryPrefix')"
            ></v-text-field>
          </v-form>
        </v-card-text>
        <terms-and-policies-modal
          :dialog="dialog"
          @onClose="dialog = false"
          :type="'termsAndConditions'"
        ></terms-and-policies-modal>

        <div class="d-flex align-center">
          <v-checkbox v-model="checkedTerms"> </v-checkbox>
          <span>{{ $t("registerForm.accept") }}</span>
          <v-btn
            style="margin-left: -8px; margin-bottom: 2px"
            color="accent"
            plain
            text
            @click="dialog = true"
            >{{ $t("registerForm.termsAndConditions") }}</v-btn
          >
        </div>

        <div
          v-if="!passwordFieldsAreEqual && registerAction"
          class="password-fields-warning mb-3"
        >
          <span>
            {{ $t("registerForm.passwordFieldsNotEqual") }}
          </span>
        </div>
        <v-card-actions class="pa-0 d-block text-align-center mb-32">
          <v-btn
            :disabled="!formIsValid"
            color="accent"
            width="100%"
            type="submit"
            @click="register"
            >{{ $t("registerForm.register") }}</v-btn
          >
        </v-card-actions>

        <v-card-text
          @click="backToLogin"
          class="ma-auto text-body-2 accent--text pa-0 register-here-link mb-32 d-none d-sm-none d-md-block text-align-center"
        >
          {{ $t("registerForm.backToLogin") }}
        </v-card-text>
      </v-card>
    </v-flex>
  </v-layout>
</template>
<script>
import { mapGetters } from "vuex";
import TermsAndPoliciesModal from "../components/TermsAndPoliciesModal.vue";
import emailValidator from "../shared/Shared";
export default {
  name: "SignUpPage",
  components: {
    TermsAndPoliciesModal,
  },

  data() {
    const defaultForm = Object.freeze({
      email: "",
      username: "",
      password: "",
      passwordRepeat: "",
      phone: "",
    });

    return {
      form: Object.assign({}, defaultForm),
      rules: {
        email: [
          (val) => (val || "").length > 0 || this.$t("common.requiredField"),
        ],
        username: [
          (val) => (val || "").length > 0 || this.$t("common.requiredField"),
        ],
        password: [
          (val) => (val || "").length > 0 || this.$t("common.requiredField"),
        ],
        passwordRepeat: [
          (val) => (val || "").length > 0 || this.$t("common.requiredField"),
        ],
        phone: [
          (val) => (val || "").length > 0 || this.$t("common.requiredField"),
        ],
      },
      myImage: require("../assets/img/NavLogo.png"),
      passwordFieldsAreEqual: false,
      registerAction: false,
      tags: [
        this.$t("restaurantGroups.italian"),
        this.$t("restaurantGroups.mediterranean"),
        this.$t("restaurantGroups.sushi"),
        this.$t("restaurantGroups.asian"),
        this.$t("restaurantGroups.national"),
        this.$t("restaurantGroups.coffe"),
        this.$t("restaurantGroups.fastFood"),
        this.$t("restaurantGroups.pizzaPasta"),
        this.$t("restaurantGroups.greek"),
        this.$t("restaurantGroups.barbeque"),
        this.$t("restaurantGroups.mexican"),
      ],
      timeout: null,
      invalidEmail: false,
      showPasswordPreview: false,
      showPasswordRepeat: false,
      checkedTerms: false,
      dialog: false,
    };
  },
  mounted() {
    this.$watch(
      (vm) => [vm.form.password, vm.form.passwordRepeat],
      () => {
        if (
          this.form.password &&
          this.form.passwordRepeat &&
          this.form.password !== this.form.passwordRepeat
        ) {
          this.rules.password = [""];
          this.rules.passwordRepeat = [this.$t("common.passwordMissmatch")];
        } else if (
          (this.form.password && this.form.password.length < 8) ||
          (this.form.passwordRepeat && this.form.passwordRepeat.length < 8)
        ) {
          this.rules.password = [""];
          this.rules.passwordRepeat = [this.$t("common.passwordLength")];
        } else if (
          this.form.password &&
          !this.form.password.match(/^\w+$/) &&
          this.form.passwordRepeat &&
          !this.form.passwordRepeat.match(/^\w+$/)
        ) {
          this.rules.password = [""];
          this.rules.passwordRepeat = [this.$t("common.passwordAlphanumeric")];
        } else if (
          this.form.password &&
          this.form.passwordRepeat &&
          this.form.password === this.form.passwordRepeat &&
          this.form.password.length >= 8 &&
          this.form.passwordRepeat.length >= 8 &&
          this.form.password.match(/^\w+$/) &&
          this.form.passwordRepeat.match(/^\w+$/)
        ) {
          this.rules.password = [];
          this.rules.passwordRepeat = [];
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );
  },
  methods: {
    register() {
      this.passwordFieldsAreEqual =
        this.form.password !== "" &&
        this.form.passwordRepeat !== "" &&
        this.form.password === this.form.passwordRepeat;
      if (this.passwordFieldsAreEqual) {
        this.$store.dispatch("auth/register", this.form).then(() => {
          this.$router.push("/login");
        }),
          (error) => {
            console.log("error", error);
          };
      } else {
        this.registerAction = true;
      }
    },
    backToLogin() {
      this.$router.push("/login");
    },
    checkEmail() {
      this.form.email = this.form.email.toLowerCase();
      clearTimeout(this.timeout);

      this.timeout = setTimeout(() => {
        this.$store.dispatch("auth/checkEmail", this.form.email);
      }, 1000);
    },
  },
  computed: {
    formIsValid() {
      return (
        this.form.email &&
        this.form.username &&
        this.form.password &&
        this.form.passwordRepeat &&
        this.form.phone &&
        !this.emailExists &&
        this.invalidEmail &&
        this.checkedTerms
      );
    },
    ...mapGetters({
      getEmailExists: "auth/getEmailExists",
    }),
    emailExists() {
      return this.getEmailExists;
    },
    emailValue() {
      return this.form.email;
    },
  },
  watch: {
    emailExists() {
      if (this.emailExists) {
        this.rules.email = [
          this.form.email.length > 0
            ? this.$t("errors.emailExists")
            : this.$t("common.requiredField"),
        ];
      } else {
        this.rules.email =
          this.form.email.length > 0 ? [] : [this.$t("common.requiredField")];
      }
    },
    emailValue() {
      this.invalidEmail = emailValidator(this.emailValue);
      this.rules.email =
        this.form.email.length > 0 && this.invalidEmail
          ? []
          : this.form.email.length > 0 && !this.invalidEmail
          ? [this.$t("common.invalidEmailInput")]
          : [this.$t("common.requiredField")];
    },
  },
};
</script>

<style type="scss" scoped>
@media screen and (max-width: 959px) {
  .mt-160 {
    margin-top: 0 !important;
  }
}

.forgot-pass-link {
  cursor: pointer;
}
.text-align-center {
  text-align: center;
}
.forgot-pass-link:hover {
  text-decoration: underline;
}
.register-here-link {
  cursor: pointer;
}
.register-here-link:hover {
  text-decoration: underline;
}
.noAccountTextWrapper {
  position: relative;
}
.noAccountTextWrapper:before {
  position: absolute;
  content: "";
  left: 0;
  top: 10px;
  height: 1px;
  background: #e0e0e0;
  width: 75px;
}
.noAccountTextWrapper:after {
  position: absolute;
  content: "";
  right: 0;
  top: 10px;
  height: 1px;
  background: #e0e0e0;
  width: 75px;
}

.password-fields-warning {
  color: #fd4a34;
  width: 100%;
  text-align: center;
  min-height: 24px;
}
.mt-24 {
  margin-bottom: 24px;
}
.mt-32 {
  margin-top: 32px !important;
}
.mt-34 {
  margin-top: 34px;
}
.mt-50 {
  margin-top: 50px !important;
}
.mt-160 {
  margin-top: 24px;
}
.mb-118 {
  margin-bottom: 118px !important;
}
.mb-80 {
  margin-bottom: 80px;
}
.mb-64 {
  margin-bottom: 64px;
}
.mb-32 {
  margin-bottom: 32px !important;
}
.back-to-login-mobile {
  align-items: center;
  margin-bottom: 42px !important;
  cursor: pointer;
}
.arrow-left-wrap {
  background: #ffffff;
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.06);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 44px;
  height: 40px;
}
</style>
