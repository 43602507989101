<template>
  <modal-component
    :dialog="showPreviewOrdersModal"
    @onClose="onClosePreviewOrdersModal"
  >
    <div class="pt-7 pb-7 width100">
      <div class="text-h6 font-weight-black mb-4">
        {{ $t("newOrder.existingOrdersList") }}
      </div>
      <v-expansion-panels popout>
        <v-expansion-panel v-for="(item, i) in orderWithSameDate" :key="i">
          <v-expansion-panel-header>
            <div class="d-flex justify-space-between align-center">
              <span
                class="status-tag"
                :style="{
                  background: getOrderStatusColor(item.status),
                }"
                >{{ getOrderStatusText(item.status) }}</span
              >
              <!-- <span>ID #{{ item.id }}</span> -->
            </div>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <div>
              <v-simple-table class="mt-3">
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th>{{ $t("common.name") }}</th>
                      <th>{{ $t("common.qty") }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="product in item.products"
                      :key="product.id"
                      style="text-align: left"
                    >
                      <td>{{ product.name }}</td>
                      <td>x {{ product.amount }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
              <div
                v-if="selectedArticles.length > 0 && item.status === 'OPEN'"
                class="mt-3 mb-3"
              >
                <span>{{ $t("newOrder.articlesFromStartedOrder") }}</span>
              </div>
              <div
                v-if="selectedArticles.length > 0 && item.status === 'OPEN'"
                class="border-selected-articles mb-3"
              >
                <div
                  class="d-flex align-center justify-space-between pa-3"
                  :class="{
                    'botom-border': index < selectedArticles.length - 1,
                  }"
                  v-for="(product, index) in selectedArticles"
                  :key="product.id"
                  style="text-align: left"
                >
                  <span style="width: 80%">{{ product.name }}</span>
                  <span style="width: 20%">x {{ product.amount }}</span>
                </div>
              </div>
              <div
                v-if="item.status === 'OPEN' || item.status === 'IN_PROGRESS'"
              >
                <v-btn class="mt-2" @click="handleGoToOrder(item)">{{
                  $t("newOrder.goToOrder")
                }}</v-btn>

                <v-btn
                  :disabled="selectedArticles.length === 0"
                  class="mt-2 ml-2"
                  color="accent"
                  @click="handleMergeOrder(item)"
                  >{{ $t("newOrder.mergeThisOrder") }}</v-btn
                >
              </div>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
  </modal-component>
</template>
<script>
import ModalComponent from "../../../components/ModalComponent.vue";
import { orderStatuses } from "../../../shared/statuses";
export default {
  name: "PreviewOrdersForMergeModal",
  components: { ModalComponent },
  props: {
    showPreviewOrdersModal: {},
    orderWithSameDate: {},
    selectedArticles: {},
    orderToMerge: {},
    goToOrderItem: {},
  },
  data: () => ({}),
  mounted() {},
  created() {},
  methods: {
    onClosePreviewOrdersModal() {
      this.$emit("onClose");
    },
    handleGoToOrder(item) {
      this.$emit("onGoingToOrderWarningModal", item);
    },
    handleMergeOrder(item) {
      this.$emit("onMergeToOrderWarningModal", item);
    },
    getOrderStatusText(status) {
      switch (status) {
        case orderStatuses.OPEN:
          return this.$t("common.statusOpen");
        case orderStatuses.COMPLETED:
          return this.$t("common.statusCompleted");
        case orderStatuses.IN_PROGRESS:
          return this.$t("common.statusInProgress");
      }
    },
    getOrderStatusColor(status) {
      switch (status) {
        case orderStatuses.IN_PROGRESS:
          return "#276EF1";
        case orderStatuses.COMPLETED:
          return "#00A991";
        case orderStatuses.OPEN:
          return "#FD4A34";
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.width100 {
  width: 100%;
}
.status-tag {
  padding: 6px 8px;
  border-radius: 8px;
  color: #ffffff;
}
.border-selected-articles {
  opacity: 0.7;
  // background-color: #35d2bc;
  // color: #ffffff;
  border: 6px dashed #35d2bc;
}
.botom-border {
  border-bottom: 1px solid #a6a6a6;
}
</style>
