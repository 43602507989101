var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('modal-component',{attrs:{"dialog":_vm.dialog,"modalWidth":'650px'},on:{"onClose":_vm.closePreviewDialog}},[_c('div',{staticClass:"dialog-style"},[_c('div',{staticClass:"header"},[_c('span',{staticClass:"text-h5 font-weight-black"},[_vm._v(_vm._s(_vm.$t( _vm.ordersDue && _vm.ordersDue.length > 1 ? "orderDueWarning.titlePlural" : "orderDueWarning.title" )))])]),_c('span',{staticClass:"text-caption mt-2 secondaryDark--text font-weight-black"},[_vm._v(_vm._s(_vm.$t( _vm.ordersDue && _vm.ordersDue.length > 1 ? "orderDueWarning.descriptionPlural" : "orderDueWarning.description" )))]),(_vm.ordersDue)?_c('div',{staticClass:"mt-5 width100 order-due-modal"},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.ordersDue,"items-per-page":5,"disable-pagination":true,"hide-default-footer":true},scopedSlots:_vm._u([{key:"item.deadline",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.getFormattedDate(item.deadline)))])]}},{key:"item.departmentName",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.department.name))])]}},{key:"item.supplierName",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.supplier ? item.supplier.name : _vm.$t("articles.market")))])]}},{key:"item.createdByName",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.createdBy.name))])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex pt-1",staticStyle:{"width":"100%"}},[_c('v-btn',{staticClass:"mr-2",attrs:{"small":"","dark":"","color":"accent"},on:{"click":function($event){return _vm.handleChangeOrderDate(item)}}},[_vm._v(_vm._s(_vm.$t("orderDueWarning.changeShippingOrderDate")))]),_c('v-btn',{attrs:{"small":"","dark":"","color":"accent"},on:{"click":function($event){return _vm.handleOrder(item)}}},[_vm._v(_vm._s(_vm.$t("orderDueWarning.handle")))])],1)]}}],null,false,4219654670)})],1):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }