import axios from "axios";

// import API_URL from "../shared/endpoints";
const instance = axios.create({
  // dev
  // baseURL: "https://supplyme-be.herokuapp.com",
  // prod
  // https://api.supplyme.rs
  baseURL:
    process.env.NODE_ENV === "development"
      ? "http://localhost:8080"
      : process.env.VUE_APP_API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

export default instance;
