var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.getData,"loading":_vm.isDataLoading,"loading-text":_vm.$t('common.loadingTableText'),"mobile-breakpoint":_vm.mobileBreakpoint || 320,"items-per-page":_vm.currentPageSize || 10,"footer-props":{
    itemsPerPageAllText: _vm.$t('common.all'),
    itemsPerPageText: _vm.$t('common.itemsPerPage'),
    itemsPerPageOptions: [10, 20, 30],
  },"server-items-length":_vm.totalCount,"options":_vm.options},on:{"update:options":function($event){_vm.options=$event},"click:row":_vm.onRowClick,"update:page":_vm.handlePageChange,"update:items-per-page":_vm.handleItemsPerPageChange},scopedSlots:_vm._u([{key:"item.orderNumber",fn:function(ref){
  var item = ref.item;
return [_c('span',{staticClass:"text-body-1 order-deadline",class:{ statusMobileText: _vm.getIsMobile }},[_vm._v(_vm._s(item.order_number && item.order_number.toUpperCase()))])]}},{key:"item.deadline",fn:function(ref){
  var item = ref.item;
return [_c('span',{staticClass:"text-body-1 accent--text order-deadline",class:{ statusMobileText: _vm.getIsMobile }},[_vm._v(_vm._s(_vm.getFormattedDate(item.deadline)))])]}},{key:"item.statusText",fn:function(ref){
  var item = ref.item;
return [(item.status === 'OPEN')?_c('span',{staticClass:"tag-style text-body-1 negative--text",class:{ statusMobileText: _vm.getIsMobile }},[_vm._v(_vm._s(item.statusText))]):(item.status === 'IN_PROGRESS')?_c('span',{staticClass:"tag-style text-body-1 positive1--text",class:{ statusMobileText: _vm.getIsMobile }},[_vm._v(_vm._s(item.statusText))]):_c('div',{staticClass:"d-flex align-start"},[_c('span',{staticClass:"tag-style text-body-1 accent--text",class:{ statusMobileText: _vm.getIsMobile }},[_vm._v(_vm._s(item.statusText))]),(_vm.isPartiallyDelivered(item))?_c('v-icon',{attrs:{"color":"primary","dark":""}},[_vm._v(" mdi-message-alert ")]):_vm._e()],1)]}},{key:"item.supplierOrders",fn:function(ref){
  var item = ref.item;
return [_c('span',{class:{ statusMobileText: _vm.getIsMobile }},[_vm._v(" "+_vm._s(_vm.isCustomOrders ? _vm.$t("articles.market") : item.supplier.name)+" ")])]}},{key:"item.options",fn:function(ref){
  var item = ref.item;
return [_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
  var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',_vm._l((_vm.optionsItems),function(opt,i){return _c('v-list-item',{key:opt.value + i},[_c('v-list-item-title',{staticClass:"option-item",class:{
              disabled:
                (item.status === 'COMPLETED' && opt.value === 'edit') ||
                (item.status !== 'IN_PROGRESS' &&
                  item.status !== 'INCOMPLETE' &&
                  opt.value === 'receive') ||
                (opt.value === 'repeat' && _vm.basicPlan) ||
                (item.status === 'OPEN' && opt.value === 'repeat') ||
                (item.status === 'OPEN' && opt.value === 'copyOrderSMS') ||
                (item.status !== 'OPEN' && opt.value === 'delete') ||
                (opt.value === 'callSupplier' && !item.supplierContactNumber),
            },on:{"click":function($event){return _vm.handleOptionsSelection(opt.value, item)}}},[_vm._v(_vm._s(opt.title))]),(opt.value === 'repeat' && _vm.basicPlan)?_c('tooltip-component',{attrs:{"icon":'info',"color":'accent',"text":_vm.$t('subscriptions.onlyProPackageConstraint'),"className":'mb-4 mt-4'}}):_vm._e()],1)}),1)],1)]}},{key:"item.name",fn:function(ref){
            var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item && item.name))])]}},{key:"item.__category__",fn:function(ref){
            var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item && item.category && item.category.name))])]}},{key:"item.supplierName",fn:function(ref){
            var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item && item.supplier && item.supplier.name))])]}},{key:"item.checkbox_action",fn:function(ref){
            var item = ref.item;
return [_c('v-checkbox',{attrs:{"color":"positive1"},on:{"change":function($event){return _vm.handleArticleSelection(item)}},model:{value:(item.enabled),callback:function ($$v) {_vm.$set(item, "enabled", $$v)},expression:"item.enabled"}})]}},{key:"item.defaultPrice",fn:function(ref){
            var item = ref.item;
return [_c('v-text-field',{staticClass:"price-input",attrs:{"placeholder":_vm.$t('articles.enterPrice'),"type":"number","solo":""},on:{"keyup":function($event){return _vm.onPriceChangedUp($event, item)},"keydown":function($event){return _vm.onPriceChangedDown($event, item)}},model:{value:(item.defaultPrice),callback:function ($$v) {_vm.$set(item, "defaultPrice", $$v)},expression:"item.defaultPrice"}})]}},{key:"item.supplersName",fn:function(ref){
            var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.name))])]}},{key:"item.add_contact",fn:function(ref){
            var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[(_vm.isAdmin && !item.isMarket)?_c('v-btn',{staticClass:"mx-2",attrs:{"fab":"","dark":"","color":"accent","height":"35px","width":"35px"},on:{"click":function($event){return _vm.addContact(item)}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-plus ")])],1):_vm._e(),(!item.isMarket)?_c('v-btn',{staticClass:"mx-2",attrs:{"fab":"","dark":"","color":"warning","height":"35px","width":"35px"},on:{"click":function($event){return _vm.previewContacts(item)}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-format-list-bulleted-square ")])],1):_vm._e(),(_vm.isAdmin)?_c('v-btn',{staticClass:"mx-2",class:{ disabled: _vm.basicPlan },attrs:{"fab":"","dark":"","color":"positive1","height":"35px","width":"35px"},on:{"click":function($event){return _vm.previewSupplieNotes(item)}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-information-outline ")])],1):_vm._e(),(_vm.basicPlan)?_c('tooltip-component',{attrs:{"icon":'info',"color":'accent',"text":_vm.$t('subscriptions.supplierNoteSubscriptionConstraint'),"className":'mb-4'}}):_vm._e()],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }