import api from "./api";
import {
  USER_PROFILE_URL,
  UPDATE_USER_PROFILE_URL,
  checkPibExistsUrl,
  requestVenueAccessURL,
  venueApproveUserURL,
  makeAdminURL,
  removeAdminURL,
  CHANGE_USER_PASSWORD_URL,
  GET_VENUE_DEPARTMENTS_URL,
  GET_VENUE_SUPPLIERS_URL,
  getSupplierContactsURL,
  getSupplierNoteURL,
} from "../shared/endpoints";

class UserProfileService {
  getUserProfile() {
    return api.get(USER_PROFILE_URL);
  }
  updateUserProfile(values) {
    const data = {
      name: values.username,
      phone: values.phone,
      language: values.selectedLanguage,
      theme: values.theme,
    };
    return api.post(UPDATE_USER_PROFILE_URL, JSON.stringify(data));
  }
  checkPibExistsService(pib) {
    return api.get(checkPibExistsUrl(pib));
  }
  requestVenueAccessService(pib) {
    return api.post(requestVenueAccessURL(pib));
  }
  approveUserRequestService(inviteId) {
    return api.post(venueApproveUserURL(inviteId));
  }
  makeAdminService(userId) {
    return api.post(makeAdminURL(userId));
  }
  removeAdminService(userId) {
    return api.post(removeAdminURL(userId));
  }
  changeUserPasswordService(values) {
    const data = {
      oldPassword: values.oldPassword,
      newPassword: values.newPassword,
      confirmPassword: values.repeatNewPassword,
    };
    return api.post(CHANGE_USER_PASSWORD_URL, JSON.stringify(data));
  }
  getDepartments() {
    return api.get(GET_VENUE_DEPARTMENTS_URL);
  }
  getSuppliers(data) {
    return api.get(GET_VENUE_SUPPLIERS_URL(data.venueId));
  }
  getSupplierContacts(data) {
    return api.get(getSupplierContactsURL(data.supplierId, data.venueId));
  }
  getSupplierNote(data) {
    return api.get(getSupplierNoteURL(data.supplierId, data.venueId));
  }
}
export default new UserProfileService();
