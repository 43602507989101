<template>
  <div class="padding-adjust">
    <div class="pa-8">
      <div>
        <span class="text-h5 primaryA--text font-weight-bold">{{
          $t("supplierDashboard.allRestaurantOrders", { name: "Broker" })
        }}</span>
        <div class="d-flex mt-4" :class="{ 'flex-column': getIsMobile }">
          <div>
            <v-select
              :items="suppliers"
              v-model="selectedSupplier"
              item-text="name"
              item-value="id"
              @change="onSelectSupplier($event)"
              :label="$t('supplierDashboard.filterByRestaurant')"
              outlined
              :full-width="getIsMobile"
            ></v-select>
          </div>
          <div :class="{ 'ml-0': getIsMobile, 'ml-4': !getIsMobile }">
            <v-select
              :items="suppliers"
              v-model="selectedSupplier"
              item-text="name"
              item-value="id"
              @change="onSelectSupplier($event)"
              :label="$t('supplierDashboard.filterByDate')"
              outlined
              :full-width="getIsMobile"
            ></v-select>
          </div>
        </div>
        <div class="mt-4">
          <tabs :navItems="navItems" @onTabSelected="handleTabSelected"></tabs>
        </div>
        <div class="mt-5">
          <supplier-data-table
            :headers="headers"
            :data="dummyData.orders"
          ></supplier-data-table>
          <!-- <v-simple-table class="mt-3">
            <template v-slot:default>
              <thead>
                <tr>
                  <th>{{ $t("common.name") }}</th>
                  <th>{{ $t("common.qty") }}</th>
                  <th>{{ $t("supplierDashboard.actions") }}</th>
                  <th>Napomena</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="item in dummyData.products"
                  :key="item.id"
                  style="text-align: left"
                >
                  <td>{{ item.name }}</td>
                  <td>x {{ item.amount }}</td>
                  <td>
                    <v-radio-group v-model="item.selected" row>
                      <v-radio label="Na stanju" value="available"></v-radio>
                      <v-radio
                        label="Nema na stanju"
                        value="unavailable"
                      ></v-radio>
                    </v-radio-group>
                  </td>
                  <td>Napomena ovde</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table> -->
        </div>

        <!-- <div class="d-flex justify-space-between align-center">
          <v-col :cols="getIsMobile ? '9' : '2'" class="mt-4">
            <v-select
              :items="restaurants"
              v-model="selectedRestaurant"
              item-text="name"
              item-value="id"
              @change="onSelectSupplier($event)"
              :label="$t('supplierDashboard.chooseRestaurant')"
              outlined
            ></v-select>
          </v-col>
        </div> -->
      </div>
    </div>
    <div class="d-flex justify-end pr-10">
      <v-btn color="accent">Zakljuci</v-btn>
    </div>
  </div>
</template>
<script>
import Tabs from "../../components/Tabs.vue";
import SupplierDataTable from "./components/SupplierDataTable.vue";
export default {
  name: "SupplierDashboard",
  components: {
    Tabs,
    SupplierDataTable,
  },
  props: {},
  data: () => ({
    navItems: [],
    restaurants: [],
    selectedRestaurant: "",
    dummyData: {
      orders: [
        {
          id: 0,
          deadline: "2022-05-10T00:00:00.000Z",
          restaurantName: "Broker",
          status: "CONFIRMED",
        },
        {
          id: 1,
          deadline: "2022-05-11T00:00:00.000Z",
          restaurantName: "Broker",
          status: "ARRIVED",
        },
        {
          id: 2,
          deadline: "2022-05-12T00:00:00.000Z",
          restaurantName: "Broker",
          status: "CONFIRMED",
        },
        {
          id: 3,
          deadline: "2022-05-13T00:00:00.000Z",
          restaurantName: "Broker",
          status: "ARRIVED",
        },
      ],
    },
    suppliers: [],
    selectedSupplier: "",
    radios: "",
    currentTabSelected: {},
    headers: [],
  }),
  mounted() {
    this.navItems = [
      { id: 0, name: this.$t("navigation.allOrders"), code: "ALL_ORDERS" },
      { id: 1, name: this.$t("navigation.arrived"), code: "ARRIVED" },
      { id: 2, name: this.$t("navigation.confirmed"), code: "CONFIRMED" },
      { id: 3, name: this.$t("navigation.delivered"), code: "DELIVERED" },
    ];
    this.headers = [
      { text: this.$t("common.date"), value: "deadline", sortable: false },
      {
        text: this.$t("common.restaurant"),
        value: "restaurantName",
        sortable: false,
      },
      { text: this.$t("common.status"), value: "status", sortable: false },
    ];
  },
  computed: {
    getIsMobile() {
      return this.$isMobile;
    },
  },
  methods: {
    onSelectSupplier() {},
    handleTabSelected(val) {
      this.currentTabSelected = val;
    },
  },
};
</script>
<style scoped lang="scss">
.padding-adjust {
  padding: 32px 0 !important;
}
</style>
