<template>
  <div class="padding-adjust">
    <div class="d-flex align-center justify-space-between">
      <span class="text-h5 font-weight-bold primaryA--text">{{
        $t("articles.listArticles")
      }}</span>
    </div>
    <div class="search-filter">
      <v-text-field
        :disabled="!selectedFilterSupplier"
        id="search"
        name="search"
        class="search-input"
        :placeholder="$t('articles.searchArticles')"
        v-model="searchValue"
        type="text"
        solo
        prepend-inner-icon="mdi-magnify"
        clearable
        @keyup="onSearchUp"
        @keydown="onSearchDown"
      ></v-text-field>
      <div class="d-flex selects-wrap">
        <v-select
          class="select-width"
          :items="allSuppliers"
          :placeholder="$t('common.suppliers')"
          v-model="selectedFilterSupplier"
          item-text="name"
          item-value="id"
          solo
          @change="onSupplierSelected"
        ></v-select>
        <v-select
          :disabled="!selectedFilterSupplier"
          class="select-width margin-adjusting"
          :items="allCategories"
          :placeholder="$t('articles.articlesCategories')"
          v-model="selectedFilterGroup"
          item-text="category"
          item-value="id"
          solo
          @change="onGroupSelected"
        ></v-select>
        <v-btn @click="onAddNewArticleClick" class="margin-adjusting" color="primary">{{
          $t("articles.addArticle")
        }}</v-btn>
      </div>
    </div>

    <div class="mt-11 admin-articles">
      <articles-data-table
        v-if="!noArticles"
        :headers="headers"
        class="elevation-0"
        :hide-default-footer="false"
        :data="getAllArticles"
        :isDataLoading="isDataLoading"
        :totalCount="getTotalCount"
        @onPageChange="handlePageChange"
        :pageSize="pageSize"
        @itemsPerPageChange="onItemsPerPageChange"
        :isPrimaryArticle="true"
        @onEditArticle="handleOnEditArticle"
        @onArticleDeleteAction="handleOnDeleteArticle"
      ></articles-data-table>
      <no-results-content
        v-else
        :noResultsText="$t('common.noResuls')"
        :page="'articles'"
      ></no-results-content>
    </div>
    <create-edit-article-modal
      v-if="showCreateEditArticleModal"
      :dialog="showCreateEditArticleModal"
      :supplierId="selectedFilterSupplier"
      :editArticleData="articleToBeEdited"
      @onClose="handleOnCloseCreateOrEditArticleModal"
      @onSuccessAddedArticle="handleOnSuccessAddNewArticle"
      @onSuccessEditArticle="handleOnSuccessEditArticle"
      :allSuppliers="allSuppliers"
    ></create-edit-article-modal>
    <modal-component
      :dialog="showDeleteWarningModal"
      @onClose="onCloseDeleteWarningModal"
    >
      <div class="pa-4">
        <div class="text-h6 font-weight-black">
          {{ $t("articles.sureToDelete") }}
        </div>
        <div
          class="text-subtitle-2 secondaryDark2--text pt-0 pb-0 pl-8 pr-8 mt-3"
        >
          {{ $t("articles.sureToDeleteDescription") }}
        </div>

        <div class="d-flex justify-center align-center mt-6">
          <v-btn color="accent" @click="onConfirmDeleteOrder">{{
            $t("newOrder.yes")
          }}</v-btn>
          <v-btn class="ml-4" @click="onCloseDeleteWarningModal">{{
            $t("newOrder.cancel")
          }}</v-btn>
        </div>
      </div>
    </modal-component>
  </div>
</template>
<script>
import NoResultsContent from "../../components/NoResultsContent.vue";
import ArticlesDataTable from "../Articles/ArticlesDataTable.vue";
import CreateEditArticleModal from "../Articles/modals/CreateEditArticleModal.vue";
//Event bus
import ModalComponent from "../../components/ModalComponent.vue";
import { mapGetters } from "vuex";
import { REQUEST_PENDING } from "../../shared/statuses";

export default {
  name: "AdminArticles",
  components: {
    NoResultsContent,
    ArticlesDataTable,
    CreateEditArticleModal,
    ModalComponent,
  },
  props: { allSuppliers: {} },
  data: () => ({
    fab: false,
    top: true,
    left: true,
    headers: [],
    articlesData: [],
    isDataLoading: false,
    selectedArticles: [],
    searchValue: "",
    selectedFilterGroup: "",
    selectedFilterSupplier: "",
    showImportArticleModal: false,
    initialArticles: {},
    typingTimer: null,
    doneTypingInterval: 1500,
    pageNumber: 1,
    pageSize: 10,
    showCreateEditArticleModal: false,
    articleToBeEdited: null,
    showDeleteWarningModal: false,
    articleToDelete: null,
  }),
  created() {
    this.$store.dispatch("userProfile/getUserProfile");

    this.headers = [
      {
        text: this.$t("articles.favorite"),
        value: "checkbox_action",
        sortable: false,
      },
      {
        text: this.$t("common.name"),
        value: "name",
        sortable: false,
      },
      {
        text: this.$t("common.group"),
        value: "__category__",
        sortable: false,
      },
      {
        text: this.$t("common.actions"),
        value: "article_action",
        sortable: false,
      },
    ];
  },
  mounted() {},
  methods: {
    handleCancel() {
      this.selectedArticles = [];
      this.articlesData.forEach((a) => {
        this.$set(a, "selected", false);
      });
      this.$forceUpdate();
    },
    onSupplierSelected(e) {
      this.selectedFilterGroup = null;

      this.$store
        .dispatch("articles/getAllArticleSupplierCategories", {
          supplierId: e,
        })
        .then((response) => {
          this.selectedFilterGroup = response && response.data[0].id;
          this.getAllArticlesSupplier();
        });
    },
    onSearchUp() {
      clearTimeout(this.typingTimer);
      this.typingTimer = setTimeout(
        this.searchArticles,
        this.doneTypingInterval
      );
    },
    onSearchDown() {
      clearTimeout(this.typingTimer);
    },
    searchArticles() {
      this.pageNumber = 1;
      this.pageSize = 10;
      this.getAllArticlesSupplier();
    },
    getAllArticlesSupplier() {
      // this.$store.dispatch("articles/clearAllArticlesSupplier");
      let payload = {
        supplierId: this.selectedFilterSupplier,
        pageNum: this.pageNumber,
        categoryId: this.selectedFilterGroup,
        searchQuery: this.searchValue,
        pageSize: this.pageSize,
      };
      !this.selectedFilterGroup && delete payload.categoryId;
      this.$store.dispatch("admin/getAllArticlesSupplier", payload).then(
        () => {},
        (error) => {
          console.log("error", error);
        }
      );
    },
    onGroupSelected() {
      this.pageNumber = 1;
      this.getAllArticlesSupplier();
    },
    handlePageChange(page) {
      this.pageNumber = page;
      this.getAllArticlesSupplier();
    },
    onItemsPerPageChange(event) {
      this.pageSize = event;
      this.getAllArticlesSupplier();
    },
    handleOnEditArticle(article) {
      this.articleToBeEdited = article;
      this.showCreateEditArticleModal = true;
    },
    handleOnCloseCreateOrEditArticleModal() {
      this.articleToBeEdited = null;
      this.showCreateEditArticleModal = false;
    },
    handleOnSuccessEditArticle() {
      this.articleToBeEdited = null;
      this.showCreateEditArticleModal = false;
      this.getAllArticlesSupplier();
    },
    handleOnSuccessAddNewArticle(selectedSupplierId) {
      this.selectedFilterSupplier = selectedSupplierId;
      this.articleToBeEdited = null;
      this.showCreateEditArticleModal = false;
      this.getAllArticlesSupplier();
    },
    onAddNewArticleClick() {
      this.showCreateEditArticleModal = true;
    },
    handleOnDeleteArticle(article) {
      this.articleToDelete = article;
      this.showDeleteWarningModal = true;
    },
    onCloseDeleteWarningModal() {
      this.articleToDelete = null;
      this.showDeleteWarningModal = false;
    },
    onConfirmDeleteOrder() {
      this.$store
        .dispatch("articles/deleteArticle", {
          articleId: this.articleToDelete.id,
        })
        .then(() => {
          this.showDeleteWarningModal = false;
          this.getAllArticlesSupplier();
        });
    },
  },
  watch: {
    getAllProductsStatus() {
      this.isDataLoading = this.getAllProductsStatus === REQUEST_PENDING;
    },
    getAllArticles() {
      // console.log("getAllArticles", this.getAllArticles);
    },
  },
  computed: {
    ...mapGetters({
      getAllProducts: "admin/getAllArticlesSupplier",
      getAllProductsStatus: "admin/getAllArticlesSupplierStatus",
      getAllArticlesCategories: "articles/getAllArticlesCategories",
      getTotalArticlesCount: "admin/getTotalArticlesCount",
      getDeleteArticleStatus: "articles/getDeleteArticleStatus",
    }),
    getAllArticles() {
      return this.getAllProducts;
    },
    noArticles() {
      return this.getAllProducts.length === 0 && this.isDataLoading === false;
    },
    getIsMobile() {
      return this.$isMobile;
    },
    allCategories() {
      let ctgs = this.getAllArticlesCategories;
      ctgs.unshift({ id: null, category: this.$t("articles.all") });
      return ctgs;
    },
    getTotalCount() {
      return this.getTotalArticlesCount;
    },
    deleteArticleStatus() {
      return this.getDeleteArticleStatus;
    },
  },
};
</script>
<style scoped lang="scss">
.search-input {
  max-width: 315px;
  height: 54px;
  @media screen and (max-width: 575px) {
    max-width: 100%;
  }
}
.search-filter {
  margin-top: 24px;
  display: flex;
  @media screen and (max-width: 575px) {
    flex-direction: column;
  }
}
.select-width {
  max-width: 315px;
  margin-left: 24px;
  @media screen and (max-width: 575px) {
    margin-left: 0;
    justify-content: space-between;
    max-width: 49%;
  }
}
.selects-wrap {
  @media screen and (max-width: 575px) {
    justify-content: space-between;
  }
}
.margin-adjusting {
  margin-left: 20px;
  @media screen and (max-width: 575px) {
   margin-left:5px
  }
}
</style>
