class TokenService {
  getLocalRefreshToken() {
    const userData = JSON.parse(localStorage.getItem("userData"));
    return userData?.refresh_token;
  }

  getLocalAccessToken() {
    const userData = JSON.parse(localStorage.getItem("userData"));
    return userData?.jwt;
  }

  updateLocalAccessToken(token, refresh_token) {
    let userData = JSON.parse(localStorage.getItem("userData"));
    userData.jwt = token;
    userData.refresh_token = refresh_token;
    localStorage.setItem("userData", JSON.stringify(userData));
  }

  getUser() {
    let userData = JSON.parse(localStorage.getItem("userData"));
    return userData?.user;
  }

  setUser(userData) {
    localStorage.setItem("userData", JSON.stringify(userData));
  }

  removeUser() {
    localStorage.removeItem("userData");
  }
}
export default new TokenService();
