<template>
  <div class="pb-8">
    <div class="d-flex align-center justify-space-between">
      <span class="text-h4">{{ $t("suppliers.suppliers") }}</span>
      <v-btn class="text-fix ml-2" color="accent" @click="handleAddSupplier">{{
        $t("adminSuppliers.addSupplier")
      }}</v-btn>
    </div>
    <div class="mt-11">
      <admin-data-table
        v-if="!noOrders"
        :headers="headers"
        :data="allSuppliers"
        :isDataLoading="isDataLoading"
        @onAddContact="handleOnAddContact"
        @onPreviewContacts="handleOnPreviewContacts"
        @onEditSupplier="handleOnEditSupplier"
        @onDeleteSupplier="handleOnDeleteSupplier"
        :isSuppliersPage="true"
      ></admin-data-table>
      <no-results-content
        v-else
        :noResultsText="$t('suppliers.noSuppliersAdded')"
        :page="'suppliers'"
      ></no-results-content>
    </div>
    <add-supplier-modal
      :dialog="showAddSupplierModal"
      @onClose="handleCloseAddSupplierModal"
      @onAddedSupplier="handleAddedSupplier"
      @onAddSupplier="handleOnAddSupplier"
      :editSupplierData="supplierToEdit"
      @onSaveSupplier="handleOnSaveSupplier"
    ></add-supplier-modal>
    <add-supplier-contact-modal
      v-if="showAddSupplierContactModal"
      :dialog="showAddSupplierContactModal"
      @onClose="handleCloseAddSupplierContactModal"
      :selectedSupplier="selectedSupplier"
      @onAddedSupplierContact="handleAddedSupplierContact"
    ></add-supplier-contact-modal>
    <preview-supplier-contacts-modal
      v-if="showPreviewContactsModal"
      :dialog="showPreviewContactsModal"
      @onClose="handleCloseSupplierContactsModal"
      :supplier="selectedSupplier"
    ></preview-supplier-contacts-modal>
  </div>
</template>
<script>
//vuex
import { mapGetters } from "vuex";
// components
import NoResultsContent from "../../components/NoResultsContent.vue";
import AdminDataTable from "./components/AdminDataTable.vue";
import AddSupplierModal from "../../components/AddSupplierModal.vue";
import AddSupplierContactModal from "../../components/AddSupplierContactModal.vue";
import PreviewSupplierContactsModal from "../../components/PreviewSupplierContactsModal.vue";
// vuex
import { REQUEST_PENDING } from "../../shared/statuses";
//constants
import EventBus from "../../shared/EventBus";

export default {
  name: "AdminSuppliers",
  components: {
    NoResultsContent,
    AddSupplierModal,
    AddSupplierContactModal,
    PreviewSupplierContactsModal,
    AdminDataTable,
  },
  props: { allSuppliers: {}, isSuperAdmin: {} },
  data: () => ({
    headers: [],
    isDataLoading: false,
    ordersData: [],
    showAddSupplierModal: false,
    showAddSupplierContactModal: false,
    selectedSupplier: null,
    showPreviewContactsModal: false,
    supplierToEdit: null,
    supplierToDelete: null,
  }),
  created() {
    this.headers = [
      { text: this.$t("common.pib"), value: "pib" },
      { text: this.$t("suppliers.name"), value: "supplersName" },
      {
        text: this.$t("suppliers.address"),
        value: "address",
      },
      {
        text: this.$t("suppliers.uploadedData"),
        value: "uploadedData",
      },
      {
        text: this.$t("suppliers.uploadSupplierData"),
        value: "upload",
        sortable: false,
      },
      {
        text: "",
        value: "actions",
        sortable: false,
      },
    ];
  },
  methods: {
    handleAddSupplier() {
      this.showAddSupplierModal = true;
    },
    handleCloseAddSupplierModal() {
      this.supplierToEdit = null;
      this.supplierToDelete = null;
      this.showAddSupplierModal = false;
    },
    handleAddedSupplier() {
      this.showAddSupplierModal = false;
      EventBus.dispatch("showSnackbar", {
        text: this.$t("notifications.addedSupplier"),
      });
    },
    handleAddedSupplierContact() {
      this.showAddSupplierContactModal = false;
      EventBus.dispatch("showSnackbar", {
        text: this.$t("notifications.addedContactSupplier"),
      });
      this.$store.dispatch("admin/getSuppliers");
    },
    handleOnAddContact(event) {
      this.selectedSupplier = event;
      this.showAddSupplierContactModal = true;
    },
    handleCloseAddSupplierContactModal() {
      this.showAddSupplierContactModal = false;
    },
    handleOnPreviewContacts(event) {
      this.selectedSupplier = event;
      this.showPreviewContactsModal = true;
    },
    handleCloseSupplierContactsModal() {
      this.selectedSupplier = null;
      this.showPreviewContactsModal = false;
    },
    handleOnAddSupplier(supplier) {
      this.isDataLoading = true;
      this.$store.dispatch("supplier/addSupplier", supplier).then(
        () => {
          this.showAddSupplierModal = false;
          this.isDataLoading = false;
          EventBus.dispatch("showSnackbar", {
            text: this.$t("notifications.addedSupplier"),
          });
          this.$store.dispatch("admin/getSuppliers");
        },
        (error) => {
          this.isDataLoading = false;
          console.log("error adding supplier", error);
        }
      );
    },
    handleOnEditSupplier(data) {
      this.supplierToEdit = data;
      this.showAddSupplierModal = true;
    },
    handleOnDeleteSupplier(data) {
      this.supplierToDelete = data;
      // TODO show modal, api etc...
    },
    handleOnSaveSupplier(data) {
      this.isDataLoading = true;
      this.showAddSupplierModal = false;
      this.$store.dispatch("supplier/editSupplierData", data).then(
        () => {
          this.isDataLoading = false;
          this.isSuperAdmin && this.$store.dispatch("admin/getSuppliers");
        },
        (error) => {
          console.error(error);
        }
      );
    },
  },
  computed: {
    ...mapGetters({
      getAllSuppliersStatus: "admin/getAllSuppliersStatus",
    }),
    noOrders() {
      return (
        this.allSuppliers &&
        this.allSuppliers.length === 0 &&
        this.isDataLoading === false &&
        this.getAllSuppliersStatus !== REQUEST_PENDING
      );
    },
  },
};
</script>
<style scoped lang="scss"></style>
