class LocalStorageService {
  setLocalStorageData(key, data) {
    localStorage.setItem(
      key,
      typeof data === "object" ? JSON.stringify(data) : data
    );
  }
  getLocalStorageData(key) {
    let obj = localStorage.getItem(key);

    return JSON.parse(obj);
  }
  deleteLocalStorageItem(key) {
    localStorage.removeItem(key);
  }
}
export default new LocalStorageService();
