<template>
  <div>
    <v-dialog
      v-model="showDialog"
      :width="modalWidth || '526px'"
      class="modal-dialog-style"
      :fullscreen="getIsMobile"
      persistent
    >
      <div class="dialog-style" :style="getStyle">
        <div v-if="!hideClosing" class="header">
          <v-img
            @click="closeDialog"
            class="close-icon"
            max-width="20px"
            :src="require('../assets/img/CloseIcon.svg')"
          >
          </v-img>
        </div>
        <slot />
      </div>
    </v-dialog>
  </div>
</template>
<script>
export default {
  name: "ModalComponent",
  props: {
    dialog: {},
    warninModalType: {},
    modalWidth: {},
    hideClosing: {},
    adjustHeight: {},
  },
  data: () => ({
    showDialog: false,
  }),
  watch: {
    dialog() {
      this.showDialog = this.dialog;
    },
  },
  created() {
    this.showDialog = this.dialog;
  },
  methods: {
    closeDialog() {
      this.$emit("onClose");
    },
  },
  computed: {
    getIsMobile() {
      return this.$isMobile;
    },
    getStyle() {
      if (this.getIsMobile) {
        return this.adjustHeight
          ? `height: ${this.adjustHeight}!important`
          : "height: 100%";
      }
    },
  },
};
</script>
<style scoped lang="scss">
.dialog-style {
  background-color: #ffffff;
  padding: 20px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  // @media screen and (max-width: 959px) {
  //   height: 100%;
  // }
}
.modal-dialog-style {
  background-color: #ffffff;
  padding: 40px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  // @media screen and (max-width: 959px) {
  //   height: 100%;
  // }
}
.close-icon {
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
}
.info-text {
  text-align: center;
}
.header {
  position: relative;
  width: 100%;
}
.margin-adjust-button {
  margin-left: 0 !important;
  margin-top: 12px;
}
.remove-uppercase {
  text-transform: none !important;
}
</style>
