<template>
  <v-dialog
    v-model="showDialog"
    width="526"
    class="dialog-style"
    persistent
    :fullscreen="getIsMobile"
  >
    <div class="dialog-style">
      <div class="header">
        <v-img
          @click="closeDialog"
          class="close-icon"
          max-width="20px"
          :src="require('../assets/img/CloseIcon.svg')"
        >
        </v-img>
        <div class="d-flex align-center flex-wrap justify-center">
          <span class="text-h5 primaryA--text font-weight-bold">
            {{ warningMessage }}
          </span>
        </div>
      </div>
      <div class="mt-8 d-flex align-center flex-wrap">
        <v-btn
          class="remove-uppercase"
          :width="getIsMobile ? '100%' : 'auto'"
          color="accent"
          @click="$emit('onConfirm')"
          >{{ $t("common.confirm") }}</v-btn
        >
        <v-btn
          :width="getIsMobile ? '100%' : 'auto'"
          class="ml-5 remove-uppercase"
          color="tertiaryDark"
          :class="{
            'margin-adjust-button': getIsMobile,
          }"
          @click="$emit('onCancel')"
          >{{ $t("common.cancel") }}</v-btn
        >
      </div>
    </div>
  </v-dialog>
</template>
<script>
export default {
  name: "SimpleWarningModal",
  props: {
    dialog: {},
    warningMessage: {},
  },
  data: () => ({
    showDialog: false,
  }),
  watch: {
    dialog() {
      this.showDialog = this.dialog;
    },
  },
  created() {
    this.showDialog = this.dialog;
  },
  mounted() {},
  methods: {
    closeDialog() {
      this.$emit("onClose");
    },
  },
  computed: {
    getIsMobile() {
      return this.$isMobile;
    },
  },
};
</script>
<style scoped lang="scss">
.dialog-style {
  background-color: #ffffff;
  padding: 40px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media screen and (max-width: 959px) {
    height: 100%;
  }
}
.close-icon {
  cursor: pointer;
  position: absolute;
  top: -12px;
  right: -24px;
}
.header {
  position: relative;
}
.remove-uppercase {
  text-transform: none !important;
}
.margin-adjust-button {
  margin-left: 0 !important;
  margin-top: 12px;
}
</style>
