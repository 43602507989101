import {
  getReportDataCategoryURL,
  getReportDataURL,
  getReportDataVenueURL,
} from "../shared/endpoints";
import api from "./api";

class ReportService {
  getReportData(data) {
    const departmentId = data.departmentId;
    const fromDate = data.fromDate;
    const toDate = data.toDate;
    const searchQuery = data.searchQuery;
    const pageSize = data.pageSize;
    const page = data.page;

    return api.get(
      getReportDataURL(
        departmentId,
        fromDate,
        toDate,
        searchQuery,
        pageSize,
        page
      )
    );
  }
  getReportDataVenue(data) {
    const venueId = data.venueId;
    const fromDate = data.fromDate;
    const toDate = data.toDate;
    const searchQuery = data.searchQuery;
    const pageSize = data.pageSize;
    const page = data.page;

    return api.get(
      getReportDataVenueURL(
        venueId,
        fromDate,
        toDate,
        searchQuery,
        pageSize,
        page
      )
    );
  }
  getReportDataPerCategory(data) {
    const departmentId = data.departmentId;
    const fromDate = data.fromDate;
    const toDate = data.toDate;
    const searchQuery = data.searchQuery;
    const pageSize = data.pageSize;
    const page = data.page;
    const categoryId = data.categoryId;

    return api.get(
      getReportDataCategoryURL(
        departmentId,
        fromDate,
        toDate,
        searchQuery,
        pageSize,
        page,
        categoryId
      )
    );
  }
}
export default new ReportService();
