var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.data,"sort-by":['name'],"sort-desc":[false, true],"loading":_vm.isDataLoading,"loading-text":_vm.$t('common.loadingTableText'),"mobile-breakpoint":"320","items-per-page":5,"footer-props":{
    itemsPerPageAllText: _vm.$t('common.all'),
    itemsPerPageText: _vm.$t('common.itemsPerPage'),
  }},scopedSlots:_vm._u([{key:"item.deadline",fn:function(ref){
  var item = ref.item;
return [_c('span',{staticClass:"text-body-1 accent--text order-deadline",class:{ statusMobileText: _vm.getIsMobile }},[_vm._v(_vm._s(_vm.getFormattedDate(item.deadline)))])]}},{key:"item.statusText",fn:function(ref){
  var item = ref.item;
return [(item.status === 'ARRIVED')?_c('span',{staticClass:"tag-style text-body-1 negative--text",class:{ statusMobileText: _vm.getIsMobile }},[_vm._v(_vm._s(_vm.getStatusText(item.status)))]):_c('span',{staticClass:"tag-style text-body-1 accent--text",class:{ statusMobileText: _vm.getIsMobile }},[_vm._v(_vm._s(item.statusText))])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }