<template>
  <v-data-table
    :headers="headers"
    :items="data"
    :sort-by="['name']"
    :sort-desc="[false, true]"
    class="elevation-1 admin-data-table"
    :loading="isDataLoading"
    :loading-text="$t('common.loadingTableText')"
    :items-per-page="5"
    :footer-props="{
      itemsPerPageAllText: $t('common.all'),
      itemsPerPageText: $t('common.itemsPerPage'),
    }"
    @click:row="tableRowClicked"
  >
    <!-- restaurants list -->
    <template v-slot:item.status="{ item }">
      <span
        class="restaurant-status"
        :class="{
          disabledStatus: item.disabled,
        }"
        >{{ item && getStatusText(item.disabled) }}</span
      >
    </template>
    <template v-slot:item.toggleStatus="{ item }">
      <div>
        <v-btn dense color="accent" @click.stop="togleRestaurantStatus(item)">
          {{
            item.disabled
              ? $t("adminDashboard.reactivate")
              : $t("adminDashboard.deactivate")
          }}</v-btn
        >
        <v-btn
          class="ml-2"
          dense
          color="secondaryDark"
          @click.stop="tableRowClicked(item)"
        >
          {{ $t("adminDashboard.details") }}
        </v-btn>
      </div>
    </template>
    <!-- restaurants list -->
    <template v-slot:item.supplersName="{ item }">
      <span>{{ item.name }}</span>
    </template>
    <!-- suppliers list -->
    <template v-slot:item.upload="{ item }">
      <v-file-input
        small-chips
        :placeholder="$t('suppliers.supportedExcelFile')"
        accept=".xlsx"
        show-size
        @change="onFileInputChange"
        @click="selectRowId(item)"
      ></v-file-input>
    </template>
    <template v-slot:item.uploadedData="{ item }">
      <div>
        <span class="uploadedYes" v-if="item.hasData">
          <v-img max-width="14" :src="require('../../../assets/img/check.png')">
          </v-img>
        </span>
        <span class="uploadedNo" v-else>
          <v-img max-width="14" :src="require('../../../assets/img/close.png')">
          </v-img>
        </span>
      </div>
    </template>
    <template v-slot:item.actions="{ item }">
      <v-menu v-if="isSuppliersPage" bottom left>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on">
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item v-for="(opt, i) in optionsItems" :key="i">
            <v-list-item-title
              class="option-item"
              @click="handleOptionsSelection(opt.value, item)"
              >{{ opt.title }}</v-list-item-title
            >
          </v-list-item>
        </v-list>
      </v-menu>
    </template>
    <!-- suppliers list -->
    <!-- invite users approval list -->
    <template v-slot:item.userName="{ item }">
      <span>{{ item && item.user && item.user.name }}</span>
    </template>
    <template v-slot:item.userEmail="{ item }">
      <span>{{ item && item.user && item.user.email }}</span>
    </template>
    <template v-slot:item.resName="{ item }">
      <span>{{ item && item.venue && item.venue.name }}</span>
    </template>
    <template v-slot:item.invResPib="{ item }">
      <span>{{ item && item.venue && item.venue.pib }}</span>
    </template>
    <!-- <template v-slot:item.invStatus="{ item }">
      <span>{{ getInviteStatusText(item.status) }}</span>
    </template> -->
    <template v-slot:item.invActions="{ item }">
      <div>
        <v-btn color="accent" @click="approveUserRequest(item)">{{
          $t("adminUsers.approve")
        }}</v-btn>
      </div>
    </template>
    <!-- invite users approval list -->
  </v-data-table>
</template>
<script>
//services
import EventBus from "../../../shared/EventBus";
import moment from "moment";
export default {
  props: {
    headers: {},
    data: {},
    isDataLoading: {},
    isSuppliersPage: {},
  },
  name: "AdminDataTable",
  data: () => ({
    loader: null,
    optionsItems: [],
  }),
  created() {
    this.optionsItems = [
      {
        id: 0,
        title: this.$t("currentOrders.edit"),
        value: "edit",
      },
      {
        id: 1,
        title: this.$t("currentOrders.delete"),
        value: "delete",
      },
    ];
  },

  methods: {
    getStatusText(disabled) {
      return disabled
        ? this.$t("adminDashboard.inactive")
        : this.$t("adminDashboard.active");
    },
    tableRowClicked(value) {
      this.$emit("onTableRowClicked", value);
    },
    togleRestaurantStatus(item) {
      this.$emit("onToggleRestaurantStatus", item.id);
    },
    addContact(supplier) {
      this.$emit("onAddContact", supplier);
    },
    previewContacts(supplier) {
      this.$emit("onPreviewContacts", supplier);
    },
    selectRowId(item) {
      this.selectedSupplierId = item.id;
    },
    onFileInputChange(e) {
      this.$store.dispatch("loader/setLoadingState");
      let data = { id: this.selectedSupplierId, data: e };
      this.$store.dispatch("supplier/uploadSupplierData", data).then(
        (response) => {
          setTimeout(() => {
            this.$store.dispatch("loader/setLoadingState");
            this.$store.dispatch("admin/getSuppliers");
            EventBus.dispatch("showSnackbar", {
              text: this.$t("notifications.uploadedSupplierData"),
            });
          }, 2000);
        },
        (error) => {
          this.$store.dispatch("loader/setLoadingState");
          console.log("upload error", error);
        }
      );
    },
    getInviteStatusText(status) {
      return status;
    },
    approveUserRequest(item) {
      this.$emit("onApproveUserRequest", item);
    },
    handleOptionsSelection(option, value) {
      switch (option) {
        case "edit":
          this.$emit("onEditSupplier", value);
          break;
        case "delete":
          this.$emit("onDeleteSupplier", value);
          break;
      }
    },
  },
  watch: {},
};
</script>
<style lang="scss" scoped>
.tag-style {
  background: #f7f7f8;
  padding: 8px 4px;
  text-transform: uppercase;
  @media screen and (max-width: 959px) {
    font-size: 14px !important;
  }
}
.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}
@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-o-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
.disabledStatus {
  background: #fd4a34 !important;
}
.restaurant-status {
  background: #00a991;
  color: #ffffff;
  padding: 4px 12px;
  border-radius: 6px;
}
.inactiveStatus {
}
.uploadedYes {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: #35d2bc;
  display: flex;
  justify-content: center;
  align-items: center;
}
.uploadedNo {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: #fd4a34;
  display: flex;
  justify-content: center;
  align-items: center;
}
.option-item {
  cursor: pointer;
}
</style>
