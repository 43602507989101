import ReportsService from "../services/reports.service";
import {
  REQUEST_FAILURE,
  REQUEST_PENDING,
  REQUEST_UNDEFINED,
  REQUEST_SUCCESS,
} from "../shared/statuses";

const initialState = {
  reportData: {},
  reportArticles: [],
  reportDataStatus: REQUEST_UNDEFINED,
  reportDataVenue: {},
  reportDataVenueStatus: REQUEST_UNDEFINED,
  reportArticlesVenue: [],
  reportDataPerCategory: {},
  reportDataPerCategoryStatus: REQUEST_UNDEFINED,
  reportArticlesPerCategory: [],
};

export const reports = {
  namespaced: true,
  state: initialState,
  actions: {
    getReportData({ commit }, payload) {
      commit("getReportDataPending");
      return ReportsService.getReportData(payload).then(
        (response) => {
          commit("getReportDataSuccess", response.data);
          return Promise.resolve(response);
        },
        (error) => {
          commit("getReportDataError");
          return Promise.reject(error);
        }
      );
    },
    getReportDataVenue({ commit }, payload) {
      commit("getReportDataVenuePending");
      return ReportsService.getReportDataVenue(payload).then(
        (response) => {
          commit("getReportDataVenueSuccess", response.data);
          return Promise.resolve(response);
        },
        (error) => {
          commit("getReportDataVenueError");
          return Promise.reject(error);
        }
      );
    },
    getReportDataPerCategory({ commit }, payload) {
      commit("getReportDataPerCategoryPending");
      return ReportsService.getReportDataPerCategory(payload).then(
        (response) => {
          commit("getReportDataPerCategorySuccess", response.data);
          return Promise.resolve(response);
        },
        (error) => {
          commit("getReportDataPerCategoryError");
          return Promise.reject(error);
        }
      );
    },
  },
  mutations: {
    getReportDataPending(state) {
      state.reportData = {};
      state.reportArticles = [];
      state.reportDataStatus = REQUEST_PENDING;
    },
    getReportDataSuccess(state, data) {
      state.reportData = data;
      state.reportArticles = data.articleData;
      state.reportDataStatus = REQUEST_SUCCESS;
    },
    getReportDataError(state) {
      state.reportData = {};
      state.reportArticles = [];
      state.reportDataStatus = REQUEST_FAILURE;
    },

    getReportDataVenuePending(state) {
      state.reportDataVenue = {};
      state.reportDataVenueStatus = REQUEST_PENDING;
    },
    getReportDataVenueSuccess(state, data) {
      state.reportDataVenue = data;
      state.reportArticlesVenue = data.articleData;
      state.reportDataVenueStatus = REQUEST_SUCCESS;
    },
    getReportDataVenueError(state) {
      state.reportDataVenue = {};
      state.reportArticlesVenue = [];
      state.reportDataVenueStatus = REQUEST_FAILURE;
    },

    getReportDataPerCategoryPending(state) {
      state.reportDataPerCategory = {};
      state.reportDataPerCategoryStatus = REQUEST_PENDING;
    },
    getReportDataPerCategorySuccess(state, data) {
      state.reportDataPerCategory = data;
      state.reportArticlesPerCategory = data.articleData;
      state.reportDataPerCategoryStatus = REQUEST_SUCCESS;
    },
    getReportDataPerCategoryError(state) {
      state.reportDataPerCategory = {};
      state.reportArticlesPerCategory = [];
      state.reportDataPerCategoryStatus = REQUEST_FAILURE;
    },
  },
  getters: {
    getReportDataStatus: (state) => {
      return state.reportDataStatus;
    },
    getReportData: (state) => {
      return state.reportData;
    },
    getReportArticles: (state) => {
      return state.reportArticles;
    },

    getReportDataVenueStatus: (state) => {
      return state.reportDataVenueStatus;
    },
    getReportDataVenue: (state) => {
      return state.reportDataVenue;
    },
    getReportArticlesVenue: (state) => {
      return state.reportArticlesVenue;
    },

    getReportDataPerCategoryStatus: (state) => {
      return state.reportDataPerCategoryStatus;
    },
    // getReportDataPerCategory: (state) => {
    //   return state.reportDataPerCategory;
    // },
    getReportArticlesPerCategory: (state) => {
      return state.reportArticlesPerCategory;
    },
  },
};
