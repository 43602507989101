<template>
  <v-card :width="width" elevation="2" class="pa-6 mt-10 mr-6">
    <div class="d-flex align-center mb-6">
      <span class="text-h4">{{ $t("adminRestaurant.suppliers") }}</span>
    </div>
    <span class="secondaryDark2--text subtitle-1">{{
      $t("adminRestaurant.addRemoveSupplier")
    }}</span>
    <v-autocomplete
      class="mt-3"
      v-model="suppliersOfVenue"
      :disabled="isUpdating"
      :items="allSuppliers"
      filled
      chips
      color="accent"
      :label="$t('adminRestaurant.addSuppliers')"
      item-text="name"
      item-value="id"
      multiple
      @change="supplierSelected"
      :hide-selected="true"
      :no-data-text="$t('adminRestaurant.noResults')"
    >
      <template v-slot:selection="data">
        <v-chip
          v-bind="data.attrs"
          :input-value="data.selected"
          close
          @click="data.select"
          @click:close="remove(data.item)"
          color="primaryLight"
        >
          <v-avatar left>
            <div class="avatar">
              {{ data && data.item && data.item.name && data.item.name[0] }}
            </div>
          </v-avatar>
          {{ data.item.name }}
        </v-chip>
      </template>
      <template v-slot:item="data">
        <template v-if="typeObject(data)">
          <v-list-item-content v-text="data.item"></v-list-item-content>
        </template>
        <template v-else>
          <v-list-item-avatar>
            <div class="avatar">
              {{ data && data.item && data.item.name && data.item.name[0] }}
            </div>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title v-html="data.item.name"></v-list-item-title>
          </v-list-item-content>
        </template>
      </template>
    </v-autocomplete>
  </v-card>
</template>
<script>
export default {
  name: "AssignSupplierCard",
  props: {
    width: {},
    allSuppliers: {},
    addedSuppliers: {},
  },
  data: () => ({
    isUpdating: false,
    suppliersOfVenue: [],
  }),
  mounted() {},
  created() {},
  methods: {
    remove(item) {
      const index = this.suppliersOfVenue.indexOf(item.id);
      if (index >= 0) this.suppliersOfVenue.splice(index, 1);
      this.$emit("onSupplierRemove", item.id);
    },
    supplierSelected(event) {
      let selectedSupplierId = event[event.length - 1];
      this.$emit("onSupplierSelected", selectedSupplierId);
    },
    typeObject(data) {
      return typeof data.item !== "object";
    },
  },
  watch: {
    isUpdating(val) {
      if (val) {
        setTimeout(() => (this.isUpdating = false), 3000);
      }
    },
    addedSuppliers() {
      this.suppliersOfVenue = this.addedSuppliers;
    },
  },
};
</script>
<style scoped lang="scss">
.avatar {
  width: 45px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #276ef1;
  color: #ffffff;
  border-radius: 50%;
}
</style>
