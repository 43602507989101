<template>
  <div class="padding-adjust">
    <div class="pa-8" :class="{ 'adjust-padding': getIsMobile }">
      <div>
        <div class="d-flex flex-column">
          <span class="text-h6 primaryA--text">{{
            $t("supplierDashboard.businessName", {
              name: order.businessName,
            })
          }}</span>
          <span class="text-h6 primaryA--text">{{
            $t("supplierDashboard.restaurantName", {
              name: order.restaurantName,
            })
          }}</span>
          <div class="d-flex">
            <span class="text-h6 primaryA--text mr-2">Datum dostave:</span>
            <div>
              <v-menu
                v-model="menu2"
                :close-on-content-click="false"
                :nudge-right="-80"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    class="date-picker"
                    v-model="date"
                    outlined
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    dense
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="date"
                  :first-day-of-week="1"
                  locale="sr-Latn-RS"
                  @input="menu2 = false"
                  :allowed-dates="allowedDates"
                  format="ampm"
                ></v-date-picker>
              </v-menu>
            </div>
          </div>

          <div class="d-flex">
            <span class="text-h6 primaryA--text"
              >{{ $t("newOrder.paymentMetod") }}:
            </span>
            <span class="text-h6 primaryA--text ml-2">
              {{ getPaymentMethod() }}</span
            >
          </div>
        </div>
        <div class="mt-5">
          <v-simple-table class="mt-3">
            <template v-slot:default>
              <thead>
                <tr>
                  <th>
                    <v-checkbox
                      v-model="allItemsSelected"
                      @change="selectAllItems"
                    ></v-checkbox>
                  </th>
                  <th>{{ $t("common.name") }}</th>
                  <th>{{ $t("common.qty") }}</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="item in order.products"
                  :key="item.id"
                  style="text-align: left"
                >
                  <td>
                    <v-checkbox v-model="item.selected"></v-checkbox>
                  </td>
                  <td>
                    <div class="d-flex flex-column">
                      <span class="primaryA--text font-weight-medium">{{
                        item.name
                      }}</span>
                      <span class="secondaryDark2--text" v-if="item.note"
                        >Napomena: {{ item.note }}</span
                      >
                    </div>
                  </td>
                  <td>x {{ item.amount }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <div class="mt-4 mb-4">
            <span class="text-h6 primaryA--text"
              >Napomena: {{ order.orderNote }}</span
            >
          </div>
        </div>
        <div class="d-flex mt-10">
          <v-btn
            v-if="!allItemsRejected"
            color="accent"
            @click="onConfirmOrder"
            >{{ $t("supplierDashboard.confirmOrder") }}</v-btn
          >
          <v-btn v-else color="accent" @click="onRejectOrder">{{
            $t("supplierDashboard.cancelOrder")
          }}</v-btn>
          <!-- <v-btn class="ml-3" @click="onRejectOrder">Odbij</v-btn> -->
          <v-btn class="ml-3" @click="onCopyOrder">{{
            $t("supplierDashboard.copyOrder")
          }}</v-btn>
        </div>
      </div>
    </div>
    <v-dialog
      v-model="showDialog"
      persistent
      :fullscreen="getIsMobile"
      width="33vw"
      class="dialog-style"
    >
      <div class="dialog-style">
        <span v-if="orderConfirmed"
          >{{ $t("supplierDashboard.confirmOrderWarningTitle") }}
          <strong>{{ $t("supplierDashboard.confirmed") }}</strong></span
        >
        <span v-if="orderRejected"
          >{{ $t("supplierDashboard.confirmOrderWarningTitle") }}
          <strong>{{ $t("supplierDashboard.rejected") }}</strong></span
        >
        <span
          ><strong>Restoran {{ order.restaurantName }}</strong> će biti
          obavešten o ovoj promeni.</span
        >
        <div v-if="!allItemsSelected" class="mt-4" style="width: 100%">
          <v-textarea
            id="noteForClient"
            no-resize
            filled
            name="noteForClient"
            :label="$t('supplierDashboard.noteForClient')"
            :value="noteForClient"
          ></v-textarea>
        </div>
        <div class="d-flex mt-7">
          <v-btn color="accent" @click="onConfirmWarning">Potvrdi</v-btn>
          <v-btn class="ml-4" @click="onCancelWarning">Otkaži</v-btn>
        </div>
      </div>
    </v-dialog>
  </div>
</template>
<script>
import Tabs from "../../components/Tabs.vue";
import SupplierDataTable from "./components/SupplierDataTable.vue";
import moment from "moment";
import EventBus from "../../shared/EventBus";
import {
  formatDate,
  generateSMSText,
  getPaymentMethodSelected,
} from "../../helpers/helper.js";
export default {
  name: "SupplierSingleOrder",
  components: {
    Tabs,
    SupplierDataTable,
  },
  props: {},
  data: () => ({
    selectedRestaurant: "",
    showDialog: false,
    order: {
      id: 0,
      restaurantName: "Bizu",
      businessName: "Biznis ime restorana",
      deadline: "2022-06-06T00:00:00.000Z",
      paymentMethod: "cash",
      orderNote: "Molim Vas da robu isporucite do 12h",
      products: [
        {
          id: 1,
          name: "Louis Latour, Ardeche Chardonnay",
          amount: "6",
          note: "",
          selected: true,
        },
        {
          id: 2,
          name: "Domaines Schlumberger, Gewurtztraminer Princes Abbes",
          amount: "3",
          note: "2019. god",
          selected: true,
        },
        {
          id: 3,
          name: "Mazzei, Fonterutoli",
          amount: "3",
          note: "",
          selected: true,
        },
      ],
    },
    suppliers: [],
    selectedSupplier: "",
    radios: "",
    orderConfirmed: false,
    orderRejected: false,
    allItemsSelected: false,
    allItemsRejected: false,
    date: "",
    menu2: false,
    todaysDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    noteForClient: "",
  }),
  mounted() {
    this.processSelectingAllItems();
    this.date = new Date(this.order.deadline).toISOString().slice(0, 10);
  },
  computed: {
    getIsMobile() {
      return this.$isMobile;
    },
  },
  watch: {
    order: {
      handler() {
        this.processSelectingAllItems();
      },
      deep: true,
    },
  },
  methods: {
    processSelectingAllItems() {
      this.allItemsSelected =
        this.order.products.filter((f) => f.selected).length ===
        this.order.products.length;
      this.allItemsRejected =
        this.order.products.filter((f) => !f.selected).length ===
        this.order.products.length;
    },
    onSelectSupplier() {},
    handleTabSelected(val) {
      this.currentTabSelected = val;
    },
    getFormattedDate(value) {
      return formatDate(value);
    },
    onConfirmOrder() {
      this.orderConfirmed = true;
      this.orderRejected = false;
      this.showDialog = true;
    },
    onRejectOrder() {
      this.orderRejected = true;
      this.orderConfirmed = false;
      this.showDialog = true;
    },
    onConfirmWarning() {
      this.showDialog = false;
      EventBus.dispatch("showSnackbar", {
        text: this.$t("notifications.successInformedRestaurant"),
      });
    },
    onCancelWarning() {
      this.showDialog = false;
    },
    getGeneratedSMSText() {
      return (
        this.order &&
        this.order.products &&
        generateSMSText(
          this.order.products,
          this.order.businessName,
          this.date,
          this.order.orderNote,
          this.order.paymentMethod,
          false,
          true
        )
      );
    },
    getPaymentMethod() {
      return getPaymentMethodSelected(this.order.paymentMethod);
    },
    onCopyOrder() {
      let textarea;
      let result;
      this.smsTextToCopy = this.getGeneratedSMSText();

      try {
        textarea = document.createElement("textarea");
        textarea.setAttribute("readonly", true);
        textarea.setAttribute("contenteditable", true);
        textarea.style.position = "fixed"; // prevent scroll from jumping to the bottom when focus is set.
        textarea.value = this.smsTextToCopy;

        document.body.appendChild(textarea);

        textarea.focus();
        textarea.select();

        const range = document.createRange();
        range.selectNodeContents(textarea);

        const sel = window.getSelection();
        sel.removeAllRanges();
        sel.addRange(range);

        textarea.setSelectionRange(0, textarea.value.length);
        result = document.execCommand("copy");
      } catch (err) {
        console.error(err);
        result = null;
      } finally {
        document.body.removeChild(textarea);
      }

      // manual copy fallback using prompt
      if (!result) {
        const isMac = navigator.platform.toUpperCase().indexOf("MAC") >= 0;
        const copyHotkey = isMac ? "⌘C" : "CTRL+C";
        result = prompt(`Press ${copyHotkey}`, string); // eslint-disable-line no-alert
        if (!result) {
          return false;
        }
      }
      setTimeout(() => {
        EventBus.dispatch("showSnackbar", {
          text: this.$t("notifications.smsCopyConfirmation"),
        });
      }, 1000);
      return true;
    },
    selectAllItems() {
      if (this.allItemsSelected) {
        this.order.products.forEach((f) => {
          this.$set(f, "selected", true);
        });
      } else {
        this.order.products.forEach((f) => {
          this.$set(f, "selected", false);
        });
      }
    },
    allowedDates(val) {
      return val >= this.todaysDate;
    },
  },
};
</script>
<style scoped lang="scss">
.padding-adjust {
  padding: 32px 0 !important;
}
.dialog-style {
  background-color: #ffffff;
  padding: 40px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media screen and (max-width: 959px) {
    height: 100%;
  }
}
.adjust-padding {
  padding: 8px !important;
}
</style>
