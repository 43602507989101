import {
  ADD_SUPPLIER_URL,
  uploadSupplierData,
  addSupplierContact,
  deleteContactSupplierURL,
  editContactSupplierURL,
  editSupplierDataURL,
  addSupplierNotesURL,
  getSuppliersForDepartmentURL, REQUEST_NEW_SUPPLIER_URL
} from "../shared/endpoints";
import api from "./api";

class SupplierService {
  addSupplier(data) {
    return api.post(ADD_SUPPLIER_URL, data);
  }
  uploadSupplierData(data) {
    const headers = {
      "Content-Type":
        "multipart/form-data; boundary=---011000010111000001101001",
    };
    let formData = new FormData();
    formData.append("sheet", data.data);
    return api
      .post(uploadSupplierData(data.id), formData, { headers })
      .then((response) => {
        return response;
      });
  }
  addSupplierContact(id, data) {
    return api.post(addSupplierContact(id), data).then((response) => {
      return response;
    });
  }
  deleteContactSupplier(contact) {
    return api
      .delete(deleteContactSupplierURL(contact.id), contact.supplierId)
      .then((response) => {
        return response;
      });
  }
  editContactSupplier(data) {
    return api
      .post(editContactSupplierURL(data.id), JSON.stringify(data))
      .then((response) => {
        return response;
      });
  }
  editSupplierDataService(supplier) {
    let formData = new FormData();
    formData.append("name", supplier.name);
    formData.append("pib", supplier.pib);
    formData.append("address", supplier.address);
    formData.append("registrationNumber", supplier.registrationNumber);
    formData.append("postNumber", supplier.postNumber);
    formData.append("phone", supplier.phone);
    formData.append("isRestaurant", supplier.isRestaurant);
    formData.append("email", supplier.email);
    formData.append("city", supplier.city);
    formData.append("bankAccountNumber", supplier.bankAccountNumber);

    return api.post(editSupplierDataURL(supplier.id), formData);
  }

  addSupplierNotesService(data) {
    let formData = new FormData();
    formData.append("userId", data.userId);
    formData.append("timeToOrder", data.timeToOrder || "");
    formData.append("timeToDeliver", data.timeToDeliver || "");
    formData.append("daysInWeek", JSON.stringify(data.daysInWeek));
    formData.append("note", data.note || "");
    formData.append("noteId", data.noteId);
    return api.post(addSupplierNotesURL(data.supplierId), formData);
  }

  getSuppliersForDepartment(payload) {
    return api.get(getSuppliersForDepartmentURL(payload.departmentId));
  }
  requestNewSupplierService(data) {
    return api.post(REQUEST_NEW_SUPPLIER_URL, data);
  }
}
export default new SupplierService();
