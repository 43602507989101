import { render, staticRenderFns } from "./CreateEditArticleModal.vue?vue&type=template&id=008df1cc&scoped=true&"
import script from "./CreateEditArticleModal.vue?vue&type=script&lang=js&"
export * from "./CreateEditArticleModal.vue?vue&type=script&lang=js&"
import style0 from "./CreateEditArticleModal.vue?vue&type=style&index=0&id=008df1cc&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "008df1cc",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VImg } from 'vuetify/lib/components/VImg';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VBtn,VDialog,VDivider,VImg,VSelect,VTextField})
