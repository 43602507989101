import { render, staticRenderFns } from "./AssignSupplierCard.vue?vue&type=template&id=847e4c38&scoped=true&"
import script from "./AssignSupplierCard.vue?vue&type=script&lang=js&"
export * from "./AssignSupplierCard.vue?vue&type=script&lang=js&"
import style0 from "./AssignSupplierCard.vue?vue&type=style&index=0&id=847e4c38&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "847e4c38",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VCard } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
installComponents(component, {VAutocomplete,VAvatar,VCard,VChip,VListItemAvatar,VListItemContent,VListItemTitle})
