<template>
  <v-dialog
    v-model="showDialog"
    width="426"
    class="dialog-style"
    persistent
    :fullscreen="getIsMobile"
  >
    <div class="dialog-style">
      <div class="header">
        <v-img
          @click="closeDialog"
          class="close-icon"
          max-width="20px"
          :src="require('../../assets/img/CloseIcon.svg')"
        >
        </v-img>
        <div class="d-flex align-center flex-wrap justify-center">
          <span class="text-h5 primaryA--text font-weight-bold">
            {{ $t("profilePage.editPassword") }}
          </span>
        </div>
      </div>
      <v-card width="100%" elevation="0" class="mt-4">
        <v-text-field
          name="password"
          :label="$t('common.password')"
          id="password"
          :placeholder="$t('common.password')"
          outlined
          color="accent"
          required
          :rules="rules.oldPassword"
          v-model="oldPassword"
          :append-icon="showOldPasswordPreview ? 'mdi-eye' : 'mdi-eye-off'"
          :type="showOldPasswordPreview ? 'text' : 'password'"
          @click:append="showOldPasswordPreview = !showOldPasswordPreview"
        ></v-text-field>
        <v-text-field
          name="newPassword"
          :label="$t('common.newPassword')"
          id="newPassword"
          :placeholder="$t('common.newPassword')"
          outlined
          color="accent"
          required
          :rules="rules.newPassword"
          v-model="newPassword"
          :append-icon="showNewPasswordPreview ? 'mdi-eye' : 'mdi-eye-off'"
          :type="showNewPasswordPreview ? 'text' : 'password'"
          @click:append="showNewPasswordPreview = !showNewPasswordPreview"
        ></v-text-field>
        <v-text-field
          name="repeatNewPassword"
          :label="$t('common.repeatNewPassword')"
          id="repeatNewPassword"
          :placeholder="$t('common.repeatNewPassword')"
          outlined
          color="accent"
          required
          :rules="rules.repeatNewPassword"
          v-model="repeatNewPassword"
          :append-icon="
            showRepeatNewPasswordPreview ? 'mdi-eye' : 'mdi-eye-off'
          "
          :type="showRepeatNewPasswordPreview ? 'text' : 'password'"
          @click:append="
            showRepeatNewPasswordPreview = !showRepeatNewPasswordPreview
          "
        ></v-text-field>
      </v-card>
      <div class="d-flex align-center justify-center width100">
        <v-btn color="tertiaryDark" @click="closeDialog">{{
          $t("common.cancel")
        }}</v-btn>
        <v-btn
          class="ml-2"
          color="accent"
          type="submit"
          @click="updateUserPassword"
          :disabled="isSaveButtonDisabled"
          >{{ $t("profilePage.saveChanges") }}</v-btn
        >
      </div>
    </div>
  </v-dialog>
</template>
<script>
export default {
  name: "ChangePasswordModal",
  props: { dialog: {} },
  data: () => ({
    oldPassword: "",
    newPassword: "",
    repeatNewPassword: "",
    showOldPasswordPreview: false,
    showNewPasswordPreview: false,
    showRepeatNewPasswordPreview: false,
    rules: {},
    showDialog: false,
  }),

  created() {
    this.showDialog = this.dialog;
  },
  mounted() {
    this.rules = {
      oldPassword: [
        (val) => (val || "").length > 0 || this.$t("common.requiredField"),
      ],
      newPassword: [
        (val) => (val || "").length > 0 || this.$t("common.requiredField"),
      ],
      repeatNewPassword: [
        (val) => (val || "").length > 0 || this.$t("common.requiredField"),
      ],
    };
  },
  methods: {
    closeDialog() {
      this.oldPassword = "";
      this.newPassword = "";
      this.repeatNewPassword = "";
      this.rules = [];
      this.$emit("onClose");
    },
    updateUserPassword() {
      this.$emit("onUpdatePassword", {
        oldPassword: this.oldPassword,
        newPassword: this.newPassword,
        repeatNewPassword: this.repeatNewPassword,
      });
    },
  },
  computed: {
    getIsMobile() {
      return this.$isMobile;
    },
    isSaveButtonDisabled() {
      return (
        !this.oldPassword ||
        !this.newPassword ||
        !this.repeatNewPassword ||
        this.newPassword !== this.repeatNewPassword
      );
    },
  },
  watch: {
    newPassword() {
      if (
        this.newPassword &&
        this.repeatNewPassword &&
        this.newPassword !== this.repeatNewPassword
      ) {
        this.rules.newPassword = [""];
        this.rules.repeatNewPassword = [this.$t("common.passwordMissmatch")];
      } else {
        this.rules.newPassword = [];
        this.rules.repeatNewPassword = [];
      }
    },
    repeatNewPassword() {
      if (
        this.newPassword &&
        this.repeatNewPassword &&
        this.newPassword !== this.repeatNewPassword
      ) {
        this.rules.newPassword = [""];
        this.rules.repeatNewPassword = [this.$t("common.passwordMissmatch")];
      } else {
        this.rules.newPassword = [];
        this.rules.repeatNewPassword = [];
      }
    },
    dialog() {
      this.showDialog = this.dialog;
    },
  },
};
</script>
<style scoped lang="scss">
.dialog-style {
  background-color: #ffffff;
  padding: 20px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media screen and (max-width: 959px) {
    height: 100%;
  }
}
.close-icon {
  cursor: pointer;
  position: absolute;
  top: 0px;
  right: 0px;
}
.header {
  position: relative;
  width: 100%;
}
.width100 {
  width: 100%;
}
</style>
