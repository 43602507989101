import InvitesService from "../services/invites.service";
import {
  REQUEST_FAILURE,
  REQUEST_PENDING,
  REQUEST_SUCCESS,
  REQUEST_UNDEFINED,
} from "../shared/statuses";

const initialState = {
  inviteUserStatus: REQUEST_UNDEFINED,
  inviteUserError: null,
  acceptInviteUserstatus: REQUEST_UNDEFINED,
  acceptInviteData: null,
};

export const invites = {
  namespaced: true,
  state: initialState,
  actions: {
    inviteUserToRestaurant({ commit }, data) {
      commit("inviteUserPending");
      return InvitesService.inviteUserToRestaurantService(
        data.email,
        data.restaurantId,
        data.userId
      ).then(
        (response) => {
          commit("inviteUserSuccess");
          return Promise.resolve(response);
        },
        (error) => {
          commit("inviteUserFailure", error.response);
          return Promise.reject(error);
        }
      );
    },
    acceptInvitationRestaurant({ commit }, token) {
      commit("acceptInvitePending");
      return InvitesService.acceptInviteToRestaurantService(token).then(
        (response) => {
          commit("acceptInviteSuccess", response.data);
          return Promise.resolve(response);
        },
        (error) => {
          commit("acceptInviteFailure");
          return Promise.reject(error);
        }
      );
    },
  },
  mutations: {
    inviteUserPending(state) {
      state.inviteUserStatus = REQUEST_PENDING;
    },
    inviteUserSuccess(state) {
      state.inviteUserStatus = REQUEST_SUCCESS;
    },
    inviteUserFailure(state, payload) {
      state.inviteUserError = payload.data;
      state.inviteUserStatus = REQUEST_FAILURE;
    },
    acceptInvitePending(state) {
      state.acceptInviteUserstatus = REQUEST_PENDING;
    },
    acceptInviteSuccess(state, payload) {
      state.acceptInviteUserstatus = REQUEST_SUCCESS;
      state.acceptInviteData = payload;
    },
    acceptInviteFailure(state) {
      state.acceptInviteUserstatus = REQUEST_FAILURE;
    },
  },
  getters: {
    getInviteUserStatus: (state) => {
      return state.inviteUserStatus;
    },
    getInviteUserError: (state) => {
      return state.inviteUserError;
    },
    getAcceptInviteData: (state) => {
      return state.acceptInviteData;
    },
  },
};
