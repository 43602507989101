var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"pa-6 mt-10 mb-10",attrs:{"width":_vm.width,"elevation":"2"}},[_c('div',[_c('div',{staticClass:"d-flex align-center mb-6"},[_c('span',{staticClass:"text-h4"},[_vm._v(_vm._s(_vm.$t("adminRestaurant.stations")))]),_c('div',{staticClass:"add-new-btn ml-4",on:{"click":_vm.onAddNewStation}},[_c('v-img',{attrs:{"max-width":"12","src":require('../../../assets/img/Plus.svg')}})],1)]),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.getAllStations,"sort-by":['name'],"sort-desc":[false, true],"items-per-page":5,"footer-props":{
        itemsPerPageAllText: _vm.$t('common.all'),
        itemsPerPageText: _vm.$t('common.itemsPerPage'),
      }},scopedSlots:_vm._u([{key:"item.users",fn:function(ref){
      var item = ref.item;
return [_c('v-tooltip',{attrs:{"right":"","color":"secondary","open-on-click":"","open-on-hover":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticStyle:{"cursor":"pointer"}},'span',attrs,false),on),[_vm._v(" "+_vm._s(item.users.length))])]}}],null,true)},[_c('div',{staticClass:"d-flex flex-column"},_vm._l((item.users),function(user,index){return _c('span',{key:user.id,staticClass:"primaryB--text font-weight-bold"},[_vm._v(_vm._s(index + 1)+". "+_vm._s(user.name)+" - "+_vm._s(_vm.getUserRole(user.role)))])}),0)])]}},{key:"item.actions",fn:function(ref){
      var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('v-btn',{attrs:{"color":"primary","small":""},on:{"click":function($event){return _vm.handleOnStationDelete(item)}}},[_vm._v(_vm._s(_vm.$t("adminRestaurant.delete")))])],1)]}}])})],1),_c('simple-warning-modal',{attrs:{"dialog":_vm.showDeleteWarningModal,"warningMessage":_vm.warningModalTitle},on:{"onClose":function($event){_vm.showDeleteWarningModal = false},"onConfirm":_vm.onConfirmDeleteStation,"onCancel":function($event){_vm.showDeleteWarningModal = false}}}),_c('modal-component',{attrs:{"dialog":_vm.showAddNewStationModal},on:{"onClose":_vm.closeAddNewStationModal}},[_c('div',[_c('v-card-title',{staticClass:"pa-0"},[_vm._v(_vm._s(_vm.$t("dashboard.creatingNewStation")))]),_c('v-text-field',{attrs:{"name":"newStationName","id":"newStationName","type":"text","color":"accent","requred":"","placeholder":_vm.$t('dashboard.enterNewStationName'),"rules":_vm.rules.stationName},on:{"keyup":_vm.onCheckStationNameUp,"keydown":_vm.onCheckStationNameDown},model:{value:(_vm.newStationName),callback:function ($$v) {_vm.newStationName=$$v},expression:"newStationName"}}),_c('div',{staticClass:"d-flex align-center justify-end"},[_c('v-btn',{on:{"click":_vm.closeAddNewStationModal}},[_vm._v(_vm._s(_vm.$t("dashboard.cancelCreateStation")))]),(_vm.loading)?_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}}):_c('v-btn',{staticClass:"ml-2",attrs:{"disabled":!_vm.newStationName || _vm.sameStationNameViolated,"color":"accent"},on:{"click":_vm.onCreateNewStation}},[_vm._v(_vm._s(_vm.$t("dashboard.saveStation")))])],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }