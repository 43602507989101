<template>
  <modal-component
    :dialog="showDialog"
    @onClose="$emit('onClose')"
    :modalWidth="'540px'"
  >
    <div class="preview-modal" v-if="data">
      <div style="text-align: left">
        <div class="title">
          {{ $t("common.listProducts") }}
        </div>
        <div v-if="isPartiallyDelivered" class="mt-3 mb-3">
          <v-alert text dense color="blue" elevation="0" type="info">{{
            $t("common.partiallyDeliveredNote")
          }}</v-alert>
        </div>
        <div class="text-body-2 mt-3">
          {{ $t("common.orderNumber") }}:
          <strong>{{
            data.order_number && data.order_number.toUpperCase()
          }}</strong>
        </div>
        <div class="text-body-2 mt-3">
          {{ $t("common.supplier") }}:
          <strong>{{
            isCustomOrder ? $t("articles.market") : data.supplier.name
          }}</strong>
        </div>
        <div class="text-body-2">
          {{ $t("common.dateForDelivering") }}:
          <strong>{{ getFormattedDate(data.deadline) }}</strong>
        </div>
        <div class="text-body-2 mt-1 d-flex">
          {{ $t("common.orderStatus") }}:
          <span
            class="status-tag"
            :style="{
              background: getOrderStatusColor(data && data.status),
            }"
          >
            <strong>{{ getOrderStatusText(data && data.status) }}</strong>
          </span>
        </div>
      </div>

      <v-simple-table class="mt-3">
        <template v-slot:default>
          <thead>
            <tr>
              <th>{{ $t("common.name") }}</th>
              <th>{{ $t("common.qty") }}</th>
              <th v-if="data.status !== 'OPEN'">
                {{ $t("common.statusItem") }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="item in data.products"
              :key="item.id"
              style="text-align: left;"
            >
              <td v-if="item.note || item.receiveNote" class="remove-padding">
                <div class="d-flex flex-column align-start">
                  <span>{{ item.name }}</span>
                  <span v-if="item.note" class="text-caption secondaryDark2--text"
                    >{{ $t("currentOrders.orderNote") }}: {{ item.note }}</span
                  >
                  <span v-if="item.receiveNote" class="text-caption secondaryDark2--text"
                    >{{ $t("currentOrders.receiveNote") }}: {{ item.receiveNote }}</span
                  >
                </div>
              </td>
              <td v-else>{{ item.name }}</td>
              <td>
                <span class="text-caption" v-if="item.deliveredAmount"
                  >x {{ item.deliveredAmount }} ({{
                    $t("currentOrders.partiallyOrdered", { num: item.amount })
                  }})</span
                >
                <span v-else>x {{ item.amount }} </span>
              </td>
              <td v-if="data.status !== 'OPEN'">
                <span
                  :style="{
                    color: getItemColor(item.status),
                  }"
                >
                  {{ getItemStatusText(item.status) }}
                </span>
                <span
                  v-if="
                    item.notDeliveredReason === 'ARRIVE_LATER' &&
                    item.status !== 'DELIVERED'
                  "
                  :style="{
                    color: getItemColor(item.status),
                  }"
                >
                  ({{ $t("receiveOrderDialog.arriveLater") }})
                </span>
                <span
                  v-if="item.move_to_order_id"
                  @click="goToOrder(item.move_to_order_id)"
                  class="cursor-pointer font-weight-bold text-decoration-underline"
                  :style="{
                    color: getItemColor(item.status),
                  }"
                  >#{{
                    item.move_to_order_id.toUpperCase().slice(0, 10) + "..."
                  }}</span
                >
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      <v-divider></v-divider>
      <span v-if="data.orderNote" class="d-flex justify-start text-body-2 mt-4"
        >{{ $t("currentOrders.orderNote") }}:
        <span class="ml-1 order-created-date-text"
          ><strong>{{ data.orderNote }}</strong></span
        >
      </span>
      <span v-if="data.receivingNote" class="d-flex justify-start text-body-2 mt-4"
        >{{ $t("currentOrders.receiveNote") }}:
        <span class="ml-1 order-created-date-text"
          ><strong>{{ data.receivingNote }}</strong></span
        >
      </span>
      <span
        v-if="!data.isCustomOrder"
        class="d-flex justify-start text-body-2 mt-3"
        >{{ $t("newOrder.paymentMetod") }}:
        <span class="ml-1 order-created-date-text"
          ><strong>{{ getPaymentMethodText(data.paymentMethod) }}</strong></span
        >
      </span>
      <div
        class="mt-4 d-flex flex-column align-start"
        style="text-align: left"
        v-if="data"
      >
        <span class="order-created-text text-body-2"
          >{{ $t("dashboard.createdBy") }}
          <span class="order-created-date-text"
            ><strong>{{ data.createdBy.name }}</strong> -
            <span>{{ data.createdAt | moment("DD.MM.YYYY") }}</span> u
            <span>{{ data.createdAt | moment("HH:mm") }}</span></span
          >
        </span>
        <span class="order-created-text text-body-2"
          >{{
            $t(
              data.status === "COMPLETED"
                ? "dashboard.receivedBy"
                : "dashboard.lastUpdatedBy"
            )
          }}
          <span class="order-created-date-text"
            ><strong>{{ data.lastUpdatedBy.name }}</strong> -
            <span>{{ data.lastUpdatedAt | moment("DD.MM.YYYY") }}</span> u
            <span>{{ data.lastUpdatedAt | moment("HH:mm") }}</span></span
          >
        </span>
      </div>
    </div>
  </modal-component>
</template>
<script>
import ModalComponent from "../components/ModalComponent.vue";

// helpers
import { formatDate, getPaymentMethodSelected } from "../helpers/helper";
import { orderStatuses } from "../shared/statuses";
export default {
  name: "PreviewOrderModal",
  components: { ModalComponent },
  props: {
    showDialog: {},
    data: {},
    isCustomOrder: {},
  },
  methods: {
    getItemStatusText(status) {
      switch (status) {
        case orderStatuses.PENDING:
          return this.$t("common.statusInProgress");
        case orderStatuses.DELIVERED:
          return this.$t("common.statusCompleted");
        case orderStatuses.NOT_DELIVERED:
          return this.$t("common.statusNotDelivered");
        case orderStatuses.INCOMPLETE:
          return this.$t("common.statusIncomplete");
        case orderStatuses.MOVE_TO_NEW:
          return this.$t("common.statusMoveToNew");
      }
    },
    getOrderStatusText(status) {
      switch (status) {
        case orderStatuses.IN_PROGRESS:
          return this.$t("common.statusInProgress");
        case orderStatuses.COMPLETED:
          return this.$t("common.statusCompleted");
        case orderStatuses.OPEN:
          return this.$t("common.statusOpen");
        case orderStatuses.INCOMPLETE:
          return this.$t("common.statusIncomplete");
        case orderStatuses.MOVE_TO_NEW:
          return this.$t("common.statusMoveToNew");
      }
    },
    getItemColor(status) {
      switch (status) {
        case orderStatuses.PENDING:
        case orderStatuses.INCOMPLETE:
        case orderStatuses.MOVE_TO_NEW:
          return "#276EF1";
        case orderStatuses.DELIVERED:
          return "#00A991";
        case orderStatuses.NOT_DELIVERED:
          return "#FD4A34";
      }
    },
    getOrderStatusColor(status) {
      switch (status) {
        case orderStatuses.IN_PROGRESS:
          return "#276EF1";
        case orderStatuses.COMPLETED:
          return "#00A991";
        case orderStatuses.OPEN:
          return "#FD4A34";
      }
    },
    getPaymentMethodText(payment) {
      return getPaymentMethodSelected(payment);
      // switch (payment) {
      //   case "cash":
      //     return this.$t("common.cash");
      //   case "wire_transfer":
      //     return this.$t("common.wire_transfer");
      // }
    },
    getFormattedDate(date) {
      return formatDate(date);
    },
    goToOrder(newOrderNumber) {
      this.$emit("onPreviewNewOrder", newOrderNumber);
    },
  },
  computed: {
    isPartiallyDelivered() {
      return this.data.products.some((item) => item.deliveredAmount);
    },
  },
};
</script>
<style lang="scss" scoped>
.preview-modal {
  // display: flex;
  // flex-direction: column;
  // width: 100%;
  // align-items: flex-start;
  width: 100%;
}
.article-list {
  // text-align: start;
  // width: 100%;
}
.article-item {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.order-created-text {
  color: #181818;
}
.order-created-date-text {
  color: #a6a6a6;
}
.status-tag {
  padding: 6px 8px;
  border-radius: 8px;
  color: #ffffff;
}
.cursor-pointer {
  cursor: pointer;
}
.remove-padding {
  padding: 8px 0!important;
}
</style>
