<template>
  <div>
    <div class="header-title" :class="[$isMobile ? 'mt-2' : 'mt-10']">
      <span
        class="text-h5 primaryA--text font-weight-bold"
        :class="{ 'text-h6': $isMobile }"
        >{{
          $t(isEdit ? "editOrders.sendEditedOrder" : "newOrder.sendOrderTitle")
        }}</span
      >
    </div>
    <div class="content-wrap mt-8">
      <div class="adjust-max-height">
        <div
          class="d-flex flex-wrap justify-space-between disabled-input-fields"
        >
          <v-text-field
            class="text-input-width mr-1"
            outlined
            id="supplier"
            name="supplier"
            type="text"
            :value="order.supplier.name"
            readonly
            :label="$t('common.supplier')"
          ></v-text-field>
          <v-text-field
            class="text-input-width mr-1"
            outlined
            id="date"
            name="date"
            type="text"
            :value="getFormattedDate(order.date)"
            readonly
            :label="$t('common.date')"
          ></v-text-field>

          <v-select
            solo
            v-if="!selectedSupplier.isMarket"
            class="text-input-width select-contact"
            :items="getAllContactsForSupplier"
            v-model="selectedSupplierId"
            :label="$t('common.contact')"
            item-text="name"
            item-value="id"
            outlined
            required
            :rules="rules.phone"
            :hint="`${
              getSelectedSupplierPhone !== ''
                ? 'Tel: ' + getSelectedSupplierPhone
                : ''
            }${
              getSelectedSupplierPhone &&
              selectedSupplierEmail &&
              selectedSupplierEmail.email
                ? ', '
                : ''
            }${
              selectedSupplierEmail && selectedSupplierEmail.email
                ? 'Email: ' + selectedSupplierEmail.email
                : ''
            } `"
            persistent-hint
            :no-data-text="$t('newOrder.noContactsAdded')"
          >
            <template v-slot:append-item>
              <div class="d-flex justify-center align-center">
                <v-btn
                  class="btn-lowercase"
                  text
                  medium
                  @click="handleAddContact"
                  >{{ $t("common.addContact") }}</v-btn
                >
              </div>
            </template>
          </v-select>
        </div>
        <div>
          <v-textarea
            id="smsText"
            no-resize
            readonly
            filled
            name="text-msg"
            :label="$t('newOrder.textMsg')"
            :value="getGeneratedSMSText"
          ></v-textarea>
        </div>
        <div v-if="!selectedSupplier.isMarket">
          <span class="text-subtitle-1 primaryA--text font-weight-bold"
            >{{ $t("newOrder.paymentMetod") }}:</span
          >
          <div class="d-flex align-center">
            <v-radio-group row v-model="paymentMethodSelected">
              <v-radio
                v-for="n in paymentMethods"
                :key="n.id"
                :label="n.text"
                :value="n.value"
              ></v-radio>
            </v-radio-group>
            <v-btn
              :disabled="paymentMethodSelected.length === 0"
              @click="paymentMethodSelected = []"
              >{{ $t("newOrder.clear") }}</v-btn
            >
          </div>
        </div>
        <div>
          <v-textarea
            id="orderNote"
            no-resize
            filled
            name="text-msg"
            :label="
              selectedSupplier.isMarket
                ? $t('newOrder.orderNoteLabelWarehouse')
                : $t('newOrder.orderNoteLabel')
            "
            v-model="orderNote"
          ></v-textarea>
        </div>

        <div v-if="selectedSupplier.isMarket">
          <div class="header-title">
            <span class="text-h5 primaryA--text font-weight-bold">{{
              $t("newOrder.concludeOrder")
            }}</span>
          </div>
          <div class="mt-3 mb-3">
            <v-banner
              color="warning"
              icon="mdi-information-outline"
              elevation="6"
              rounded
              icon-color="primaryB"
            >
              <div class="d-flex align-center">
                <span class="banner-text primaryLight--text font-weight-medium">
                  {{ $t("newOrder.marketOrderSendingHint") }}
                </span>
              </div>
            </v-banner>
          </div>
          <div class="mt-5">
            <div class="d-flex justify-center align-center button-actions">
              <v-btn
                large
                class="button-width-transform mr-2"
                color="accent"
                @click="onConcludeMarketOrder"
                >{{ $t("newOrder.conclude") }}</v-btn
              >
              <div
                class="d-flex align-center pa-4 contact-box-market-order"
                @click="copyTextForSMS()"
              >
                <v-img
                  class="contact-icon"
                  max-width="18"
                  :src="require('../../assets/img/social/CopyIcon.svg')"
                ></v-img>
                <span class="subtitle-1-text primaryA--text font-weight-bold">{{
                  $t("newOrder.copyTextMsg")
                }}</span>
              </div>
            </div>
          </div>
        </div>

        <div v-else>
          <div class="header-title">
            <span class="text-h5 primaryA--text font-weight-bold">{{
              $t("newOrder.sendOrderTo")
            }}</span>
          </div>
          <div class="mt-3 mb-3">
            <v-banner
              v-if="!isBasicPlan"
              color="warning"
              icon="mdi-information-outline"
              elevation="6"
              rounded
              icon-color="primaryB"
            >
              <div class="d-flex align-center">
                <span class="banner-text primaryLight--text font-weight-medium">
                  {{ $t("newOrder.sendingHint") }}
                </span>
              </div>
            </v-banner>
            <v-banner
              v-else
              color="warning"
              icon="mdi-information-outline"
              elevation="2"
              rounded
              icon-color="primaryB"
            >
              <div class="d-flex align-center">
                <span class="banner-text primaryLight--text font-weight-medium">
                  {{ $t("subscriptions.whatsAppViberProPackageConstraint") }}
                </span>
              </div>
            </v-banner>
          </div>
          <div class="mt-5">
            <div class="d-flex justify-space-between align-center">
              <div
                :class="{ disabledSend: !getSupplierPhone || isBasicPlan }"
                class="d-flex align-center pa-4 margin-right-adjust contact-box"
                @click="sendVia('WhatsApp')"
              >
                <v-img
                  class="contact-icon"
                  max-width="28"
                  :src="require('../../assets/img/social/WhatsAppIcon.svg')"
                ></v-img>
                <span class="subtitle-1-text primaryA--text font-weight-bold"
                  >WhatsApp</span
                >
              </div>

              <div
                :class="{ disabledSend: !getSupplierPhone || isBasicPlan }"
                class="d-flex align-center pa-4 contact-box"
                @click="sendVia('Viber')"
              >
                <v-img
                  class="contact-icon"
                  max-width="28"
                  :src="require('../../assets/img/social/ViberIcon.svg')"
                ></v-img>
                <span class="subtitle-1-text primaryA--text font-weight-bold"
                  >Viber</span
                >
              </div>
            </div>
            <div
              class="d-flex justify-space-between align-center margin-top-adjust"
            >
              <div
                :class="{ disabledSend: !getSupplierPhone }"
                class="d-flex align-center pa-4 margin-right-adjust contact-box"
                @click="sendVia('SMS')"
              >
                <v-img
                  class="contact-icon"
                  max-width="28"
                  :src="require('../../assets/img/social/SmsIcon.svg')"
                ></v-img>
                <span class="subtitle-1-text primaryA--text font-weight-bold"
                  >SMS</span
                >
              </div>
              <div
                :class="{
                  disabledSend:
                    (selectedSupplierEmail && !selectedSupplierEmail.email) ||
                    !selectedSupplierEmail,
                }"
                class="d-flex align-center pa-4 contact-box"
                @click="sendVia('Email')"
              >
                <v-img
                  class="contact-icon"
                  max-width="28"
                  :src="require('../../assets/img/social/EmailIcon.svg')"
                ></v-img>
                <span class="subtitle-1-text primaryA--text font-weight-bold"
                  >Email</span
                >
              </div>
            </div>
            <div
              v-if="getIsMobile"
              :class="{
                disabledSend:
                  selectedSupplierPhone && !selectedSupplierPhone.phone,
              }"
              class="d-flex align-center pa-4 mr-8 margin-top-adjust contact-box"
              @click="sendVia('Phone')"
            >
              <v-icon>mdi-phone</v-icon>
              <span class="subtitle-1-text primaryA--text font-weight-bold"
                >Pozovi</span
              >
            </div>
            <div
              class="d-flex align-center pa-4 mr-8 margin-top-adjust contact-box"
              @click="copyTextForSMS()"
            >
              <v-img
                class="contact-icon"
                max-width="18"
                :src="require('../../assets/img/social/CopyIcon.svg')"
              ></v-img>
              <span class="subtitle-1-text primaryA--text font-weight-bold">{{
                $t("newOrder.copyTextMsg")
              }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="button-actions d-flex align-center justify-center mt-8">
        <v-btn
          class="button-width-transform mr-2 archiveBtn"
          color="primaryB"
          @click="onSaveClick"
          >{{ $t("newOrder.save") }}</v-btn
        >
        <v-btn
          class="button-width-transform cancelBtn"
          color="primaryB"
          @click="onCancelOrder"
          >{{
            $t(isEdit ? "editOrders.cancel" : "newOrder.cancelOrder")
          }}</v-btn
        >
      </div>
    </div>
    <warning-modal
      :dialog="showWarningModal"
      @onClose="handleOnClose"
      :warninModalType="warninModalType"
      :departmentID="depID"
    ></warning-modal>
    <modal-component
      :dialog="showConfirmSendingDialog"
      @onClose="handleCloseConfirmSendingDialog"
    >
      <div class="modal-confirm-content mt-6">
        <span
          class="modal-confirm-content-title text-h6 primaryA--text font-weight-black"
        >
          {{ $t("confirmModal.areYouSure") }}
        </span>
        <span
          class="modal-confirm-content-subtitle text-subtitle-2 primaryDark--text"
        >
          {{ $t("confirmModal.areYouSureSubtitle") }}
        </span>
        <div class="mt-8 d-flex align-center flex-wrap justify-center">
          <v-btn
            class="remove-uppercase"
            :width="getIsMobile ? '100%' : 'auto'"
            color="accent"
            @click="onConfirmSend"
            >{{ $t("confirmModal.send") }}</v-btn
          >
          <v-btn
            :width="getIsMobile ? '100%' : 'auto'"
            class="ml-5 remove-uppercase margin-on-mobile"
            color="tertiaryDark"
            :class="{
              'margin-adjust-button': getIsMobile,
            }"
            @click="onCancelSend"
            >{{ $t("confirmModal.cancel") }}</v-btn
          >
        </div>
      </div>
    </modal-component>
    <add-supplier-contact-modal
      :dialog="showAddSupplierContactModal"
      @onClose="handleCloseAddSupplierContactModal"
      :selectedSupplier="selectedSupplier"
      @onAddedSupplierContact="handleAddedSupplierContact"
    ></add-supplier-contact-modal>
  </div>
</template>

<script>
//components
import WarningModal from "./WarningModal.vue";
import ModalComponent from "../../components/ModalComponent.vue";
import AddSupplierContactModal from "../../components/AddSupplierContactModal.vue";
//vuex
import { mapGetters } from "vuex";
// event bus
import EventBus from "../../shared/EventBus";
// services
import LocalStorageService from "../../services/local-storage.service";
import moment from "moment";
import { generateSMSText, generateEmailText } from "../../helpers/helper";
import { orderStatuses } from "../../shared/statuses";

export default {
  name: "StepThreePreview",
  props: {
    step: {},
    order: {},
    isEdit: {},
    orderStatus: {},
    orderID: {},
    allSteps: {},
    suppliers: {},
    user: {},
    station: {},
  },
  components: {
    WarningModal,
    ModalComponent,
    AddSupplierContactModal,
  },
  data() {
    return {
      showWarningModal: false,
      showConfirmSendingDialog: false,
      warninModalType: "",
      selectedType: null,
      company: {},
      selectedSupplierId: null,
      selectedSupplierPhone: null,
      selectedSupplierEmail: null,
      rules: {
        phone: [
          (val) => (val || "").length > 0 || this.$t("common.requiredField"),
        ],
      },
      showAddSupplierContactModal: false,
      selectedSupplier: {},
      depID: null,
      isOrderChanged: false,
      paymentMethods: [],
      paymentMethodSelected: "",
      orderNote: "",
      mergingInProgressOrder: false,
    };
  },
  created() {
    let dataChanged = LocalStorageService.getLocalStorageData("orderChanged");
    this.isOrderChanged = dataChanged;
    this.company = this.venue;
    const data = LocalStorageService.getLocalStorageData("mergeOrderData");
    if (this.isEdit && data && this.orderStatus === orderStatuses.IN_PROGRESS) {
      this.mergingInProgressOrder = true;
    }
  },
  mounted() {
    var linkElement = document.createElement("a");
    linkElement.id = "link";
    window.document.body.appendChild(linkElement);

    this.selectedSupplierPhone = "";
    this.selectedSupplierId = null;
    this.selectedSupplier = this.order.supplier;

    if (!this.selectedSupplier.isMarket) {
      this.getAllSupplierContacts();
    }

    this.paymentMethodSelected = this.order.paymentMethod || "";
    const data = LocalStorageService.getLocalStorageData("newOrderDraft");
    if (data?.orderObj) {
      this.paymentMethodSelected = data.orderObj.paymentMethod ?? "";
      this.orderNote = data.orderObj.orderNote ?? "";
    }
    this.orderNote = this.order.orderNote || "";
    this.setLocalStorage();
    this.paymentMethods = [
      { id: 0, value: "cash", text: this.$t("newOrder.paymentCash") },
      {
        id: 1,
        value: "wire_transfer",
        text: this.$t("newOrder.paymentVirman"),
      },
      {
        id: 2,
        value: "credit_card",
        text: this.$t("newOrder.paymentCC"),
      },
      {
        id: 3,
        value: "revers",
        text: this.$t("newOrder.paymentRevers"),
      },
      {
        id: 4,
        value: "accounting",
        text: this.$t("newOrder.accounting"),
      },
    ];
    this.$forceUpdate();
    window.scrollTo(0, 0);
  },
  methods: {
    getAllSupplierContacts() {
      if (this.venue && this.venue.id) {
        this.$store.dispatch("userProfile/getAllSupplierContacts", {
          supplierId: this.selectedSupplier.id,
          venueId: this.venue.id,
        });
      } else {
        this.$store.dispatch("userProfile/getUserProfile").then(() => {
          this.$store.dispatch("userProfile/getAllSupplierContacts", {
            supplierId: this.selectedSupplier.id,
            venueId: this.venue.id,
          });
        });
      }
    },
    onBackClick() {
      this.$emit("onStepChange", this.step - 1, true);
    },
    onSaveClick() {
      this.$emit("onOrderUpdate", {
        orderNote: this.orderNote,
        paymentMethod: this.paymentMethodSelected,
      });
      this.$emit("onSaveOrder");
    },
    onCancelOrder() {
      this.warninModalType = "";
      this.depID = this.$route.params && this.$route.params.departmentID;
      this.showWarningModal = true;
    },
    handleOnClose() {
      this.showWarningModal = false;
    },
    handleCloseConfirmSendingDialog() {
      this.showConfirmSendingDialog = false;
    },
    copyTextForSMS() {
      let textarea;
      let result;
      this.smsTextToCopy = this.getGeneratedSMSText;

      try {
        textarea = document.createElement("textarea");
        textarea.setAttribute("readonly", true);
        textarea.setAttribute("contenteditable", true);
        textarea.style.position = "fixed"; // prevent scroll from jumping to the bottom when focus is set.
        textarea.value = this.smsTextToCopy;

        document.body.appendChild(textarea);

        textarea.focus();
        textarea.select();

        const range = document.createRange();
        range.selectNodeContents(textarea);

        const sel = window.getSelection();
        sel.removeAllRanges();
        sel.addRange(range);

        textarea.setSelectionRange(0, textarea.value.length);
        result = document.execCommand("copy");
      } catch (err) {
        console.error(err);
        result = null;
      } finally {
        document.body.removeChild(textarea);
      }

      // manual copy fallback using prompt
      if (!result) {
        const isMac = navigator.platform.toUpperCase().indexOf("MAC") >= 0;
        const copyHotkey = isMac ? "⌘C" : "CTRL+C";
        result = prompt(`Press ${copyHotkey}`, this.smsTextToCopy); // eslint-disable-line no-alert
        if (!result) {
          return false;
        }
      }
      setTimeout(() => {
        EventBus.dispatch("showSnackbar", {
          text: this.$t("notifications.smsCopyConfirmation"),
        });
      }, 1000);
      return true;
    },
    onConfirmSend() {
      const departmentID =
        this.$route.params && this.$route.params.departmentID;
      this.showConfirmSendingDialog = false;
      LocalStorageService.deleteLocalStorageItem("newOrderDraft");
      LocalStorageService.deleteLocalStorageItem("orderChanged");
      this.order.orderNote = this.orderNote;
      this.order.paymentMethod = this.paymentMethodSelected;
      const utcOrderDate = new moment.utc(this.order.date).format();
      this.order.date = utcOrderDate;
      this.order.articles.forEach((article) => {
        article.amount = parseFloat(article.amount);
      });
      this.$set(this.order, "orderNote", this.orderNote);

      const viberLink = `viber://chat?number=${this.getSelectedSupplierPhone}&text=${this.getGeneratedSMSText}`;
      const smsLink = `sms:${this.getSelectedSupplierPhone}`;
      const emailLink = `mailto:${this.selectedSupplierEmail.email}?subject=${
        "Porudzbina za " + this.venue.name
      }&body=${this.getGeneratedSMSTextEmail}`;
      const phoneLink = "tel:" + this.getSelectedSupplierPhone;
      const whatsAppLink = `https://wa.me/${this.$t(
        "common.phoneCountryPrefix"
      )}${this.getSelectedSupplierPhone}`;
      const link = document.getElementById("link");

      this.isEdit
        ? this.$store
            .dispatch(
              this.order.supplier.isMarket
                ? "orders/updateCustomOrder"
                : "orders/updateOrder",
              {
                orderId: this.orderID,
                order: this.order,
              }
            )
            .then(
              (response) => {
                // console.log("response", response);
                this.$store
                  .dispatch("orders/sendOrder", {
                    id: response.data && response.data.id,
                    supplierContactNumber: this.getSupplierPhone,
                  })
                  .then(() => {
                    this.$router.push("/");
                  });
              },
              (error) => {
                console.log("error", error);
              }
            )
        : this.$store
            .dispatch(
              this.isSupplierMarket
                ? "orders/createCustomOrder"
                : "orders/createNewOrder",
              {
                departmentID: departmentID,
                order: this.order,
              }
            )
            .then(
              (response) => {
                // console.log("response", response);
                this.$store
                  .dispatch("orders/sendOrder", {
                    id: response.data && response.data.id,
                    supplierContactNumber: this.getSupplierPhone,
                  })
                  .then(() => {
                    this.$router.push("/");
                  });
              },
              (error) => {
                console.log("error", error);
              }
            );
      switch (this.selectedType) {
        case "WhatsApp":
          link.setAttribute("href", whatsAppLink);
          link.click();
          break;
        case "Viber":
          link.setAttribute("href", viberLink);
          link.click();
          break;
        case "SMS":
          link.setAttribute("href", smsLink);
          link.click();
          break;
        case "Email":
          link.setAttribute("href", emailLink);
          link.click();
          break;
        case "Phone":
          link.setAttribute("href", phoneLink);
          link.click();
          break;
      }
    },
    onCancelSend() {
      this.selectedType = null;
      this.showConfirmSendingDialog = false;
    },
    sendVia(type) {
      this.copyTextForSMS();
      this.selectedType = type;
      this.showConfirmSendingDialog = true;
    },
    handleAddContact() {
      this.showAddSupplierContactModal = true;
    },
    handleCloseAddSupplierContactModal() {
      this.showAddSupplierContactModal = false;
    },
    handleAddedSupplierContact() {
      this.$store.dispatch("userProfile/getAllSupplierContacts", {
        supplierId: this.selectedSupplier.id,
        venueId: this.venue.id,
      });
      this.showAddSupplierContactModal = false;
      EventBus.dispatch("showSnackbar", {
        text: this.$t("notifications.addedContactSupplier"),
      });
    },
    setLocalStorage() {
      let data = LocalStorageService.getLocalStorageData("newOrderDraft");
      data.allSteps = this.allSteps;
      data.currentStep = this.step;
      data.orderObj.orderNote = data.orderObj.orderNote ?? this.orderNote;
      data.orderObj.paymentMethod =
        data.orderObj.paymentMethod ?? this.paymentMethodSelected;
      LocalStorageService.setLocalStorageData("newOrderDraft", data);
    },
    getFormattedDate(value) {
      return moment(value).format("DD.MM.YYYY");
    },
    getPhoneFormatted(user) {
      return user && user.phone && user.phone[0] === "0"
        ? user.phone
        : "0" + user.phone;
    },
    onConcludeMarketOrder() {
      const departmentID =
        this.$route.params && this.$route.params.departmentID;
      this.$set(this.order, "orderNote", this.orderNote);
      this.isEdit
        ? this.$store
            .dispatch("orders/updateCustomOrder", {
              orderId: this.orderID,
              order: this.order,
            })
            .then(
              (response) => {
                // console.log("response", response);
                this.$store
                  .dispatch(
                    this.order.supplier.isMarket
                      ? "orders/sendCustomOrder"
                      : "orders/sendOrder",
                    response.data && response.data.id
                  )
                  .then(() => {
                    this.$router.push(
                      `/current-orders/${this.$route.params.departmentID}/custom-orders`
                    );
                  });
              },
              (error) => {
                console.log("error", error);
              }
            )
        : this.$store
            .dispatch("orders/createCustomOrder", {
              departmentID: departmentID,
              order: this.order,
            })
            .then(
              (response) => {
                // console.log("response", response);
                const payload = !this.isSupplierMarket
                  ? {
                      id: response.data && response.data.id,
                      supplierContactNumber: this.getSupplierPhone,
                    }
                  : response.data && response.data.id;
                this.$store
                  .dispatch(
                    this.order.supplier.isMarket
                      ? "orders/sendCustomOrder"
                      : "orders/sendOrder",
                    payload
                  )
                  .then(() => {
                    // this.$analytics.logEvent("new_order_created", {
                    //   venue_name: this.venue.name,
                    // });
                    this.$router.push(
                      this.$router.push(
                        `/current-orders/${this.$route.params.departmentID}/custom-orders`
                      )
                    );
                  });
              },
              (error) => {
                console.log("error", error);
              }
            );
    },
  },
  computed: {
    ...mapGetters({
      sendOrderStatus: "orders/getSendOrderStatus",
      createOrderStatus: "orders/getCreateOrderStatus",
      allSupplierContacts: "userProfile/allSupplierContacts",
      getVenue: "userProfile/getVenue",
    }),
    venue() {
      return this.getVenue;
    },
    getSendOrderStatus() {
      return this.sendOrderStatus;
    },
    getCreateOrderStatus() {
      return this.createOrderStatus;
    },
    getGeneratedSMSText() {
      const dataForMerge =
        LocalStorageService.getLocalStorageData("mergeOrderData");

      return (
        this.order &&
        this.order.articles &&
        generateSMSText(
          this.order.articles,
          this.venue.bussiness_name,
          this.venue.address,
          this.getFormattedDate(this.order.date),
          this.orderNote,
          this.order.supplier.isMarket ? "" : this.paymentMethodSelected,
          this.isEdit &&
            this.isOrderChanged &&
            this.orderStatus !== orderStatuses.OPEN,
          null,
          this.mergingInProgressOrder,
          dataForMerge,
          this.order.supplier.isMarket,
          this.station
        )
      );
    },
    getGeneratedSMSTextEmail() {
      const dataForMerge =
        LocalStorageService.getLocalStorageData("mergeOrderData");

      return (
        this.order &&
        this.order.articles &&
        generateEmailText(
          this.order.articles,
          this.venue.bussiness_name,
          this.venue.address,
          this.getFormattedDate(this.order.date),
          this.orderNote,
          this.order.supplier.isMarket ? "" : this.paymentMethodSelected,
          this.isEdit &&
            this.isOrderChanged &&
            this.orderStatus !== orderStatuses.OPEN,
          this.mergingInProgressOrder,
          dataForMerge,
          this.order.supplier.isMarket,
          this.station
        )
      );
    },
    getIsMobile() {
      return this.$isMobile;
    },
    getSelectedSupplierPhone() {
      let supplierContactPhone = this.getAllContactsForSupplier?.filter(
        (contact) => contact.id === this.selectedSupplierId
      );
      if (
        supplierContactPhone[0]?.phone !== "" &&
        supplierContactPhone[0]?.phone !== undefined
      ) {
        let phone =
          supplierContactPhone && supplierContactPhone[0]?.phone[0] === "0"
            ? supplierContactPhone[0]?.phone
            : "0" + supplierContactPhone[0]?.phone;
        return phone;
      } else {
        return "";
      }
    },
    getAllContactsForSupplier() {
      return this.allSupplierContacts;
    },
    getSupplierPhone() {
      return this.selectedSupplierPhone && this.selectedSupplierPhone.phone;
    },
    isBasicPlan() {
      return this.$attrs.isBasicPlan;
    },
    isSupplierMarket() {
      return this.order.supplier.isMarket;
    },
  },
  watch: {
    selectedSupplierId() {
      if (!this.selectedSupplierId) {
        this.rules.phone = [
          (val) => (val || "").length > 0 || this.$t("common.requiredField"),
        ];
      } else {
        this.rules.phone = [];
      }
      if (this.getAllContactsForSupplier) {
        this.selectedSupplierEmail = this.getAllContactsForSupplier.filter(
          (f) => f.id === this.selectedSupplierId
        )[0];
        this.selectedSupplierPhone = this.getAllContactsForSupplier.filter(
          (f) => f.id === this.selectedSupplierId
        )[0];
      }
    },
    orderNote() {
      // this.setLocalStorage();
      const data = LocalStorageService.getLocalStorageData("newOrderDraft");
      data.orderObj.orderNote = this.orderNote;
      LocalStorageService.setLocalStorageData("newOrderDraft", data);
    },
    paymentMethodSelected() {
      const data = LocalStorageService.getLocalStorageData("newOrderDraft");
      data.orderObj.paymentMethod = this.paymentMethodSelected;
      LocalStorageService.setLocalStorageData("newOrderDraft", data);

      // if (data) {
      //   if (data.orderObj?.orderNote) {
      //     this.orderNote = data.orderObj?.orderNote;
      //   }
      //   if (data.orderObj?.paymentMethod) {
      //     this.paymentMethodSelected = data.orderObj?.paymentMethod;
      //   }

      //   this.setLocalStorage(
      //     data.orderObj?.orderNote,
      //     data.orderObj?.paymentMethod
      //   );
      // } else {
      //   this.setLocalStorage();
      // }
      // this.setLocalStorage();
    },
  },
};
</script>
<style lang="scss" scoped>
.header-title {
  width: 100%;
  text-align: center;
}
.content-wrap {
  @media screen and (min-width: 960px) {
    max-width: 33vw;
    margin: 0 auto;
  }
}
.text-input-width {
  width: 178px;
}
// .select-contact {
//   .v-input__slot {
//     border: 2px solid #276ef1;
//   }
// }
// .select-contact.theme--light.v-text-field.v-text-field--is-booted.v-text-field--enclosed.v-text-field--outlined.v-select
//   > div
//   > div.v-input__slot {
//   border: 2px solid #276ef1 !important;
// }
.contact-box {
  width: 100%;
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 2px;
  background: #ffffff;
  height: 60px;
  cursor: pointer;
  position: relative;
  justify-content: center;
  @media screen and (max-width: 959px) {
    flex-direction: column;
  }
  &:hover {
    background: #f6f7f8;
  }
}
.contact-box-market-order {
  width: 50%;
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 2px;
  background: #ffffff;
  height: 50px;
  cursor: pointer;
  position: relative;
  justify-content: center;
  @media screen and (max-width: 959px) {
    // flex-direction: column;
    width: 100%;
    margin-top: 10px;
  }
  &:hover {
    background: #f6f7f8;
  }
}
.contact-icon {
  @media screen and (max-width: 959px) {
    max-width: 20px !important;
  }
  @media screen and (min-width: 960px) {
    position: absolute;
    top: 16px;
    left: 16px;
  }
}
.margin-top-adjust {
  margin-top: 32px;
  @media screen and (max-width: 959px) {
    margin-top: 10px !important;
  }
}
.margin-right-adjust {
  margin-right: 32px;
  @media screen and (max-width: 959px) {
    margin-right: 10px !important;
  }
}
.button-actions {
  @media screen and (max-width: 959px) {
    flex-wrap: wrap;
  }
}
.button-width-transform {
  text-transform: none !important;
  @media screen and (max-width: 959px) {
    width: 100% !important;
    margin-right: 0 !important;
    margin-top: 8px;
  }
}
.archiveBtn {
  min-height: 50px;
}
.cancelBtn {
  min-height: 50px;
}
.backBtn {
  min-height: 50px;
}
.modal-confirm-content {
}
.modal-confirm-content-title {
  display: block;
  width: 100%;
}
.modal-confirm-content-subtitle {
  display: block;
  width: 100%;
}
.disabledSend {
  pointer-events: none;
  opacity: 0.5;
}
.btn-lowercase {
  text-transform: capitalize;
}
.banner-text {
  font-size: 16px;
  @media screen and(max-width:660px) {
    font-size: 14px;
  }
}
.margin-on-mobile {
  @media screen and(max-width:660px) {
    margin-left: 0 !important;
    margin-top: 6px;
  }
}
.adjust-max-height {
  // @media screen and(min-width:960px) {
  //   max-height: 50vh;
  //   overflow-x: hidden;
  //   overflow-y: auto;
  // }
}
</style>
