import Vue from "vue";
import Vuex from "vuex";

import { auth } from "./auth.module";
import { loader } from "./loader.module";
import { userProfile } from "./userProfile.module";
import { orders } from "./orders.module";
import { supplier } from "./supplier.module";
import { articles } from "./articles.module";
import { stations } from "./stations.module";
import { admin } from "./admin/admin.module";
import { invites } from "./invites.module";
import { subscription } from "./subscription.module";
import { help } from "./help.module";
import { reports } from "./reports.module";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    loader,
    userProfile,
    orders,
    supplier,
    articles,
    stations,
    admin,
    invites,
    subscription,
    help,
    reports
  },
});
