<template>
  <div class="padding-adjust">
    <div class="d-flex flex-column justify-start">
      <span class="text-h5 font-weight-bold primaryA--text">{{
        $t("suppliers.suppliers")
      }}</span>
      <div
        v-if="stations && stations.length > 0"
        class="d-flex mt-8 align-top justify-space-between"
      >
        <div class="select-wrapper">
          <v-select
            class="select-width"
            :items="stations"
            item-value="id"
            item-text="name"
            outlined
            filled
            color="accent"
            :label="$t('dashboard.chooseStation')"
            persistent-hint
            dense
            @change="onStationSelected"
          ></v-select>
        </div>
        <v-btn color="primary" @click="openRequestSupplierModal">
          {{ $t("suppliers.requestSupplier") }}
        </v-btn>
      </div>
    </div>
    <div class="mt-11">
      <data-table
        v-if="
          suppliersStatus === 'REQUEST_SUCCESS' &&
          suppliers &&
          suppliers.length > 0
        "
        :headers="headers"
        :data="suppliers"
        :isDataLoading="isDataLoading"
        @onAddContact="handleOnAddContact"
        @onPreviewContacts="handleOnPreviewContacts"
        @onPreviewSupplierNotes="handleOnPreviewSupplierNotes"
        :mobileBreakpoint="600"
        :isAdmin="isAdmin"
        :isBasicPlan="isBasicPlan"
        :totalCount="getTotalCountSuppliers"
      ></data-table>
      <no-results-content
        v-else
        :noResultsText="$t('suppliers.noSuppliersAdded')"
        :page="'suppliers'"
      ></no-results-content>
    </div>
    <add-supplier-contact-modal
      :dialog="showAddSupplierContactModal"
      @onClose="handleCloseAddSupplierContactModal"
      :selectedSupplier="selectedSupplier"
      @onAddedSupplierContact="handleAddedSupplierContact"
    ></add-supplier-contact-modal>
    <preview-supplier-contacts-modal
      v-if="showPreviewContactsModal"
      :dialog="showPreviewContactsModal"
      @onClose="handleCloseSupplierContactsModal"
      :supplier="selectedSupplier"
      :isAdmin="isAdmin"
    ></preview-supplier-contacts-modal>
    <preview-supplier-notes-modal
      :dialog="showPreviewSupplierNotesModal"
      @onClose="onClosePreviewSupplierNotes"
      :supplier="selectedSupplier"
      :user="user"
      :venue="getVenue"
    ></preview-supplier-notes-modal>
    <request-supplier-modal
      v-if="isRequestSupplierModalShowing"
      :dialog="isRequestSupplierModalShowing"
      @onClose="isRequestSupplierModalShowing = false"
      @onSuccessRequestSent="handleOnSuccessRequestSent"
    ></request-supplier-modal>
  </div>
</template>
<script>
//vuex
import { mapGetters } from "vuex";
import { REQUEST_PENDING } from "../shared/statuses";
//Components
import NoResultsContent from "../components/NoResultsContent.vue";
import DataTable from "../components/DataTable.vue";
import AddSupplierContactModal from "../components/AddSupplierContactModal.vue";
import PreviewSupplierContactsModal from "../components/PreviewSupplierContactsModal.vue";
import PreviewSupplierNotesModal from "../components/PreviewSupplierNotesModal.vue";
import RequestSupplierModal from "../views/Articles/modals/RequestSupplierModal.vue";
//Event bus
import EventBus from "../shared/EventBus";
export default {
  name: "SuppliersPage",
  components: {
    NoResultsContent,
    DataTable,
    AddSupplierContactModal,
    PreviewSupplierContactsModal,
    PreviewSupplierNotesModal,
    RequestSupplierModal,
  },
  props: { user: {} },
  data: () => ({
    headers: [],
    showAddSupplierContactModal: false,
    selectedSupplier: null,
    showPreviewContactsModal: false,
    showPreviewSupplierNotesModal: false,
    stationsOptions: [],
    selectedStationId: null,
    isRequestSupplierModalShowing: false,
  }),
  created() {
    this.headers = [
      { text: this.$t("common.pib"), value: "pib", sortable: false },
      {
        text: this.$t("suppliers.name"),
        value: "supplersName",
        sortable: false,
      },
      {
        text: this.$t("suppliers.address"),
        value: "address",
        sortable: false,
      },
      {
        text: this.$t("suppliers.contacts"),
        value: "add_contact",
        sortable: false,
      },
    ];
  },
  mounted() {
    this.$store.dispatch("userProfile/getUserProfile");
    this.$store.dispatch("userProfile/getDepartments");
  },
  methods: {
    handleAddedSupplierContact() {
      this.showAddSupplierContactModal = false;
      EventBus.dispatch("showSnackbar", {
        text: this.$t("notifications.addedContactSupplier"),
      });
      this.$store.dispatch("userProfile/getVenueSuppliers", {
        venueId: this.getVenue.id,
      });
    },
    handleOnAddContact(event) {
      this.selectedSupplier = event;
      this.showAddSupplierContactModal = true;
    },
    handleCloseAddSupplierContactModal() {
      this.showAddSupplierContactModal = false;
    },
    handleOnPreviewContacts(event) {
      this.selectedSupplier = event;
      this.showPreviewContactsModal = true;
    },
    handleCloseSupplierContactsModal() {
      this.selectedSupplier = null;
      this.showPreviewContactsModal = false;
    },
    handleOnPreviewSupplierNotes(supplier) {
      this.selectedSupplier = supplier;

      this.$store.dispatch("userProfile/getSupplierNote", {
        supplierId: supplier.id,
        venueId: this.getVenue.id,
      });
      this.showPreviewSupplierNotesModal = true;
    },
    onClosePreviewSupplierNotes() {
      this.showPreviewSupplierNotesModal = false;
      this.selectedSupplier = null;
      this.$store.dispatch("userProfile/getVenueSuppliers", {
        venueId: this.getVenue.id,
      });
    },
    onStationSelected(station) {
      this.selectedStationId = station;
      this.$store.dispatch("supplier/getSuppliersForDepartment", {
        departmentId: this.selectedStationId,
      });
    },
    openRequestSupplierModal() {
      this.isRequestSupplierModalShowing = true;
    },
    handleOnSuccessRequestSent() {
      this.isRequestSupplierModalShowing = false;
    },
  },
  computed: {
    ...mapGetters({
      getSuppliers: "supplier/getDepartmentSuppliers",
      suppliersStatus: "supplier/getGetSuppliersForDepartmentStatus",
      isUserAdmin: "auth/isUserAdmin",
      isUserSuperAdmin: "auth/isUserSuperAdmin",
      getVenue: "userProfile/getVenue",
      getStations: "userProfile/getStations",
    }),
    suppliers() {
      return this.getSuppliers.filter((f) => !f.isMarket);
    },
    isDataLoading() {
      return this.suppliersStatus === REQUEST_PENDING;
    },
    isAdmin() {
      return this.isUserAdmin || this.isUserSuperAdmin;
    },
    isBasicPlan() {
      return this.$attrs.isBasicPlan;
    },
    stations() {
      return this.getStations;
    },
    getTotalCountSuppliers() {
      return this.suppliers.length;
    },
  },
  watch: {
    stations() {
      if (this.stations && this.stations.length > 0) {
        this.stations.filter((f) => this.stationsOptions.push(f));
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.text-fix {
  text-transform: unset !important;
}
.padding-adjust {
  padding: 32px;
  margin-top: 32px;
  @media screen and (max-width: 575px) {
    padding: 8px;
  }
}
</style>
