<template>
  <div>
    <div>
      <div class="d-flex justify-space-between align-center">
        <v-col :cols="getIsMobile ? '9' : '2'" class="mt-4">
          <v-select
            :items="suppliers"
            v-model="selectedSupplier"
            item-text="name"
            item-value="id"
            @change="onSelectSupplier($event)"
            :label="$t('currentOrders.filterBySupplier')"
            outlined
            dense
          ></v-select>
        </v-col>
      </div>
    </div>
    <v-alert
      v-if="allOrdersInProgressAndDueDate.length > 0"
      dense
      dismissible
      type="warning"
      border="bottom"
      color="orange"
    >
      <v-row align="center">
        <v-col class="grow">
          {{ $t("orderDueWarning.orderDueWarning") }}
        </v-col>
        <v-col class="shrink">
          <v-btn @click="showPassDueWarningModal = true">{{
            $t("orderDueWarning.more")
          }}</v-btn>
        </v-col>
      </v-row>
    </v-alert>
    <div class="mt-4">
      <tabs :navItems="navItems" @onTabSelected="handleTabSelected"></tabs>
    </div>
    <div class="mt-8 currentOrdersDataTable">
      <data-table
        v-if="!noOrders"
        :headers="headers"
        :data="getAllOrders"
        :isDataLoading="isDataLoading"
        @onReceiveOrder="handleReceiveOrder"
        @onPreviewOrderItem="handlePreviewOrderItem"
        @onDeleteOrder="handleOnDeleteOrder"
        @onRepeatOrder="handleRepeatOrder"
        @onCopySMSOrder="handleOnCopySMSOrder"
        :getIsMobile="getIsMobile"
        :isBasicPlan="isBasicPlan"
        :isCurrentOrders="true"
        :currentPage="page"
        :currentPageSize="pageSize"
        :totalCount="totalOrdersCount"
        @onPageChange="handleOnPageChange"
        @itemsPerPageChange="handleItemsPerPageChange"
      ></data-table>
      <no-results-content
        v-else
        :tabName="{ currentTabSelected }"
        :page="'orders'"
      ></no-results-content>
    </div>
    <receive-order-dialog
      :orderProp="orderReceiveObject"
      :dialog="showReceiveOrderModal"
      :venueId="getVenue.id"
      @onClose="showReceiveOrderModal = false"
      @allOrdersArrived="handleAllOrdersArrived"
      @allOrdersArrivedMoveToNew="handleOrdersArrivedMoveToNew"
      :isBasicPlan="isBasicPlan"
      @onOrderArriveLaterUpdated="handleOnOrderArrivedLaterUpdated"
    ></receive-order-dialog>
    <preview-order-modal
      v-if="showPreviewOrderModal"
      :data="orderForPreview"
      :showDialog="showPreviewOrderModal"
      @onClose="onClosePreviewModal"
      @onPreviewNewOrder="handlePreviewNewOrder"
    >
    </preview-order-modal>
    <modal-component
      :dialog="showDeleteWarningModal"
      @close="onCloseDeleteWarningModal"
    >
      <div>
        <div class="text-h6 font-weight-black">
          {{ $t("newOrder.sureToDelete") }}
        </div>
        <div
          class="text-subtitle-2 secondaryDark2--text pt-0 pb-0 pl-8 pr-8 mt-3"
        >
          {{ $t("newOrder.sureToDeleteDescription") }}
        </div>

        <div class="d-flex justify-center align-center mt-6">
          <v-btn color="accent" @click="onConfirmDeleteOrder">{{
            $t("newOrder.yes")
          }}</v-btn>
          <v-btn class="ml-4" @click="onCloseDeleteWarningModal">{{
            $t("newOrder.cancel")
          }}</v-btn>
        </div>
      </div>
    </modal-component>
    <repeat-order-modal
      :showDialog="showRepeatOrderModal && orderToRepeat"
      @onClose="handleCloseRepeatOrderModal"
      :order="orderToRepeat"
      @onCloseSent="handleOnCloseSent"
    ></repeat-order-modal>
    <order-due-warning-modal
      :dialog="showPassDueWarningModal"
      :ordersDue="allOrdersInProgressAndDueDate"
      @handleOrder="onHandleOrder"
      @onChangeOrderDate="handleChangeOrderDate"
      @onClose="handleOnCloseOrderDueWarningModal"
    ></order-due-warning-modal>
    <change-order-date-modal
      :orderToChangeDate="orderToChangeDate"
      :dialog="isChangeOrderDateModalOpened"
      @onClose="handleOnCloseChangeOrderModal"
      @onUpdatedOrderDate="handleOnUpdatedOrderDate"
    ></change-order-date-modal>
    <div class="hidden-field">
      <textarea readonly :value="smsTextToCopy" />
    </div>
  </div>
</template>
<script>
//components
import DataTable from "../../components/DataTable.vue";
import Tabs from "../../components/Tabs.vue";
import ReceiveOrderDialog from "../../components/ReceiveOrderDialog.vue";
import NoResultsContent from "../../components/NoResultsContent.vue";
import PreviewOrderModal from "../../components/PreviewOrderModal.vue";
import ModalComponent from "../../components/ModalComponent.vue";
import RepeatOrderModal from "../CurrentOrders/components/RepeatOrderModal.vue";
import OrderDueWarningModal from "../../components/OrderDueWarningModal.vue";
import ChangeOrderDateModal from "../../components/ChangeOrderDateModal.vue";
//vuex
import { mapGetters } from "vuex";
import {
  orderStatuses,
  REQUEST_PENDING,
  REQUEST_SUCCESS,
} from "../../shared/statuses";
//services
import EventBus from "../../shared/EventBus";
// helpers
import { formatDate, generateSMSText } from "../../helpers/helper";

export default {
  name: "CurrentRegularOrders",
  components: {
    DataTable,
    Tabs,
    ReceiveOrderDialog,
    NoResultsContent,
    PreviewOrderModal,
    ModalComponent,
    RepeatOrderModal,
    OrderDueWarningModal,
    ChangeOrderDateModal,
  },
  data: () => ({
    headers: [],
    navItems: [],
    isDataLoading: true,
    showReceiveOrderModal: false,
    orderReceiveObject: {},
    showWarningModal: false,
    currentTabSelected: {},
    stationID: null,
    orderForPreview: null,
    showPreviewOrderModal: false,
    getAllOrders: [],
    yesterdayDate: null,
    tabIndex: null,
    allOrdersImmutable: [],
    componentMounted: false,
    showDeleteWarningModal: false,
    orderDeleteId: null,
    showRepeatOrderModal: false,
    orderToRepeat: null,
    selectedSupplier: "",
    smsTextToCopy: "",
    todayDate: "",
    orderToBeDeleted: null,
    page: 1,
    pageSize: 10,
    orderStatusSelected: orderStatuses.OPEN,
    showPassDueWarningModal: false,
    orderToChangeDate: null,
    isChangeOrderDateModalOpened: false,
  }),
  created() {
    this.todayDate = new Date().getTime();
    this.todayDate = new Date(this.todayDate).toISOString();
    this.setYesterdayDate();
    this.navItems = [
      {
        id: 0,
        name: this.$t("navigation.noRealized"),
        code: orderStatuses.OPEN,
      },
      {
        id: 1,
        name: this.$t("navigation.currentOrders"),
        code: orderStatuses.IN_PROGRESS,
      },
      {
        id: 2,
        name: this.$t("navigation.acceptedOrders"),
        code: orderStatuses.COMPLETED,
      },
    ];
    this.headers = [
      {
        text: this.$t("common.orderNumberShort"),
        value: "orderNumber",
        sortable: false,
      },
      {
        text: this.$t("common.date"),
        value: "deadline",
        sortable: false,
      },
      {
        text: this.$t("common.supplier"),
        value: "supplierOrders",
        sortable: false,
      },
      // { text: this.$t("common.id"), value: "id" },
      { text: this.$t("common.status"), value: "statusText", sortable: false },
      { text: "", value: "actions", sortable: false },
      { text: "", value: "options", sortable: false },
    ];

    this.stationID = this.$route.params?.departmentID;
  },
  mounted() {
    this.$store.dispatch("orders/getAllRegularOrdersInProgress", {
      departmentId: this.stationID,
      page: this.page,
      pageSize: this.pageSize,
      status: orderStatuses.IN_PROGRESS,
    });
    if (this.$route.query && this.$route.query.tab) {
      this.tabIndex = parseInt(this.$route.query.tab) - 1;
      const value = this.navItems[this.tabIndex];
      this.handleTabSelected(value);

      const el =
        this.tabIndex === 0
          ? document.getElementById("tab-0")
          : document.getElementById("tab-1");
      el.click();
    } else {
      this.$store.dispatch("orders/getAllRegularOrders", {
        departmentId: this.stationID,
        page: this.page,
        pageSize: this.pageSize,
        status: this.orderStatusSelected,
        supplierId: this.selectedSupplier,
      });
    }
    this.$store.dispatch("supplier/getSuppliersForDepartment", {
      departmentId: this.stationID,
    });
    this.componentMounted = true;
  },
  methods: {
    handleReceiveOrder(item) {
      this.orderReceiveObject = item;
      this.showReceiveOrderModal = true;
    },
    handleTabSelected(val) {
      this.$store.dispatch("orders/getAllRegularOrders", {
        departmentId: this.stationID,
        page: this.page,
        pageSize: this.pageSize,
        status: val.code,
      });
      this.orderStatusSelected = val.code;
      this.currentTabSelected = val;
      this.selectedSupplier = "";
    },
    handleAllOrdersArrived() {
      this.showReceiveOrderModal = false;
      window.location.reload();
    },

    handlePreviewOrderItem(value) {
      this.orderForPreview = value;
      this.showPreviewOrderModal = true;
    },
    onClosePreviewModal() {
      this.orderForPreview = null;
      this.showPreviewOrderModal = false;
    },
    handleOnDeleteOrder(value) {
      this.orderDeleteId = value.id;
      this.orderToBeDeleted = value;
      this.showDeleteWarningModal = true;
    },
    onCloseDeleteWarningModal() {
      this.showDeleteWarningModal = false;
    },
    onConfirmDeleteOrder() {
      this.$store
        .dispatch(
          this.orderToBeDeleted.isCustomOrder
            ? "orders/deleteCustomOrder"
            : "orders/deleteOrder",
          this.orderDeleteId
        )
        .then(
          () => {
            this.showDeleteWarningModal = false;
            this.$store
              .dispatch("orders/getAllRegularOrders", {
                departmentId: this.stationID,
                page: 1,
                pageSize: 10,
                status: this.orderStatusSelected,
              })
              .then(() => {
                EventBus.dispatch("showSnackbar", {
                  text: this.$t("notifications.orderDeletedSuccess"),
                });
              });
          },
          (error) => {
            console.log(error);
          }
        );
    },
    setYesterdayDate() {
      const timestamp = new Date().getTime();
      const yesterdayTimeStamp = timestamp - 24 * 60 * 60 * 1000;
      this.yesterdayDate = new Date(yesterdayTimeStamp).toISOString();
    },
    handleRepeatOrder(order) {
      this.orderToRepeat = order;
      this.showRepeatOrderModal = true;
    },
    handleCloseRepeatOrderModal() {
      this.orderToRepeat = null;
      this.showRepeatOrderModal = false;
    },
    handleOnCloseSent() {
      this.orderToRepeat = null;
      this.showRepeatOrderModal = false;
      this.$store.dispatch("orders/getAllRegularOrders", {
        departmentId: this.stationID,
        page: 1,
        pageSize: 10,
        status: this.orderStatusSelected,
      });
    },
    onSelectSupplier(event) {
      const payload = {
        departmentId: this.stationID,
        page: this.page,
        pageSize: this.pageSize,
        status: this.orderStatusSelected,
        supplierId:
          event !== 999 && event !== 1000 ? this.selectedSupplier : undefined,
      };
      this.$store.dispatch("orders/getAllRegularOrders", payload);
    },
    handleOnCopySMSOrder(order) {
      let textarea;
      let result;
      this.smsTextToCopy = this.getGeneratedSMSText(order);

      try {
        textarea = document.createElement("textarea");
        textarea.setAttribute("readonly", true);
        textarea.setAttribute("contenteditable", true);
        textarea.style.position = "fixed"; // prevent scroll from jumping to the bottom when focus is set.
        textarea.value = this.smsTextToCopy;

        document.body.appendChild(textarea);

        textarea.focus();
        textarea.select();

        const range = document.createRange();
        range.selectNodeContents(textarea);

        const sel = window.getSelection();
        sel.removeAllRanges();
        sel.addRange(range);

        textarea.setSelectionRange(0, textarea.value.length);
        result = document.execCommand("copy");
      } catch (err) {
        console.error(err);
        result = null;
      } finally {
        document.body.removeChild(textarea);
      }

      // manual copy fallback using prompt
      if (!result) {
        const isMac = navigator.platform.toUpperCase().indexOf("MAC") >= 0;
        const copyHotkey = isMac ? "⌘C" : "CTRL+C";
        // eslint-disable-next-line no-undef
        result = prompt(`Press ${copyHotkey}`, string); // eslint-disable-line no-alert
        if (!result) {
          return false;
        }
      }
      setTimeout(() => {
        EventBus.dispatch("showSnackbar", {
          text: this.$t("notifications.smsCopyConfirmation"),
        });
      }, 1000);
      return true;
    },
    getGeneratedSMSText(order) {
      return (
        order &&
        order.products &&
        generateSMSText(
          order.products,
          this.venue.bussiness_name,
          this.venue.address,
          this.getFormattedDate(order.deadline),
          order.orderNote,
          order.paymentMethod,
          null,
          null,
          null,
          null,
          order.isCustomOrder,
          order.department
        )
      );
    },
    getPhoneFormatted(user) {
      return user && user.phone && user.phone[0] === "0"
        ? user.phone
        : "0" + user.phone;
    },
    getFormattedDate(value) {
      return formatDate(value);
    },
    handleOrdersArrivedMoveToNew(data) {
      this.showReceiveOrderModal = false;
      this.$router.push(
        `/${this.$route.params.departmentID}/edit-order/${data.newOrderId}`
      );
    },
    handlePreviewNewOrder(newOrderNumber) {
      this.showPreviewOrderModal = false;
      this.orderForPreview = this.allOrders.filter(
        (order) => order.id === newOrderNumber
      )[0];
      this.$store.dispatch("orders/getOrder", newOrderNumber).then((res) => {
        this.orderForPreview = res.data;
        this.showPreviewOrderModal = true;
      });
    },
    handleOnPageChange(page) {
      this.$store.dispatch("orders/getAllRegularOrders", {
        departmentId: this.stationID,
        page: page,
        pageSize: this.pageSize,
        status: this.orderStatusSelected,
      });
    },
    handleItemsPerPageChange(pageSize) {
      this.pageSize = pageSize;
      this.$store.dispatch("orders/getAllRegularOrders", {
        departmentId: this.stationID,
        page: this.page,
        pageSize: this.pageSize,
        status: this.orderStatusSelected,
      });
    },
    onHandleOrder(order) {
      switch (order.status) {
        case orderStatuses.OPEN:
          order.isCustomOrder
            ? this.$store.dispatch("orders/setCustomOrderToEdit", order)
            : this.$store.dispatch("orders/setCustomOrderToEdit", null);

          this.$router.push(`/${order.department.id}/edit-order/${order.id}`);
          break;
        case orderStatuses.IN_PROGRESS:
        case orderStatuses.INCOMPLETE:
          this.$store
            .dispatch(
              order.isCustomOrder ? "orders/getCustomOrder" : "orders/getOrder",
              order.id
            )
            .then(
              (response) => {
                const responseOrder = response.data;
                if (order.isCustomOrder) {
                  responseOrder.isCustomOrder = true;
                }
                this.orderReceiveObject = responseOrder;
                this.showReceiveOrderModal = true;
              },
              (error) => {
                console.log("getting order error", error);
              }
            );
          break;
      }
    },
    handleChangeOrderDate(order) {
      this.$store
        .dispatch(
          order.isCustomOrder ? "orders/getCustomOrder" : "orders/getOrder",
          order.id
        )
        .then((response) => {
          const responseOrder = response.data;
          if (order.isCustomOrder) {
            responseOrder.isCustomOrder = true;
          }
          this.orderToChangeDate = responseOrder;
          this.isChangeOrderDateModalOpened = true;
        });
    },
    handleOnCloseChangeOrderModal() {
      this.orderToChangeDate = null;
      this.isChangeOrderDateModalOpened = false;
    },
    handleOnUpdatedOrderDate() {
      this.showPassDueWarningModal = false;
      this.$store
        .dispatch("orders/getAllRegularOrders", {
          departmentId: this.stationID,
          page: this.page,
          pageSize: this.pageSize,
          status: this.orderStatusSelected,
        })
        .then(() => {
          this.orderToChangeDate = null;
          this.isChangeOrderDateModalOpened = false;
          this.showPassDueWarningModal = false;
          EventBus.dispatch("showSnackbar", {
            text: this.$t("notifications.orderDateSuccessfullyUpdated"),
          });
        });
    },
    handleOnCloseOrderDueWarningModal() {
      this.showPassDueWarningModal = false;
    },
    handleOnOrderArrivedLaterUpdated() {
      this.showReceiveOrderModal = false;
      this.showPassDueWarningModal = false;
      this.$store.dispatch("orders/getAllRegularOrders", {
        departmentId: this.stationID,
        page: this.page,
        pageSize: this.pageSize,
        status: this.orderStatusSelected,
      });
    },
  },
  computed: {
    ...mapGetters({
      getOrders: "orders/getAllRegularOrdersState",
      getOrdersStatus: "orders/getAllRegularOrdersStatus",
      getVenue: "userProfile/getVenue",
      getUser: "auth/getUser",
      venueUsers: "userProfile/getAllUsers",
      totalOrdersCount: "orders/getTotalRegularOrdersCount",
      getAllRegularOrdersInProgressDueDate:
        "orders/getAllRegularOrdersInProgressDueDate",
      getSuppliers: "supplier/getDepartmentSuppliers",
    }),
    allOrders: {
      get: function () {
        return this.getAllOrders;
      },
      set: function (newValue) {
        this.getAllOrders = newValue;
      },
    },
    allOrdersInProgressAndDueDate() {
      return this.getAllRegularOrdersInProgressDueDate;
    },
    noOrders() {
      return (
        this.totalOrdersCount === 0 && this.getOrdersStatus === REQUEST_SUCCESS
      );
    },
    allOrdersStatus() {
      return this.getOrdersStatus;
    },
    getTabIndex() {
      return this.tabIndex;
    },
    getIsMobile() {
      return this.$isMobile;
    },
    venue() {
      return this.getVenue;
    },
    user() {
      return this.getUser;
    },
    allUsers() {
      return this.venueUsers;
    },
    isBasicPlan() {
      return this.$attrs.isBasicPlan;
    },
    suppliers() {
      let suppliers = this.getSuppliers;
      suppliers = this.getSuppliers?.filter((f) => f.isMarket === false);
      suppliers?.unshift({
        id: 999,
        name: this.$t("currentOrders.allSuppliers"),
      });
      return suppliers;
    },
  },
  watch: {
    getOrders() {
      this.getAllOrders = this.getOrders;
    },
    allOrdersStatus() {
      this.isDataLoading = this.allOrdersStatus === REQUEST_PENDING;
    },
    getTabIndex() {
      if (this.getTabIndex === 1) {
        this.headers = this.headers.filter((f) => f.value !== "statusText");
        this.allOrders =
          this.getOrders &&
          this.getOrders.filter((f) => f.status === orderStatuses.OPEN);
      } else if (this.getTabIndex === 2) {
        this.headers = this.headers.filter((f) => f.value !== "statusText");
        this.allOrders =
          this.getOrders &&
          this.getOrders.filter(
            (f) =>
              f.status === orderStatuses.IN_PROGRESS ||
              f.status === orderStatuses.INCOMPLETE
          );
      }
      this.$forceUpdate();
    },
  },
};
</script>
<style lang="scss" scoped>
.root {
  padding: 0 32px 32px 32px;
  @media screen and (max-width: 959px) {
    padding: 8px;
  }
}
.create-button {
  text-transform: none !important;
}
.button-plus {
  width: 40px;
  height: 40px;
  align-items: center;
  justify-content: center;
  background-color: #276ef1;
  cursor: pointer;
  border-radius: 50%;
  &:hover {
    box-shadow: 1px 1px 11px 1px #276ef1;
  }
}
.padding-adjust {
  padding: 32px;
  margin-top: 32px;
  @media screen and (max-width: 575px) {
    padding: 8px;
  }
}
.hidden-field {
  position: absolute;
  opacity: 0;
}
</style>
