<template>
  <v-dialog
    v-model="showDialog"
    width="626"
    class="dialog-style"
    persistent
    :fullscreen="getIsMobile"
  >
    <div class="dialog-style-content">
      <div class="header mb-6">
        <div class="d-flex justify-space-between align-center">
          <span class="text-h5 primaryA--text font-weight-bold">{{
            editArticleData
              ? $t("editArticle.title", {
                  articleName: editArticleData.name,
                })
              : $t("addArticle.title2")
          }}</span>
          <v-img
            @click="closeDialog"
            class="close-icon"
            max-width="20px"
            :src="require('../../../assets/img/CloseIcon.svg')"
          >
          </v-img>
        </div>
      </div>
      <v-divider></v-divider>
      <div class="pt-6">
        <v-select
          class="select-width"
          :items="getAllSuppliers"
          :placeholder="$t('common.suppliers')"
          v-model="selectedSupplierId"
          item-text="name"
          item-value="id"
          solo
          required
          :rules="rules.department"
          :disabled="editArticleData !== null"
        ></v-select>
        <v-text-field
          name="name"
          id="name"
          type="text"
          :placeholder="$t('editArticle.name')"
          :label="$t('editArticle.name')"
          outlined
          color="accent"
          required
          :rules="rules.name"
          v-model="article.name"
        ></v-text-field>

        <!-- <v-text-field
          name="category"
          id="category"
          type="text"
          :placeholder="$t('editArticle.category')"
          :label="$t('editArticle.category')"
          outlined
          color="accent"
          v-model="article.category"
        ></v-text-field> -->
        <v-select
          class="select-width"
          :items="allCategories"
          :placeholder="$t('articles.articlesCategories')"
          v-model="article.category"
          item-text="category"
          item-value="id"
          solo
          @change="onCategorySelected"
        ></v-select>
      </div>
      <div class="d-flex">
        <v-btn @click="closeDialog">{{ $t("suppliers.close") }}</v-btn>
        <v-btn
          v-if="editArticleData"
          :disabled="isFormValid"
          class="ml-3"
          @click="updateArticle"
          color="accent"
          >{{ $t("common.save") }}</v-btn
        >
        <v-btn
          v-else
          :disabled="isFormValid"
          class="ml-3"
          @click="addArticle"
          color="accent"
          >{{ $t("common.save") }}</v-btn
        >
      </div>
    </div>
  </v-dialog>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "CreateEditArticleModal",
  props: {
    dialog: {},
    editArticleData: {},
    supplierId: {},
    allSuppliers: {},
  },
  data: () => ({
    showDialog: false,
    article: {},
    rules: {},
    isFormValid: true,
    selectedSupplierId: null,
  }),
  created() {
    this.showDialog = this.dialog;
    this.rules = {
      name: [
        (val) => (val || "").length > 0 || this.$t("common.requiredField"),
      ],
      storeName: [
        (val) => (val || "").length > 0 || this.$t("common.requiredField"),
      ],
      category: [
        (val) => (val || "").length > 0 || this.$t("common.requiredField"),
      ],
      department: [
        (val) => (val || "").length > 0 || this.$t("common.requiredField"),
      ],
    };
  },
  mounted() {
    this.$store.dispatch("admin/getAllArticleCategories");
    if (this.supplierId) {
      this.selectedSupplierId = this.supplierId;
    }
    if (this.editArticleData) {
      this.article = JSON.parse(JSON.stringify(this.editArticleData));

      this.$store.dispatch("articles/getAllArticleSupplierCategories", {
        supplierId: this.supplierId,
      });
    } else {
      this.article = {
        name: "",
        storeName: "",
        note: "",
        category: "",
        defaultPrice: "",
      };
    }
  },
  methods: {
    closeDialog() {
      this.resetForm();
      this.$emit("onClose");
    },
    resetForm() {
      this.article = {
        name: "",
        storeName: "",
        note: "",
        category: "",
        defaultPrice: "",
      };
    },
    addArticle() {
      const selectedCategory = this.allCategories.find(
        (c) => c.id === this.article.category
      );

      const payload = {
        article: {
          articleName: this.article.name,
          category: selectedCategory,
        },
        supplierId: this.selectedSupplierId,
      };
      this.$store
        .dispatch("articles/createOrUpdateArticle", payload)
        .then(() => {
          this.$emit("onSuccessAddedArticle", this.selectedSupplierId);
          this.closeDialog();
        });
    },
    updateArticle() {
      const categoryId = this.article.category.id || this.article.category;
      const payload = {
        article: {
          articleName: this.article.name,
          category: { id: categoryId },
          id: this.article.id,
        },
        supplierId: this.article.supplier.id,
      };
      this.$store
        .dispatch("articles/createOrUpdateArticle", payload)
        .then(() => {
          this.$emit("onSuccessEditArticle", this.selectedDepartmentId);
          this.closeDialog();
        });
    },
    onCategorySelected(event) {
      // console.log("event", event);
    },
  },
  computed: {
    ...mapGetters({
      getAllCategories: "admin/getAllCategories"
    }),
    getIsMobile() {
      return this.$isMobile;
    },
    articleName() {
      return this.article.name;
    },
    articleStoreName() {
      return this.article.storeName;
    },
    articleCategory() {
      return this.article.category;
    },
    getAllSuppliers() {
      return this.allSuppliers;
    },
    allCategories() {
      return this.getAllCategories
    },
    supplierIdComputed() {
      return this.supplierId;
    },
  },
  watch: {
    dialog() {
      this.showDialog = this.dialog;
    },
    articleName() {
      this.isFormValid = this.articleName === "" ? true : false;
    },
    articleStoreName() {
      this.isFormValid = this.articleStoreName === "" ? true : false;
    },
    articleCategory() {
      this.isFormValid = this.articleCategory === "" ? true : false;
    },
    editArticleData() {
      if (this.editArticleData) {
        this.article = this.editArticleData;
      } else {
        this.article = {
          name: "",
          storeName: "",
          note: "",
          category: "",
          defaultPrice: "",
        };
      }
    },
    selectedSupplierId() {
      if (this.selectedSupplierId) {
        this.$store.dispatch("articles/getAllArticleSupplierCategories", {
          supplierId: this.selectedSupplierId,
        });
      }
    },
    supplierIdComputed() {
      this.selectedSupplierId = this.supplierIdComputed;
    },
  },
};
</script>
<style scoped lang="scss">
.dialog-style {
  background-color: #ffffff;
  padding: 24px;
  @media screen and (max-width: 959px) {
    height: 100%;
  }
}
.dialog-style-content {
  background-color: #ffffff;
  padding: 24px;
  height: 100%;
}
.close-icon {
  cursor: pointer;
}
.price-input {
  max-width: 200px;
  min-width: 60px;
  position: relative;
}
</style>
