export const loader = {
    namespaced: true,
    state: { loading: false },
    actions: {
        setLoadingState({ commit }) {
            commit("setLoading")
        }
    },
    mutations: {
        setLoading(state) {
            state.loading = !state.loading;
        }
    },
    getters: {
        isLoading: state => {
            return state.loading;
        }
    }

}