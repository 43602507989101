<template>
  <modal-component
    :dialog="dialog"
    :modalWidth="'540px'"
    :hideClosing="false"
    @onClose="$emit('onClose')"
  >
    <div class="dialog-style">
      <div class="header">
        <span class="text-h5 font-weight-black">{{
          $t("sendPlanInquiry.title")
        }}</span>
      </div>
      <div class="mt-4">
        <span>{{ $t("sendPlanInquiry.description") }}</span>
      </div>
      <div class="mt-4">
        <v-container fluid>
          <v-select
            v-model="selectedPlan"
            :items="plans"
            item-text="label"
            item-value="value"
            :label="$t('sendPlanInquiry.choosePlan')"
          ></v-select>
        </v-container>
      </div>
      <div
        v-if="
          selectedPlan === 'BASIC' &&
          allVenueStations &&
          allVenueStations.length > ALLOWED_NUM_OF_STATIONS
        "
      >
        <div class="d-flex flex-column">
          <span class="secondaryDark2--text text-subtitle-1">{{
            $t("sendPlanInquiry.chosenPlanNumOfStationsViolation", {
              numOfStations: ALLOWED_NUM_OF_STATIONS,
            })
          }}</span>
        </div>
        <v-container fluid>
          <v-checkbox
            v-for="station in allVenueStations"
            :key="station.id"
            :label="station.department.name"
            v-model="station.isChecked"
          ></v-checkbox>
        </v-container>
        <span class="secondaryDark2--text text-subtitle-2">{{
          $t("sendPlanInquiry.chosenPlanNumOfStationsViolationDescription")
        }}</span>
      </div>

      <div class="d-flex justify-center mt-4">
        <v-btn :disabled="formInvalid" color="accent" @click="sendInquiry">{{
          $t("sendPlanInquiry.send")
        }}</v-btn>
        <v-btn class="ml-3" color="primaryB" @click="$emit('onClose')">{{
          $t("sendPlanInquiry.cancel")
        }}</v-btn>
      </div>
    </div>
  </modal-component>
</template>
<script>
import { mapGetters } from "vuex";
import ModalComponent from "../components/ModalComponent.vue";
import { subscriptionPlans } from "../shared/subscriptionPlans";

export default {
  name: "SendPlanInquiryModal",
  components: { ModalComponent },
  props: { dialog: {} },
  data: () => ({
    plans: [],
    selectedPlan: "",
    stationsToKeep: [],
    ALLOWED_NUM_OF_STATIONS: 2,
  }),
  created() {
    this.plans = [
      {
        id: 1,
        label: subscriptionPlans.BASIC,
        value: subscriptionPlans.BASIC,
        disabled: this.currentPlan === subscriptionPlans.BASIC,
      },
      {
        id: 2,
        label: subscriptionPlans.PRO,
        value: subscriptionPlans.PRO,
        disabled: this.currentPlan === subscriptionPlans.PRO,
      },
    ];
  },
  mounted() {
    this.$store.dispatch("userProfile/getUserProfile").then(() => {
      this.$store.dispatch("stations/getAllStations", this.getVenue.id);
    });
  },
  computed: {
    ...mapGetters({
      getVenueSubscriptionPlan: "subscription/getVenueSubscriptionPlan",
      getAllVenueStations: "stations/getAllVenueStations",
      getVenue: "userProfile/getVenue",
    }),
    allVenueStations() {
      return this.getAllVenueStations;
    },
    currentPlan() {
      return this.getVenueSubscriptionPlan;
    },
    formInvalid() {
      return (
        !this.selectedPlan ||
        (this.selectedPlan === "BASIC" &&
          this.allVenueStations.length > 2 &&
          this.allVenueStations.filter((station) => station.isChecked)
            .length === 0)
      );
    },
  },
  methods: {
    sendInquiry() {
      const checkedStations = this.allVenueStations
        .filter((station) => station.isChecked)
        .map((checkedStation) => {
          return checkedStation.department;
        });
      this.$emit("onSendInquiry", {
        selectedPlan: this.selectedPlan,
        checkedStations,
      });
    },
    onStationChecked(event) {
      if (event) {
        const isStationIn =
          this.stationsToKeep.filter(
            (station) => station.id === event.department.id
          ).length > 0;
        if (!isStationIn) {
          this.stationsToKeep.push(event);
        }
      }
    },
  },
  watch: {
    selectedPlan() {
      if (this.selectedPlan) {
        this.allVenueStations.forEach((station) =>
          this.$set(station, "isChecked", false)
        );
      }
    },
  },
};
</script>
<style scoped lang="scss"></style>
