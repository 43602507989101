import api from "./api";
import {
  INVITE_USER_URL,
  ACCEPT_INVITE_RESTAURANT_URL,
} from "../shared/endpoints";

class InvitesService {
  inviteUserToRestaurantService(email, restaurantId, userId) {
    let data = new FormData();
    data.append("email", email);
    data.append("restaurantId", restaurantId);
    data.append("userId", userId);
    return api.post(INVITE_USER_URL, data);
  }
  acceptInviteToRestaurantService(token) {
    let data = new FormData();
    data.append("token", token);
    return api.post(ACCEPT_INVITE_RESTAURANT_URL, data);
  }
}
export default new InvitesService();
