<template>
  <v-card :width="width" elevation="2" class="pa-6 mt-10 mb-10">
    <div>
      <div class="d-flex align-center mb-6">
        <span class="text-h4">{{ $t("adminRestaurant.stations") }}</span>
        <div class="add-new-btn ml-4" @click="onAddNewStation">
          <v-img
            max-width="12"
            :src="require('../../../assets/img/Plus.svg')"
          ></v-img>
        </div>
      </div>
      <v-data-table
        :headers="headers"
        :items="getAllStations"
        :sort-by="['name']"
        :sort-desc="[false, true]"
        :items-per-page="5"
        class="elevation-1"
        :footer-props="{
          itemsPerPageAllText: $t('common.all'),
          itemsPerPageText: $t('common.itemsPerPage'),
        }"
      >
        <template v-slot:item.users="{ item }">
          <v-tooltip right color="secondary" open-on-click open-on-hover>
            <template v-slot:activator="{ on, attrs }">
              <span style="cursor: pointer" v-bind="attrs" v-on="on">
                {{ item.users.length }}</span
              >
            </template>
            <div class="d-flex flex-column">
              <span
                v-for="(user, index) in item.users"
                :key="user.id"
                class="primaryB--text font-weight-bold"
                >{{ index + 1 }}. {{ user.name }} -
                {{ getUserRole(user.role) }}</span
              >
            </div>
          </v-tooltip>
        </template>
        <template v-slot:item.actions="{ item }">
          <div class="d-flex align-center">
            <v-btn color="primary" small @click="handleOnStationDelete(item)">{{
              $t("adminRestaurant.delete")
            }}</v-btn>
          </div>
        </template>
      </v-data-table>
    </div>
    <simple-warning-modal
      :dialog="showDeleteWarningModal"
      @onClose="showDeleteWarningModal = false"
      @onConfirm="onConfirmDeleteStation"
      @onCancel="showDeleteWarningModal = false"
      :warningMessage="warningModalTitle"
    ></simple-warning-modal>
    <modal-component
      :dialog="showAddNewStationModal"
      @onClose="closeAddNewStationModal"
    >
      <div>
        <v-card-title class="pa-0">{{
          $t("dashboard.creatingNewStation")
        }}</v-card-title>
        <v-text-field
          name="newStationName"
          id="newStationName"
          type="text"
          color="accent"
          requred
          v-model="newStationName"
          :placeholder="$t('dashboard.enterNewStationName')"
          @keyup="onCheckStationNameUp"
          @keydown="onCheckStationNameDown"
          :rules="rules.stationName"
        ></v-text-field>
        <div class="d-flex align-center justify-end">
          <v-btn @click="closeAddNewStationModal">{{
            $t("dashboard.cancelCreateStation")
          }}</v-btn>
          <v-progress-circular
            v-if="loading"
            indeterminate
            color="primary"
          ></v-progress-circular>
          <v-btn
            v-else
            :disabled="!newStationName || sameStationNameViolated"
            class="ml-2"
            color="accent"
            @click="onCreateNewStation"
            >{{ $t("dashboard.saveStation") }}</v-btn
          >
        </div>
      </div>
    </modal-component>
  </v-card>
</template>
<script>
import { mapGetters } from "vuex";
import ModalComponent from "../../../components/ModalComponent.vue";
import SimpleWarningModal from "../../../components/SimpleWarningModal.vue";
import { REQUEST_PENDING } from "../../../shared/statuses";
export default {
  name: "StationsCard",
  props: {
    width: {},
  },
  components: { SimpleWarningModal, ModalComponent },
  data: () => ({
    headers: [],
    showDeleteWarningModal: false,
    stationToDelete: null,
    warningModalTitle: "",
    restaurantID: null,
    showAddNewStationModal: false,
    newStationName: "",
    typingTimer: null,
    doneTypingInterval: 500,
    rules: {},
    sameStationNameViolated: false,
    loading: false,
  }),
  mounted() {
    this.rules = {
      stationName: [
        (val) => (val || "").length > 0 || this.$t("common.requiredField"),
      ],
    };
    this.headers = [
      {
        text: "ID",
        value: "id",
        sortable: false,
      },
      {
        text: this.$t("adminRestaurant.name"),
        value: "name",
        sortable: false,
      },
      {
        text: this.$t("adminRestaurant.users"),
        value: "users",
        sortable: false,
      },
      { text: this.$t("common.actions"), value: "actions", sortable: false },
    ];
  },
  created() {
    this.restaurantID = this.$route.params.id;
    this.$store.dispatch("stations/getAllStations", this.restaurantID);
  },
  methods: {
    getUserRole(role) {
      switch (role) {
        case "ROLE_ADMIN":
          return this.$t("common.roleAdmin");
        case "ROLE_SUPER_ADMIN":
          return this.$t("common.roleSuperAdmin");
        case "ROLE_USER":
          return this.$t("common.roleUser");
      }
    },
    handleOnStationDelete(data) {
      this.stationToDelete = data;
      this.warningModalTitle = this.$t(
        "adminStations.deleteWarningModalMessage"
      );
      this.showDeleteWarningModal = true;
    },
    onConfirmDeleteStation() {
      this.$store
        .dispatch("stations/deleteStation", this.stationToDelete)
        .then(() => {
          this.showDeleteWarningModal = false;

          this.$store.dispatch("stations/getAllStations", this.restaurantID);
        });
    },
    onAddNewStation() {
      this.showAddNewStationModal = true;
    },
    closeAddNewStationModal() {
      this.showAddNewStationModal = false;
    },
    onConfirmAddNewStationModal() {
      this.showAddNewStationModal = false;
    },
    onCheckStationNameUp() {
      clearTimeout(this.typingTimer);
      this.typingTimer = setTimeout(
        this.checkStationNameNew,
        this.doneTypingInterval
      );
    },
    onCheckStationNameDown() {
      clearTimeout(this.typingTimer);
    },
    onCreateNewStation() {
      this.loading = true;
      setTimeout(() => {
        if (!this.sameStationNameViolated) {
          this.$store
            .dispatch("stations/createStation", {
              venueID: this.restaurantID,
              stationName: this.newStationName,
            })
            .then(() => {
              this.loading = false;
              window.location.reload();
            });
          // this.$emit("onStationCreated", {
          //   venueID: this.restaurantID,
          //   stationName: this.newStationName,
          // });
        }
      }, 1000);
    },
    checkStationNameNew() {
      this.sameStationNameViolated =
        this.stations &&
        this.stations.filter(
          (f) =>
            f.department.name.toLowerCase() ===
            this.newStationName.toLowerCase()
        ).length > 0;
    },
  },
  computed: {
    ...mapGetters({
      stations: "stations/getAllVenueStations",
      getStationsStatus: "stations/getCreateNewStationStatus",
    }),
    getAllStations() {
      if (this.stations) {
        let allStations = [];
        this.stations.forEach((s) => {
          allStations.push({
            id: s.department.id,
            name: s.department.name,
            users: s.users,
          });
        });
        return allStations;
      }
      return null;
    },
    createStationStatus() {
      return this.getStationsStatus;
    },
  },
  watch: {
    getStationsStatus() {
      this.loading = this.getStationsStatus === REQUEST_PENDING;
    },
    sameStationNameViolated() {
      if (this.sameStationNameViolated) {
        this.rules.stationName = [this.$t("errors.sameStations")];
      } else {
        this.rules.stationName = [];
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.add-new-btn {
  background: #276ef1;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
</style>
