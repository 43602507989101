var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-1 admin-data-table",attrs:{"headers":_vm.headers,"items":_vm.data,"sort-by":['name'],"sort-desc":[false, true],"loading":_vm.isDataLoading,"loading-text":_vm.$t('common.loadingTableText'),"items-per-page":5,"footer-props":{
    itemsPerPageAllText: _vm.$t('common.all'),
    itemsPerPageText: _vm.$t('common.itemsPerPage'),
  }},on:{"click:row":_vm.tableRowClicked},scopedSlots:_vm._u([{key:"item.status",fn:function(ref){
  var item = ref.item;
return [_c('span',{staticClass:"restaurant-status",class:{
        disabledStatus: item.disabled,
      }},[_vm._v(_vm._s(item && _vm.getStatusText(item.disabled)))])]}},{key:"item.toggleStatus",fn:function(ref){
      var item = ref.item;
return [_c('div',[_c('v-btn',{attrs:{"dense":"","color":"accent"},on:{"click":function($event){$event.stopPropagation();return _vm.togleRestaurantStatus(item)}}},[_vm._v(" "+_vm._s(item.disabled ? _vm.$t("adminDashboard.reactivate") : _vm.$t("adminDashboard.deactivate")))]),_c('v-btn',{staticClass:"ml-2",attrs:{"dense":"","color":"secondaryDark"},on:{"click":function($event){$event.stopPropagation();return _vm.tableRowClicked(item)}}},[_vm._v(" "+_vm._s(_vm.$t("adminDashboard.details"))+" ")])],1)]}},{key:"item.supplersName",fn:function(ref){
      var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.name))])]}},{key:"item.upload",fn:function(ref){
      var item = ref.item;
return [_c('v-file-input',{attrs:{"small-chips":"","placeholder":_vm.$t('suppliers.supportedExcelFile'),"accept":".xlsx","show-size":""},on:{"change":_vm.onFileInputChange,"click":function($event){return _vm.selectRowId(item)}}})]}},{key:"item.uploadedData",fn:function(ref){
      var item = ref.item;
return [_c('div',[(item.hasData)?_c('span',{staticClass:"uploadedYes"},[_c('v-img',{attrs:{"max-width":"14","src":require('../../../assets/img/check.png')}})],1):_c('span',{staticClass:"uploadedNo"},[_c('v-img',{attrs:{"max-width":"14","src":require('../../../assets/img/close.png')}})],1)])]}},{key:"item.actions",fn:function(ref){
      var item = ref.item;
return [(_vm.isSuppliersPage)?_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',_vm._l((_vm.optionsItems),function(opt,i){return _c('v-list-item',{key:i},[_c('v-list-item-title',{staticClass:"option-item",on:{"click":function($event){return _vm.handleOptionsSelection(opt.value, item)}}},[_vm._v(_vm._s(opt.title))])],1)}),1)],1):_vm._e()]}},{key:"item.userName",fn:function(ref){
      var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item && item.user && item.user.name))])]}},{key:"item.userEmail",fn:function(ref){
      var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item && item.user && item.user.email))])]}},{key:"item.resName",fn:function(ref){
      var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item && item.venue && item.venue.name))])]}},{key:"item.invResPib",fn:function(ref){
      var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item && item.venue && item.venue.pib))])]}},{key:"item.invActions",fn:function(ref){
      var item = ref.item;
return [_c('div',[_c('v-btn',{attrs:{"color":"accent"},on:{"click":function($event){return _vm.approveUserRequest(item)}}},[_vm._v(_vm._s(_vm.$t("adminUsers.approve")))])],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }