var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('modal-component',{staticStyle:{"min-height":"332px"},attrs:{"dialog":_vm.dialog,"modalWidth":'540px'},on:{"onClose":_vm.closePreviewDialog}},[(_vm.selectedSupplier)?_c('div',{staticClass:"preview-modal"},[_c('span',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.$t("suppliers.listContacts"))+" - "+_vm._s(_vm.selectedSupplier && _vm.selectedSupplier.name)+" ")]),(_vm.allSupplierContactsStatus === 'REQUEST_PENDING')?_c('loader-component'):_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.allSupplierContacts,"loading-text":_vm.$t('common.loadingTableText'),"hide-default-footer":true},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [(item.showEditItem)?_c('div',[_c('v-text-field',{attrs:{"type":"text"},model:{value:(_vm.contactForEditing.name),callback:function ($$v) {_vm.$set(_vm.contactForEditing, "name", $$v)},expression:"contactForEditing.name"}})],1):_c('span',[_vm._v(_vm._s(item.name))])]}},{key:"item.phone",fn:function(ref){
var item = ref.item;
return [(item.showEditItem)?_c('div',[_c('v-text-field',{attrs:{"type":"text"},model:{value:(_vm.contactForEditing.phone),callback:function ($$v) {_vm.$set(_vm.contactForEditing, "phone", $$v)},expression:"contactForEditing.phone"}})],1):_c('span',[_vm._v(_vm._s(item.phone && item.phone[0] === "0" ? item.phone : "0" + item.phone))])]}},{key:"item.email",fn:function(ref){
var item = ref.item;
return [(item.showEditItem)?_c('div',[_c('v-text-field',{attrs:{"type":"text"},model:{value:(_vm.contactForEditing.email),callback:function ($$v) {_vm.$set(_vm.contactForEditing, "email", $$v)},expression:"contactForEditing.email"}})],1):_c('span',[_vm._v(_vm._s(item.email))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(item.showDeleteAlert)?_c('div',{staticClass:"d-flex align-center"},[_c('div',[_c('span',[_vm._v(_vm._s(_vm.$t("suppliers.deleteContactAlert")))])]),_c('div',{staticClass:"d-flex pt-1 ml-4"},[_c('v-btn',{attrs:{"small":"","dark":"","color":"accent"},on:{"click":function($event){return _vm.onYesDeleteContact(item)}}},[_vm._v(_vm._s(_vm.$t("suppliers.yes")))]),_c('v-btn',{staticClass:"ml-1",attrs:{"small":"","dark":"","color":"accent"},on:{"click":function($event){return _vm.onNoDeleteContact(item)}}},[_vm._v(_vm._s(_vm.$t("suppliers.no")))])],1)]):_vm._e(),(item.showEditItem)?_c('td',[_c('div',{staticClass:"d-flex pt-1"},[_c('v-btn',{staticClass:"mx-2",attrs:{"fab":"","small":"","dark":"","color":"accent","height":"35px","width":"35px"},on:{"click":function($event){return _vm.onSaveEditedContact()}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-content-save-outline ")])],1),_c('v-btn',{staticClass:"mx-2",attrs:{"fab":"","small":"","dark":"","color":"negative","height":"35px","width":"35px"},on:{"click":function($event){return _vm.onCancelEditingContact(item)}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-close ")])],1)],1)]):_vm._e(),(!item.showDeleteAlert && !item.showEditItem)?_c('div',[(_vm.isAdmin)?_c('v-btn',{staticClass:"mx-2",attrs:{"fab":"","small":"","dark":"","color":"accent","height":"35px","width":"35px"},on:{"click":function($event){return _vm.editContact(item)}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-pencil ")])],1):_vm._e(),(_vm.isAdmin)?_c('v-btn',{staticClass:"mx-2",attrs:{"fab":"","small":"","dark":"","color":"negative","height":"35px","width":"35px"},on:{"click":function($event){return _vm.deleteContact(item)}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-delete ")])],1):_vm._e()],1):_vm._e()]}}],null,false,1072677438)})],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }