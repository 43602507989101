import AdminService from "../../services/admin/admin.service";
import {
  REQUEST_FAILURE,
  REQUEST_PENDING,
  REQUEST_SUCCESS,
  REQUEST_UNDEFINED,
} from "../../shared/statuses";

const initialState = {
  allRestaurants: [],
  allUsers: [],
  getAllRestaurantsStatus: REQUEST_UNDEFINED,
  getAllUsersStatus: REQUEST_UNDEFINED,
  allSuppliers: [],
  getAllSuppliersStatus: REQUEST_UNDEFINED,
  linkVenueSupplierStatus: REQUEST_UNDEFINED,
  unLinkVenueSupplierStatus: REQUEST_UNDEFINED,
  singleRestaurant: {},
  getSingleRestaurantStatus: REQUEST_UNDEFINED,
  getUnnaprovedUsersStatus: REQUEST_UNDEFINED,
  unnaprovedUsers: [],
  allUsersOfVenue: [],
  getAllUsersOfVenueStatus: REQUEST_UNDEFINED,
  toggleVenueStatus: REQUEST_UNDEFINED,
  disableUserStatus: REQUEST_UNDEFINED,
  verifyUserSuperAdminStatus: REQUEST_UNDEFINED,
  resendVerifyEmailStatus: REQUEST_UNDEFINED,
  linkSupplierAndDepartmentStatus: REQUEST_UNDEFINED,
  unLinkSupplierAndDepartmentStatus: REQUEST_UNDEFINED,
  getAllArticlesSupplierStatus: REQUEST_UNDEFINED,
  allArticlesSupplier: [],
  allArticlesCount: 0,
  getAllArticleCategoriesStatus: REQUEST_UNDEFINED,
  allCategories: [],
  disableEmailNotificationsForUserStatus: REQUEST_UNDEFINED,
  manuallyVerifyEmailStatus: REQUEST_UNDEFINED,
};
export const admin = {
  namespaced: true,
  state: initialState,
  actions: {
    getAllRestaurants({ commit }) {
      commit("getAllRestaurantsPending");
      return AdminService.getAllRestaurants().then(
        (response) => {
          commit("getAllRestaurantsSuccess", response.data);
          return Promise.resolve(response);
        },
        (error) => {
          commit("getAllRestaurantsFailure");
          return Promise.reject(error);
        }
      );
    },
    getSuppliers({ commit }) {
      commit("getAllSuppliersPending");
      return AdminService.getAllSuppliersAPI().then(
        (response) => {
          commit("getAllSuppliersSuccess", response.data);
          return Promise.resolve(response);
        },
        (error) => {
          commit("getAllSuppliersFailure");
          return Promise.reject(error);
        }
      );
    },
    linkVenueSupplier({ commit }, payload) {
      commit("linkVenueSupplierPending");
      return AdminService.postLinkVenueSupplier(
        payload.venueID,
        payload.supplierID
      ).then(
        (response) => {
          commit("linkVenueSupplierSuccess", response.data);
          return Promise.resolve(response);
        },
        (error) => {
          commit("linkVenueSupplierFailure");
          return Promise.reject(error);
        }
      );
    },
    unLinkVenueSupplier({ commit }, payload) {
      commit("unLinkVenueSupplierPending");
      return AdminService.postUnlinkVenueSupplier(
        payload.venueID,
        payload.supplierID
      ).then(
        (response) => {
          commit("unLinkVenueSupplierSuccess", response.data);
          return Promise.resolve(response);
        },
        (error) => {
          commit("unLinkVenueSupplierFailure");
          return Promise.reject(error);
        }
      );
    },
    getAllUsers({ commit }) {
      commit("getAllUsersPending");
      return AdminService.getAllUsersService().then(
        (response) => {
          commit("getAllUsersSuccess", response.data);
          return Promise.resolve(response);
        },
        (error) => {
          commit("getAllUsersFailure");
          return Promise.reject(error);
        }
      );
    },
    getSingleRestaurant({ commit }, id) {
      commit("getSingleRestaurantPending");
      return AdminService.getSingleRestaurantService(id).then(
        (response) => {
          commit("getSingleRestaurantSuccess", response.data);
          return Promise.resolve(response);
        },
        (error) => {
          commit("getSingleRestaurantFailure");
          return Promise.reject(error);
        }
      );
    },
    clearRestaurantData({ commit }) {
      commit("clearRestaurant");
    },

    getUnnaprovedUsers({ commit }) {
      commit("getUnnaprovedUsersPending");
      return AdminService.getUnnaprovedUsersService().then(
        (response) => {
          commit("getUnnaprovedUsersSuccess", response.data);
          return Promise.resolve(response);
        },
        (error) => {
          commit("getUnnaprovedUsersFailure");
          return Promise.reject(error);
        }
      );
    },

    getAllUsersForVenueRequest({ commit }, venueId) {
      commit("getAllUsersForVenuePending");
      return AdminService.getAllUsersForVenueService(venueId).then(
        (response) => {
          commit("getAllUsersForVenueSuccess", response.data);
          return Promise.resolve(response);
        },
        (error) => {
          commit("getAllUsersForVenueFailure");
          return Promise.reject(error);
        }
      );
    },

    toggleVenueStatus({ commit }, venueId) {
      commit("toggleVenueStatusPending");
      return AdminService.toggleVenueStatusService(venueId).then(
        (response) => {
          commit("toggleVenueStatusSuccess");
          return Promise.resolve(response);
        },
        (error) => {
          commit("toggleVenueStatusFailure");
          return Promise.reject(error);
        }
      );
    },
    disableUser({ commit }, userId) {
      commit("disableUserStatusPending");
      return AdminService.disableUserService(userId).then(
        (response) => {
          commit("disableUserStatusSuccess");
          return Promise.resolve(response);
        },
        (error) => {
          commit("disableUserStatusError");
          return Promise.reject(error);
        }
      );
    },
    disableEmailNotificationsForUser({ commit }, userId) {
      commit("disableEmailNotificationsForUserPending");
      return AdminService.disableEmailNotificationsForUser(userId).then(
        (response) => {
          commit("disableEmailNotificationsForUserSuccess");
          return Promise.resolve(response);
        },
        (error) => {
          commit("disableEmailNotificationsForUserError");
          return Promise.reject(error);
        }
      );
    },
    verifyUserSuperAdmin({ commit }, payload) {
      commit("verifyUserSuperAdminPending");
      return AdminService.verifyUserSuperAdmin(payload).then(
        (response) => {
          commit("verifyUserSuperAdminSuccess");
          return Promise.resolve(response);
        },
        (error) => {
          commit("verifyUserSuperAdminError");
          return Promise.reject(error);
        }
      );
    },
    resendVerifyEmail({ commit }, email) {
      commit("resendVerifyEmailPending");
      return AdminService.resendVerifyEmail(email).then(
        (response) => {
          commit("resendVerifyEmailSuccess");
          return Promise.resolve(response);
        },
        (error) => {
          commit("resendVerifyEmailError");
          return Promise.reject(error);
        }
      );
    },
    linkSupplierAndDepartment({ commit }, payload) {
      commit("linkSupplierAndDepartmentPending");
      return AdminService.linkSupplierAndDepartment(payload).then(
        (response) => {
          commit("linkSupplierAndDepartmentSuccess");
          return Promise.resolve(response);
        },
        (error) => {
          commit("linkSupplierAndDepartmentError");
          return Promise.reject(error);
        }
      );
    },
    unLinkSupplierAndDepartment({ commit }, payload) {
      commit("unLinkSupplierAndDepartmentPending");
      return AdminService.unLinkSupplierAndDepartment(payload).then(
        (response) => {
          commit("unLinkSupplierAndDepartmentSuccess");
          return Promise.resolve(response);
        },
        (error) => {
          commit("unLinkSupplierAndDepartmentError");
          return Promise.reject(error);
        }
      );
    },
    getAllArticlesSupplier({ commit }, payload) {
      commit("getAllArticlesSupplierPending");
      return AdminService.getAllArticlesSupplier(payload).then(
        (response) => {
          commit("getAllArticlesSupplierSuccess", response.data);
          return Promise.resolve(response);
        },
        (error) => {
          commit("getAllArticlesSupplierError");
          return Promise.reject(error);
        }
      );
    },

    getAllArticleCategories({ commit }) {
      commit("getAllArticleCategoriesPending");
      return AdminService.getAllArticleCategories().then(
        (response) => {
          commit("getAllArticleCategoriesSuccess", response.data);
          return Promise.resolve(response);
        },
        (error) => {
          commit("getAllArticleCategoriesError");
          return Promise.reject(error);
        }
      );
    },

    manuallyVerifyEmail({ commit }, payload) {
      commit("manuallyVerifyEmailPending");
      return AdminService.manuallyVerifyEmail(payload).then(
        (response) => {
          commit("manuallyVerifyEmailSuccess", response.data);
          return Promise.resolve(response);
        },
        (error) => {
          commit("manuallyVerifyEmailError");
          return Promise.reject(error);
        }
      );
    },
  },
  mutations: {
    getAllRestaurantsPending(state) {
      state.getAllRestaurantsStatus = REQUEST_PENDING;
    },
    getAllRestaurantsSuccess(state, payload) {
      state.allRestaurants = payload;
      state.getAllRestaurantsStatus = REQUEST_SUCCESS;
    },
    getAllRestaurantsFailure(state) {
      state.getAllRestaurantsStatus = REQUEST_FAILURE;
    },
    getAllSuppliersPending(state) {
      state.getAllSuppliersStatus = REQUEST_PENDING;
    },
    getAllSuppliersSuccess(state, payload) {
      state.allSuppliers = payload;
      state.getAllSuppliersStatus = REQUEST_SUCCESS;
    },
    getAllSuppliersFailure(state) {
      state.getAllSuppliersStatus = REQUEST_FAILURE;
    },
    linkVenueSupplierPending(state) {
      state.linkVenueSupplierStatus = REQUEST_PENDING;
    },
    linkVenueSupplierSuccess(state) {
      state.linkVenueSupplierStatus = REQUEST_SUCCESS;
    },
    linkVenueSupplierFailure(state) {
      state.linkVenueSupplierStatus = REQUEST_FAILURE;
    },
    unLinkVenueSupplierPending(state) {
      state.unLinkVenueSupplierStatus = REQUEST_PENDING;
    },
    unLinkVenueSupplierSuccess(state) {
      state.unLinkVenueSupplierStatus = REQUEST_SUCCESS;
    },
    unLinkVenueSupplierFailure(state) {
      state.unLinkVenueSupplierStatus = REQUEST_FAILURE;
    },
    getAllUsersPending(state) {
      state.getAllUsersStatus = REQUEST_PENDING;
    },
    getAllUsersSuccess(state, payload) {
      state.allUsers = payload;
      state.getAllUsersStatus = REQUEST_SUCCESS;
    },
    getAllUsersFailure(state) {
      state.getAllUsersStatus = REQUEST_FAILURE;
    },
    getSingleRestaurantPending(state) {
      state.getSingleRestaurantStatus = REQUEST_PENDING;
    },
    getSingleRestaurantSuccess(state, payload) {
      state.singleRestaurant = payload;
      state.getSingleRestaurantStatus = REQUEST_SUCCESS;
    },
    getSingleRestaurantFailure(state) {
      state.getSingleRestaurantStatus = REQUEST_FAILURE;
    },
    getUnnaprovedUsersPending(state) {
      state.getUnnaprovedUsersStatus = REQUEST_PENDING;
    },
    getUnnaprovedUsersSuccess(state, payload) {
      state.unnaprovedUsers = payload;
      state.getUnnaprovedUsersStatus = REQUEST_SUCCESS;
    },
    getUnnaprovedUsersFailure(state) {
      state.getUnnaprovedUsersStatus = REQUEST_FAILURE;
    },
    getAllUsersForVenuePending(state) {
      state.getAllUsersOfVenueStatus = REQUEST_PENDING;
    },
    getAllUsersForVenueSuccess(state, payload) {
      state.allUsersOfVenue = payload;
      state.getAllUsersOfVenueStatus = REQUEST_SUCCESS;
    },
    getAllUsersForVenueFailure(state) {
      state.getAllUsersOfVenueStatus = REQUEST_FAILURE;
    },
    clearRestaurant(state) {
      state.singleRestaurant = {};
    },

    toggleVenueStatusPending(state) {
      state.toggleVenueStatus = REQUEST_PENDING;
    },
    toggleVenueStatusSuccess(state) {
      state.toggleVenueStatus = REQUEST_SUCCESS;
    },
    toggleVenueStatusFailure(state) {
      state.toggleVenueStatus = REQUEST_FAILURE;
    },

    disableUserStatusPending(state) {
      state.disableUserStatus = REQUEST_PENDING;
    },
    disableUserStatusSuccess(state) {
      state.disableUserStatus = REQUEST_SUCCESS;
    },
    disableUserStatusError(state) {
      state.disableUserStatus = REQUEST_FAILURE;
    },

    disableEmailNotificationsForUserPending(state) {
      state.disableEmailNotificationsForUserStatus = REQUEST_PENDING;
    },
    disableEmailNotificationsForUserSuccess(state) {
      state.disableEmailNotificationsForUserStatus = REQUEST_SUCCESS;
    },
    disableEmailNotificationsForUserError(state) {
      state.disableEmailNotificationsForUserStatus = REQUEST_FAILURE;
    },

    verifyUserSuperAdminPending(state) {
      state.verifyUserSuperAdminStatus = REQUEST_PENDING;
    },
    verifyUserSuperAdminSuccess(state) {
      state.verifyUserSuperAdminStatus = REQUEST_SUCCESS;
    },
    verifyUserSuperAdminError(state) {
      state.verifyUserSuperAdminStatus = REQUEST_FAILURE;
    },

    resendVerifyEmailPending(state) {
      state.resendVerifyEmailStatus = REQUEST_PENDING;
    },
    resendVerifyEmailSuccess(state) {
      state.resendVerifyEmailStatus = REQUEST_SUCCESS;
    },
    resendVerifyEmailError(state) {
      state.resendVerifyEmailStatus = REQUEST_FAILURE;
    },

    linkSupplierAndDepartmentPending(state) {
      state.resendVerifyEmailStatus = REQUEST_PENDING;
    },
    linkSupplierAndDepartmentSuccess(state) {
      state.resendVerifyEmailStatus = REQUEST_SUCCESS;
    },
    linkSupplierAndDepartmentError(state) {
      state.resendVerifyEmailStatus = REQUEST_FAILURE;
    },

    unLinkSupplierAndDepartmentPending(state) {
      state.unLinkSupplierAndDepartmentStatus = REQUEST_PENDING;
    },
    unLinkSupplierAndDepartmentSuccess(state) {
      state.unLinkSupplierAndDepartmentStatus = REQUEST_SUCCESS;
    },
    unLinkSupplierAndDepartmentError(state) {
      state.unLinkSupplierAndDepartmentStatus = REQUEST_FAILURE;
    },

    getAllArticlesSupplierPending(state) {
      state.getAllArticlesSupplierStatus = REQUEST_PENDING;
    },
    getAllArticlesSupplierSuccess(state, payload) {
      state.getAllArticlesSupplierStatus = REQUEST_SUCCESS;
      state.allArticlesSupplier = payload.articles;
      state.allArticlesCount = payload.count;
    },
    getAllArticlesSupplierError(state) {
      state.getAllArticlesSupplierStatus = REQUEST_FAILURE;
    },

    getAllArticleCategoriesPending(state) {
      state.getAllArticleCategoriesStatus = REQUEST_PENDING;
    },
    getAllArticleCategoriesSuccess(state, payload) {
      state.getAllArticleCategoriesStatus = REQUEST_SUCCESS;
      state.allCategories = payload;
    },
    getAllArticleCategoriesError(state) {
      state.getAllArticleCategoriesStatus = REQUEST_FAILURE;
    },

    manuallyVerifyEmailPending(state) {
      state.manuallyverifyEmailStatus = REQUEST_PENDING;
    },
    manuallyVerifyEmailSuccess(state) {
      state.manuallyverifyEmailStatus = REQUEST_SUCCESS;
    },
    manuallyVerifyEmailError(state) {
      state.manuallyverifyEmailStatus = REQUEST_FAILURE;
    },
  },
  getters: {
    getRestaurantsStatus: (state) => {
      return state.getAllRestaurantsStatus;
    },
    getAllRestaurants: (state) => {
      return state.allRestaurants;
    },
    getAllSuppliers: (state) => {
      return state.allSuppliers;
    },
    getAllSuppliersStatus: (state) => {
      return state.getAllSuppliersStatus;
    },
    getLinkVenueSupplierStatus: (state) => {
      return state.linkVenueSupplierStatus;
    },
    getUnLinkVenueSupplierStatus: (state) => {
      return state.unLinkVenueSupplierStatus;
    },
    getAllUsers: (state) => {
      return state.allUsers;
    },
    getAllUsersStatus: (state) => {
      return state.getAllUsersStatus;
    },
    getSingleRestaurant: (state) => {
      return state.singleRestaurant;
    },
    getSingleRestaurantStatus: (state) => {
      return state.getSingleRestaurantStatus;
    },
    getUnnaprovedUsers: (state) => {
      return state.unnaprovedUsers;
    },
    getUnnaprovedUsersStatus: (state) => {
      return state.getUnnaprovedUsersStatus;
    },
    getAllUsersOfVenue: (state) => {
      return state.allUsersOfVenue;
    },
    getAllUsersOfVenueStatus: (state) => {
      return state.getAllUsersOfVenueStatus;
    },
    getToggleVenueStatus: (state) => {
      return state.toggleVenueStatus;
    },
    getAllArticlesSupplier: (state) => {
      return state.allArticlesSupplier;
    },
    getAllArticlesSupplierStatus: (state) => {
      return state.getAllArticlesSupplierStatus;
    },
    getTotalArticlesCount: (state) => {
      return state.allArticlesCount;
    },
    getAllCategories: (state) => {
      return state.allCategories;
    },
    getDisableEmailNotificationsForUserStatus: (state) => {
      return state.disableEmailNotificationsForUserStatus;
    },
    getManuallyVerifyEmailStatus: (state) => {
      return state.manuallyverifyEmailStatus
    }
  },
};
