<template>
  <modal-component
    :dialog="dialog"
    @onClose="closePreviewDialog"
    :modalWidth="'540px'"
    style="min-height: 332px"
  >
    <div class="preview-modal" v-if="selectedSupplier">
      <span class="title">
        {{ $t("suppliers.listContacts") }} -
        {{ selectedSupplier && selectedSupplier.name }}
      </span>
      <loader-component
        v-if="allSupplierContactsStatus === 'REQUEST_PENDING'"
      ></loader-component>
      <v-data-table
        v-else
        :headers="headers"
        :items="allSupplierContacts"
        :loading-text="$t('common.loadingTableText')"
        :hide-default-footer="true"
      >
        <template v-slot:item.name="{ item }">
          <div v-if="item.showEditItem">
            <v-text-field
              v-model="contactForEditing.name"
              type="text"
            ></v-text-field>
          </div>
          <span v-else>{{ item.name }}</span>
        </template>
        <template v-slot:item.phone="{ item }">
          <div v-if="item.showEditItem">
            <v-text-field
              v-model="contactForEditing.phone"
              type="text"
            ></v-text-field>
          </div>
          <span v-else>{{
            item.phone && item.phone[0] === "0" ? item.phone : "0" + item.phone
          }}</span>
        </template>
        <template v-slot:item.email="{ item }">
          <div v-if="item.showEditItem">
            <v-text-field
              v-model="contactForEditing.email"
              type="text"
            ></v-text-field>
          </div>
          <span v-else>{{ item.email }}</span>
        </template>
        <template v-slot:item.actions="{ item }">
          <div class="d-flex align-center" v-if="item.showDeleteAlert">
            <div>
              <span>{{ $t("suppliers.deleteContactAlert") }}</span>
            </div>
            <div class="d-flex pt-1 ml-4">
              <v-btn
                small
                dark
                color="accent"
                @click="onYesDeleteContact(item)"
                >{{ $t("suppliers.yes") }}</v-btn
              >
              <v-btn
                class="ml-1"
                small
                dark
                color="accent"
                @click="onNoDeleteContact(item)"
                >{{ $t("suppliers.no") }}</v-btn
              >
            </div>
          </div>
          <td v-if="item.showEditItem">
            <div class="d-flex pt-1">
              <v-btn
                class="mx-2"
                fab
                small
                dark
                color="accent"
                height="35px"
                width="35px"
                @click="onSaveEditedContact()"
                ><v-icon dark> mdi-content-save-outline </v-icon></v-btn
              >
              <v-btn
                class="mx-2"
                fab
                small
                dark
                color="negative"
                height="35px"
                width="35px"
                @click="onCancelEditingContact(item)"
                ><v-icon dark> mdi-close </v-icon></v-btn
              >
            </div>
          </td>
          <div v-if="!item.showDeleteAlert && !item.showEditItem">
            <v-btn
              v-if="isAdmin"
              class="mx-2"
              fab
              small
              dark
              color="accent"
              height="35px"
              width="35px"
              @click="editContact(item)"
            >
              <v-icon dark> mdi-pencil </v-icon>
            </v-btn>
            <v-btn
              v-if="isAdmin"
              class="mx-2"
              fab
              small
              dark
              color="negative"
              height="35px"
              width="35px"
              @click="deleteContact(item)"
            >
              <v-icon dark> mdi-delete </v-icon>
            </v-btn>
          </div>
        </template>
      </v-data-table>
    </div>
  </modal-component>
</template>
<script>
import { mapGetters } from "vuex";
import ModalComponent from "../components/ModalComponent.vue";
import LoaderComponent from "../components/LoaderComponent.vue";
import EventBus from "../shared/EventBus";

export default {
  name: "PreviewSupplierContactsModal",
  components: { ModalComponent, LoaderComponent },
  props: {
    dialog: {},
    supplier: {},
    isAdmin: {},
  },
  data: () => ({
    contactForEditing: null,
    selectedSupplier: {},
    headers: [],
  }),
  mounted() {
    this.$store.dispatch("userProfile/getAllSupplierContacts", {
      supplierId: this.supplier.id,
      venueId: this.getVenue.id,
    });
    this.headers = [
      { text: this.$t("common.name"), value: "name", sortable: false },
      { text: this.$t("common.phone"), value: "phone", sortable: false },
      { text: this.$t("common.email"), value: "email", sortable: false },
      { text: "", value: "actions", sortable: false },
    ];
    this.$watch(
      (vm) => [vm.supplier, vm.allSuppliers],
      () => {
        if (this.supplier && this.allSuppliers) {
          this.allSuppliers.filter((s, index) => {
            if (s.id === this.supplier.id) {
              this.selectedSupplier = this.allSuppliers[index];
              this.$forceUpdate();
            }
          });
        }
      }
    );
  },
  created() {
    this.getSuppliers &&
      this.supplier &&
      this.getSuppliers.filter((s) => {
        if (s.id === this.supplier.id) {
          this.selectedSupplier = s;
        }
      });
  },
  methods: {
    editContact(contact) {
      this.allSupplierContacts.filter((c) => {
        this.$set(c, "showEditItem", false);
        this.$set(c, "showDeleteAlert", false);
      });

      this.contactForEditing = JSON.parse(JSON.stringify(contact));
      this.contactForEditing.phone =
        this.contactForEditing.phone[0] === "0"
          ? this.contactForEditing.phone
          : "0" + this.contactForEditing.phone;
      this.$set(contact, "showEditItem", true);
    },
    deleteContact(contact) {
      this.allSupplierContacts.filter((c) => {
        this.$set(c, "showEditItem", false);
        this.$set(c, "showDeleteAlert", false);
      });
      this.$set(contact, "showDeleteAlert", true);
    },
    onNoDeleteContact(contact) {
      this.$set(contact, "showDeleteAlert", false);
    },
    onYesDeleteContact(contact) {
      contact.supplierId = this.selectedSupplier.id;
      this.$store.dispatch("supplier/deleteContactSupplier", contact).then(
        () => {
          this.allSupplierContacts.filter((c) => {
            this.$set(c, "showEditItem", false);
            this.$set(c, "showDeleteAlert", false);
          });
          // this.$store.dispatch("userProfile/getUserProfile");
          // this.$store.dispatch("userProfile/getVenueSuppliers");
          this.$store.dispatch("userProfile/getAllSupplierContacts", {
            supplierId: this.selectedSupplier.id,
            venueId: this.getVenue.id,
          });

          EventBus.dispatch("showSnackbar", {
            text: this.$t("notifications.contactDeleted"),
          });
        },
        (error) => {
          console.log("error", error);
        }
      );
    },
    onSaveEditedContact() {
      this.contactForEditing.supplierId = this.selectedSupplier.id;
      this.$store
        .dispatch("supplier/updateContactSupplier", this.contactForEditing)
        .then(
          () => {
            this.allSupplierContacts.filter((c) => {
              this.$set(c, "showEditItem", false);
              this.$set(c, "showDeleteAlert", false);
            });
            // this.$store.dispatch("userProfile/getUserProfile");
            // this.$store.dispatch("userProfile/getVenueSuppliers");
            this.$store.dispatch("userProfile/getAllSupplierContacts", {
              supplierId: this.selectedSupplier.id,
              venueId: this.getVenue.id,
            });

            EventBus.dispatch("showSnackbar", {
              text: this.$t("notifications.contactUpdated"),
            });
          },
          (error) => {
            console.log("error", error);
          }
        );
    },
    onCancelEditingContact(contact) {
      this.contactForEditing = null;
      this.$set(contact, "showEditItem", false);
    },
    clearData() {
      this.contactForEditing = null;
      this.allSupplierContacts &&
        this.allSupplierContacts.filter((c) => {
          this.$set(c, "showEditItem", false);
          this.$set(c, "showDeleteAlert", false);
        });
    },
    closePreviewDialog() {
      this.clearData();
      this.$emit("onClose");
    },
  },
  computed: {
    ...mapGetters({
      getSuppliers: "supplier/getDepartmentSuppliers",
      allSupplierContacts: "userProfile/allSupplierContacts",
      allSupplierContactsStatus: "userProfile/allSupplierContactsStatus",
      getVenue: "userProfile/getVenue",
    }),
    allSuppliers() {
      return this.getSuppliers;
    },
    allContacts() {
      return this.allSupplierContacts && this.allSupplierContacts.contacts;
    },
    venue() {
      return this.getVenue;
    },
  },
};
</script>
<style lang="scss" scoped>
.preview-modal {
  // display: flex;
  // flex-direction: column;
  width: 100%;
  // align-items: flex-start;
}
.article-list {
  // text-align: start;
  // width: 100%;
}
.article-item {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
</style>
