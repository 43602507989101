<template>
  <modal-component
    :dialog="showGoingToOrderWarningModal"
    @close="onCloseGoingToOrderWarningModal"
    :hideClosing="false"
  >
    <div>
      <div class="text-h6 font-weight-black">
        {{ $t(orderToMerge ? "newOrder.mergeOrder" : "newOrder.leavingOrder") }}
      </div>
      <div
        class="text-subtitle-2 secondaryDark2--text pt-0 pb-0 pl-8 pr-8 mt-3"
      >
        {{
          $t(
            orderToMerge
              ? "newOrder.mergeOrderDesc"
              : "newOrder.leavingOrderDesc"
          )
        }}
      </div>

      <v-btn class="mt-6" @click="onCancel">{{ $t("newOrder.cancel") }}</v-btn>
      <v-btn class="mt-6 ml-3" color="accent" @click="onConfirm">{{
        $t("newOrder.thatIsFine")
      }}</v-btn>
    </div>
  </modal-component>
</template>
<script>
import ModalComponent from "../../../components/ModalComponent.vue";
export default {
  name: "ChangeMergeWarningModal",
  components: { ModalComponent },
  props: {
    showGoingToOrderWarningModal: {},
    hideClosing: {},
    orderToMerge: {},
  },
  data: () => ({}),
  mounted() {},
  created() {},
  methods: {
    onCloseGoingToOrderWarningModal() {
      this.$emit("onClose");
    },
    onCancel() {
      this.$emit("onCancel");
    },
    onConfirm() {
      this.$emit("onConfirm");
    },
  },
};
</script>
