import api from "../api";
import {
  GET_ALL_RESTAURANTS_URL,
  GET_ALL_SUPPLIERS_URL,
  linkVenueSupplierURL,
  unlinkVenueSupplierURL,
  GET_ALL_USERS_URL,
  getSingleRestaurant,
  UNNAPROVED_USERS_URL,
  getAlUsersVenue,
  toggleVenueStatusURL,
  disableUserURL,
  verifyUserSuperAdminURL,
  resendVerifyEmailURL,
  linkSupplierAndDepartmentURL,
  unLinkSupplierAndDepartmentURL,
  getAllArticlesSupplierAdminURL,
  getAllArticleCategoriesURL,
  disableEmailNotificationsForUserURL,
  manuallyVerifyEmail,
} from "../../shared/endpoints";

class AdminService {
  getAllRestaurants() {
    return api.get(GET_ALL_RESTAURANTS_URL);
  }
  getAllSuppliersAPI() {
    return api.get(GET_ALL_SUPPLIERS_URL);
  }
  postLinkVenueSupplier(venueID, supplierID) {
    return api.post(linkVenueSupplierURL(venueID, supplierID));
  }
  postUnlinkVenueSupplier(venueID, supplierID) {
    return api.post(unlinkVenueSupplierURL(venueID, supplierID));
  }
  getAllUsersService() {
    return api.get(GET_ALL_USERS_URL);
  }
  getSingleRestaurantService(id) {
    return api.get(getSingleRestaurant(id));
  }
  getUnnaprovedUsersService() {
    return api.get(UNNAPROVED_USERS_URL);
  }
  getAllUsersForVenueService(venueId) {
    return api.get(getAlUsersVenue(venueId));
  }
  toggleVenueStatusService(id) {
    return api.post(toggleVenueStatusURL(id));
  }
  disableUserService(id) {
    return api.post(disableUserURL(id));
  }
  disableEmailNotificationsForUser(id) {
    return api.post(disableEmailNotificationsForUserURL(id));
  }
  verifyUserSuperAdmin(payload) {
    return api.post(verifyUserSuperAdminURL(payload.venueId, payload.userId));
  }
  resendVerifyEmail(email) {
    return api.post(resendVerifyEmailURL, { email });
  }
  linkSupplierAndDepartment(payload) {
    return api.post(
      linkSupplierAndDepartmentURL(payload.departmentId, payload.supplierId)
    );
  }
  unLinkSupplierAndDepartment(payload) {
    return api.post(
      unLinkSupplierAndDepartmentURL(payload.departmentId, payload.supplierId)
    );
  }
  getAllArticlesSupplier(payload) {
    return api.get(
      getAllArticlesSupplierAdminURL(
        payload.supplierId,
        payload.pageNum,
        payload.pageSize,
        payload.searchQuery,
        payload.categoryId
      )
    );
  }
  getAllArticleCategories() {
    return api.get(getAllArticleCategoriesURL());
  }
  manuallyVerifyEmail(email) {
    return api.post(manuallyVerifyEmail(email));
  }
}

export default new AdminService();
