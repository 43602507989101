import api from "./api";
import {
  createStationURL,
  getAllDepartmentsURL,
  linkUserAndDepartment,
  unlinkUserAndDepartment,
  editDepartmentURL,
  deleteDepartmentURL,
} from "../shared/endpoints";

class StationsService {
  createStation(data) {
    let formData = new FormData();
    formData.append("name", data.stationName);
    return api.post(createStationURL(data.venueID), formData);
  }
  getAllDepartmentStations(venueId) {
    return api.get(getAllDepartmentsURL(venueId));
  }
  linkUserAndDepartmentService(payload) {
    return api.post(
      linkUserAndDepartment(payload.userId, payload.departmentId)
    );
  }
  unLinkUserAndDepartmentService(payload) {
    return api.post(
      unlinkUserAndDepartment(payload.userId, payload.departmentId)
    );
  }
  editStationService(payload) {
    let formData = new FormData();
    formData.append("name", payload.editStationName);
    return api.post(editDepartmentURL(payload.departmentId), formData);
  }
  deleteStationService(payload) {
    return api.delete(deleteDepartmentURL(payload.id));
  }
}
export default new StationsService();
